// THIS FILE IS GENERATED, DO NOT EDIT!, run yarn graphql:codegen:types to update
import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AddBeneficiaryResponse": [
      "AddBeneficiaryErrorResponse",
      "AddedBeneficiary"
    ],
    "AddBeneficiaryWithMailResponse": [
      "AddBeneficiaryErrorResponse",
      "AddedBeneficiaryResult"
    ],
    "AddBeneficiaryWithMailResponseV2": [
      "AddBeneficiaryErrorResponse",
      "AddBeneficiaryErrorResponseV2",
      "AddBeneficiaryWarningResponse",
      "AddedBeneficiaryResult"
    ],
    "AdminImplemAddResult": [
      "GestionError",
      "PacEmpty"
    ],
    "AdminImplemCancellationResult": [
      "GestionError",
      "PacEmpty"
    ],
    "AdminImplemDownload": [
      "GestionError",
      "MultipartBody"
    ],
    "AdminImplemMigrationResult": [
      "AdminImplemMigration",
      "GestionError"
    ],
    "AdminImplemStartResult": [
      "GestionError",
      "PacEmpty"
    ],
    "AnswerCommunicationSurveyResult": [
      "AnswerCommunicationSurvey",
      "CommunicationError"
    ],
    "AutonomousSubscriptionResponseNew": [
      "AutonomousSubscriptionError",
      "AutonomousSubscriptionValidResponse"
    ],
    "AutonomousSubscriptionResponseV2": [
      "AutonomousSubscriptionError",
      "AutonomousSubscriptionResponse"
    ],
    "AutonomousUnsubscriptionResponse": [
      "AutonomousUnsubscriptionError",
      "AutonomousUnsubscriptionValidResponse"
    ],
    "Banner": [
      "AdBanner",
      "CategoryBanner",
      "ShopBanner"
    ],
    "BaseUser": [
      "AuthenticatedUser",
      "Beneficiary"
    ],
    "CardCombinationResponse": [
      "CardCombinationResult",
      "GiftError"
    ],
    "CardRefundResponse": [
      "CardRefundResult",
      "GiftError"
    ],
    "CardsProposalResponse": [
      "CardsProposalResult",
      "GiftError"
    ],
    "ChangePasswordResponse": [
      "Beneficiary",
      "ChangePasswordErrorResponse"
    ],
    "CheckNewsletterCampaignNameResult": [
      "Empty",
      "NewsletterError"
    ],
    "CommunicationCampaignInterface": [
      "CommunicationCampaign",
      "CommunicationCampaignWithBeneficiaryView"
    ],
    "CreateBankAccountResponse": [
      "BankAccount",
      "CreateBankAccountError"
    ],
    "CreateCommunicationFileCampaignResult": [
      "CommunicationCampaign",
      "CommunicationError"
    ],
    "CreateCommunicationNewsCampaignResult": [
      "CommunicationCampaign",
      "CommunicationError"
    ],
    "CreateCommunicationSurveyCampaignResult": [
      "CommunicationCampaign",
      "CommunicationError"
    ],
    "CreationSiteResult": [
      "SiteError",
      "SiteInfo"
    ],
    "DeleteNewsletterCampaignResult": [
      "Empty",
      "NewsletterError"
    ],
    "DeleteNewsletterTargetingRuleResult": [
      "Empty",
      "NewsletterError"
    ],
    "DeleteReasonResponse": [
      "DeleteReasonResult",
      "GiftError"
    ],
    "DetachedBeneficiaryResponse": [
      "DetachedBeneficiary",
      "DetachedBeneficiaryErrorResponse"
    ],
    "EventHistoryResponse": [
      "EventHistoryError",
      "EventHistoryPage"
    ],
    "ExecuteRecipientTargetingRuleResult": [
      "Empty",
      "NewsletterError"
    ],
    "ExternalAccountResult": [
      "AccountingError",
      "ExternalAccount"
    ],
    "FoodAuditing": [
      "FoodOrderLine",
      "FoodOrderV2"
    ],
    "FoodIdListResponse": [
      "FoodBaseError",
      "FoodIdList"
    ],
    "FoodPageResult": [
      "FoodOrderLinePage",
      "FoodOrderPage"
    ],
    "FoodPageResultV2": [
      "FoodReloadSummaryPage"
    ],
    "FoodReloadFileValidationResponse": [
      "FoodBaseError",
      "Task"
    ],
    "FoodReloadSummaryErrorsResponse": [
      "FoodBaseError",
      "FoodReloadSummaryErrors"
    ],
    "FoodReloadSummaryResponse": [
      "FoodBaseError",
      "FoodReloadSummaryPage"
    ],
    "FoodReloadSummarySingleResponse": [
      "FoodBaseError",
      "FoodReloadSummary"
    ],
    "FoodTaskResponse": [
      "FoodBaseError",
      "FoodTask"
    ],
    "FoodTaskResponseV2": [
      "FoodBaseError",
      "Task"
    ],
    "FoodTotalResponse": [
      "FoodBaseError",
      "FoodTotal"
    ],
    "FranceBilletSsoResponse": [
      "FranceBilletSsoLink",
      "GiftError"
    ],
    "GenericDistribution": [
      "OrganizationDistribution",
      "UserDistribution"
    ],
    "GetCommunicationCampaignResult": [
      "CommunicationCampaign",
      "CommunicationError"
    ],
    "GetCommunicationFileTrackingResult": [
      "CommunicationError",
      "CommunicationFileTracking"
    ],
    "GetCommunicationFileUrlResult": [
      "CommunicationError",
      "CommunicationFileUrl"
    ],
    "GetCommunicationTrackingResult": [
      "CommunicationError",
      "CommunicationTracking"
    ],
    "GetCommunicationUserSurveyAnswersResult": [
      "CommunicationError",
      "GetCommunicationUserSurveyAnswers"
    ],
    "GetNewsletterAccountResult": [
      "NewsletterAccount",
      "NewsletterError"
    ],
    "GetNewsletterCampaignParametersResult": [
      "NewsletterCampaignParameters",
      "NewsletterError"
    ],
    "GetNewsletterCanvasResult": [
      "NewsletterCanvas",
      "NewsletterError"
    ],
    "GetNewsletterGuideUrlResult": [
      "NewsletterError",
      "NewsletterGuideUrl"
    ],
    "GetNewsletterRecipientCountResult": [
      "NewsletterError",
      "NewsletterRecipientCount"
    ],
    "GetNewsletterRecipientTargetingRuleResult": [
      "GetNewsletterRecipientTargetingRule",
      "NewsletterError"
    ],
    "GetNewsletterTemplateListResult": [
      "NewsletterError",
      "NewsletterTemplateList"
    ],
    "GetNewsletterTemplatePreviewResult": [
      "NewsletterError",
      "NewsletterTemplatePreview"
    ],
    "GetSiteResult": [
      "SiteError",
      "SiteInfo"
    ],
    "GiftFileResponse": [
      "GiftError",
      "GiftFile"
    ],
    "GiftMailPreviewResponse": [
      "GiftError",
      "GiftMailPreview"
    ],
    "GiftOrderResponse": [
      "GiftError",
      "GiftOrder"
    ],
    "GiftTaskResponse": [
      "GiftError",
      "GiftTask"
    ],
    "GiftTaskResultResponse": [
      "GiftBooleanTaskResult",
      "GiftError",
      "GiftErrors",
      "GiftFileTaskResult",
      "GiftShareTaskResult",
      "GiftShareValidationTaskResult"
    ],
    "IGiftError": [
      "GiftError",
      "GiftErrors"
    ],
    "LastDistributionOrigin": [
      "Beneficiary",
      "Organization"
    ],
    "ListAccVoucherResult": [
      "AccountingError",
      "ListAccVoucher"
    ],
    "ListCommunicationCampaignResult": [
      "CommunicationError",
      "ListCommunicationCampaign"
    ],
    "ListCommunicationCampaignsWithBeneficiaryViewResult": [
      "CommunicationError",
      "ListCommunicationCampaignsWithBeneficiaryView"
    ],
    "ListNewsletterDraftCampaignResult": [
      "NewsletterError",
      "NewsletterListDraftCampaigns"
    ],
    "ListNewsletterSentCampaignResult": [
      "NewsletterError",
      "NewsletterListSentCampaigns"
    ],
    "NewsletterCampaignResult": [
      "NewsletterCampaign",
      "NewsletterError"
    ],
    "NewsletterTrackingReportResult": [
      "NewsletterError",
      "NewsletterTrackingReport"
    ],
    "NewsletterTrackingResult": [
      "NewsletterError",
      "NewsletterTracking"
    ],
    "PacArchiveAdditionalDataSchemaResult": [
      "GestionError",
      "PacEmpty"
    ],
    "PacArchiveUserGroupOutput": [
      "GestionError",
      "PacEmpty"
    ],
    "PacBankDetailsResult": [
      "GestionError",
      "PacBankDetails"
    ],
    "PacBatchDownload": [
      "GestionError",
      "MultipartBody"
    ],
    "PacBatchFileResult": [
      "GestionError",
      "PacBatchJob"
    ],
    "PacBenefit": [
      "PacCultureBenefit",
      "PacGiftBenefit"
    ],
    "PacCancelEpRequestResult": [
      "GestionError",
      "PacEpRequest"
    ],
    "PacCreateAdsResult": [
      "GestionError",
      "PacAdditionalDataSchema"
    ],
    "PacCreateBenefitOutput": [
      "GestionError",
      "PacCreateBenefitResult"
    ],
    "PacCreateRoResult": [
      "GestionError",
      "PacEmpty"
    ],
    "PacCreateUserGroupOutput": [
      "GestionError",
      "PacUserGroup"
    ],
    "PacDeleteAdditionalDataSchemaResult": [
      "GestionError",
      "PacEmpty"
    ],
    "PacDeleteBankDetailsResult": [
      "GestionError",
      "PacEmpty"
    ],
    "PacDeleteRightsOwnerResult": [
      "GestionError",
      "PacEmpty"
    ],
    "PacDestributionExportResult": [
      "GestionError",
      "PacBatchJobs"
    ],
    "PacDistributionErrorReport": [
      "DistributionErrorReport",
      "GestionError"
    ],
    "PacEndowmentConfiguration": [
      "PacTargetedEndowmentConfiguration",
      "PacUntargetedEndowmentConfiguration"
    ],
    "PacEpCreateTargetingRuleResult": [
      "GestionError",
      "PacEpTargetingRule"
    ],
    "PacEpInitTargetingCalculation": [
      "GestionError",
      "PacEpInitTargetingCalculationResponse"
    ],
    "PacEpPollTargetingCalculation": [
      "GestionError",
      "PacEpPollTargetingCalculationResponse"
    ],
    "PacHasDependentChildrenResult": [
      "GestionError",
      "PacHasDependentChildren"
    ],
    "PacManagerDeleteRightsOwnerResult": [
      "GestionError",
      "PacEmpty"
    ],
    "PacManagerUpdateAdResult": [
      "GestionError",
      "PacEmpty"
    ],
    "PacManagerUpdateRoResult": [
      "GestionError",
      "PacEmpty"
    ],
    "PacOrgaEpCompletionRate": [
      "GestionError",
      "PacOrgaEpCompletionRateResponse"
    ],
    "PacPerkExportResult": [
      "GestionError",
      "PacPerkBatchJobs"
    ],
    "PacPerkResult": [
      "GestionError",
      "PacPerk"
    ],
    "PacPerkUtilisationResult": [
      "GestionError",
      "PacPerkUtilisation"
    ],
    "PacPublishUserGroupOutput": [
      "GestionError",
      "PacEmpty"
    ],
    "PacRefuseFillingAdResult": [
      "GestionError",
      "PacAdditionalData"
    ],
    "PacRightOwnerConfResult": [
      "GestionError",
      "PacRightsOwnerConf"
    ],
    "PacRightsOwner": [
      "PacRightsOwnerChild",
      "PacRightsOwnerPartner",
      "PacRightsOwnerSimple"
    ],
    "PacUpdateAdResult": [
      "GestionError",
      "PacUpdateAdResponse"
    ],
    "PacUpdateAdsResult": [
      "GestionError",
      "PacAdditionalDataSchema"
    ],
    "PacUpdateRoResult": [
      "GestionError",
      "PacEpRequest"
    ],
    "PacUpdateUserGroupOutput": [
      "GestionError",
      "PacEmpty"
    ],
    "PacUserEpCompletionRate": [
      "GestionError",
      "PacUserEpCompletionRateResponse"
    ],
    "PacUserExtendedProfile": [
      "PacUserExtendedProfileBenef",
      "PacUserExtendedProfileForManager"
    ],
    "PacValidateRequestResult": [
      "GestionError",
      "PacEpRequest"
    ],
    "PageResult": [
      "CardMultiShopTransactionPage",
      "DepositTransactionResult",
      "DistributionReasonPage",
      "DpsAssignedCreditsPage",
      "EmailPage",
      "ExtendedCampaignPage",
      "GenericDistributionPage",
      "GiftOrderPage",
      "OfferWallShopPage",
      "PurchasedCardPage",
      "SharedDistributionPage",
      "ShopPackagePage",
      "WalletConfigurationPage",
      "WalletPage"
    ],
    "ParsingError": [
      "GlobalParsingError",
      "LineParsingError"
    ],
    "PaymentResult": [
      "GiftError",
      "Payment"
    ],
    "PublishCommunicationCampaignResult": [
      "CommunicationCampaign",
      "CommunicationError"
    ],
    "PurchaseResult": [
      "GiftError",
      "PurchaseResponse"
    ],
    "PurchasedCard": [
      "CardMultiShop",
      "StandardCard"
    ],
    "ReasonResponse": [
      "DistributionReason",
      "GiftError"
    ],
    "SaveNewsletterCanvasResult": [
      "Empty",
      "NewsletterError"
    ],
    "SaveNewsletterRecipientTargetingRuleResult": [
      "NewsletterError",
      "SaveNewsletterRecipientTargetingRule"
    ],
    "SearchNewsletterUnsplashImagesResult": [
      "NewsletterError",
      "NewsletterUnsplashSearchImages"
    ],
    "SendCommunicationTestMailResult": [
      "CommunicationError",
      "Empty"
    ],
    "SendNewsletterCampaignResult": [
      "Empty",
      "NewsletterError"
    ],
    "SendNewsletterTestResult": [
      "Empty",
      "NewsletterError"
    ],
    "SetCommunicationPreferencesResult": [
      "CommunicationError",
      "CommunicationPreference"
    ],
    "SetNewsletterRecipientScopeResult": [
      "NewsletterCampaignParameters",
      "NewsletterError"
    ],
    "ShareResult": [
      "DistributionDetail",
      "GiftError"
    ],
    "ShippingFeesResponse": [
      "GiftError",
      "Money"
    ],
    "ShopFee": [
      "GenericShopFee",
      "OrganizationShopFee"
    ],
    "SingleUseTokenResponse": [
      "SingleUseToken",
      "SingleUseTokenErrorResponse"
    ],
    "StatusAdditionalData": [
      "ArchiveReason"
    ],
    "UpdateBankAccountResponse": [
      "BankAccount",
      "UpdateBankAccountError"
    ],
    "UpdateCommunicationBeneficiaryViewStatusResult": [
      "CommunicationBeneficiaryViewStatus",
      "CommunicationError"
    ],
    "UpdateCommunicationFileCampaignResult": [
      "CommunicationCampaign",
      "CommunicationError"
    ],
    "UpdateCommunicationNewsCampaignResult": [
      "CommunicationCampaign",
      "CommunicationError"
    ],
    "UpdateCommunicationSurveyCampaignResult": [
      "CommunicationCampaign",
      "CommunicationError"
    ],
    "UpdateCreateAddressResponse": [
      "AuthenticatedUser",
      "Organization"
    ],
    "UpdateOrganizationMailLogoResult": [
      "Organization",
      "UpdateLogoError",
      "UpdateOrganizationMailLogoError"
    ],
    "UpdatePreferenceLogoResult": [
      "Preference",
      "UpdateLogoError"
    ],
    "UpdatedBeneficiaryResponse": [
      "Beneficiary",
      "UpdateBeneficiaryErrorResponse"
    ],
    "UpdatedUserResponse": [
      "Beneficiary",
      "UpdateUserErrorResponse"
    ],
    "UploadCommunicationFileResult": [
      "CommunicationError",
      "CommunicationFile"
    ],
    "UploadCommunicationImageResult": [
      "CommunicationError",
      "CommunicationImage"
    ],
    "UploadNewsletterImageResult": [
      "NewsletterError",
      "NewsletterImageUploaded"
    ],
    "VoucherFileResult": [
      "AccountingError",
      "VoucherFile"
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Base64EncodedBinary: { input: string; output: string; }
  /** An arbitrary precision signed decimal */
  BigDecimal: { input: number; output: number; }
  /** A slightly refined version of RFC-3339 compliant DateTime Scalar */
  DateTime: { input: string; output: string; }
  /** A Java LocalDate type */
  LocalDate: { input: any; output: any; }
  /** A 64-bit signed integer */
  Long: { input: number; output: number; }
  /** A custom scalar that represents files */
  Upload: { input: any; output: any; }
  Void: { input: any; output: any; }
};

export type AccAmount = {
  __typename?: 'AccAmount';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type AccPaginationInput = {
  id: Scalars['String']['input'];
  issueDate: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
};

export type AccVoucher = {
  __typename?: 'AccVoucher';
  amount: AccAmount;
  completionDate: Scalars['String']['output'];
  issueDate: Scalars['String']['output'];
  productDesignation: Scalars['String']['output'];
  sourceAddress: Scalars['String']['output'];
  sourceId: Scalars['String']['output'];
  sourceName: Scalars['String']['output'];
  targetAddress?: Maybe<Scalars['String']['output']>;
  targetId: Scalars['String']['output'];
  targetName: Scalars['String']['output'];
  voucherId: Scalars['String']['output'];
};

export type AccVoucherPagination = {
  __typename?: 'AccVoucherPagination';
  id: Scalars['String']['output'];
  issueDate: Scalars['String']['output'];
  sourceId: Scalars['String']['output'];
};

export enum AccountStatus {
  Completed = 'COMPLETED',
  NotAuthorized = 'NOT_AUTHORIZED',
  PendingValidation = 'PENDING_VALIDATION',
  Rejected = 'REJECTED',
  Uncompleted = 'UNCOMPLETED'
}

export type AccountingError = {
  __typename?: 'AccountingError';
  description?: Maybe<Scalars['String']['output']>;
  errorCodes: Array<Scalars['String']['output']>;
};

export type ActivateCustomerCardQueryInput = {
  beneficiaryId: Scalars['ID']['input'];
  cardNumberValidation: Scalars['ID']['input'];
};

export type AdBanner = Banner & {
  __typename?: 'AdBanner';
  id: Scalars['Long']['output'];
  imageUrl: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type AddAddressesInfoRequest = {
  billingAddressId?: InputMaybe<Scalars['String']['input']>;
  deliveryContact?: InputMaybe<DeliveryContact>;
  orderId?: InputMaybe<Scalars['String']['input']>;
  shippingAddressId?: InputMaybe<Scalars['String']['input']>;
};

export type AddAnonymousBeneficiariesRequest = {
  addWithSafeLink: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type AddAnonymousBeneficiariesResponse = {
  __typename?: 'AddAnonymousBeneficiariesResponse';
  errors?: Maybe<Array<AddAnonymousBeneficiariesResponseError>>;
};

export enum AddAnonymousBeneficiariesResponseError {
  BadOrgId = 'BAD_ORG_ID',
  BadQuantity = 'BAD_QUANTITY',
  Unknown = 'UNKNOWN'
}

export type AddBeneficiariesWithSafeLinkRequest = {
  organizationId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type AddBeneficiariesWithSafeLinkResponse = {
  __typename?: 'AddBeneficiariesWithSafeLinkResponse';
  errors?: Maybe<Array<AddAnonymousBeneficiariesResponseError>>;
};

export enum AddBeneficiaryError {
  AlreadyBeneficiary = 'ALREADY_BENEFICIARY',
  BirthdateFormat = 'BIRTHDATE_FORMAT',
  BirthdateFuture = 'BIRTHDATE_FUTURE',
  CheckRightError = 'CHECK_RIGHT_ERROR',
  EmailFormat = 'EMAIL_FORMAT',
  EmailMandatory = 'EMAIL_MANDATORY',
  EmailSize = 'EMAIL_SIZE',
  FirstnameFormat = 'FIRSTNAME_FORMAT',
  FirstnameMandatory = 'FIRSTNAME_MANDATORY',
  FirstnameSize = 'FIRSTNAME_SIZE',
  GenderFormat = 'GENDER_FORMAT',
  LastnameFormat = 'LASTNAME_FORMAT',
  LastnameMandatory = 'LASTNAME_MANDATORY',
  LastnameSize = 'LASTNAME_SIZE',
  LocaleMandatory = 'LOCALE_MANDATORY',
  MobilePhoneFormat = 'MOBILE_PHONE_FORMAT',
  MobilePhoneTooMuchDuplicates = 'MOBILE_PHONE_TOO_MUCH_DUPLICATES',
  SettingValueFormat = 'SETTING_VALUE_FORMAT',
  SettingValueMandatory = 'SETTING_VALUE_MANDATORY',
  Unknown = 'UNKNOWN',
  UnknownLocale = 'UNKNOWN_LOCALE',
  UnknownSetting = 'UNKNOWN_SETTING',
  UuidMandatory = 'UUID_MANDATORY'
}

export type AddBeneficiaryErrorResponse = {
  __typename?: 'AddBeneficiaryErrorResponse';
  error?: Maybe<AddBeneficiaryError>;
};

export type AddBeneficiaryErrorResponseV2 = {
  __typename?: 'AddBeneficiaryErrorResponseV2';
  error?: Maybe<AddBeneficiaryErrorV2>;
};

export enum AddBeneficiaryErrorV2 {
  NoEmailOrLogin = 'NO_EMAIL_OR_LOGIN'
}

export type AddBeneficiaryResponse = AddBeneficiaryErrorResponse | AddedBeneficiary;

export enum AddBeneficiaryWarning {
  BeneficiaryWithDifferentLoginSameEmail = 'BENEFICIARY_WITH_DIFFERENT_LOGIN_SAME_EMAIL',
  BeneficiaryWithSameLoginDifferentEmail = 'BENEFICIARY_WITH_SAME_LOGIN_DIFFERENT_EMAIL'
}

export type AddBeneficiaryWarningResponse = {
  __typename?: 'AddBeneficiaryWarningResponse';
  warning?: Maybe<AddBeneficiaryWarning>;
};

export type AddBeneficiaryWithMailResponse = AddBeneficiaryErrorResponse | AddedBeneficiaryResult;

export type AddBeneficiaryWithMailResponseV2 = AddBeneficiaryErrorResponse | AddBeneficiaryErrorResponseV2 | AddBeneficiaryWarningResponse | AddedBeneficiaryResult;

export type AddedBeneficiary = {
  __typename?: 'AddedBeneficiary';
  birthDate?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type AddedBeneficiaryResult = {
  __typename?: 'AddedBeneficiaryResult';
  beneficiary: AddedBeneficiary;
  invitationMailSent: Scalars['Boolean']['output'];
};

export type AdditionalData = {
  __typename?: 'AdditionalData';
  editRole?: Maybe<AdditionalDataRole>;
  enumValues?: Maybe<Array<Scalars['String']['output']>>;
  name: Scalars['String']['output'];
  regexValue?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum AdditionalDataRole {
  Beneficiary = 'BENEFICIARY',
  Manager = 'MANAGER'
}

export type AdditionalInformation = {
  __typename?: 'AdditionalInformation';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type Address = {
  __typename?: 'Address';
  /** The address data id */
  addressId?: Maybe<Scalars['ID']['output']>;
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** The attachment id */
  id: Scalars['ID']['output'];
  isAttachedToUndeletable?: Maybe<Scalars['Boolean']['output']>;
  isProcessed?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  type?: Maybe<AddressTypeGql>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type AddressData = {
  __typename?: 'AddressData';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  locality?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum AddressTypeGql {
  Billing = 'BILLING',
  Shipping = 'SHIPPING'
}

export type AdminImplemAddResult = GestionError | PacEmpty;

export type AdminImplemCancellationResult = GestionError | PacEmpty;

export type AdminImplemDownload = GestionError | MultipartBody;

export type AdminImplemFileImport = {
  __typename?: 'AdminImplemFileImport';
  filePath: Scalars['String']['output'];
  priority: Scalars['Int']['output'];
  resultFilePath?: Maybe<Scalars['String']['output']>;
  resultTimestamp?: Maybe<Scalars['Float']['output']>;
  startTimestamp?: Maybe<Scalars['Float']['output']>;
  status: Scalars['String']['output'];
  totalLines?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type AdminImplemMigration = {
  __typename?: 'AdminImplemMigration';
  fileImports: Array<AdminImplemFileImport>;
  uuid: Scalars['String']['output'];
};

export type AdminImplemMigrationCancellationInput = {
  fileImport: Scalars['ID']['input'];
  uuid: Scalars['ID']['input'];
};

export type AdminImplemMigrationResult = AdminImplemMigration | GestionError;

export type AdminImplemStartResult = GestionError | PacEmpty;

export type AdminSharingRequest = {
  amount: MoneyInput;
  receiverUuid: Scalars['ID']['input'];
  zendeskUrl: Scalars['String']['input'];
};

export enum Advantage {
  AdvantageAsc = 'ADVANTAGE_ASC',
  AdvantageIct = 'ADVANTAGE_ICT',
  AdvantageReductions = 'ADVANTAGE_REDUCTIONS',
  AdvantageRh = 'ADVANTAGE_RH',
  AdvantageSaas = 'ADVANTAGE_SAAS',
  AdvantageUnknown = 'ADVANTAGE_UNKNOWN'
}

export type AllOrdersQueryInput = {
  orderStatus: Array<InputMaybe<OrderStatusType>>;
  orderTypes: Array<InputMaybe<OrderType>>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort: SortInput;
};

export type Amount = {
  __typename?: 'Amount';
  grossDiscountAmount?: Maybe<Money>;
  unitAmountWithoutTax?: Maybe<Money>;
};

export type Amounts = {
  __typename?: 'Amounts';
  grossDiscountAmount?: Maybe<Money>;
  quantity?: Maybe<Scalars['Int']['output']>;
  taxAmount?: Maybe<Money>;
  totalAmountWithoutTax?: Maybe<Money>;
  unitAmountWithoutTax?: Maybe<Money>;
  unitOfMeasure?: Maybe<PaperPriceCatalogUnitOfMeasure>;
};

export type AnswerCommunicationSurvey = {
  __typename?: 'AnswerCommunicationSurvey';
  answer?: Maybe<SurveyAnswer>;
};

export type AnswerCommunicationSurveyResult = AnswerCommunicationSurvey | CommunicationError;

export type ArchiveCompanyRequest = {
  archiveReasonId: Scalars['ID']['input'];
  companyId: Scalars['ID']['input'];
  customReason?: InputMaybe<Scalars['String']['input']>;
};

export type ArchiveReason = {
  __typename?: 'ArchiveReason';
  customReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type AssociatePaymentMethodReponse = {
  __typename?: 'AssociatePaymentMethodReponse';
  id: Scalars['ID']['output'];
  success: Scalars['Boolean']['output'];
};

export enum AssociationType {
  AssociationTypeFoodShipping = 'ASSOCIATION_TYPE_FOOD_SHIPPING',
  AssociationTypeOrgaBilling = 'ASSOCIATION_TYPE_ORGA_BILLING',
  AssociationTypeOrgaLocation = 'ASSOCIATION_TYPE_ORGA_LOCATION',
  AssociationTypeOrgaShipping = 'ASSOCIATION_TYPE_ORGA_SHIPPING',
  AssociationTypeUnspecified = 'ASSOCIATION_TYPE_UNSPECIFIED',
  AssociationTypeUserBilling = 'ASSOCIATION_TYPE_USER_BILLING',
  AssociationTypeUserLocation = 'ASSOCIATION_TYPE_USER_LOCATION',
  AssociationTypeUserShipping = 'ASSOCIATION_TYPE_USER_SHIPPING'
}

export enum AsyncPaymentMethod {
  BankTransfer = 'BANK_TRANSFER',
  Check = 'CHECK'
}

export type AttachUserLocationAddressRequest = {
  beneficiaryId: Scalars['ID']['input'];
  currentUserLocationAddressId?: InputMaybe<Scalars['ID']['input']>;
  locationAddressType: Scalars['Int']['input'];
  organizationId: Scalars['ID']['input'];
  referencedAddressId?: InputMaybe<Scalars['ID']['input']>;
};

export type AttachUserLocationAddressResponse = {
  __typename?: 'AttachUserLocationAddressResponse';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type AuthenticatedUser = BaseUser & {
  __typename?: 'AuthenticatedUser';
  /** the beneficiary organizations data */
  allOrganizationSpecificData?: Maybe<Array<UserOrganizationSpecificData>>;
  balance: Money;
  balances?: Maybe<Array<Balance>>;
  bankDetails: PacBankDetailsResult;
  bankingCards?: Maybe<Array<BankingCard>>;
  beneficiary?: Maybe<Beneficiary>;
  billingAddress?: Maybe<Address>;
  birthDate?: Maybe<Scalars['String']['output']>;
  communicationPreference?: Maybe<Array<Maybe<CommunicationPreference>>>;
  dpsAssignedCredits: DpsAssignedCreditsPage;
  dpsNearestExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  dtsUiRights: DtsUiRights;
  endowmentDetail: EndowmentDetail;
  endowments?: Maybe<Array<Endowment>>;
  extendedProfiles: Array<PacUserExtendedProfile>;
  /** Check if a given feature flag is active for the user */
  featureIsActive?: Maybe<Scalars['Boolean']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  foodIsUserWalletExists?: Maybe<Scalars['Boolean']['output']>;
  franceBilletSsoLink?: Maybe<FranceBilletSsoResponse>;
  genericDistributions?: Maybe<GenericDistributionPage>;
  hasBankDetails: Scalars['Boolean']['output'];
  hasDeposit: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isAdmin: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  login: Scalars['String']['output'];
  mail?: Maybe<Scalars['String']['output']>;
  managerOf?: Maybe<Array<Organization>>;
  memberOf?: Maybe<Array<Organization>>;
  perkUtilisations: PacPerkUtilisationsSearch;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  purchasedCards?: Maybe<PurchasedCardPage>;
  savedAmount?: Maybe<Money>;
  sharedDistributions?: Maybe<SharedDistributionPage>;
  sharingConfig?: Maybe<SharingConfig>;
  shippingAddress?: Maybe<Address>;
  supportRoles?: Maybe<Array<SupportRole>>;
  tasks?: Maybe<Array<Maybe<Task>>>;
  uiAdminParametersRights: UiAdminParametersRights;
  uiGiftActionRights?: Maybe<UiGiftActionRights>;
  uiImplemRights: UiImplemRights;
  uiRights: UiRights;
  wedoositeinfo?: Maybe<WedoositeInfo>;
  wedoositesInfo?: Maybe<Array<Maybe<WedoositeInfo>>>;
};


export type AuthenticatedUserBalanceArgs = {
  benefit: BenefitCanonicalName;
};


export type AuthenticatedUserDpsAssignedCreditsArgs = {
  filtersAndSort?: InputMaybe<DpsAssignedCreditsFiltersAndSort>;
  page: PageRequest;
};


export type AuthenticatedUserDpsNearestExpirationDateArgs = {
  benefit: BenefitCanonicalName;
};


export type AuthenticatedUserEndowmentDetailArgs = {
  id: Scalars['Long']['input'];
};


export type AuthenticatedUserFeatureIsActiveArgs = {
  featureName: Scalars['ID']['input'];
};


export type AuthenticatedUserFranceBilletSsoLinkArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


export type AuthenticatedUserGenericDistributionsArgs = {
  request: PageRequest;
  shareableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  specs?: InputMaybe<GiftGenericDistributionSpec>;
};


export type AuthenticatedUserPerkUtilisationsArgs = {
  searchParams?: InputMaybe<PacPerkUtilisationsSearchSelfParams>;
};


export type AuthenticatedUserPurchasedCardsArgs = {
  request: PageRequest;
  specs?: InputMaybe<Scalars['String']['input']>;
};


export type AuthenticatedUserSharedDistributionsArgs = {
  request: PageRequest;
};


export type AuthenticatedUserSupportRolesArgs = {
  onSubjectId?: InputMaybe<Scalars['ID']['input']>;
};


export type AuthenticatedUserWedoositeinfoArgs = {
  orgId: Scalars['ID']['input'];
};

export type AutocompleteAddressRequest = {
  autocompleteText: Scalars['String']['input'];
  coordinateX?: InputMaybe<Scalars['Float']['input']>;
  coordinateY?: InputMaybe<Scalars['Float']['input']>;
  countryIsoCode: Scalars['String']['input'];
  resultCount: Scalars['Int']['input'];
};

export type AutocompleteCityResponse = {
  __typename?: 'AutocompleteCityResponse';
  city: Scalars['String']['output'];
  id: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type AutocompleteResponse = {
  __typename?: 'AutocompleteResponse';
  addressId: Scalars['String']['output'];
  addressLine: Scalars['String']['output'];
};

export type AutonomousSubscription = {
  __typename?: 'AutonomousSubscription';
  accountId?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  cancelReason?: Maybe<CancelReason>;
  contractEffectiveDate?: Maybe<Scalars['String']['output']>;
  contractedMrr?: Maybe<Scalars['String']['output']>;
  currentTermPeriodType?: Maybe<CurrentTermPeriodType>;
  moduleCode?: Maybe<Scalars['String']['output']>;
  nextBillingDate?: Maybe<Scalars['String']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  productRatePlanId?: Maybe<Scalars['String']['output']>;
  ratePlanId?: Maybe<Scalars['String']['output']>;
  ratePlanName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  subscriptionNumber?: Maybe<Scalars['String']['output']>;
  termEndDate?: Maybe<Scalars['String']['output']>;
  termStartDate?: Maybe<Scalars['String']['output']>;
  termType?: Maybe<TermType>;
};

export type AutonomousSubscriptionError = {
  __typename?: 'AutonomousSubscriptionError';
  error: Scalars['String']['output'];
};

export type AutonomousSubscriptionRequest = {
  moduleCode: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type AutonomousSubscriptionResponse = {
  __typename?: 'AutonomousSubscriptionResponse';
  subscriptionId: Scalars['ID']['output'];
};

export type AutonomousSubscriptionResponseNew = AutonomousSubscriptionError | AutonomousSubscriptionValidResponse;

/** delete next mep TODO */
export type AutonomousSubscriptionResponseV2 = AutonomousSubscriptionError | AutonomousSubscriptionResponse;

export type AutonomousSubscriptionValidResponse = {
  __typename?: 'AutonomousSubscriptionValidResponse';
  subscriptionId: Scalars['ID']['output'];
};

export type AutonomousUnsubscriptionError = {
  __typename?: 'AutonomousUnsubscriptionError';
  error: Scalars['String']['output'];
};

export type AutonomousUnsubscriptionRequest = {
  moduleCode: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type AutonomousUnsubscriptionResponse = AutonomousUnsubscriptionError | AutonomousUnsubscriptionValidResponse;

export type AutonomousUnsubscriptionValidResponse = {
  __typename?: 'AutonomousUnsubscriptionValidResponse';
  moduleCode: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
};

export type AvailableEndowment = {
  __typename?: 'AvailableEndowment';
  available: Scalars['Boolean']['output'];
  editable: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  organizations: Array<EndowmentProvider>;
  remainingAmount?: Maybe<Money>;
  wallet: Wallet;
};

export type Balance = {
  __typename?: 'Balance';
  amount: Money;
  benefit: BenefitCanonicalName;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  accountName: Scalars['String']['output'];
  accountOwner: Scalars['String']['output'];
  bic: Scalars['String']['output'];
  defaultAccount?: Maybe<Scalars['Boolean']['output']>;
  iban: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
};

export type BankInfos = {
  __typename?: 'BankInfos';
  bic?: Maybe<Scalars['String']['output']>;
  holderName?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  pattern?: Maybe<Scalars['String']['output']>;
};

export type BankingCard = {
  __typename?: 'BankingCard';
  bin?: Maybe<Scalars['String']['output']>;
  /** @deprecated use brands instead */
  brand: BankingCardBrand;
  brands: Array<BankingCardBrand>;
  creationDate: Scalars['DateTime']['output'];
  expirationMonth: Scalars['Int']['output'];
  expirationYear: Scalars['Int']['output'];
  id: Scalars['Long']['output'];
  last4: Scalars['String']['output'];
  /** @deprecated use last 4 instead */
  lastFourDigits: Scalars['Int']['output'];
};

export enum BankingCardBrand {
  CartesBancaires = 'CARTES_BANCAIRES',
  Mastercard = 'MASTERCARD',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export type Banner = {
  id: Scalars['Long']['output'];
  imageUrl: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export enum BannerType {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE'
}

export type BaseUser = {
  birthDate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  login: Scalars['String']['output'];
  mail?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type BeneficiariesPage = {
  __typename?: 'BeneficiariesPage';
  index: Scalars['Int']['output'];
  items: Array<Maybe<Beneficiary>>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type BeneficiariesQueryInput = {
  /** Filter by mail invitation */
  invited?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  /** A string to filter the beneficiaries with names or emails matching. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Filter the status */
  searchStatusList?: InputMaybe<Array<InputMaybe<SearchStatus>>>;
  sortParameters?: InputMaybe<BeneficiariesSortParameters>;
  /** Filter the beneficiaries that have at least one of the given tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BeneficiariesSortParameters = {
  /** The sort order. Default is DESC */
  isSortDesc?: InputMaybe<Scalars['Boolean']['input']>;
  orderedSortFields?: InputMaybe<Array<SortFieldsBeneficiariesQuery>>;
};

export type BeneficiariesToDetach = {
  benefiaries?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationUuid: Scalars['String']['input'];
};

export type BeneficiariesToInvite = {
  beneficiaries?: InputMaybe<Array<Scalars['String']['input']>>;
  invitationType?: InputMaybe<InvitationType>;
  organizationUuid: Scalars['String']['input'];
};

export type Beneficiary = BaseUser & {
  __typename?: 'Beneficiary';
  /**
   * All the additional data of a beneficiary
   * @deprecated use organizationSpecificData instead
   */
  additionalData?: Maybe<Array<AdditionalData>>;
  /** the beneficiary organizations */
  allOrganizationSpecificData?: Maybe<Array<UserOrganizationSpecificData>>;
  /** All the roles of the beneficiary */
  allRoles?: Maybe<Array<UserRole>>;
  /** The beneficiary auth status */
  authStatus?: Maybe<Scalars['String']['output']>;
  /** The beneficiary auth status reason */
  authStatusReason?: Maybe<Scalars['String']['output']>;
  /** @deprecated Field no longer supported */
  automaticReminderCount?: Maybe<Scalars['Int']['output']>;
  /**  For admin support specific usage */
  availableEndowments?: Maybe<Array<AvailableEndowment>>;
  bankDetails: PacBankDetailsResult;
  billingAddress?: Maybe<Address>;
  /** The beneficiary birthdate */
  birthDate?: Maybe<Scalars['String']['output']>;
  blockedCause?: Maybe<Scalars['String']['output']>;
  /** Right to modify users passwords */
  canEditPassword?: Maybe<PasswordConditionResponse>;
  /** The beneficiary creation date */
  creationDate?: Maybe<Scalars['String']['output']>;
  /** The current number of failed OTP */
  dailyOtpCount?: Maybe<Scalars['Int']['output']>;
  dpsAssignedCredits: DpsAssignedCreditsPage;
  extendedProfile: PacUserExtendedProfile;
  /**
   * The external reference (also called registration number) of the beneficiary
   * @deprecated use organizationSpecificData instead
   */
  externalReference?: Maybe<Scalars['String']['output']>;
  /** The beneficiary first connection date */
  firstConnectionDate?: Maybe<Scalars['String']['output']>;
  /** The beneficiary firstName */
  firstName?: Maybe<Scalars['String']['output']>;
  /**  Beneficiary food cards */
  foodCards?: Maybe<Array<Maybe<CustomerCard>>>;
  foodShippingAddress?: Maybe<Address>;
  genericDistributions?: Maybe<GenericDistributionPage>;
  hasBankDetails: Scalars['Boolean']['output'];
  /** The beneficiary technical id */
  id: Scalars['ID']['output'];
  /** The status of the last invitation mail send to the beneficiary */
  invitationMailStatus?: Maybe<Scalars['String']['output']>;
  /** Either the biometric status of the user is set as `Enabled` or not */
  isBiometryEnabled?: Maybe<Scalars['Boolean']['output']>;
  isFromWeDooGift: Scalars['Boolean']['output'];
  /** The beneficiary last connection date */
  lastConnectionDate?: Maybe<Scalars['String']['output']>;
  lastDistributionOrigin?: Maybe<LastDistributionOrigin>;
  /** The beneficiary lastName */
  lastName?: Maybe<Scalars['String']['output']>;
  /** The locale language used by the beneficiary */
  locale?: Maybe<Scalars['String']['output']>;
  locationAddress?: Maybe<Address>;
  /** The beneficiary login */
  login: Scalars['String']['output'];
  /** The beneficiary mail */
  mail?: Maybe<Scalars['String']['output']>;
  mails?: Maybe<MailPage>;
  offerWallShops?: Maybe<Array<OfferWallShop>>;
  organizationSpecificData?: Maybe<UserOrganizationSpecificData>;
  /** The beneficiary phone number */
  phoneNumber?: Maybe<Scalars['String']['output']>;
  purchasedCards?: Maybe<PurchasedCardPage>;
  /**  Internal number in the client organization */
  registrationNumber?: Maybe<Scalars['String']['output']>;
  /** All the roles of the beneficiary for an organization */
  roles?: Maybe<Array<Scalars['String']['output']>>;
  sharedDistributions?: Maybe<SharedDistributionPage>;
  shippingAddress?: Maybe<Address>;
  siteAccess: Scalars['Boolean']['output'];
  situations: PacPerkSituationList;
  /** The beneficiary status */
  status?: Maybe<Scalars['String']['output']>;
  /**
   * All the tags of a beneficiary
   * @deprecated use organizationSpecificData instead
   */
  tags?: Maybe<Array<Scalars['String']['output']>>;
  /** The current number of failed OTP */
  twoFactorAuthenticationStatus?: Maybe<TwoFactorAuthenticationStatus>;
  uiGiftActionRights?: Maybe<UiGiftActionRightsOnBeneficiary>;
  /** All the different fields the current User can modify on a beneficiary profile */
  uiRights: UiEditRightsOnBeneficiary;
  /**  For admin support specific usage */
  walletAccesses?: Maybe<Array<OrganizationWallet>>;
};


export type BeneficiaryAdditionalDataArgs = {
  organizationId: Scalars['ID']['input'];
};


export type BeneficiaryDpsAssignedCreditsArgs = {
  page: PageRequest;
};


export type BeneficiaryExtendedProfileArgs = {
  orgaId: Scalars['ID']['input'];
};


export type BeneficiaryExternalReferenceArgs = {
  organizationId: Scalars['ID']['input'];
};


export type BeneficiaryGenericDistributionsArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  request: PageRequest;
  shareableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  specs?: InputMaybe<GiftGenericDistributionSpec>;
};


export type BeneficiaryInvitationMailStatusArgs = {
  organizationId: Scalars['ID']['input'];
};


export type BeneficiaryLocationAddressArgs = {
  organizationUuid: Scalars['ID']['input'];
};


export type BeneficiaryMailsArgs = {
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};


export type BeneficiaryOfferWallShopsArgs = {
  id: Scalars['Long']['input'];
};


export type BeneficiaryOrganizationSpecificDataArgs = {
  organizationId: Scalars['ID']['input'];
};


export type BeneficiaryPurchasedCardsArgs = {
  request: PageRequest;
  specs?: InputMaybe<Scalars['String']['input']>;
};


export type BeneficiaryRolesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type BeneficiarySharedDistributionsArgs = {
  request: PageRequest;
};


export type BeneficiarySiteAccessArgs = {
  organizationId: Scalars['ID']['input'];
};


export type BeneficiarySituationsArgs = {
  orgaId: Scalars['ID']['input'];
  searchParams?: InputMaybe<PacPerksSearchParams>;
};


export type BeneficiaryTagsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type BeneficiaryWalletAccessesArgs = {
  organizationId: Scalars['ID']['input'];
};

export type BeneficiaryData = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  externalRef?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  invitationType?: InputMaybe<InvitationType>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  login?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  sendInvitationMail?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BeneficiaryDataV2 = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  externalRef?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  ignoreWarnings: Scalars['Boolean']['input'];
  invitationType?: InputMaybe<InvitationType>;
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  language: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  login?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
  sendInvitationMail?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BeneficiaryEnableSite = {
  active: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type BeneficiaryIdsQueryInput = {
  /** Filter by mail invitation */
  invited?: InputMaybe<Scalars['Boolean']['input']>;
  orgId: Scalars['ID']['input'];
  /** A string to filter the beneficiaries with names or emails matching. */
  search?: InputMaybe<Scalars['String']['input']>;
  /** Filter the status */
  searchStatusList?: InputMaybe<Array<InputMaybe<SearchStatus>>>;
  /** Filter the beneficiaries that have at least one of the given tags */
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type BeneficiaryPaymentRequest = {
  creditCardId?: InputMaybe<Scalars['Long']['input']>;
  hostUrl: Scalars['String']['input'];
  preferredScheme?: InputMaybe<Scalars['String']['input']>;
  proposal: ProposalPurchaseRequest;
  returnPath: Scalars['String']['input'];
  saveCreditCard?: InputMaybe<Scalars['Boolean']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  walletId: Scalars['Long']['input'];
};

export type BeneficiaryToDetach = {
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type Benefit = {
  __typename?: 'Benefit';
  canonicalName: BenefitCanonicalName;
  defaultDuration?: Maybe<Period>;
  expires: Scalars['Boolean']['output'];
  hasEvents: Scalars['Boolean']['output'];
  moduleCode: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export enum BenefitCanonicalName {
  CultureCheck = 'CULTURE_CHECK',
  GiftCheck = 'GIFT_CHECK',
  Unrecognized = 'UNRECOGNIZED'
}

export type BillingAddress = {
  __typename?: 'BillingAddress';
  addressLine1: Scalars['String']['output'];
  addressLine2?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  countryIsoCode: Scalars['String']['output'];
  label?: Maybe<Scalars['String']['output']>;
  recipient: Scalars['String']['output'];
  zipCode: Scalars['String']['output'];
};

export type BillingGroupOption = {
  __typename?: 'BillingGroupOption';
  amounts?: Maybe<Amounts>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<GroupType>;
};

export type BillingInvoice = {
  __typename?: 'BillingInvoice';
  bytes?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
};

export type BillingLetterTemplateOption = {
  __typename?: 'BillingLetterTemplateOption';
  amounts?: Maybe<Amounts>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BillingLogoOption = {
  __typename?: 'BillingLogoOption';
  amounts?: Maybe<Amounts>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<LogoType>;
};

export type BillingMessageOption = {
  __typename?: 'BillingMessageOption';
  amounts?: Maybe<Amounts>;
  message?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BillingMyGiftAdvantageOption = {
  __typename?: 'BillingMyGiftAdvantageOption';
  amounts?: Maybe<Amounts>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BillingNominativeVoucherOption = {
  __typename?: 'BillingNominativeVoucherOption';
  amounts?: Maybe<Amounts>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BillingOptions = {
  __typename?: 'BillingOptions';
  groupOption?: Maybe<BillingGroupOption>;
  letterTemplateOption?: Maybe<BillingLetterTemplateOption>;
  logoOption?: Maybe<BillingLogoOption>;
  messageOption?: Maybe<BillingMessageOption>;
  myGiftAdvantageOption?: Maybe<BillingMyGiftAdvantageOption>;
  nominativeVoucherOption?: Maybe<BillingNominativeVoucherOption>;
  packagingOption?: Maybe<BillingPackagingOption>;
  shippingOption?: Maybe<BillingShippingOption>;
  signaturesListOption?: Maybe<BillingSignaturesListOption>;
  summaryVoucherOption?: Maybe<BillingSummaryVoucherOption>;
};

export type BillingOrder = {
  __typename?: 'BillingOrder';
  amount: BillingOrderAmount;
  billingAddress: BillingAddress;
  discounts?: Maybe<Array<DiscountItem>>;
  enablePromoCode: Scalars['Boolean']['output'];
  items: Array<BillingOrderItem>;
  journeyName: Scalars['String']['output'];
  journeyUrl?: Maybe<Scalars['String']['output']>;
  negotiatedDiscounts?: Maybe<Array<NegotiatedDiscountItem>>;
  orderId: Scalars['ID']['output'];
  organizationId: Scalars['ID']['output'];
  paymentMethods: Array<BillingPaymentMethod>;
  productSkuType: ProductSkuType;
  promoCode?: Maybe<Scalars['String']['output']>;
  successUrl?: Maybe<Scalars['String']['output']>;
};

export type BillingOrderAmount = {
  __typename?: 'BillingOrderAmount';
  /** Order discounts amount including taxes */
  discountAmount?: Maybe<Scalars['BigDecimal']['output']>;
  /** Order discounts tax amount */
  discountTaxAmount?: Maybe<Scalars['BigDecimal']['output']>;
  /** HT */
  excludingTaxAmount: Scalars['BigDecimal']['output'];
  /** Order discounts amount excluding taxes */
  excludingTaxDiscountAmount?: Maybe<Scalars['BigDecimal']['output']>;
  /** Order discounts amount excluding taxes */
  excludingTaxNegotiatedDiscountAmount?: Maybe<Scalars['BigDecimal']['output']>;
  /** TTC */
  includingTaxAmount: Scalars['BigDecimal']['output'];
  /** Order discounts amount including taxes */
  negotiatedDiscountAmount?: Maybe<Scalars['BigDecimal']['output']>;
  /** Order discounts tax amount */
  negotiatedDiscountTaxAmount?: Maybe<Scalars['BigDecimal']['output']>;
  /** TVA */
  taxAmount: Scalars['BigDecimal']['output'];
};

export type BillingOrderCancellationRequest = {
  billingOrderId?: InputMaybe<Scalars['String']['input']>;
};

export type BillingOrderForm = {
  __typename?: 'BillingOrderForm';
  bytes?: Maybe<Scalars['String']['output']>;
};

export type BillingOrderItem = {
  __typename?: 'BillingOrderItem';
  businessVolumeType?: Maybe<BusinessVolumeType>;
  checkType?: Maybe<CheckType>;
  description?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  totalAmount: Scalars['BigDecimal']['output'];
  transactionalCode: Scalars['String']['output'];
};

export enum BillingOrderStatus {
  CanceledByManager = 'CANCELED_BY_MANAGER',
  CanceledFailedPayment = 'CANCELED_FAILED_PAYMENT',
  CanceledUnreceivedPayment = 'CANCELED_UNRECEIVED_PAYMENT',
  Draft = 'DRAFT',
  PartialPayment = 'PARTIAL_PAYMENT',
  PendingPayment = 'PENDING_PAYMENT',
  RefusedPayment = 'REFUSED_PAYMENT',
  Validated = 'VALIDATED'
}

export type BillingPackagingOption = {
  __typename?: 'BillingPackagingOption';
  amounts?: Maybe<Amounts>;
  name?: Maybe<Scalars['String']['output']>;
  packaging?: Maybe<PaperOrderChequePackaging>;
};

export type BillingPaymentMethod = {
  __typename?: 'BillingPaymentMethod';
  maxAmount?: Maybe<Scalars['BigDecimal']['output']>;
  type: PaymentMethodType;
};

export type BillingShippingOption = {
  __typename?: 'BillingShippingOption';
  amounts?: Maybe<Amounts>;
  name?: Maybe<Scalars['String']['output']>;
  shippingMode?: Maybe<ShippingModeType>;
};

export type BillingSignaturesListOption = {
  __typename?: 'BillingSignaturesListOption';
  amounts?: Maybe<Amounts>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<SignaturesListType>;
};

export type BillingSummaryVoucherOption = {
  __typename?: 'BillingSummaryVoucherOption';
  amounts?: Maybe<Amounts>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BlockCustomerCardQueryInput = {
  beneficiaryId: Scalars['ID']['input'];
  cardBlockingReason: CardBlockingReason;
  cardId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

export enum BusinessVolumeType {
  Culture = 'CULTURE',
  Food = 'FOOD',
  Gift = 'GIFT',
  NotApplicable = 'NOT_APPLICABLE',
  Unspecified = 'UNSPECIFIED'
}

export type Campaign = {
  __typename?: 'Campaign';
  company: Company;
  missingAddress: Scalars['Int']['output'];
};

export type CampaignReport = {
  __typename?: 'CampaignReport';
  file?: Maybe<Scalars['String']['output']>;
};

export type CampaignRequest = {
  depositId: Scalars['ID']['input'];
  distributions: Array<CampaignRequestDistribution>;
  message?: InputMaybe<Scalars['String']['input']>;
  reasonId: Scalars['Long']['input'];
  sendBeneficiaryMail?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CampaignRequestDistribution = {
  amount?: InputMaybe<MoneyInput>;
  beneficiaryId: Scalars['ID']['input'];
};

export enum CampaignStatus {
  Cancelled = 'CANCELLED',
  Ended = 'ENDED',
  Planned = 'PLANNED',
  Refund = 'REFUND',
  Running = 'RUNNING'
}

export type CancelCustomerCardOrderQueryInput = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
};

export enum CancelReason {
  ManagerDemand = 'MANAGER_DEMAND',
  PaymentDefault = 'PAYMENT_DEFAULT',
  Undefined = 'UNDEFINED'
}

export enum CardBlockingReason {
  Lost = 'LOST',
  Other = 'OTHER',
  Stolen = 'STOLEN'
}

export type CardCodes = {
  __typename?: 'CardCodes';
  locationUrl?: Maybe<Scalars['String']['output']>;
  primary?: Maybe<Scalars['String']['output']>;
  secondary?: Maybe<Scalars['String']['output']>;
};

export type CardCombination = {
  __typename?: 'CardCombination';
  cards?: Maybe<Array<Maybe<CardCombinationCard>>>;
  valid?: Maybe<Scalars['Boolean']['output']>;
  value?: Maybe<Money>;
};

export type CardCombinationCard = {
  __typename?: 'CardCombinationCard';
  basePrice?: Maybe<Money>;
  cardType?: Maybe<CardType>;
  code?: Maybe<Scalars['String']['output']>;
};

export type CardCombinationResponse = CardCombinationResult | GiftError;

export type CardCombinationResult = {
  __typename?: 'CardCombinationResult';
  cardCombinations?: Maybe<Array<Maybe<CardCombinationWrapper>>>;
};

export type CardCombinationWrapper = {
  __typename?: 'CardCombinationWrapper';
  cardCombination?: Maybe<CardCombination>;
  searchType?: Maybe<SearchType>;
};

export type CardEditRequest = {
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  locationUrl?: InputMaybe<Scalars['String']['input']>;
  locationUrlDownloadable?: InputMaybe<Scalars['Boolean']['input']>;
  primaryCode?: InputMaybe<Scalars['String']['input']>;
  secondaryCode?: InputMaybe<Scalars['String']['input']>;
};

export type CardMultiShop = PurchasedCard & {
  __typename?: 'CardMultiShop';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  balance?: Maybe<CardMultiShopBalance>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  consumptionDate: Scalars['DateTime']['output'];
  details?: Maybe<CardMultiShopDetails>;
  expeditionDate?: Maybe<Scalars['DateTime']['output']>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  mailTracking?: Maybe<MailTracking>;
  manualConsumptionDate?: Maybe<Scalars['DateTime']['output']>;
  oppositionDate?: Maybe<Scalars['DateTime']['output']>;
  price: Money;
  purchasedCardActions: Array<PurchasedCardAction>;
  refundInitiator?: Maybe<Beneficiary>;
  refundReason?: Maybe<CardRefundReason>;
  serviceAmount?: Maybe<Money>;
  serviceFunded: Scalars['Boolean']['output'];
  shippingAddress?: Maybe<GiftAddress>;
  shippingAmount?: Maybe<Money>;
  shippingFunded: Scalars['Boolean']['output'];
  shop: Shop;
  transactions?: Maybe<CardMultiShopTransactionPage>;
  type: CardType;
  usages?: Maybe<CardUsages>;
};


export type CardMultiShopTransactionsArgs = {
  request: PageRequest;
};

export type CardMultiShopBalance = {
  __typename?: 'CardMultiShopBalance';
  authorizationsAmount: Money;
  authorizedBalanceAmount: Money;
  calculationDate: Scalars['DateTime']['output'];
  currentBalanceAmount: Money;
};

export type CardMultiShopDetails = {
  __typename?: 'CardMultiShopDetails';
  cardHashedNumber: Scalars['String']['output'];
  initialAmount: Money;
  pin: Scalars['String']['output'];
  publicToken: Scalars['String']['output'];
};

export type CardMultiShopTransaction = {
  __typename?: 'CardMultiShopTransaction';
  error?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  mccCode?: Maybe<Scalars['String']['output']>;
  merchantId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  transactionAmount: Money;
  transactionDate: Scalars['DateTime']['output'];
};

export type CardMultiShopTransactionPage = PageResult & {
  __typename?: 'CardMultiShopTransactionPage';
  index: Scalars['Int']['output'];
  items: Array<CardMultiShopTransaction>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type CardOrderQueryInput = {
  billingAddressId: Scalars['ID']['input'];
  idBeneficiariesSelected?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  organizationId: Scalars['ID']['input'];
};

/** ########### CREATE ORDERS ############ */
export type CardOrderSummary = {
  __typename?: 'CardOrderSummary';
  cardCreationOrder?: Maybe<FoodCardOrder>;
  cardNotReceivedOrder?: Maybe<FoodCardOrder>;
  cardOrderTotal: TotalPricing;
  cardReplacementOrder?: Maybe<FoodCardOrder>;
  shippingFees?: Maybe<TotalPricing>;
};

export type CardOrderSummaryQueryInput = {
  cardCreationCount?: InputMaybe<Scalars['Long']['input']>;
  idBeneficiariesSelected: Array<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type CardOwner = {
  __typename?: 'CardOwner';
  civility?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export type CardProgram = {
  __typename?: 'CardProgram';
  employerMaxContribution?: Maybe<Scalars['Float']['output']>;
  employerSharePercentage?: Maybe<Scalars['Float']['output']>;
  employerShareValue?: Maybe<Scalars['Float']['output']>;
  unitValue?: Maybe<Scalars['BigDecimal']['output']>;
};

export type CardProgramQueryInput = {
  employerMaxContribution?: InputMaybe<Scalars['Float']['input']>;
  employerSharePercentage?: InputMaybe<Scalars['Float']['input']>;
  employerShareValue?: InputMaybe<Scalars['Float']['input']>;
  unitValue?: InputMaybe<Scalars['BigDecimal']['input']>;
};

export enum CardRefundReason {
  AcceptableWithdrawalPeriod = 'ACCEPTABLE_WITHDRAWAL_PERIOD',
  CardBlockedCode = 'CARD_BLOCKED_CODE',
  CardDoubleCode = 'CARD_DOUBLE_CODE',
  CardFraud = 'CARD_FRAUD',
  CardProsodieTilloBlockedCode = 'CARD_PROSODIE_TILLO_BLOCKED_CODE',
  CardShopTeamResponsability = 'CARD_SHOP_TEAM_RESPONSABILITY',
  CardShopTeamValidation = 'CARD_SHOP_TEAM_VALIDATION',
  CardVirtualTicketsSoldOut = 'CARD_VIRTUAL_TICKETS_SOLD_OUT',
  CommercialGesture = 'COMMERCIAL_GESTURE',
  MultiAskedByCsm = 'MULTI_ASKED_BY_CSM',
  MultiBlocked = 'MULTI_BLOCKED',
  MultiDifferentExpirationDate = 'MULTI_DIFFERENT_EXPIRATION_DATE',
  MultiLost = 'MULTI_LOST',
  MultiNotReceived = 'MULTI_NOT_RECEIVED',
  MultiNotReceivedFullRefund = 'MULTI_NOT_RECEIVED_FULL_REFUND',
  MultiOppositionFailed = 'MULTI_OPPOSITION_FAILED',
  MultiOppositionMistake = 'MULTI_OPPOSITION_MISTAKE',
  MultiStolen = 'MULTI_STOLEN',
  MultiUnlockFailed = 'MULTI_UNLOCK_FAILED',
  MultiUnreadable = 'MULTI_UNREADABLE'
}

export type CardRefundRequest = {
  fees?: InputMaybe<MoneyInput>;
  reason: CardRefundReason;
  refund?: InputMaybe<MoneyInput>;
};

export type CardRefundResponse = CardRefundResult | GiftError;

export type CardRefundResult = {
  __typename?: 'CardRefundResult';
  fees: Money;
  reason?: Maybe<CardRefundReason>;
  refund: Money;
};

export type CardReloadOrderSummary = {
  __typename?: 'CardReloadOrderSummary';
  cardOrderTotal: TotalPricing;
  reloadingFees: Scalars['BigDecimal']['output'];
};

export type CardReloadSummaryQueryInput = {
  orderLines?: InputMaybe<Array<InputMaybe<FoodOrderLineInput>>>;
  organizationId: Scalars['ID']['input'];
};

export enum CardStatusType {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Destroyed = 'DESTROYED',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE',
  Locked = 'LOCKED',
  Lost = 'LOST',
  NotOrdered = 'NOT_ORDERED',
  NotReceived = 'NOT_RECEIVED',
  Pending = 'PENDING',
  PendingForValidation = 'PENDING_FOR_VALIDATION',
  Renew = 'RENEW',
  Stolen = 'STOLEN'
}

export enum CardType {
  Code = 'CODE',
  Manual = 'MANUAL',
  Multi = 'MULTI',
  Paper = 'PAPER',
  Physical = 'PHYSICAL',
  PrivilegedDeal = 'PRIVILEGED_DEAL',
  RemoteCode = 'REMOTE_CODE',
  Unknown = 'UNKNOWN',
  Voucher = 'VOUCHER'
}

export type CardUsages = {
  __typename?: 'CardUsages';
  distributionAmount: Money;
  fundedAmount: Money;
  refundFeesAmount: Money;
  refundFeesOnFundedAmount: Money;
  upsellAmount: Money;
};

export type CardsProposalArticle = {
  __typename?: 'CardsProposalArticle';
  discount?: Maybe<Money>;
  discountRatio?: Maybe<Scalars['BigDecimal']['output']>;
  facialPrice?: Maybe<Money>;
  firstExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  shippingFees?: Maybe<Array<Maybe<ShippingFee>>>;
  unitPrice?: Maybe<Money>;
};

export type CardsProposalItem = {
  __typename?: 'CardsProposalItem';
  amount?: Maybe<Money>;
  articles?: Maybe<Array<Maybe<CardsProposalArticle>>>;
  code?: Maybe<Scalars['String']['output']>;
  itemCount?: Maybe<Scalars['Int']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  shopId?: Maybe<Scalars['Long']['output']>;
  totalDiscountPrice?: Maybe<Money>;
  totalFacialPrice?: Maybe<Money>;
  totalPrice?: Maybe<Money>;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type CardsProposalRequest = {
  items?: InputMaybe<Array<InputMaybe<CardsProposalRequestItem>>>;
  walletId?: InputMaybe<Scalars['Long']['input']>;
};

export type CardsProposalRequestItem = {
  amount?: InputMaybe<MoneyInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  shopId?: InputMaybe<Scalars['Long']['input']>;
};

export type CardsProposalResponse = CardsProposalResult | GiftError;

export type CardsProposalResult = {
  __typename?: 'CardsProposalResult';
  items?: Maybe<Array<Maybe<CardsProposalItem>>>;
  shopFee?: Maybe<PurchaseShopFee>;
  totalAmount?: Maybe<Money>;
  totalDiscountAmount?: Maybe<Money>;
  totalEndowment?: Maybe<Money>;
  totalFacialAmount?: Maybe<Money>;
  totalFeesAmount?: Maybe<Money>;
  totalFundedAmount?: Maybe<Money>;
  /** @deprecated Field no longer supported */
  upsalePossible?: Maybe<Scalars['Boolean']['output']>;
  upsellPossible?: Maybe<Scalars['Boolean']['output']>;
  walletId?: Maybe<Scalars['Long']['output']>;
};

export type Carrier = {
  __typename?: 'Carrier';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export enum CartStatus {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Timeout = 'TIMEOUT',
  Unknown = 'UNKNOWN'
}

export type Category = {
  __typename?: 'Category';
  children?: Maybe<Array<Category>>;
  id: Scalars['Long']['output'];
  names?: Maybe<Array<Translation>>;
  /** @deprecated will be removed, always null from now on */
  shopSuggestion?: Maybe<CategoryShopSuggestion>;
};

export type CategoryBanner = Banner & {
  __typename?: 'CategoryBanner';
  category?: Maybe<Category>;
  id: Scalars['Long']['output'];
  imageUrl: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type CategoryShopSuggestion = {
  __typename?: 'CategoryShopSuggestion';
  descriptions?: Maybe<Array<Translation>>;
  shop: Shop;
  titles?: Maybe<Array<Translation>>;
};

export enum ChangePasswordError {
  OldPassword = 'OLD_PASSWORD',
  PatternMismatch = 'PATTERN_MISMATCH',
  Unknown = 'UNKNOWN',
  WeakStrength = 'WEAK_STRENGTH'
}

export type ChangePasswordErrorResponse = {
  __typename?: 'ChangePasswordErrorResponse';
  error?: Maybe<ChangePasswordError>;
};

export type ChangePasswordRequest = {
  password: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type ChangePasswordResponse = Beneficiary | ChangePasswordErrorResponse;

export type CheckAddressValidityRequest = {
  address?: InputMaybe<UpdateAddressRequest>;
};

export type CheckAddressValidityResponse = {
  __typename?: 'CheckAddressValidityResponse';
  address?: Maybe<AddressData>;
  rnvpErrorCode: RnvpErrorCode;
};

export type CheckBeneficiariesMassImportV3Request = {
  balance?: InputMaybe<Scalars['Float']['input']>;
  isManagerProxi?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type CheckFeatureFlagRequest = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  featureFlagName?: InputMaybe<Scalars['String']['input']>;
};

export type CheckFeatureFlagResponse = {
  __typename?: 'CheckFeatureFlagResponse';
  isActive?: Maybe<Scalars['Boolean']['output']>;
};

export type CheckNewsletterCampaignNameResult = Empty | NewsletterError;

export enum CheckType {
  BackToSchool = 'BACK_TO_SCHOOL',
  Culture = 'CULTURE',
  Happiness = 'HAPPINESS',
  Liberty = 'LIBERTY',
  Unspecified = 'UNSPECIFIED'
}

export type CommercialOffer = {
  __typename?: 'CommercialOffer';
  amountLostStolenCard?: Maybe<Scalars['BigDecimal']['output']>;
  amountOrderCard?: Maybe<Scalars['BigDecimal']['output']>;
  uuidCompagny?: Maybe<Scalars['String']['output']>;
};

export type CommercialOfferQueryInput = {
  amountLostStolenCard?: InputMaybe<Scalars['BigDecimal']['input']>;
  amountOrderCard?: InputMaybe<Scalars['BigDecimal']['input']>;
  uuidCompany?: InputMaybe<Scalars['String']['input']>;
};

export type CommunicationAnswerSurveyInput = {
  campaignId: Scalars['String']['input'];
  inputId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  surveyId: Scalars['String']['input'];
};

export type CommunicationBeneficiaryViewStatus = {
  __typename?: 'CommunicationBeneficiaryViewStatus';
  status: Scalars['String']['output'];
};

export type CommunicationCampaign = CommunicationCampaignInterface & {
  __typename?: 'CommunicationCampaign';
  button1?: Maybe<CommunicationCampaignButton>;
  campaignId: Scalars['String']['output'];
  campaignType: Scalars['String']['output'];
  creationDate: Scalars['String']['output'];
  endDate1?: Maybe<Scalars['String']['output']>;
  file1?: Maybe<CommunicationCampaignFile>;
  image1?: Maybe<CommunicationCampaignImage>;
  mainText: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  publicationDate: Scalars['String']['output'];
  question: Scalars['String']['output'];
  question1?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  surveyInputs1?: Maybe<Array<Maybe<CommunicationCampaignSurvey>>>;
  title: Scalars['String']['output'];
};

export type CommunicationCampaignButton = {
  __typename?: 'CommunicationCampaignButton';
  label: Scalars['String']['output'];
  link: Scalars['String']['output'];
};

export type CommunicationCampaignButtonInput = {
  label: Scalars['String']['input'];
  link: Scalars['String']['input'];
};

export type CommunicationCampaignFile = {
  __typename?: 'CommunicationCampaignFile';
  privateFileName?: Maybe<Scalars['String']['output']>;
  publicFileName?: Maybe<Scalars['String']['output']>;
};

export type CommunicationCampaignFileInput = {
  privateFileName: Scalars['String']['input'];
  publicFileName: Scalars['String']['input'];
};

export type CommunicationCampaignImage = {
  __typename?: 'CommunicationCampaignImage';
  filename: Scalars['String']['output'];
  link: Scalars['String']['output'];
};

export type CommunicationCampaignImageInput = {
  filename: Scalars['String']['input'];
  link: Scalars['String']['input'];
};

export type CommunicationCampaignInterface = {
  button1?: Maybe<CommunicationCampaignButton>;
  campaignId: Scalars['String']['output'];
  campaignType: Scalars['String']['output'];
  file1?: Maybe<CommunicationCampaignFile>;
  image1?: Maybe<CommunicationCampaignImage>;
  mainText: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  publicationDate: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CommunicationCampaignPagination = {
  __typename?: 'CommunicationCampaignPagination';
  campaignId: Scalars['String']['output'];
  creationDate?: Maybe<Scalars['String']['output']>;
  orgId: Scalars['String']['output'];
  publicationDate?: Maybe<Scalars['String']['output']>;
};

export type CommunicationCampaignPaginationInput = {
  campaignId: Scalars['String']['input'];
  creationDate?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  publicationDate?: InputMaybe<Scalars['String']['input']>;
};

export type CommunicationCampaignSurvey = {
  __typename?: 'CommunicationCampaignSurvey';
  inputId?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

export type CommunicationCampaignSurveyInput = {
  inputId?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
};

export type CommunicationCampaignWithBeneficiaryView = CommunicationCampaignInterface & {
  __typename?: 'CommunicationCampaignWithBeneficiaryView';
  button1?: Maybe<CommunicationCampaignButton>;
  campaignId: Scalars['String']['output'];
  campaignType: Scalars['String']['output'];
  creationDate: Scalars['String']['output'];
  endDate1?: Maybe<Scalars['String']['output']>;
  file1?: Maybe<CommunicationCampaignFile>;
  image1?: Maybe<CommunicationCampaignImage>;
  mainText: Scalars['String']['output'];
  newsStatus: Scalars['String']['output'];
  orgId: Scalars['String']['output'];
  publicationDate: Scalars['String']['output'];
  question1?: Maybe<Scalars['String']['output']>;
  surveyInputs1?: Maybe<Array<Maybe<CommunicationCampaignSurvey>>>;
  title: Scalars['String']['output'];
};

export type CommunicationError = {
  __typename?: 'CommunicationError';
  description?: Maybe<Scalars['String']['output']>;
  errorCodes?: Maybe<Array<Scalars['String']['output']>>;
};

export type CommunicationFile = {
  __typename?: 'CommunicationFile';
  file?: Maybe<File>;
};

export type CommunicationFileCampaignInput = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  file1: CommunicationCampaignFileInput;
  mainText: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CommunicationFileInput = {
  filePath: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type CommunicationFileTracking = {
  __typename?: 'CommunicationFileTracking';
  file1DownloadCount: Scalars['Int']['output'];
};

export type CommunicationFileUrl = {
  __typename?: 'CommunicationFileUrl';
  url: Scalars['String']['output'];
};

export type CommunicationImage = {
  __typename?: 'CommunicationImage';
  url: Scalars['String']['output'];
};

export type CommunicationImageInput = {
  filePath: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type CommunicationListCampaigns = {
  __typename?: 'CommunicationListCampaigns';
  campaigns?: Maybe<Array<Maybe<CommunicationCampaign>>>;
  pagination?: Maybe<CommunicationCampaignPagination>;
};

export type CommunicationNewsCampaignInput = {
  button1?: InputMaybe<CommunicationCampaignButtonInput>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
  image1?: InputMaybe<CommunicationCampaignImageInput>;
  mainText: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CommunicationPreference = {
  __typename?: 'CommunicationPreference';
  newsletterOptIn: Scalars['Boolean']['output'];
  orgId: Scalars['String']['output'];
};

export type CommunicationSurveyCampaignInput = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  endDate1: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  question1: Scalars['String']['input'];
  surveyInputs1: Array<InputMaybe<CommunicationCampaignSurveyInput>>;
  title: Scalars['String']['input'];
};

export type CommunicationTracking = {
  __typename?: 'CommunicationTracking';
  countBenef: Scalars['Int']['output'];
  countOpen: Scalars['Int']['output'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CompanyAutoCompleteResult = {
  __typename?: 'CompanyAutoCompleteResult';
  contractId?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  full_registration_number?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CompanyAutoCompleteResultWithoutSiret = {
  __typename?: 'CompanyAutoCompleteResultWithoutSiret';
  formattedAddress?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CompanyFundedShipping = {
  __typename?: 'CompanyFundedShipping';
  fundedShippingAmount: Scalars['Float']['output'];
  fundedShippingMode: FundedShippingMode;
};

export type CompanyGrant = {
  __typename?: 'CompanyGrant';
  companyUsed?: Maybe<Scalars['Boolean']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  fundedAmountPerUser?: Maybe<Money>;
  fundedRatio?: Maybe<Scalars['BigDecimal']['output']>;
  id: Scalars['Long']['output'];
  labels?: Maybe<Array<Translation>>;
  limitAmount?: Maybe<Money>;
  limitCount?: Maybe<Scalars['Int']['output']>;
  organization: Organization;
  paidAmountPerUser?: Maybe<Money>;
  remainingAmount?: Maybe<Money>;
  remainingCount?: Maybe<Scalars['Int']['output']>;
  renewal?: Maybe<Scalars['Boolean']['output']>;
  shops: Array<Shop>;
  used?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyIdentifier = {
  identifierType?: InputMaybe<IdentifierType>;
  value: Scalars['String']['input'];
};

export type CompanyOfferWallShop = {
  __typename?: 'CompanyOfferWallShop';
  discountRatio?: Maybe<Scalars['BigDecimal']['output']>;
  id: Scalars['String']['output'];
  inShopPackage?: Maybe<Scalars['Boolean']['output']>;
  shop: Shop;
};

export enum CompanyOrderType {
  CompanyBill = 'COMPANY_BILL',
  CompanyBillPaper = 'COMPANY_BILL_PAPER',
  CompanyDebitNoShowRefunding = 'COMPANY_DEBIT_NO_SHOW_REFUNDING',
  CompanyServiceCharge = 'COMPANY_SERVICE_CHARGE',
  CompanyShippingCharge = 'COMPANY_SHIPPING_CHARGE',
  CreditCompany = 'CREDIT_COMPANY',
  CreditCompanyPaper = 'CREDIT_COMPANY_PAPER'
}

export type CompanySdxData = {
  clientCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  idContract?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum CompanySegment {
  CompanySegmentCse = 'COMPANY_SEGMENT_CSE',
  CompanySegmentIncentive = 'COMPANY_SEGMENT_INCENTIVE',
  CompanySegmentRh = 'COMPANY_SEGMENT_RH',
  CompanySegmentSme = 'COMPANY_SEGMENT_SME'
}

export type CompoundFilter = {
  csmStatusFilter?: InputMaybe<CsmStatusFilter>;
  remadeStatusFilter?: InputMaybe<RemadeStatusFilter>;
  statusPeriodFilter?: InputMaybe<StatusPeriodFilter>;
};

export type CopyReconciliationFileInput = {
  /** file's path and name on S3 */
  sourceKey: Scalars['String']['input'];
};

export type CopyReconciliationFileResponse = {
  __typename?: 'CopyReconciliationFileResponse';
  /** path to find the file in the bucket. Example: uploaded/2024/01/30/12/00/45/BankTransfer.csv */
  destinationKey: Scalars['String']['output'];
};

export type CountryCode = {
  __typename?: 'CountryCode';
  iso: Scalars['String']['output'];
  prefix: Scalars['String']['output'];
};

export type CreateApiClientResponse = {
  __typename?: 'CreateApiClientResponse';
  clientId: Scalars['String']['output'];
  isCreated: Scalars['Boolean']['output'];
};

export type CreateBankAccount = {
  accountName: Scalars['String']['input'];
  accountOwner: Scalars['String']['input'];
  bic: Scalars['String']['input'];
  defaultAccount?: InputMaybe<Scalars['Boolean']['input']>;
  iban: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type CreateBankAccountError = {
  __typename?: 'CreateBankAccountError';
  type: CreateBankAccountErrorType;
};

export enum CreateBankAccountErrorType {
  AccountNameAlreadyExist = 'ACCOUNT_NAME_ALREADY_EXIST',
  InvalidIban = 'INVALID_IBAN',
  Unknown = 'UNKNOWN'
}

export type CreateBankAccountResponse = BankAccount | CreateBankAccountError;

export type CreateBillingDraftPaperOrderRequest = {
  orderId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateBillingDraftPaperOrderResponse = {
  __typename?: 'CreateBillingDraftPaperOrderResponse';
  billingInformation: PaperBillingInformation;
  orderId?: Maybe<Scalars['String']['output']>;
};

export type CreateCard = {
  __typename?: 'CreateCard';
  bankInfos?: Maybe<BankInfos>;
  orderReference?: Maybe<Scalars['String']['output']>;
};

export type CreateClientApiRequest = {
  organizationId: Scalars['ID']['input'];
  organizationName: Scalars['String']['input'];
};

export type CreateCommunicationFileCampaignResult = CommunicationCampaign | CommunicationError;

export type CreateCommunicationNewsCampaignResult = CommunicationCampaign | CommunicationError;

export type CreateCommunicationSurveyCampaignResult = CommunicationCampaign | CommunicationError;

export type CreateComplexOrderTemplateFileRequest = {
  options?: InputMaybe<PaperOrderOptions>;
  products?: InputMaybe<Array<InputMaybe<PaperOrderProduct>>>;
};

export type CreateDraftPaperOrderRequest = {
  options: PaperOrderOptions;
  organizationId: Scalars['String']['input'];
  products: Array<PaperOrderProduct>;
};

export type CreateDraftPaperOrderResponse = {
  __typename?: 'CreateDraftPaperOrderResponse';
  orderId: Scalars['String']['output'];
};

export type CreatePresignedUrlInput = {
  metadata?: InputMaybe<Array<S3MetadataInput>>;
};

export type CreatePresignedUrlResponse = {
  __typename?: 'CreatePresignedUrlResponse';
  bucket?: Maybe<Scalars['String']['output']>;
  s3Key: Scalars['ID']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type CreateProspectDraftByRedirectionRequest = {
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  paperGiftEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
  subSegment?: InputMaybe<UpdateSubSegment>;
};

export type CreateReasonRequest = {
  titles?: InputMaybe<Array<TranslationInput>>;
  walletId: Scalars['Long']['input'];
};

export type CreatedPresignedUrl = {
  bucket: Scalars['String']['input'];
  s3Key: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type CreationSiteResult = SiteError | SiteInfo;

export type CreditCardPaymentConfiguration = {
  __typename?: 'CreditCardPaymentConfiguration';
  maximumAmount: Money;
  minimumAmount: Money;
};

export enum CriteriaOperator {
  Contain = 'CONTAIN',
  DoNotWishToFill = 'DO_NOT_WISH_TO_FILL',
  Equals = 'EQUALS',
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  IntervalGreater = 'INTERVAL_GREATER',
  IntervalLower = 'INTERVAL_LOWER',
  Lower = 'LOWER',
  LowerOrEqual = 'LOWER_OR_EQUAL',
  NotContain = 'NOT_CONTAIN',
  NotEquals = 'NOT_EQUALS',
  OneOf = 'ONE_OF',
  Unspecified = 'UNSPECIFIED'
}

export type CsmStatusFilter = {
  csmLastName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export enum CurrentTermPeriodType {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type CustomerCard = {
  __typename?: 'CustomerCard';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  contactlessPayment?: Maybe<Scalars['Boolean']['output']>;
  expeditionDate?: Maybe<Scalars['DateTime']['output']>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  hashedCode?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lastStatusChangeDate?: Maybe<Scalars['DateTime']['output']>;
  nfc?: Maybe<Scalars['Boolean']['output']>;
  orderDate?: Maybe<Scalars['DateTime']['output']>;
  shippingAddress?: Maybe<Address>;
  status?: Maybe<CardStatusType>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  /**  Date of the creation in ms food orders */
  unblockedDate?: Maybe<Scalars['DateTime']['output']>;
};

export type DefaultPaymentMethod = {
  __typename?: 'DefaultPaymentMethod';
  accountHolderName?: Maybe<Scalars['String']['output']>;
  accountKey?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  cardNumber?: Maybe<Scalars['String']['output']>;
  creditCardType?: Maybe<Scalars['String']['output']>;
  defaultPaymentMethodType?: Maybe<Scalars['String']['output']>;
  expirationMonth?: Maybe<Scalars['Int']['output']>;
  expirationYear?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type DefaultSharingRequest = {
  amount: MoneyInput;
  receiverFirstName: Scalars['String']['input'];
  receiverLastName: Scalars['String']['input'];
  receiverLogin: Scalars['String']['input'];
};

export type DeleteAddressResponse = {
  __typename?: 'DeleteAddressResponse';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteBankAccountResponse = {
  __typename?: 'DeleteBankAccountResponse';
  id?: Maybe<Scalars['ID']['output']>;
};

export type DeleteDistributionsRequest = {
  campaignId?: InputMaybe<Scalars['Long']['input']>;
  /**  deprecated, use campaignIds instead */
  campaignIds: Array<Scalars['Long']['input']>;
  organizationId: Scalars['ID']['input'];
  reason: DistributionRevertReason;
};

export type DeleteDraftOrdersRequest = {
  organizationId: Scalars['String']['input'];
};

export type DeleteDraftOrdersResponse = {
  __typename?: 'DeleteDraftOrdersResponse';
  deletedDraftIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DeleteNewsletterCampaignResult = Empty | NewsletterError;

export type DeleteNewsletterTargetingRuleResult = Empty | NewsletterError;

export type DeleteReasonResponse = DeleteReasonResult | GiftError;

export type DeleteReasonResult = {
  __typename?: 'DeleteReasonResult';
  deleted: Scalars['Boolean']['output'];
};

export type DeliveryAddressFoodInput = {
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  complementaryAddress?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  recipient: Scalars['String']['input'];
  zipCode: Scalars['String']['input'];
};

export type DeliveryContact = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type DeliveryDetails = {
  __typename?: 'DeliveryDetails';
  deliveryDate?: Maybe<Scalars['DateTime']['output']>;
  estimatedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
};

export type Denomination = {
  __typename?: 'Denomination';
  quantity?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Money>;
};

export type Denominations = {
  __typename?: 'Denominations';
  additionalDenominationValue: Scalars['Int']['output'];
  numberOfFifteenPerBeneficiary: Scalars['Int']['output'];
  numberOfFiftyPerBeneficiary: Scalars['Int']['output'];
  numberOfHundredPerBeneficiary: Scalars['Int']['output'];
  numberOfTenPerBeneficiary: Scalars['Int']['output'];
  numberOfThirtyPerBeneficiary: Scalars['Int']['output'];
  numberOfTwentyPerBeneficiary: Scalars['Int']['output'];
};

export type DenominationsInput = {
  additionalDenominationValue: Scalars['Int']['input'];
  numberOfFifteenPerBeneficiary: Scalars['Int']['input'];
  numberOfFiftyPerBeneficiary: Scalars['Int']['input'];
  numberOfHundredPerBeneficiary: Scalars['Int']['input'];
  numberOfTenPerBeneficiary: Scalars['Int']['input'];
  numberOfThirtyPerBeneficiary: Scalars['Int']['input'];
  numberOfTwentyPerBeneficiary: Scalars['Int']['input'];
};

export type DepositTransaction = {
  __typename?: 'DepositTransaction';
  amount: Money;
  date: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  refund?: Maybe<Scalars['Boolean']['output']>;
  type: TransactionType;
};

export type DepositTransactionResult = PageResult & {
  __typename?: 'DepositTransactionResult';
  index: Scalars['Int']['output'];
  items: Array<DepositTransaction>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type DetachedBeneficiary = {
  __typename?: 'DetachedBeneficiary';
  organizationId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export enum DetachedBeneficiaryError {
  DeniedMainManager = 'DENIED_MAIN_MANAGER',
  DeniedSelf = 'DENIED_SELF',
  Unknown = 'UNKNOWN'
}

export type DetachedBeneficiaryErrorResponse = {
  __typename?: 'DetachedBeneficiaryErrorResponse';
  error?: Maybe<DetachedBeneficiaryError>;
};

export type DetachedBeneficiaryResponse = DetachedBeneficiary | DetachedBeneficiaryErrorResponse;

export type DiscountItem = {
  __typename?: 'DiscountItem';
  description: Scalars['String']['output'];
  discountTaxAmount: Scalars['BigDecimal']['output'];
  discountType: Scalars['String']['output'];
  discountValue: Scalars['BigDecimal']['output'];
  discountWithTax: Scalars['BigDecimal']['output'];
  discountWithoutTax: Scalars['BigDecimal']['output'];
  promoCode: Scalars['String']['output'];
  transactionalCode: Scalars['String']['output'];
};

export type DistributionCancelRequest = {
  distributionId: Scalars['Long']['input'];
  organizationUuid?: InputMaybe<Scalars['ID']['input']>;
  reason: DistributionRevertReason;
  userUuid: Scalars['ID']['input'];
};

export type DistributionConfigurationRequest = {
  __typename?: 'DistributionConfigurationRequest';
  amount: Money;
  beneficiaryId: Scalars['ID']['output'];
};

export type DistributionDetail = {
  __typename?: 'DistributionDetail';
  amount: Money;
  consumedAmount: Money;
  deepConsumedAmount: Money;
  effectiveLeftAmount: Money;
  effectiveUsedAmount: Money;
  leftAmount: Money;
  sharedAmount: Money;
  usedAmount: Money;
};

export type DistributionErrorReport = {
  __typename?: 'DistributionErrorReport';
  jobUuid: Scalars['String']['output'];
  lines?: Maybe<Array<Maybe<DistributionErrorReportLine>>>;
  orgaUuid: Scalars['String']['output'];
};

export type DistributionErrorReportLine = {
  __typename?: 'DistributionErrorReportLine';
  ruleUuid?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  userUuid?: Maybe<Scalars['String']['output']>;
};

export type DistributionOrganizationPreview = {
  __typename?: 'DistributionOrganizationPreview';
  accountName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
};

export type DistributionReason = {
  __typename?: 'DistributionReason';
  generic: Scalars['Boolean']['output'];
  hasCampaign: Scalars['Boolean']['output'];
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  titles?: Maybe<Array<Translation>>;
  visible: Scalars['Boolean']['output'];
  wallet: Wallet;
};

export type DistributionReasonPage = PageResult & {
  __typename?: 'DistributionReasonPage';
  index: Scalars['Int']['output'];
  items: Array<DistributionReason>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type DistributionRequest = {
  amount: MoneyInput;
  beneficiaryId: Scalars['ID']['input'];
};

export enum DistributionRevertReason {
  DistributionRevert = 'DISTRIBUTION_REVERT',
  UserDeleted = 'USER_DELETED'
}

export type DistributionSharingFileRequest = {
  distributionId: Scalars['Long']['input'];
  execute?: InputMaybe<Scalars['Boolean']['input']>;
  senderUuid: Scalars['ID']['input'];
};

export type DistributionSharingRequest = {
  /**  admin sharing */
  adminRequest?: InputMaybe<AdminSharingRequest>;
  distributionId: Scalars['Long']['input'];
  /**  default sharing */
  requests?: InputMaybe<Array<InputMaybe<DefaultSharingRequest>>>;
  senderUuid: Scalars['ID']['input'];
};

export type DistributionSharingRequestForUser = {
  amount: MoneyInput;
  distributionId: Scalars['Long']['input'];
  receiverUuid: Scalars['ID']['input'];
  senderUuid: Scalars['ID']['input'];
  zendeskUrl: Scalars['String']['input'];
};

export type DistributionUserPreview = {
  __typename?: 'DistributionUserPreview';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  user?: Maybe<Beneficiary>;
};

export type DownloadOrderComplexFileRequest = {
  orderId: Scalars['String']['input'];
};

export type DownloadOrderComplexFileResponse = {
  __typename?: 'DownloadOrderComplexFileResponse';
  url: Scalars['String']['output'];
};

export type DownloadSimulationRequest = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  simulationId: Scalars['String']['input'];
};

export type DownloadSimulationResponse = {
  __typename?: 'DownloadSimulationResponse';
  pdfBytes: Scalars['String']['output'];
};

export type DpsAssignedCredit = {
  __typename?: 'DpsAssignedCredit';
  benefit: BenefitCanonicalName;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  endDate: Scalars['DateTime']['output'];
  event: UrssafEventType;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  receivedAmount: Money;
  remainingAmount: Money;
  startDate: Scalars['DateTime']['output'];
};

export type DpsAssignedCreditsFiltersAndSort = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  benefit?: InputMaybe<BenefitCanonicalName>;
  sortBy?: InputMaybe<SortCredits>;
  spentAll?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DpsAssignedCreditsPage = PageResult & {
  __typename?: 'DpsAssignedCreditsPage';
  index: Scalars['Int']['output'];
  items: Array<DpsAssignedCredit>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export enum DtsBenefitFormat {
  Dematerialized = 'DEMATERIALIZED',
  Paper = 'PAPER',
  Unrecognized = 'UNRECOGNIZED'
}

/**  Internal type */
export type DtsCampaign = {
  __typename?: 'DtsCampaign';
  amount: Money;
  beneficiariesCount: Scalars['Int']['output'];
  benefit?: Maybe<BenefitCanonicalName>;
  campaignState: DtsCampaignState;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  creationDate: Scalars['DateTime']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  event: UrssafEventType;
  id: Scalars['ID']['output'];
  initialAmount: Money;
  isCancellable: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  paymentState: DtsPaymentState;
  scheduled: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
};

export type DtsCampaignConfiguration = {
  __typename?: 'DtsCampaignConfiguration';
  benefit: BenefitCanonicalName;
  billingOrderId: Scalars['String']['output'];
  campaignState: DtsCampaignState;
  creationDate: Scalars['DateTime']['output'];
  distributions: Array<DistributionConfigurationRequest>;
  event: UrssafEventType;
  id: Scalars['ID']['output'];
  importJobId?: Maybe<Scalars['String']['output']>;
  isValid?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  paymentMethod?: Maybe<DtsPaymentMethod>;
  scheduled: Scalars['Boolean']['output'];
  startDate: Scalars['DateTime']['output'];
  totalAmount: Money;
  updateDate?: Maybe<Scalars['DateTime']['output']>;
};

export type DtsCampaignCreationFromImportRequest = {
  benefit: BenefitCanonicalName;
  event: UrssafEventType;
  initiatorId: Scalars['ID']['input'];
  jobId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  scheduled: Scalars['Boolean']['input'];
  /**  startDate mandatory when sheduled  */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DtsCampaignCreationRequest = {
  benefit: BenefitCanonicalName;
  distributions: Array<DistributionRequest>;
  event: UrssafEventType;
  initiatorId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  scheduled: Scalars['Boolean']['input'];
  /**  startDate mandatory when sheduled  */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DtsCampaignDraftCreationResponse = {
  __typename?: 'DtsCampaignDraftCreationResponse';
  amount: Money;
  billingOrderId?: Maybe<Scalars['ID']['output']>;
  campaignId?: Maybe<Scalars['ID']['output']>;
};

export enum DtsCampaignState {
  BeingCancelled = 'BEING_CANCELLED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Running = 'RUNNING',
  Scheduled = 'SCHEDULED',
  Unrecognized = 'UNRECOGNIZED',
  WaitingForPayment = 'WAITING_FOR_PAYMENT'
}

export type DtsCampaignUpdateFromImportRequest = {
  benefit: BenefitCanonicalName;
  campaignId: Scalars['ID']['input'];
  event: UrssafEventType;
  jobId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  scheduled: Scalars['Boolean']['input'];
  /**  startDate mandatory when sheduled  */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DtsCampaignUpdateRequest = {
  benefit: BenefitCanonicalName;
  campaignId: Scalars['ID']['input'];
  distributions: Array<DistributionRequest>;
  event: UrssafEventType;
  name: Scalars['String']['input'];
  scheduled: Scalars['Boolean']['input'];
  /**  startDate mandatory when sheduled  */
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DtsCampaignsReportInput = {
  intervalEnd: Scalars['DateTime']['input'];
  intervalStart: Scalars['DateTime']['input'];
  locale: Scalars['String']['input'];
};

export type DtsCancelCampaignRequest = {
  campaignId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DtsCreateOrganizationRequest = {
  benefitFormat: DtsBenefitFormat;
  organizationId: Scalars['ID']['input'];
  paymentType?: InputMaybe<DtsPaymentType>;
};

export type DtsCreateOrganizationResponse = {
  __typename?: 'DtsCreateOrganizationResponse';
  benefitFormat: DtsBenefitFormat;
  organizationId: Scalars['ID']['output'];
  paymentType?: Maybe<DtsPaymentType>;
  welcomePopUpCompletionDate: Scalars['DateTime']['output'];
};

export type DtsDeleteCampaignDraftRequest = {
  campaignId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DtsDistributionsReportInput = {
  campaignId: Scalars['ID']['input'];
  locale: Scalars['String']['input'];
};

export type DtsFinalizeCampaignRequest = {
  campaignId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};

export type DtsFinalizeCampaignResponse = {
  __typename?: 'DtsFinalizeCampaignResponse';
  campaignState: DtsCampaignState;
  id: Scalars['ID']['output'];
  paymentMethod?: Maybe<DtsPaymentMethod>;
};

export type DtsFindCampaignDraft = {
  __typename?: 'DtsFindCampaignDraft';
  campaignId?: Maybe<Scalars['ID']['output']>;
};

export type DtsIFrameData = {
  __typename?: 'DtsIFrameData';
  accountId: Scalars['String']['output'];
  currency: Scalars['String']['output'];
  oauthToken: Scalars['String']['output'];
  pageId: Scalars['String']['output'];
  paymentPageUrl: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  rsaSignature: Scalars['String']['output'];
  tenantId: Scalars['String']['output'];
  zuoraBaseUrl: Scalars['String']['output'];
};

export type DtsMonitoringDistribution = {
  __typename?: 'DtsMonitoringDistribution';
  beneficiary: Beneficiary;
  mailStatus?: Maybe<Scalars['String']['output']>;
  receivedAmount: Money;
  remainingAmount: Money;
  uuid: Scalars['ID']['output'];
};

export enum DtsPaymentMethod {
  Check = 'CHECK',
  CreditCard = 'CREDIT_CARD',
  ExternalBankTransfer = 'EXTERNAL_BANK_TRANSFER',
  Unrecognized = 'UNRECOGNIZED'
}

export enum DtsPaymentState {
  NotReceived = 'NOT_RECEIVED',
  PaymentCancelled = 'PAYMENT_CANCELLED',
  Received = 'RECEIVED',
  Unrecognized = 'UNRECOGNIZED'
}

export enum DtsPaymentType {
  Cb = 'CB',
  TransferCheck = 'TRANSFER_CHECK',
  Unrecognized = 'UNRECOGNIZED'
}

export type DtsPreparePaymentSessionRequest = {
  campaignId: Scalars['ID']['input'];
  previousUrl: Scalars['String']['input'];
  stepTitle: Scalars['String']['input'];
  successUrl: Scalars['String']['input'];
};

export type DtsPreparePaymentSessionResponse = {
  __typename?: 'DtsPreparePaymentSessionResponse';
  paymentUrl: Scalars['String']['output'];
};

export type DtsUiRights = {
  __typename?: 'DtsUiRights';
  canCancelCampaign: Scalars['Boolean']['output'];
};

export type DtsValidateCommandResponse = {
  __typename?: 'DtsValidateCommandResponse';
  dtsIFrameData: DtsIFrameData;
  invoiceNumber: Scalars['String']['output'];
};

export type DuplicateOrderRequest = {
  orderId?: InputMaybe<Scalars['String']['input']>;
};

export type DuplicateOrderResponse = {
  __typename?: 'DuplicateOrderResponse';
  billingInformation?: Maybe<PaperBillingInformation>;
  orderId?: Maybe<Scalars['String']['output']>;
  unavailableOptions?: Maybe<BillingOptions>;
};

export type Email = {
  __typename?: 'Email';
  address: Scalars['String']['output'];
  date: Scalars['DateTime']['output'];
  status: EmailStatus;
  subType?: Maybe<EmailSubType>;
  type: EmailType;
};

export type EmailPage = PageResult & {
  __typename?: 'EmailPage';
  index: Scalars['Int']['output'];
  items: Array<Email>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export enum EmailStatus {
  Bounce = 'BOUNCE',
  Click = 'CLICK',
  Deferred = 'DEFERRED',
  Delivered = 'DELIVERED',
  Dropped = 'DROPPED',
  NotSent = 'NOT_SENT',
  Open = 'OPEN',
  Processed = 'PROCESSED',
  Sent = 'SENT',
  SpamReport = 'SPAM_REPORT',
  Unknown = 'UNKNOWN'
}

export enum EmailSubType {
  Automatic = 'AUTOMATIC',
  Changed = 'CHANGED',
  ExternalReset = 'EXTERNAL_RESET',
  Failed = 'FAILED',
  Manual = 'MANUAL',
  NewContactAddress = 'NEW_CONTACT_ADDRESS',
  OldContactAddress = 'OLD_CONTACT_ADDRESS',
  Other = 'OTHER',
  Receiver = 'RECEIVER',
  Reset = 'RESET',
  Sender = 'SENDER'
}

export enum EmailType {
  Burn = 'BURN',
  ContactMailChange = 'CONTACT_MAIL_CHANGE',
  Distribution = 'DISTRIBUTION',
  Invitation = 'INVITATION',
  Other = 'OTHER',
  Password = 'PASSWORD',
  PasswordChanged = 'PASSWORD_CHANGED',
  PasswordReset = 'PASSWORD_RESET',
  Relaunch = 'RELAUNCH',
  Share = 'SHARE',
  ShareCompleted = 'SHARE_COMPLETED',
  ShareFailed = 'SHARE_FAILED'
}

export type Empty = {
  __typename?: 'Empty';
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type Endowment = {
  __typename?: 'Endowment';
  id: Scalars['Long']['output'];
  nextExpirationDate?: Maybe<Scalars['DateTime']['output']>;
  remainingAmount?: Maybe<Money>;
  wallet: Wallet;
};

export type EndowmentDetail = {
  __typename?: 'EndowmentDetail';
  /**  if absent: DESKTOP */
  banners?: Maybe<Array<Maybe<Banner>>>;
  categories?: Maybe<Array<Category>>;
  categorySuggestions?: Maybe<Array<Category>>;
  filteredShops?: Maybe<OfferWallShopPage>;
  grants?: Maybe<Array<CompanyGrant>>;
  highlightSections: Array<WalletHighlightSection>;
  id: Scalars['Long']['output'];
  shopSuggestions?: Maybe<Array<Shop>>;
  sortings?: Maybe<Array<WalletSorting>>;
};


export type EndowmentDetailBannersArgs = {
  bannerType?: InputMaybe<BannerType>;
};


export type EndowmentDetailFilteredShopsArgs = {
  filter?: InputMaybe<OfferWallShopFilter>;
};


export type EndowmentDetailGrantsArgs = {
  shopIds: Array<Scalars['Long']['input']>;
};

export type EndowmentProvider = {
  __typename?: 'EndowmentProvider';
  available: Scalars['Boolean']['output'];
  organization: Organization;
  runningDistribution: Scalars['Boolean']['output'];
};

export enum EntityType {
  GlobalEntity = 'GLOBAL_ENTITY',
  OrganizationEntity = 'ORGANIZATION_ENTITY',
  UserEntity = 'USER_ENTITY'
}

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}

export enum EventCategory {
  AccessRights = 'ACCESS_RIGHTS',
  Security = 'SECURITY',
  TwoFactor = 'TWO_FACTOR',
  UserInformation = 'USER_INFORMATION'
}

export type EventHistoryError = {
  __typename?: 'EventHistoryError';
  message: Scalars['String']['output'];
};

export type EventHistoryPage = {
  __typename?: 'EventHistoryPage';
  events?: Maybe<Array<FrontendAdminEvent>>;
};

export type EventHistoryRequest = {
  orderByMostRecent?: InputMaybe<Scalars['Boolean']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  userId: Scalars['String']['input'];
};

export type EventHistoryResponse = EventHistoryError | EventHistoryPage;

export type ExecuteRecipientTargetingRuleResult = Empty | NewsletterError;

export type ExportReconciliationResultInput = {
  /** filter on results : all, reconciliated, unreconciliated */
  filter: Scalars['String']['input'];
  /** Path of the file to be reconciliated */
  path: Scalars['String']['input'];
};

export type ExportReconciliationResultResponse = {
  __typename?: 'ExportReconciliationResultResponse';
  /** Invoice body in Base64 bytes */
  body: Scalars['String']['output'];
  extension?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ExtendedCampaign = {
  __typename?: 'ExtendedCampaign';
  amount: Money;
  amountLeft: Money;
  beneficiaryCount: Scalars['Long']['output'];
  discountRatio?: Maybe<Scalars['BigDecimal']['output']>;
  /** @deprecated use distributedByPreview */
  distributedBy: Beneficiary;
  distributedByPreview: DistributionUserPreview;
  distributionsCanBeDeleted: Scalars['Boolean']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  initialAmount: Money;
  reason: DistributionReason;
  startDate: Scalars['DateTime']['output'];
  status: CampaignStatus;
};

export type ExtendedCampaignPage = PageResult & {
  __typename?: 'ExtendedCampaignPage';
  index: Scalars['Int']['output'];
  items: Array<ExtendedCampaign>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type ExternalAccount = {
  __typename?: 'ExternalAccount';
  name?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  sourceId: Scalars['String']['output'];
  status: Scalars['String']['output'];
  synchronizationDate?: Maybe<Scalars['String']['output']>;
};

export type ExternalAccountResult = AccountingError | ExternalAccount;

export enum ExtraDataStatusType {
  Activated = 'ACTIVATED',
  Blocked = 'BLOCKED'
}

export type Fees = {
  __typename?: 'Fees';
  serviceFee?: Maybe<ServiceFeeOutput>;
};

export type File = {
  __typename?: 'File';
  privateFileName: Scalars['String']['output'];
  publicFileName?: Maybe<Scalars['String']['output']>;
};

export type FileError = {
  __typename?: 'FileError';
  codes: Array<Scalars['String']['output']>;
  line?: Maybe<Scalars['Int']['output']>;
};

export enum FileFormat {
  Excel = 'EXCEL',
  Pdf = 'PDF'
}

export type FileMetadata = {
  __typename?: 'FileMetadata';
  content: Array<MetadataEntry>;
};

export type FileResult = {
  __typename?: 'FileResult';
  content: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  filename: Scalars['String']['output'];
};

export type FlagRemadeOrderRequest = {
  orderId?: InputMaybe<Scalars['String']['input']>;
};

export type FlagRemadeOrderResponse = {
  __typename?: 'FlagRemadeOrderResponse';
  orderId?: Maybe<Scalars['String']['output']>;
};

export type FoodAddressDataInput = {
  addressLineOne?: InputMaybe<Scalars['String']['input']>;
  addressLineTwo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryIsoCode?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export enum FoodAddressTypeEnum {
  FoodHome = 'FOOD_HOME',
  Organization = 'ORGANIZATION',
  User = 'USER'
}

export type FoodAuditing = {
  createdBy?: Maybe<Beneficiary>;
  createdDate?: Maybe<Scalars['String']['output']>;
  lastModifiedBy?: Maybe<Beneficiary>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
};

export type FoodBalance = {
  __typename?: 'FoodBalance';
  activeWallets?: Maybe<Array<Maybe<FoodWallet>>>;
  isNonWorkingDay?: Maybe<Scalars['Boolean']['output']>;
  isRestrictedOnNonWorkingDays?: Maybe<Scalars['Boolean']['output']>;
  shareDailyBalanceValue?: Maybe<Money>;
  totalBalanceValue?: Maybe<Money>;
  totalDailyBalanceValue?: Maybe<Money>;
};

export type FoodBankInfo = {
  __typename?: 'FoodBankInfo';
  bic: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  owner: Scalars['String']['output'];
};

export type FoodBaseError = {
  __typename?: 'FoodBaseError';
  message?: Maybe<Scalars['String']['output']>;
  /**  A user friendly message */
  statusCode?: Maybe<Scalars['Int']['output']>;
  /**  The http status code */
  type?: Maybe<FoodErrorType>;
};

export type FoodBeneficiariesPage = {
  __typename?: 'FoodBeneficiariesPage';
  items: Array<Maybe<Beneficiary>>;
  pageIndex: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type FoodBeneficiariesQueryInput = {
  fetchAddress?: InputMaybe<Scalars['Boolean']['input']>;
  fetchCards?: InputMaybe<Scalars['Boolean']['input']>;
  fetchExtraData?: InputMaybe<Scalars['Boolean']['input']>;
  /**  True : fetch users with no main active card; False : all users */
  hasNoActiveCard?: InputMaybe<Scalars['Boolean']['input']>;
  hasShippingAddress?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['ID']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sorts: Array<InputMaybe<SortInput>>;
};

export type FoodCardOrder = {
  __typename?: 'FoodCardOrder';
  cardOrderLines: Array<FoodCardOrderLine>;
  total: TotalPricing;
  unitPrice: Scalars['BigDecimal']['output'];
  unitVatAmount?: Maybe<Scalars['BigDecimal']['output']>;
};

export type FoodCardOrderInput = {
  beneficiariesUuids: Array<Scalars['String']['input']>;
  billingAddressId: Scalars['String']['input'];
  organizationUuid: Scalars['String']['input'];
};

export type FoodCardOrderLine = {
  __typename?: 'FoodCardOrderLine';
  addressOriginUuid: Scalars['String']['output'];
  beneficiaryId: Scalars['String']['output'];
};

export type FoodCustomerCards = {
  __typename?: 'FoodCustomerCards';
  hasWedoofoodCard?: Maybe<Scalars['Boolean']['output']>;
  items?: Maybe<Array<Maybe<CustomerCard>>>;
};

export type FoodDefaultAddress = {
  __typename?: 'FoodDefaultAddress';
  defaultAddress?: Maybe<Address>;
  defaultAddressType?: Maybe<FoodAddressTypeEnum>;
};

export type FoodDownloadOrderDocumentInput = {
  orderUuid: Scalars['String']['input'];
  organizationUuid: Scalars['String']['input'];
  type: FoodOrderDocumentType;
};

export type FoodErrorLine = {
  __typename?: 'FoodErrorLine';
  errors?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
};

export enum FoodErrorType {
  FoodNotFoundError = 'FoodNotFoundError',
  FoodPayloadTooLargeError = 'FoodPayloadTooLargeError',
  FoodUnprocessableEntityError = 'FoodUnprocessableEntityError',
  FoodUnsupportedMediaTypeError = 'FoodUnsupportedMediaTypeError'
}

export type FoodIdList = {
  __typename?: 'FoodIdList';
  ids?: Maybe<Array<Scalars['Long']['output']>>;
};

export type FoodIdListResponse = FoodBaseError | FoodIdList;

export enum FoodOrderDocumentType {
  Bill = 'BILL',
  PurchaseOrder = 'PURCHASE_ORDER',
  PurchaseOrderDetails = 'PURCHASE_ORDER_DETAILS'
}

export type FoodOrderItem = {
  __typename?: 'FoodOrderItem';
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
};

export type FoodOrderLine = FoodAuditing & {
  __typename?: 'FoodOrderLine';
  beneficiaryUuid: Beneficiary;
  card?: Maybe<CustomerCard>;
  createdBy?: Maybe<Beneficiary>;
  createdDate?: Maybe<Scalars['String']['output']>;
  expeditionDate?: Maybe<Scalars['String']['output']>;
  lastModifiedBy?: Maybe<Beneficiary>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  order?: Maybe<FoodOrderV2>;
  price?: Maybe<FoodOrderLinePrice>;
  quantity?: Maybe<Scalars['Int']['output']>;
  shippingAddressAttachmentUuid?: Maybe<Address>;
  status: FoodOrderLineStatus;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  type: FoodOrderLineType;
  uuid: Scalars['String']['output'];
};

export type FoodOrderLineInput = {
  beneficiaryId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};

export type FoodOrderLinePage = FoodPageResult & {
  __typename?: 'FoodOrderLinePage';
  items: Array<FoodOrderLine>;
  pageIndex: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type FoodOrderLinePrice = {
  __typename?: 'FoodOrderLinePrice';
  amount?: Maybe<Scalars['Float']['output']>;
  amountVat?: Maybe<Scalars['Float']['output']>;
};

export enum FoodOrderLineStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  CreationInProgress = 'CREATION_IN_PROGRESS',
  Error = 'ERROR',
  PendingForValidation = 'PENDING_FOR_VALIDATION',
  Shipped = 'SHIPPED',
  Validated = 'VALIDATED'
}

export enum FoodOrderLineType {
  Card = 'CARD',
  Reload = 'RELOAD'
}

export enum FoodOrderOrigin {
  Beneficiary = 'BENEFICIARY',
  Manager = 'MANAGER'
}

export type FoodOrderPage = FoodPageResult & {
  __typename?: 'FoodOrderPage';
  items: Array<FoodOrderV2>;
  pageIndex: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type FoodOrderSearchInput = {
  organizationUuid?: InputMaybe<Scalars['String']['input']>;
  page: FoodPageRequest;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<FoodOrderStatus>>>;
  types?: InputMaybe<Array<InputMaybe<FoodOrderType>>>;
};

export enum FoodOrderStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED',
  CreationInProgress = 'CREATION_IN_PROGRESS',
  Error = 'ERROR',
  Shipped = 'SHIPPED',
  Validated = 'VALIDATED',
  ValidationInProgress = 'VALIDATION_IN_PROGRESS'
}

export enum FoodOrderType {
  Card = 'CARD',
  Multi = 'MULTI',
  Reload = 'RELOAD'
}

/** ########### DISPLAY ORDERS ############ */
export type FoodOrderV2 = FoodAuditing & {
  __typename?: 'FoodOrderV2';
  bankInfo?: Maybe<FoodBankInfo>;
  billReference?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Beneficiary>;
  createdDate?: Maybe<Scalars['String']['output']>;
  lastModifiedBy?: Maybe<Beneficiary>;
  lastModifiedDate?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  origin?: Maybe<FoodOrderOrigin>;
  reference: Scalars['String']['output'];
  status?: Maybe<FoodOrderStatus>;
  totalAmount?: Maybe<Scalars['BigDecimal']['output']>;
  type?: Maybe<FoodOrderType>;
  uuid: Scalars['String']['output'];
  validatedBy?: Maybe<Beneficiary>;
  validationDate?: Maybe<Scalars['String']['output']>;
};

/** ########### DISPLAY ORDERS LINES ############ */
export type FoodOrdersLinesSearchInput = {
  beneficiaryUuids?: InputMaybe<Array<Scalars['String']['input']>>;
  organizationUuid: Scalars['String']['input'];
  page: FoodPageRequest;
  search?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<InputMaybe<FoodOrderLineType>>>;
};

export type FoodPageRequest = {
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type FoodPageResult = {
  pageIndex: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type FoodPageResultV2 = {
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

/**  Retrieve beneficiaries reload summary task */
export type FoodReloadFileValidationResponse = FoodBaseError | Task;

export type FoodReloadOrderQueryInput = {
  billingAddressId: Scalars['ID']['input'];
  orderLines: Array<InputMaybe<FoodOrderLineInput>>;
  organizationId: Scalars['ID']['input'];
};

export type FoodReloadSummary = {
  __typename?: 'FoodReloadSummary';
  active?: Maybe<Scalars['Boolean']['output']>;
  beneficiaryUuid?: Maybe<Scalars['String']['output']>;
  creation?: Maybe<Scalars['Boolean']['output']>;
  details?: Maybe<Array<FoodReloadSummaryDetail>>;
  disabledDate?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
  login?: Maybe<Scalars['String']['output']>;
  organizationUuid?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  selected?: Maybe<Scalars['Boolean']['output']>;
  taskUuid: Scalars['String']['output'];
  workingDays?: Maybe<Scalars['Int']['output']>;
};

export type FoodReloadSummaryDetail = {
  __typename?: 'FoodReloadSummaryDetail';
  message?: Maybe<FoodReloadSummaryDetailSeverityMessage>;
  severity: FoodReloadSummaryDetailSeverity;
};

export enum FoodReloadSummaryDetailSeverity {
  Critical = 'CRITICAL',
  Error = 'ERROR',
  Info = 'INFO',
  Warning = 'WARNING'
}

export enum FoodReloadSummaryDetailSeverityMessage {
  BeneficiaryCreation = 'BENEFICIARY_CREATION',
  BeneficiaryCreationError = 'BENEFICIARY_CREATION_ERROR',
  EmailInvalid = 'EMAIL_INVALID',
  EmailSizeLimitExceeded = 'EMAIL_SIZE_LIMIT_EXCEEDED',
  FirstNameInvalid = 'FIRST_NAME_INVALID',
  FirstNameRequired = 'FIRST_NAME_REQUIRED',
  FirstNameSizeLimitExceeded = 'FIRST_NAME_SIZE_LIMIT_EXCEEDED',
  LastNameInvalid = 'LAST_NAME_INVALID',
  LastNameRequired = 'LAST_NAME_REQUIRED',
  LastNameSizeLimitExceeded = 'LAST_NAME_SIZE_LIMIT_EXCEEDED',
  LoginDuplicate = 'LOGIN_DUPLICATE',
  LoginInvalid = 'LOGIN_INVALID',
  LoginInvalidBlankMail = 'LOGIN_INVALID_BLANK_MAIL',
  LoginInvalidBlankMailSizeLimitExceeded = 'LOGIN_INVALID_BLANK_MAIL_SIZE_LIMIT_EXCEEDED',
  LoginRequired = 'LOGIN_REQUIRED',
  RegistrationNumberInvalid = 'REGISTRATION_NUMBER_INVALID',
  RegistrationNumberSizeLimitExceeded = 'REGISTRATION_NUMBER_SIZE_LIMIT_EXCEEDED',
  UserDisabledSince = 'USER_DISABLED_SINCE',
  WorkingDaysInvalid = 'WORKING_DAYS_INVALID',
  WorkingDaysNegative = 'WORKING_DAYS_NEGATIVE',
  WorkingDaysSizeLimitExceeded = 'WORKING_DAYS_SIZE_LIMIT_EXCEEDED'
}

export type FoodReloadSummaryError = {
  __typename?: 'FoodReloadSummaryError';
  details?: Maybe<Array<FoodReloadSummaryDetail>>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
};

export type FoodReloadSummaryErrors = {
  __typename?: 'FoodReloadSummaryErrors';
  content?: Maybe<Array<FoodReloadSummaryError>>;
};

/**  Retrieve beneficiaries reload summary errors */
export type FoodReloadSummaryErrorsInput = {
  organizationUuid: Scalars['String']['input'];
  taskUuid: Scalars['String']['input'];
};

export type FoodReloadSummaryErrorsResponse = FoodBaseError | FoodReloadSummaryErrors;

export type FoodReloadSummaryInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  beneficiaryUuid?: InputMaybe<Scalars['String']['input']>;
  creation?: InputMaybe<Scalars['Boolean']['input']>;
  disabledDate?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  lineNumber?: InputMaybe<Scalars['Int']['input']>;
  login?: InputMaybe<Scalars['String']['input']>;
  organizationUuid?: InputMaybe<Scalars['String']['input']>;
  registrationNumber?: InputMaybe<Scalars['String']['input']>;
  selected?: InputMaybe<Scalars['Boolean']['input']>;
  taskUuid: Scalars['String']['input'];
  workingDays?: InputMaybe<Scalars['Int']['input']>;
};

/**  Retrieve beneficiaries reload summary page */
export type FoodReloadSummaryPage = FoodPageResultV2 & {
  __typename?: 'FoodReloadSummaryPage';
  content?: Maybe<Array<FoodReloadSummary>>;
  number: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalElements: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type FoodReloadSummaryResponse = FoodBaseError | FoodReloadSummaryPage;

export type FoodReloadSummarySearch = {
  organizationUuid: Scalars['String']['input'];
  page: FoodPageRequest;
  search?: InputMaybe<Scalars['String']['input']>;
  taskUuid: Scalars['String']['input'];
};

export type FoodReloadSummarySingleResponse = FoodBaseError | FoodReloadSummary;

export type FoodRenewalCardOrderInput = {
  addressOriginUuid?: InputMaybe<Scalars['ID']['input']>;
  beneficiaryId: Scalars['ID']['input'];
};

export type FoodSetOrganizationAddressInput = {
  addressAttachmentId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type FoodSetUserAddressInput = {
  addressAttachmentId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type FoodSetUserHomeAddressInput = {
  address?: InputMaybe<FoodAddressDataInput>;
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type FoodShippingAddress = {
  __typename?: 'FoodShippingAddress';
  address?: Maybe<Address>;
  cardCount?: Maybe<Scalars['Int']['output']>;
};

export type FoodTask = {
  __typename?: 'FoodTask';
  errorLines?: Maybe<Array<Maybe<FoodErrorLine>>>;
  id: Scalars['ID']['output'];
  orderItems?: Maybe<Array<Maybe<FoodOrderItem>>>;
  status?: Maybe<Scalars['String']['output']>;
};

export type FoodTaskResponse = FoodBaseError | FoodTask;

export type FoodTaskResponseV2 = FoodBaseError | Task;

export type FoodTinyBalance = {
  __typename?: 'FoodTinyBalance';
  authorizedBalance?: Maybe<Scalars['BigDecimal']['output']>;
  settledBalance?: Maybe<Scalars['BigDecimal']['output']>;
};

export type FoodTotal = {
  __typename?: 'FoodTotal';
  total: Scalars['Int']['output'];
};

export type FoodTotalResponse = FoodBaseError | FoodTotal;

export type FoodTransaction = {
  __typename?: 'FoodTransaction';
  amount: Scalars['BigDecimal']['output'];
  date: Scalars['DateTime']['output'];
  details?: Maybe<Scalars['String']['output']>;
  errorStatus?: Maybe<Scalars['String']['output']>;
  hashedCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  status: FoodTransactionStatus;
  topupAmount: Scalars['BigDecimal']['output'];
  total: Scalars['BigDecimal']['output'];
  totalAmountReported: Scalars['BigDecimal']['output'];
  type: FoodTransactionType;
};

export enum FoodTransactionStatus {
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED'
}

export enum FoodTransactionType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
  Vintage = 'VINTAGE',
  VintageAdjustment = 'VINTAGE_ADJUSTMENT'
}

export type FoodTransactionsPage = {
  __typename?: 'FoodTransactionsPage';
  items: Array<Maybe<FoodTransaction>>;
  pageIndex: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type FoodTransactionsQueryInput = {
  beneficiaryId: Scalars['ID']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort: SortInput;
  transactionStatus?: InputMaybe<Array<InputMaybe<FoodTransactionStatus>>>;
  transactionType?: InputMaybe<Array<InputMaybe<FoodTransactionType>>>;
};

export type FoodWallet = {
  __typename?: 'FoodWallet';
  activatedDate?: Maybe<Scalars['DateTime']['output']>;
  balance?: Maybe<FoodTinyBalance>;
  expiredDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  status?: Maybe<FoodWalletStatus>;
};

export enum FoodWalletStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type FranceBilletSsoLink = {
  __typename?: 'FranceBilletSsoLink';
  url: Scalars['String']['output'];
};

export type FranceBilletSsoResponse = FranceBilletSsoLink | GiftError;

export type FrontendAdminEvent = {
  __typename?: 'FrontendAdminEvent';
  category: EventCategory;
  date: Scalars['String']['output'];
  hour: Scalars['String']['output'];
  initiator?: Maybe<Beneficiary>;
  newValue?: Maybe<Scalars['String']['output']>;
  oldValue?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

export type FunctionalModule = {
  __typename?: 'FunctionalModule';
  code: Scalars['ID']['output'];
  internalName: Scalars['String']['output'];
};

export type FundableWallet = {
  __typename?: 'FundableWallet';
  grantConfigurations: Array<GrantConfiguration>;
  wallet: Wallet;
};

export enum FundedShippingMode {
  Company = 'COMPANY',
  Consumer = 'CONSUMER'
}

export type GenerateOnboardingLinkRequest = {
  organizationId: Scalars['ID']['input'];
  refreshUrl?: InputMaybe<Scalars['String']['input']>;
  returnUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GenericDistribution = {
  amount: Money;
  campaignStatus: CampaignStatus;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  creationDate: Scalars['DateTime']['output'];
  /**  can be null if campaign is planned */
  detail?: Maybe<DistributionDetail>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  massSharingEnabled: Scalars['Boolean']['output'];
  reason: DistributionReason;
  userPreview: DistributionUserPreview;
  wallet: Wallet;
};

export type GenericDistributionPage = PageResult & {
  __typename?: 'GenericDistributionPage';
  index: Scalars['Int']['output'];
  items: Array<GenericDistribution>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type GenericShopFee = ShopFee & {
  __typename?: 'GenericShopFee';
  amount?: Maybe<Money>;
  amountRoundingMode?: Maybe<ShopFeeAmountRoundingMode>;
  billingMode: ShopFeeBillingMode;
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  percentage?: Maybe<Scalars['BigDecimal']['output']>;
  shippingMode?: Maybe<ShopFeeShippingMode>;
  shops?: Maybe<Array<Maybe<Shop>>>;
  type: ShopFeeType;
};

export type GestionError = {
  __typename?: 'GestionError';
  errorCodes: Array<Scalars['String']['output']>;
};

export type GetAddressAutocompleteRequest = {
  address: Scalars['String']['input'];
  autocompleteId: Scalars['String']['input'];
  countryIsoCode: Scalars['String']['input'];
};

export type GetCommunicationCampaignResult = CommunicationCampaign | CommunicationError;

export type GetCommunicationFileTrackingResult = CommunicationError | CommunicationFileTracking;

export type GetCommunicationFileUrlResult = CommunicationError | CommunicationFileUrl;

export type GetCommunicationTrackingResult = CommunicationError | CommunicationTracking;

export type GetCommunicationUserSurveyAnswers = {
  __typename?: 'GetCommunicationUserSurveyAnswers';
  answer?: Maybe<SurveyAnswer>;
};

export type GetCommunicationUserSurveyAnswersResult = CommunicationError | GetCommunicationUserSurveyAnswers;

export type GetDiscOrderReferenceRequest = {
  externalReference?: InputMaybe<Scalars['String']['input']>;
};

export type GetDiscOrderReferenceResponse = {
  __typename?: 'GetDiscOrderReferenceResponse';
  discOrderReference?: Maybe<Scalars['String']['output']>;
};

export type GetLastPaperDraftOrderRequest = {
  numberOfHours?: InputMaybe<Scalars['Long']['input']>;
  organizationId: Scalars['String']['input'];
};

export type GetLastPaperDraftOrderResponse = {
  __typename?: 'GetLastPaperDraftOrderResponse';
  billingOrderId?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  options: PaperOrderOptionsOutput;
  products: Array<PaperOrderProductOutput>;
  simulationId?: Maybe<Scalars['String']['output']>;
};

export type GetNewsletterAccountResult = NewsletterAccount | NewsletterError;

export type GetNewsletterCampaignParametersResult = NewsletterCampaignParameters | NewsletterError;

export type GetNewsletterCanvasResult = NewsletterCanvas | NewsletterError;

export type GetNewsletterGuideUrlResult = NewsletterError | NewsletterGuideUrl;

export type GetNewsletterRecipientCountResult = NewsletterError | NewsletterRecipientCount;

export type GetNewsletterRecipientTargetingRule = {
  __typename?: 'GetNewsletterRecipientTargetingRule';
  targetingRule?: Maybe<NewsletterRecipientTargetingRule>;
};

export type GetNewsletterRecipientTargetingRuleResult = GetNewsletterRecipientTargetingRule | NewsletterError;

export type GetNewsletterTemplateListResult = NewsletterError | NewsletterTemplateList;

export type GetNewsletterTemplatePreviewResult = NewsletterError | NewsletterTemplatePreview;

export type GetOrderDetailsRequest = {
  orderRefGlady?: InputMaybe<Scalars['String']['input']>;
};

export type GetOrderHistoryRequest = {
  lastEvaluatedKey?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type GetOrderHistoryResponse = {
  __typename?: 'GetOrderHistoryResponse';
  items?: Maybe<Array<Maybe<OrderHistoryItem>>>;
  lastEvaluatedKey?: Maybe<Scalars['String']['output']>;
};

export type GetOrderRequest = {
  billingOrderId?: InputMaybe<Scalars['String']['input']>;
};

export type GetOrderResponse = {
  __typename?: 'GetOrderResponse';
  billingInformation?: Maybe<PaperBillingInformation>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  options?: Maybe<PaperOrderOptionsOutput>;
  products?: Maybe<Array<Maybe<PaperOrderProductOutput>>>;
};

export type GetOrderShipmentsRequest = {
  orderId?: InputMaybe<Scalars['String']['input']>;
};

export type GetOrderShipmentsResponse = {
  __typename?: 'GetOrderShipmentsResponse';
  shipments?: Maybe<Array<Maybe<Shipment>>>;
};

export type GetOrderStatusRequest = {
  orderId?: InputMaybe<Scalars['String']['input']>;
};

export type GetOrderStatusResponse = {
  __typename?: 'GetOrderStatusResponse';
  status?: Maybe<PaperOrderOrderStatus>;
};

export type GetOrganizationInformationRequest = {
  SocieteInfoId: Scalars['String']['input'];
  prospectId?: InputMaybe<Scalars['ID']['input']>;
};

export type GetOrganizationInformationResult = {
  __typename?: 'GetOrganizationInformationResult';
  addressLine?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
  full_registration_number?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  last_staff?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type GetPaymentUrlRequest = {
  billingOrderId?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  successUrl?: InputMaybe<Scalars['String']['input']>;
};

export type GetPaymentUrlResponse = {
  __typename?: 'GetPaymentUrlResponse';
  url?: Maybe<Scalars['String']['output']>;
};

export type GetSimulationProduct = {
  __typename?: 'GetSimulationProduct';
  amountPerBeneficiary: Money;
  chequeClassification: PaperOrderChequeClassification;
  chequeType?: Maybe<PaperOrderChequeType>;
  denominations: Denominations;
  numberOfBeneficiaries: Scalars['Int']['output'];
};

export type GetSimulationRequest = {
  simulationId: Scalars['String']['input'];
};

export type GetSimulationResponse = {
  __typename?: 'GetSimulationResponse';
  email: Scalars['String']['output'];
  paperSimulationOptions: SimulationOptions;
  products: Array<GetSimulationProduct>;
};

export type GetSiteResult = SiteError | SiteInfo;

export type GiftAddress = {
  __typename?: 'GiftAddress';
  city?: Maybe<Scalars['String']['output']>;
  corporateName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  streetLineOne?: Maybe<Scalars['String']['output']>;
  streetLineTwo?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type GiftBooleanTaskResult = {
  __typename?: 'GiftBooleanTaskResult';
  result: Scalars['Boolean']['output'];
};

export type GiftConfiguration = {
  __typename?: 'GiftConfiguration';
  checkoutPublicKey: Scalars['String']['output'];
  payment: PaymentConfiguration;
  sharing: SharingConfiguration;
  shippingCountriesIso3?: Maybe<Array<Scalars['String']['output']>>;
};


export type GiftConfigurationPaymentArgs = {
  currency: Scalars['String']['input'];
};

export type GiftDefaultCampaignConfiguration = {
  __typename?: 'GiftDefaultCampaignConfiguration';
  discountRatio: Scalars['Float']['output'];
  discountRatioEndDate?: Maybe<Scalars['DateTime']['output']>;
  discountRatioStartDate?: Maybe<Scalars['DateTime']['output']>;
  externalOrderNumberEnabled: Scalars['Boolean']['output'];
};

export type GiftDeposit = {
  __typename?: 'GiftDeposit';
  active: Scalars['Boolean']['output'];
  balance: Money;
  companyDiscountSensitive: Scalars['Boolean']['output'];
  currency: Scalars['String']['output'];
  distributionAvailable: Scalars['Boolean']['output'];
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  noShowBalance: Money;
  principal: Scalars['Boolean']['output'];
  transactions?: Maybe<DepositTransactionResult>;
  transactionsReport?: Maybe<Scalars['String']['output']>;
  uuid: Scalars['ID']['output'];
};


export type GiftDepositTransactionsArgs = {
  request: PageRequest;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type GiftError = IGiftError & {
  __typename?: 'GiftError';
  code: Scalars['Int']['output'];
  fileErrors?: Maybe<Array<FileError>>;
  message: Scalars['String']['output'];
};

export type GiftErrors = IGiftError & {
  __typename?: 'GiftErrors';
  code: Scalars['Int']['output'];
  messages: Array<Scalars['String']['output']>;
};

export type GiftFile = {
  __typename?: 'GiftFile';
  file: Scalars['String']['output'];
};

export type GiftFileResponse = GiftError | GiftFile;

export type GiftFileTaskResult = {
  __typename?: 'GiftFileTaskResult';
  file: Scalars['String']['output'];
};

export type GiftGenericDistributionSpec = {
  nextExpirationFirst?: InputMaybe<Scalars['Boolean']['input']>;
  notExpired?: InputMaybe<Scalars['Boolean']['input']>;
  positiveLeftAmount?: InputMaybe<Scalars['Boolean']['input']>;
  shareableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  walletId?: InputMaybe<Scalars['Long']['input']>;
};

export type GiftMailPreview = {
  __typename?: 'GiftMailPreview';
  content: Scalars['String']['output'];
};

export type GiftMailPreviewResponse = GiftError | GiftMailPreview;

export type GiftOrder = {
  __typename?: 'GiftOrder';
  amountIncludingVat: Money;
  amountVat?: Maybe<Money>;
  creationDate: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  paymentType?: Maybe<PaymentType>;
  products?: Maybe<Array<OrderProduct>>;
  reference?: Maybe<Scalars['String']['output']>;
  status: OrderStatus;
  type: CompanyOrderType;
};

export type GiftOrderPage = PageResult & {
  __typename?: 'GiftOrderPage';
  index: Scalars['Int']['output'];
  items: Array<GiftOrder>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type GiftOrderResponse = GiftError | GiftOrder;

export type GiftOrderSpec = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  amount?: InputMaybe<MoneyInput>;
  paid?: InputMaybe<Scalars['Boolean']['input']>;
  paymentTypes?: InputMaybe<Array<PaymentTargetType>>;
  search?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<OrderStatus>>;
  types?: InputMaybe<Array<CompanyOrderType>>;
};

export type GiftOrganizationConfiguration = {
  __typename?: 'GiftOrganizationConfiguration';
  defaultCampaignConfiguration: GiftDefaultCampaignConfiguration;
};

export type GiftShareTaskResult = {
  __typename?: 'GiftShareTaskResult';
  result?: Maybe<Array<GiftShareTaskResultItem>>;
};

export type GiftShareTaskResultItem = {
  __typename?: 'GiftShareTaskResultItem';
  amount: Money;
  distributionId: Scalars['Long']['output'];
  userUuid: Scalars['ID']['output'];
};

export type GiftShareValidationTaskResult = {
  __typename?: 'GiftShareValidationTaskResult';
  result?: Maybe<Array<GiftShareValidationTaskResultItem>>;
};

export type GiftShareValidationTaskResultItem = {
  __typename?: 'GiftShareValidationTaskResultItem';
  amount: Money;
  receiverFirstName: Scalars['String']['output'];
  receiverLastName: Scalars['String']['output'];
  receiverLogin: Scalars['String']['output'];
};

export type GiftShopPackageSpec = {
  isDefaultConfigOfWallet?: InputMaybe<Scalars['Long']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<ShopPackageType>>;
};

export type GiftTask = {
  __typename?: 'GiftTask';
  id: Scalars['Long']['output'];
  initDate: Scalars['DateTime']['output'];
  status?: Maybe<GiftTaskStatus>;
  statusMessage?: Maybe<Scalars['String']['output']>;
  step: Scalars['Int']['output'];
  totalSteps: Scalars['Int']['output'];
  updateDate?: Maybe<Scalars['DateTime']['output']>;
};

export type GiftTaskResponse = GiftError | GiftTask;

export type GiftTaskResultResponse = GiftBooleanTaskResult | GiftError | GiftErrors | GiftFileTaskResult | GiftShareTaskResult | GiftShareValidationTaskResult;

export enum GiftTaskStatus {
  Completed = 'COMPLETED',
  Executing = 'EXECUTING',
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Rejected = 'REJECTED',
  Starting = 'STARTING'
}

export enum GiftTaskType {
  AnonymousBeneficiariesCreation = 'ANONYMOUS_BENEFICIARIES_CREATION',
  BeneficiariesArchival = 'BENEFICIARIES_ARCHIVAL',
  BeneficiariesCreation = 'BENEFICIARIES_CREATION',
  CampaignsReport = 'CAMPAIGNS_REPORT',
  CampaignCreation = 'CAMPAIGN_CREATION',
  CampaignMailsReportGeneration = 'CAMPAIGN_MAILS_REPORT_GENERATION',
  CardsCodesEdition = 'CARDS_CODES_EDITION',
  CardsImport = 'CARDS_IMPORT',
  CardsPricesEdition = 'CARDS_PRICES_EDITION',
  CardExpedition = 'CARD_EXPEDITION',
  CardExport = 'CARD_EXPORT',
  CompanyBeneficiariesAccessExportGeneration = 'COMPANY_BENEFICIARIES_ACCESS_EXPORT_GENERATION',
  CompanyBeneficiariesExportGeneration = 'COMPANY_BENEFICIARIES_EXPORT_GENERATION',
  CompanyBeneficiaryAccessUpdate = 'COMPANY_BENEFICIARY_ACCESS_UPDATE',
  CompanyCategoryUpdate = 'COMPANY_CATEGORY_UPDATE',
  CompanyManagerUpdate = 'COMPANY_MANAGER_UPDATE',
  ConsumedPrimaryCodesExport = 'CONSUMED_PRIMARY_CODES_EXPORT',
  CreateMultiShopCards = 'CREATE_MULTI_SHOP_CARDS',
  DistributionsCancel = 'DISTRIBUTIONS_CANCEL',
  GenerateCardVisuals = 'GENERATE_CARD_VISUALS',
  GroupRefund = 'GROUP_REFUND',
  ManualJob = 'MANUAL_JOB',
  OrderCsvAnalyze = 'ORDER_CSV_ANALYZE',
  OrderValidation = 'ORDER_VALIDATION',
  Sharing = 'SHARING',
  StockAlertImport = 'STOCK_ALERT_IMPORT',
  UsersSync = 'USERS_SYNC',
  UserDelete = 'USER_DELETE',
  UserInvite = 'USER_INVITE',
  UserLoginRename = 'USER_LOGIN_RENAME',
  UserRelaunch = 'USER_RELAUNCH',
  UserRelaunchAutomatic = 'USER_RELAUNCH_AUTOMATIC',
  WalletSpecificSettingsImport = 'WALLET_SPECIFIC_SETTINGS_IMPORT'
}

export type GlobalParameters = {
  __typename?: 'GlobalParameters';
  isGlobalCaptchaEnabled: Scalars['Boolean']['output'];
  isGlobalTwoFactorEnabled: Scalars['Boolean']['output'];
};

export type GlobalParsingError = {
  __typename?: 'GlobalParsingError';
  type?: Maybe<ParsingErrorType>;
};

export type GrantConfiguration = {
  __typename?: 'GrantConfiguration';
  deposit?: Maybe<GiftDeposit>;
  durationDays?: Maybe<Scalars['Int']['output']>;
  durationMonths?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  fundedAmountPerUser?: Maybe<Money>;
  fundedRatio?: Maybe<Scalars['BigDecimal']['output']>;
  id: Scalars['Long']['output'];
  limitAmountPerCompany?: Maybe<Money>;
  limitAmountPerUser?: Maybe<Money>;
  limitCountPerCompany?: Maybe<Scalars['Int']['output']>;
  limitCountPerUser?: Maybe<Scalars['Int']['output']>;
  paidAmountPerUser?: Maybe<Money>;
  renewal?: Maybe<Scalars['Boolean']['output']>;
  renewalCount?: Maybe<Scalars['Int']['output']>;
  shopPackage?: Maybe<ShopPackage>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type GrantRequest = {
  depositId: Scalars['ID']['input'];
  durationDays?: InputMaybe<Scalars['Int']['input']>;
  durationMonths?: InputMaybe<Scalars['Int']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  fundedAmountPerUser?: InputMaybe<MoneyInput>;
  fundedRatio?: InputMaybe<Scalars['BigDecimal']['input']>;
  limitAmountPerCompany?: InputMaybe<MoneyInput>;
  limitAmountPerUser?: InputMaybe<MoneyInput>;
  limitCountPerCompany?: InputMaybe<Scalars['Int']['input']>;
  limitCountPerUser?: InputMaybe<Scalars['Int']['input']>;
  paidAmountPerUser?: InputMaybe<MoneyInput>;
  renewal?: InputMaybe<Scalars['Boolean']['input']>;
  renewalCount?: InputMaybe<Scalars['Int']['input']>;
  shopPackageId: Scalars['Long']['input'];
  startDate: Scalars['DateTime']['input'];
  walletId: Scalars['Long']['input'];
};

export type GroupOption = {
  __typename?: 'GroupOption';
  type?: Maybe<GroupType>;
};

export type GroupOptionInput = {
  type?: InputMaybe<GroupType>;
};

export type GroupOptionOutput = {
  __typename?: 'GroupOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<GroupType>;
};

export enum GroupType {
  Amount = 'AMOUNT',
  AmountAndEvent = 'AMOUNT_AND_EVENT',
  Beneficiary = 'BENEFICIARY',
  Event = 'EVENT',
  Site = 'SITE',
  Unknown = 'UNKNOWN'
}

export type IFrameData = {
  __typename?: 'IFrameData';
  accountId?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  oauthToken?: Maybe<Scalars['String']['output']>;
  pageId?: Maybe<Scalars['String']['output']>;
  paymentPageUrl?: Maybe<Scalars['String']['output']>;
  publicKey?: Maybe<Scalars['String']['output']>;
  rsaSignature?: Maybe<Scalars['String']['output']>;
  tenantId?: Maybe<Scalars['String']['output']>;
  zuoraBaseUrl?: Maybe<Scalars['String']['output']>;
};

export type IGiftError = {
  code: Scalars['Int']['output'];
};

export enum IdentifierType {
  EuropeanVatNumber = 'EUROPEAN_VAT_NUMBER',
  Rna = 'RNA',
  Siret = 'SIRET'
}

export type ImportAnonymousBeneficiariesMassImportV2Request = {
  organizationId: Scalars['ID']['input'];
};

export type ImportBeneficiariesMassImportV6Request = {
  importParameters: ImportParametersBatchV3;
  organizationId: Scalars['ID']['input'];
};

export type ImportComplexOrderFileRequest = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  fileUuid?: InputMaybe<Scalars['String']['input']>;
  orderId?: InputMaybe<Scalars['String']['input']>;
};

export type ImportComplexOrderFileResponse = {
  __typename?: 'ImportComplexOrderFileResponse';
  errors?: Maybe<Array<Maybe<ParsingError>>>;
};

export type ImportParameters = {
  generatePassword: Scalars['Boolean']['input'];
  invitationType?: InputMaybe<InvitationType>;
  isDistribution: Scalars['Boolean']['input'];
  isManagerProxi: Scalars['Boolean']['input'];
  sendMail: Scalars['Boolean']['input'];
};

export type ImportParametersBatchV3 = {
  balance?: InputMaybe<Scalars['Float']['input']>;
  generatePassword: Scalars['Boolean']['input'];
  ignoreWarnings: Scalars['Boolean']['input'];
  invitationType?: InputMaybe<InvitationType>;
  isDistribution: Scalars['Boolean']['input'];
  isManagerProxi: Scalars['Boolean']['input'];
  sendMail: Scalars['Boolean']['input'];
};

export type InputCardOwner = {
  civility: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type InputGiftAddress = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  streetLineOne: Scalars['String']['input'];
  streetLineTwo?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type InvitationOptions = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  type: InvitationType;
};

export type InvitationResponse = {
  __typename?: 'InvitationResponse';
  /** @deprecated Field no longer supported */
  isSent: Scalars['Boolean']['output'];
};

export enum InvitationType {
  Site = 'SITE',
  Standard = 'STANDARD'
}

export type Invoice = {
  __typename?: 'Invoice';
  /** Zuora account id */
  accountId: Scalars['String']['output'];
  adjustmentAmount: Scalars['String']['output'];
  amount: Scalars['String']['output'];
  amountWithoutTax: Scalars['String']['output'];
  balance: Scalars['String']['output'];
  /** Invoice body in Base64 bytes */
  body: Scalars['String']['output'];
  createdById: Scalars['String']['output'];
  createdDate: Scalars['DateTime']['output'];
  dueDate: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  includesOneTime: Scalars['Boolean']['output'];
  includesRecurring: Scalars['Boolean']['output'];
  includesUsage: Scalars['Boolean']['output'];
  invoiceDate: Scalars['DateTime']['output'];
  invoiceNumber: Scalars['String']['output'];
  lastEmailSentDate: Scalars['DateTime']['output'];
  paymentAmount: Scalars['String']['output'];
  postedBy: Scalars['String']['output'];
  postedDate: Scalars['DateTime']['output'];
  refundAmount: Scalars['String']['output'];
  status: Scalars['String']['output'];
  targetDate: Scalars['DateTime']['output'];
  taxAmount: Scalars['String']['output'];
  taxExemptAmount: Scalars['String']['output'];
  updatedById: Scalars['String']['output'];
  updatedDate: Scalars['DateTime']['output'];
};

export type KnownDevice = {
  __typename?: 'KnownDevice';
  browser?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  hasBiometryActivated: Scalars['Boolean']['output'];
  hash: Scalars['String']['output'];
  lastActivityDate: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  validated: Scalars['Boolean']['output'];
  visitorId: Scalars['String']['output'];
};

export type KnownDeviceDeletion = {
  deviceId: Scalars['String']['input'];
  userId: Scalars['ID']['input'];
};

export type KnownDeviceDeletionResponse = {
  __typename?: 'KnownDeviceDeletionResponse';
  error?: Maybe<Scalars['String']['output']>;
};

export enum Language {
  Dutch = 'DUTCH',
  English = 'ENGLISH',
  French = 'FRENCH'
}

export type LastDistributionOrigin = Beneficiary | Organization;

export type LetterTemplateOption = {
  __typename?: 'LetterTemplateOption';
  /** deprecated */
  bucket?: Maybe<Scalars['String']['output']>;
  fileUuid?: Maybe<Scalars['String']['output']>;
};

export type LetterTemplateOptionInput = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  fileUuid?: InputMaybe<Scalars['String']['input']>;
};

export type LetterTemplateOptionOutput = {
  __typename?: 'LetterTemplateOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export enum LineErrorType {
  EmptyAddress = 'EMPTY_ADDRESS',
  EmptyCityName = 'EMPTY_CITY_NAME',
  EmptyCountry = 'EMPTY_COUNTRY',
  EmptyEmail = 'EMPTY_EMAIL',
  EmptyFirstName = 'EMPTY_FIRST_NAME',
  EmptyLastName = 'EMPTY_LAST_NAME',
  EmptyPhoneNumber = 'EMPTY_PHONE_NUMBER',
  EmptyShippingContactFirstName = 'EMPTY_SHIPPING_CONTACT_FIRST_NAME',
  EmptyShippingContactLastName = 'EMPTY_SHIPPING_CONTACT_LAST_NAME',
  EmptySiteName = 'EMPTY_SITE_NAME',
  EmptyZipCode = 'EMPTY_ZIP_CODE',
  InvalidAddressHasTooManyLineBreaks = 'INVALID_ADDRESS_HAS_TOO_MANY_LINE_BREAKS',
  InvalidEmail = 'INVALID_EMAIL',
  InvalidFirstName = 'INVALID_FIRST_NAME',
  InvalidLastName = 'INVALID_LAST_NAME',
  InvalidMaxCharactersFirstName = 'INVALID_MAX_CHARACTERS_FIRST_NAME',
  InvalidMaxCharactersLastName = 'INVALID_MAX_CHARACTERS_LAST_NAME',
  InvalidPhoneNumber = 'INVALID_PHONE_NUMBER',
  InvalidShippingContactFirstName = 'INVALID_SHIPPING_CONTACT_FIRST_NAME',
  InvalidShippingContactLastName = 'INVALID_SHIPPING_CONTACT_LAST_NAME',
  InvalidZipCode = 'INVALID_ZIP_CODE'
}

export type LineParsingError = {
  __typename?: 'LineParsingError';
  lineErrorType?: Maybe<LineErrorType>;
  lineNumber?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<ParsingErrorType>;
};

export type ListAccVoucher = {
  __typename?: 'ListAccVoucher';
  pagination?: Maybe<AccVoucherPagination>;
  vouchers: Array<AccVoucher>;
};

export type ListAccVoucherResult = AccountingError | ListAccVoucher;

export type ListCommunicationCampaign = {
  __typename?: 'ListCommunicationCampaign';
  campaigns?: Maybe<Array<Maybe<CommunicationCampaign>>>;
  pagination?: Maybe<CommunicationCampaignPagination>;
};

export type ListCommunicationCampaignResult = CommunicationError | ListCommunicationCampaign;

export type ListCommunicationCampaignsWithBeneficiaryView = {
  __typename?: 'ListCommunicationCampaignsWithBeneficiaryView';
  campaigns?: Maybe<Array<Maybe<CommunicationCampaignWithBeneficiaryView>>>;
  pagination?: Maybe<CommunicationCampaignPagination>;
};

export type ListCommunicationCampaignsWithBeneficiaryViewResult = CommunicationError | ListCommunicationCampaignsWithBeneficiaryView;

export type ListNewsletterDraftCampaignResult = NewsletterError | NewsletterListDraftCampaigns;

export type ListNewsletterSentCampaignResult = NewsletterError | NewsletterListSentCampaigns;

export type LogoOption = {
  __typename?: 'LogoOption';
  /** deprecated */
  bucket: Scalars['String']['output'];
  fileUuid: Scalars['String']['output'];
  type: LogoType;
};

export type LogoOptionInput = {
  bucket: Scalars['String']['input'];
  fileUuid: Scalars['String']['input'];
  type: LogoType;
};

export type LogoOptionOutput = {
  __typename?: 'LogoOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<LogoType>;
};

export enum LogoType {
  BlackWhite = 'BLACK_WHITE',
  Color = 'COLOR'
}

export type Mail = {
  __typename?: 'Mail';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  toRecipients?: Maybe<Array<Scalars['String']['output']>>;
  type: Scalars['String']['output'];
};

export type MailPage = {
  __typename?: 'MailPage';
  index: Scalars['Int']['output'];
  items: Array<Mail>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type MailStatus = {
  __typename?: 'MailStatus';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  eventTime?: Maybe<Scalars['DateTime']['output']>;
  externalUuid?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  messageId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type MailTracking = {
  __typename?: 'MailTracking';
  date: Scalars['DateTime']['output'];
  status: MailTrackingStatus;
};

export enum MailTrackingStatus {
  Bounce = 'BOUNCE',
  Click = 'CLICK',
  Deferred = 'DEFERRED',
  Delivered = 'DELIVERED',
  Dropped = 'DROPPED',
  NotSent = 'NOT_SENT',
  Open = 'OPEN',
  Processed = 'PROCESSED',
  Sent = 'SENT',
  SpamReport = 'SPAM_REPORT',
  Unknown = 'UNKNOWN'
}

export type MassFileUpdateRequest = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  massFileType: MassFileUpdateType;
  presignedUrl: CreatedPresignedUrl;
};

export enum MassFileUpdateType {
  MassKamUpdate = 'MASS_KAM_UPDATE',
  MassOrgInfoUpdate = 'MASS_ORG_INFO_UPDATE'
}

export type MessageOption = {
  __typename?: 'MessageOption';
  message: Scalars['String']['output'];
  type: MessageType;
};

export type MessageOptionInput = {
  message: Scalars['String']['input'];
  type: MessageType;
};

export type MessageOptionOutput = {
  __typename?: 'MessageOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<MessageType>;
};

export enum MessageType {
  Free = 'FREE',
  Standard = 'STANDARD'
}

export type MetadataBatchEntry = {
  __typename?: 'MetadataBatchEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MetadataEntry = {
  __typename?: 'MetadataEntry';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Metrics = {
  __typename?: 'Metrics';
  activeCount: Scalars['Int']['output'];
  inactiveCount: Scalars['Int']['output'];
};

export enum MigrationOrigin {
  Glady = 'GLADY',
  Sodexo = 'SODEXO'
}

export type ModuleBlock = {
  __typename?: 'ModuleBlock';
  contents: Scalars['String']['output'];
  marginTop: Scalars['Int']['output'];
  type: ProductSheetBlockEnum;
};

export type ModuleCategory = {
  __typename?: 'ModuleCategory';
  background: Scalars['String']['output'];
  colors: Array<Maybe<Scalars['String']['output']>>;
  icon: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ModuleRedirectUri = {
  __typename?: 'ModuleRedirectUri';
  target: Scalars['String']['output'];
  type: RedirectUrlType;
};

/** A section of a module */
export type ModuleSection = {
  __typename?: 'ModuleSection';
  content: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ModuleSubscription = {
  __typename?: 'ModuleSubscription';
  code?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  internalName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Money = {
  __typename?: 'Money';
  currency: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/**  Input */
export type MoneyInput = {
  currency: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type MultipartBody = {
  __typename?: 'MultipartBody';
  file: Scalars['String']['output'];
  metadata: Array<MetadataEntry>;
};

export type Mutation = {
  __typename?: 'Mutation';
  PacDuplicatePerk: PacPerkResult;
  activateCard?: Maybe<GiftError>;
  activateCustomerCard?: Maybe<Array<Maybe<CustomerCard>>>;
  addAddressesInfo?: Maybe<Scalars['String']['output']>;
  addAnonymousBeneficiaries?: Maybe<AddAnonymousBeneficiariesResponse>;
  /** @deprecated Use `addBeneficiaryWithMailV3` instead. */
  addBeneficiaryWithMail?: Maybe<AddBeneficiaryWithMailResponse>;
  addBeneficiaryWithMailV3?: Maybe<AddBeneficiaryWithMailResponseV2>;
  addOrganizationGrant?: Maybe<GiftError>;
  addOrganizationWallet: Wallet;
  addOrganizationWalletSpecificConfiguration?: Maybe<WalletSpecificConfiguration>;
  addTransactionOnTokenizedCard?: Maybe<TopUpTokenizedCards>;
  adminImplemMigrationAdd: AdminImplemAddResult;
  adminImplemMigrationCancel: AdminImplemCancellationResult;
  /** Admin Implem Migration */
  adminImplemMigrationCreate: AdminImplemMigrationResult;
  adminImplemMigrationStart: AdminImplemAddResult;
  /** Answer Survey. */
  answerCommunicationSurveyCampaign: AnswerCommunicationSurveyResult;
  archiveCompany?: Maybe<Scalars['Boolean']['output']>;
  associateCompanyToPreference?: Maybe<Organization>;
  associatePaymentMethod?: Maybe<AssociatePaymentMethodReponse>;
  attachUserLocationAddress?: Maybe<AttachUserLocationAddressResponse>;
  autonomousSubscription?: Maybe<AutonomousSubscriptionResponseNew>;
  autonomousSubscriptionV2?: Maybe<AutonomousSubscriptionResponseV2>;
  autonomousUnsubscription?: Maybe<AutonomousUnsubscriptionResponse>;
  beneficiaryPayment?: Maybe<PaymentResponse>;
  blockCustomerCard?: Maybe<Array<Maybe<CustomerCard>>>;
  /** deprecated */
  cancelBillingOrder?: Maybe<Scalars['String']['output']>;
  cancelCardMultiShop?: Maybe<GiftError>;
  cancelCustomerCardOrder?: Maybe<Array<Maybe<CustomerCard>>>;
  cancelDistribution?: Maybe<Scalars['DateTime']['output']>;
  cancelSharedDistribution?: Maybe<Scalars['DateTime']['output']>;
  changePassword: ChangePasswordResponse;
  /** copy reconcilation file from common-tool common-tools-microgateway-uploads to glady-payment-reconciliation with uuid file */
  copyReconciliationFile: CopyReconciliationFileResponse;
  createApiClient: CreateApiClientResponse;
  createBillingDraftPaperOrder?: Maybe<CreateBillingDraftPaperOrderResponse>;
  createCardForBeneficiary?: Maybe<CustomerCard>;
  /** Create file campaign. */
  createCommunicationFileCampaign: CreateCommunicationFileCampaignResult;
  /** Create news campaign. */
  createCommunicationNewsCampaign: CreateCommunicationNewsCampaignResult;
  /** Create file campaign. */
  createCommunicationSurveyCampaign: CreateCommunicationSurveyCampaignResult;
  createComplexOrderTemplateFile?: Maybe<Scalars['String']['output']>;
  createConnectAccount?: Maybe<AccountStatus>;
  createDistributionsCampaign?: Maybe<GiftTaskResponse>;
  createDraftPaperOrder?: Maybe<CreateDraftPaperOrderResponse>;
  createManagerSimulation?: Maybe<PaperOrderSimulationResponse>;
  /** Create Newsletter campaign. */
  createNewsletterCampaign: NewsletterCampaignResult;
  createOrUpdatePreference?: Maybe<Preference>;
  createOrUpdateProspect?: Maybe<Prospect>;
  createOrganizationBankAccount?: Maybe<CreateBankAccountResponse>;
  createPresignedUrl?: Maybe<CreatePresignedUrlResponse>;
  createProspectDraft?: Maybe<ProspectDraft>;
  createProspectDraftByRedirection?: Maybe<ProspectDraft>;
  createProspectDraftBySimulationId?: Maybe<ProspectDraft>;
  createReason?: Maybe<ReasonResponse>;
  createResetPasswordSingleUseToken: SingleUseToken;
  createResetPhoneSingleUseToken: SingleUseToken;
  createSimulation?: Maybe<PaperOrderSimulationResponse>;
  /** Create a site. The site might not be fully ready when this has returned. */
  createSite: CreationSiteResult;
  createSsoSingleUseTokenV2: SingleUseTokenResponse;
  createTokenizedCard?: Maybe<Scalars['Boolean']['output']>;
  deleteBankingCards: Array<Scalars['Long']['output']>;
  deleteDistributionsS3?: Maybe<GiftTaskResponse>;
  deleteDraftOrders?: Maybe<DeleteDraftOrdersResponse>;
  deleteKnownDevice: KnownDeviceDeletionResponse;
  /** Delete Newsletter campaign. */
  deleteNewsletterCampaign: DeleteNewsletterCampaignResult;
  /** Delete targeting rule. */
  deleteNewsletterRecipientTargetingRule: DeleteNewsletterTargetingRuleResult;
  deleteOrder?: Maybe<Scalars['Boolean']['output']>;
  deleteOrganizationAddress?: Maybe<DeleteAddressResponse>;
  deleteOrganizationBankAccount?: Maybe<DeleteBankAccountResponse>;
  deleteOrganizationGrant?: Maybe<GiftError>;
  deleteReason?: Maybe<DeleteReasonResponse>;
  deleteTokenizedCards?: Maybe<Scalars['Boolean']['output']>;
  denyProspect?: Maybe<ProspectDraft>;
  denyProspectV2?: Maybe<Prospect>;
  detachBeneficiaries?: Maybe<Task>;
  detachBeneficiaryIam: DetachedBeneficiaryResponse;
  downloadBatchImportModel: Array<Maybe<Scalars['Int']['output']>>;
  dtsCancelCampaign?: Maybe<Scalars['Void']['output']>;
  /** Creates a campaign draft on the Distribution MS. Do not mistake with gift-api's createDistributionsCampaign */
  dtsCreateCampaignDraft: DtsCampaignDraftCreationResponse;
  dtsCreateCampaignDraftFromImport: DtsCampaignDraftCreationResponse;
  dtsCreateOrganization: DtsCreateOrganizationResponse;
  dtsDeleteCampaignDraft?: Maybe<Scalars['Void']['output']>;
  dtsFinalizeCampaign: DtsFinalizeCampaignResponse;
  dtsSendDistributionEmail?: Maybe<Scalars['Void']['output']>;
  dtsUpdateCampaignDraft: DtsCampaignDraftCreationResponse;
  dtsUpdateCampaignDraftFromImport: DtsCampaignDraftCreationResponse;
  /** Duplicate Newsletter campaign. */
  duplicateNewsletterCampaign: NewsletterCampaignResult;
  duplicateOrder?: Maybe<DuplicateOrderResponse>;
  editCard?: Maybe<GiftError>;
  editOrganizationGrant?: Maybe<GiftError>;
  editOrganizationWalletSpecificConfiguration?: Maybe<GiftError>;
  exclude: Scalars['Boolean']['output'];
  /** Execute targeting rule. */
  executeNewsletterRecipientTargetingRule: ExecuteRecipientTargetingRuleResult;
  /** Start a task that export the users of an organization */
  exportBeneficiaries?: Maybe<Task>;
  /** Get reconcilation result export */
  exportReconciliationResult: ExportReconciliationResultResponse;
  /** deprecated */
  flagRemadeOrder?: Maybe<FlagRemadeOrderResponse>;
  foodCancelOrder: Scalars['Boolean']['output'];
  /**  Order */
  foodCreateCardOrder: FoodOrderV2;
  foodCreateReloadOrder: FoodOrderV2;
  foodCreateRenewalCardOrder?: Maybe<CustomerCard>;
  /**  Reload */
  foodIsReloadFileValid?: Maybe<FoodReloadFileValidationResponse>;
  foodReloadSummaryPartialUpdate?: Maybe<FoodReloadSummarySingleResponse>;
  foodReloadSummarySelectAll?: Maybe<FoodIdListResponse>;
  foodReloadSummaryValidation?: Maybe<FoodTaskResponseV2>;
  foodSetOrganizationDefaultAddress?: Maybe<Scalars['Boolean']['output']>;
  foodSetUserDefaultAddress?: Maybe<Scalars['Boolean']['output']>;
  foodSetUserDefaultHomeAddress?: Maybe<FoodDefaultAddress>;
  foodUploadCardExpeditionFile?: Maybe<Scalars['String']['output']>;
  foodValidateOrder: Scalars['Boolean']['output'];
  generateOnboardingLink?: Maybe<Scalars['String']['output']>;
  /**
   * Start a task that import anonymous beneficiaries from a file.
   * @deprecated Use `importAnonymousBeneficiariesMassImportV2` instead.
   */
  importAnonymousBeneficiariesMassImport?: Maybe<Task>;
  /**
   * Start a task that import anonymous beneficiaries from a file.
   * @deprecated Field no longer supported
   */
  importAnonymousBeneficiariesMassImportV2?: Maybe<Task>;
  /** Start a task that import beneficiaries from a file with parameters which indicates how the batch should run. */
  importBeneficiariesMassImportV6?: Maybe<Task>;
  importComplexOrderFile?: Maybe<ImportComplexOrderFileResponse>;
  makeOrder?: Maybe<GiftOrderResponse>;
  manageSynchronizationAccountExternalAccount: ExternalAccountResult;
  massFileUpdate?: Maybe<Task>;
  /**
   * Start a task that check the beneficiaries from a file, then update or delete them as specified in said file.
   * @deprecated Field no longer supported
   */
  massModifyBeneficiaries?: Maybe<Task>;
  /** Start a task that check the beneficiaries from a file, then update or delete them as specified in said file. */
  massModifyBeneficiariesV2?: Maybe<Task>;
  /** Modify Newsletter campaign name. */
  modifyNewsletterCampaignName: NewsletterCampaignResult;
  needRecontact?: Maybe<ProspectDraft>;
  orderCustomerCard?: Maybe<Array<Maybe<CustomerCard>>>;
  organizationAsyncOrderPayment?: Maybe<PaymentResponse>;
  overrideShopFee?: Maybe<OrganizationShopFee>;
  overrideWallet?: Maybe<GiftError>;
  pacArchiveAdditionalDataSchema: PacArchiveAdditionalDataSchemaResult;
  pacArchiveUserGroup: PacArchiveUserGroupOutput;
  pacCancelEpRequest: PacCancelEpRequestResult;
  /** Check ANCV Perk types are not overlapping */
  pacCheckAncvPerkOverlap?: Maybe<PacCheckAncvPerkOverlapOutput>;
  pacCheckDuplicatedPerkRangeAvailability?: Maybe<PacCheckPerkRangeAvailabilityOutput>;
  pacCheckPerkNameAvailability?: Maybe<PacCheckPerkNameAvailabilityOutput>;
  pacCheckPerkRangeAvailability?: Maybe<PacCheckPerkRangeAvailabilityOutput>;
  /** Extended Profile User Group */
  pacCheckUserGroupNameAvailability: PacCheckUserGroupNameAvailabilityOutput;
  /** Extended Profile */
  pacCreateAdditionalDataSchema: PacCreateAdsResult;
  /** Benefit */
  pacCreateBenefit: PacCreateBenefitOutput;
  pacCreateHasDependentChildren: PacHasDependentChildrenResult;
  /** Perk */
  pacCreatePerk: PacPerkResult;
  /**  FIXME: files input parameter will be removed in favor of PacCreateRoInput.uploadDocuments */
  pacCreateRightsOwner: PacCreateRoResult;
  pacCreateUpdateSegment: PacPerkResult;
  pacCreateUserGroup: PacCreateUserGroupOutput;
  pacDeleteAdditionalDataSchema: PacDeleteAdditionalDataSchemaResult;
  pacDeleteBankDetails: PacDeleteBankDetailsResult;
  pacDeleteRightsOwner: PacDeleteRightsOwnerResult;
  pacDeleteSegment: PacPerkResult;
  /** Extended Profile Targeting Rule */
  pacEpCreateTargetingRule: PacEpCreateTargetingRuleResult;
  /** Extended Profile Targeting Calculation Request */
  pacEpInitTargetingCalculationRequest?: Maybe<PacEpInitTargetingCalculation>;
  pacGenerateAncvFile: PacBatchFileResult;
  /** Batch */
  pacGenerateDistribFile: PacBatchFileResult;
  pacGenerateEpChildrenDistributionFile: PacBatchFileResult;
  pacGenerateEpDistributionFile: PacBatchFileResult;
  pacGenerateEpExportFile?: Maybe<Array<Maybe<PacBatchFileResult>>>;
  pacGeneratePerkUtilisationFile: PacBatchFileResult;
  pacGenerateSepaFile: PacBatchFileResult;
  /**  FIXME: attachements input parameter will be removed in favor of PacManagerPerkUtilisationCreateInput.attachments */
  pacManagerCreatePerkUtilisation: PacPerkUtilisationResult;
  /**  FIXME: attachements input parameter will be removed in favor of PacManagerPerkUtilisationCreateForRoInput.attachments */
  pacManagerCreatePerkUtilisationForRo: PacPerkUtilisationResult;
  pacManagerDeleteRightsOwner: PacManagerDeleteRightsOwnerResult;
  pacManagerRefuseFillingAd: PacRefuseFillingAdResult;
  /**  FIXME: files input parameter will be removed in favor of PacManagerUpdateAdInput.uploadDocuments */
  pacManagerUpdateAd: PacManagerUpdateAdResult;
  /**  FIXME: files input parameter will be removed in favor of PacManagerUpdateRoInput.uploadDocuments */
  pacManagerUpdateRightsOwner: PacManagerUpdateRoResult;
  /**  FIXME: attachements input parameter will be removed in favor of PacPerkUtilisationCreateForRoInput.attachments */
  pacPerkUtilisationForRo: PacPerkUtilisationResult;
  /** Perk utilisation refund payment */
  pacPerkUtilisationRefundPayment: PacPerkUtilisationResult;
  /** Perk utilisation */
  pacPerkUtilisationSelf: PacPerkUtilisationResult;
  /** Perk utilisation update status */
  pacPerkUtilisationUpdateStatus: PacPerkUtilisationResult;
  pacPublishUserGroup: PacPublishUserGroupOutput;
  pacRefuseFillingAd: PacRefuseFillingAdResult;
  pacSaveRightsOwnerConf: PacRightOwnerConfResult;
  /** User extended profile */
  pacUpdateAd: PacUpdateAdResult;
  pacUpdateAdditionalDataSchema: PacUpdateAdsResult;
  /** Bank details */
  pacUpdateBankDetails: PacBankDetailsResult;
  pacUpdateHasDependentChildren: PacHasDependentChildrenResult;
  /** Perk update general data */
  pacUpdatePerk: PacPerkResult;
  /** Perk update status */
  pacUpdatePerkStatus: PacPerkResult;
  /**  FIXME: files input parameter will be removed in favor of PacUpdateRoInput.uploadDocuments */
  pacUpdateRightsOwner: PacUpdateRoResult;
  pacUpdateUserGroup: PacUpdateUserGroupOutput;
  /** Extended Profile Request */
  pacValidateRequest: PacValidateRequestResult;
  paymentSheet?: Maybe<PaymentSheet>;
  proposalPurchase?: Maybe<PurchaseResult>;
  /** Publish campaign. */
  publishCommunicationCampaign: PublishCommunicationCampaignResult;
  /** Try to reconcilate one line */
  reconciliate: ReconciliateResponse;
  /** Get reconcilation result */
  reconciliateFile: ReconciliationResult;
  refundCard?: Maybe<CardRefundResponse>;
  regularizeNotPaidTransactionsTopUp?: Maybe<RegularizationTopUp>;
  regularizeTokenizedCards?: Maybe<Scalars['Boolean']['output']>;
  regularizeTokenizedCardsV2?: Maybe<Scalars['Boolean']['output']>;
  removeExclusion: Scalars['Boolean']['output'];
  removeOrganizationWallet?: Maybe<GiftError>;
  removeOrganizationWalletSpecificConfiguration?: Maybe<GiftError>;
  /** remove all promo codes from the order associated with a token */
  removePromoCode: RemovePromoCodeResponse;
  /** Try to reconcilate one line */
  removeReconciliationLine: RemoveLineResponse;
  requestCredentialsByIdV2?: Maybe<Beneficiary>;
  resetOtpCountByIdV2: Beneficiary;
  resetPasswordByIdV2: Beneficiary;
  resetPhoneNumberByIdV2: Beneficiary;
  saveCardProgram?: Maybe<CardProgram>;
  /** Save targeting rule. */
  saveNewsletterRecipientTargetingRule: SaveNewsletterRecipientTargetingRuleResult;
  /**  TODO : faire des types d'erreur de retour fortement typés et non juste code / message */
  sendBurnMail: Scalars['Boolean']['output'];
  sendDistributionMail: Scalars['Boolean']['output'];
  sendEmailToManager?: Maybe<Scalars['String']['output']>;
  sendInvitationByIdV3: Beneficiary;
  sendInvitationByIds?: Maybe<Task>;
  sendInviteMailByIdForHighestRole: Beneficiary;
  /** Send Newsletter campaign. */
  sendNewsletterCampaign: SendNewsletterCampaignResult;
  sendSurveyResponse?: Maybe<Scalars['Boolean']['output']>;
  /** Update Beneficiary view status. */
  setBeneficiaryNewsOpened?: Maybe<UpdateCommunicationBeneficiaryViewStatusResult>;
  /** Set Communication preference */
  setCommunicationPreferences: SetCommunicationPreferencesResult;
  /** Set recipient scope. */
  setNewsletterRecipientScope: SetNewsletterRecipientScopeResult;
  /** Set Newsletter sender. */
  setNewsletterSender: GetNewsletterCampaignParametersResult;
  /** Set Newsletter subject. */
  setNewsletterSubject: GetNewsletterCampaignParametersResult;
  shareDistributionFileS3?: Maybe<GiftTaskResponse>;
  shareDistributionForUser?: Maybe<DistributionDetail>;
  shareDistributionV2?: Maybe<ShareResult>;
  /** Submits a promo code to the order associated with a token */
  submitPromoCode: SubmitPromoCodeResponse;
  subscribe?: Maybe<Array<Maybe<ModuleSubscription>>>;
  switchConsumed?: Maybe<GiftError>;
  transfer?: Maybe<GiftError>;
  unblockPin?: Maybe<Array<Maybe<CustomerCard>>>;
  unblockPinCardMultiShop?: Maybe<GiftError>;
  unlockCardMultiShop?: Maybe<GiftError>;
  unsubscribe?: Maybe<Array<Maybe<ModuleSubscription>>>;
  update2FAStatus: Beneficiary;
  updateBeneficiaryAdditionalData: Beneficiary;
  updateBeneficiaryFoodStatus?: Maybe<ExtraDataStatusType>;
  updateBeneficiaryFoodWorkingDaysStatus?: Maybe<ExtraDataStatusType>;
  updateBeneficiaryPreferenceLogo?: Maybe<UpdatePreferenceLogoResult>;
  updateBeneficiarySiteAccess: Beneficiary;
  updateBeneficiaryV2: UpdatedBeneficiaryResponse;
  updateCampaign?: Maybe<GiftError>;
  updateCommercialOffer?: Maybe<CommercialOffer>;
  /** Update file campaign. */
  updateCommunicationFileCampaign: UpdateCommunicationFileCampaignResult;
  /** Update news campaign. */
  updateCommunicationNewsCampaign: UpdateCommunicationNewsCampaignResult;
  /** Update survey campaign. */
  updateCommunicationSurveyCampaign: UpdateCommunicationSurveyCampaignResult;
  updateCreateAuthenticatedUserBillingAndShippingAddress?: Maybe<AuthenticatedUser>;
  updateCreateOrganizationAddress?: Maybe<Address>;
  updateCreateOrganizationBillingAndShippingAddress?: Maybe<Organization>;
  updateCustomerCard?: Maybe<Array<Maybe<CustomerCard>>>;
  updateGlobalCaptchaValue: UpdateGlobalParametersValueResponse;
  updateGlobalTwoFactorValue: UpdateGlobalParametersValueResponse;
  updateMainManager?: Maybe<Organization>;
  updateManagerAdditionalData: Beneficiary;
  updateMultiShopsCardPhoneNumber?: Maybe<GiftError>;
  updateOrderAmount?: Maybe<UpdateOrderAmountResponse>;
  updateOrganizationBankAccount?: Maybe<UpdateBankAccountResponse>;
  updateOrganizationBeneficiariesWalletAccessesS3: GiftTaskResponse;
  updateOrganizationEmailLogo?: Maybe<UpdateOrganizationMailLogoResult>;
  updateOrganizationFoodWorkingDaysStatus?: Maybe<ExtraDataStatusType>;
  updateOrganizationInformation?: Maybe<Organization>;
  updateOrganizationKam?: Maybe<Organization>;
  updateOrganizationMailLogo?: Maybe<UpdateOrganizationMailLogoResult>;
  updatePreferenceLogo?: Maybe<UpdatePreferenceLogoResult>;
  updateProspectAsAdmin?: Maybe<ProspectDraft>;
  updateProspectDraft?: Maybe<ProspectDraft>;
  updateProspectDraftAsAdmin?: Maybe<ProspectDraft>;
  updateReason?: Maybe<ReasonResponse>;
  updateSelfPersonalInformation: AuthenticatedUser;
  updateSimulationContactInformations?: Maybe<Scalars['String']['output']>;
  updateStatusTopUp?: Maybe<Scalars['Boolean']['output']>;
  updateUserBiometryStatus: Beneficiary;
  updateUserBlockedCause?: Maybe<Beneficiary>;
  updateUserEmail: Beneficiary;
  updateUserInfo: Beneficiary;
  updateUserLanguage: Beneficiary;
  updateUserListStatus: Array<Maybe<Scalars['String']['output']>>;
  updateUserLogin: UpdatedUserResponse;
  updateUserRoles?: Maybe<Beneficiary>;
  updateUserStatus: Beneficiary;
  uploadCardReloadBatchFile?: Maybe<FoodTaskResponse>;
  /** Upload File. */
  uploadCommunicationFile?: Maybe<UploadCommunicationFileResult>;
  /** Upload Image. */
  uploadCommunicationImage?: Maybe<UploadCommunicationImageResult>;
  uploadFileBank?: Maybe<OrderTask>;
  /** Start a task that resynchronize user based on input userId or on the input orgId (to resync all users of given org) */
  usersResync?: Maybe<Task>;
  validateFileBankFood?: Maybe<Scalars['Boolean']['output']>;
  /** Validate a payment associated with a token */
  validatePayment: ValidatePaymentResponse;
  validateProspect?: Maybe<ProspectDraft>;
  validateProspectV2?: Maybe<Prospect>;
  waitForPayment?: Maybe<WaitForPaymentResponse>;
};


export type MutationPacDuplicatePerkArgs = {
  data?: InputMaybe<PacDuplicatePerkInput>;
};


export type MutationActivateCardArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
  lastDigits?: InputMaybe<Scalars['String']['input']>;
  publicToken: Scalars['String']['input'];
};


export type MutationActivateCustomerCardArgs = {
  queryInput: ActivateCustomerCardQueryInput;
};


export type MutationAddAddressesInfoArgs = {
  request?: InputMaybe<AddAddressesInfoRequest>;
};


export type MutationAddAnonymousBeneficiariesArgs = {
  input: AddAnonymousBeneficiariesRequest;
};


export type MutationAddBeneficiaryWithMailArgs = {
  input: BeneficiaryData;
};


export type MutationAddBeneficiaryWithMailV3Args = {
  input: BeneficiaryDataV2;
};


export type MutationAddOrganizationGrantArgs = {
  organizationId: Scalars['ID']['input'];
  request: GrantRequest;
};


export type MutationAddOrganizationWalletArgs = {
  organizationId: Scalars['ID']['input'];
  walletId: Scalars['Long']['input'];
};


export type MutationAddOrganizationWalletSpecificConfigurationArgs = {
  organizationId: Scalars['ID']['input'];
  request: WalletSpecificConfigurationRequest;
  walletId: Scalars['Long']['input'];
};


export type MutationAddTransactionOnTokenizedCardArgs = {
  queryInput: TokenizedCardTransactionInput;
};


export type MutationAdminImplemMigrationAddArgs = {
  fileUuid: Scalars['ID']['input'];
  migrationUuid: Scalars['ID']['input'];
  type: Scalars['String']['input'];
};


export type MutationAdminImplemMigrationCancelArgs = {
  cancellationRequest: AdminImplemMigrationCancellationInput;
};


export type MutationAdminImplemMigrationCreateArgs = {
  orgaUuid: Scalars['ID']['input'];
};


export type MutationAdminImplemMigrationStartArgs = {
  migrationUuid: Scalars['ID']['input'];
};


export type MutationAnswerCommunicationSurveyCampaignArgs = {
  answer: CommunicationAnswerSurveyInput;
};


export type MutationArchiveCompanyArgs = {
  input: ArchiveCompanyRequest;
};


export type MutationAssociateCompanyToPreferenceArgs = {
  orgId: Scalars['ID']['input'];
  preferenceId: Scalars['ID']['input'];
};


export type MutationAssociatePaymentMethodArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  paymentMethodId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAttachUserLocationAddressArgs = {
  patch: AttachUserLocationAddressRequest;
};


export type MutationAutonomousSubscriptionArgs = {
  autonomousSubscription?: InputMaybe<AutonomousSubscriptionRequest>;
};


export type MutationAutonomousSubscriptionV2Args = {
  autonomousSubscription?: InputMaybe<AutonomousSubscriptionRequest>;
};


export type MutationAutonomousUnsubscriptionArgs = {
  autonomousUnsubscription?: InputMaybe<AutonomousUnsubscriptionRequest>;
};


export type MutationBeneficiaryPaymentArgs = {
  request: BeneficiaryPaymentRequest;
};


export type MutationBlockCustomerCardArgs = {
  queryInput: BlockCustomerCardQueryInput;
};


export type MutationCancelBillingOrderArgs = {
  request?: InputMaybe<BillingOrderCancellationRequest>;
};


export type MutationCancelCardMultiShopArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
};


export type MutationCancelCustomerCardOrderArgs = {
  queryInput: CancelCustomerCardOrderQueryInput;
};


export type MutationCancelDistributionArgs = {
  input: DistributionCancelRequest;
};


export type MutationCancelSharedDistributionArgs = {
  distributionId: Scalars['Long']['input'];
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordRequest;
};


export type MutationCopyReconciliationFileArgs = {
  input: CopyReconciliationFileInput;
};


export type MutationCreateApiClientArgs = {
  input: CreateClientApiRequest;
};


export type MutationCreateBillingDraftPaperOrderArgs = {
  request: CreateBillingDraftPaperOrderRequest;
};


export type MutationCreateCardForBeneficiaryArgs = {
  queryInput: OrderCustomerCardOrderQueryInput;
};


export type MutationCreateCommunicationFileCampaignArgs = {
  campaign: CommunicationFileCampaignInput;
};


export type MutationCreateCommunicationNewsCampaignArgs = {
  campaign: CommunicationNewsCampaignInput;
};


export type MutationCreateCommunicationSurveyCampaignArgs = {
  campaign: CommunicationSurveyCampaignInput;
};


export type MutationCreateComplexOrderTemplateFileArgs = {
  request?: InputMaybe<CreateComplexOrderTemplateFileRequest>;
};


export type MutationCreateConnectAccountArgs = {
  input: Scalars['ID']['input'];
};


export type MutationCreateDistributionsCampaignArgs = {
  companyId: Scalars['ID']['input'];
  request: CampaignRequest;
};


export type MutationCreateDraftPaperOrderArgs = {
  request: CreateDraftPaperOrderRequest;
};


export type MutationCreateManagerSimulationArgs = {
  request: PaperOrderSimulationRequest;
};


export type MutationCreateNewsletterCampaignArgs = {
  campaignName: Scalars['String']['input'];
  orgaId: Scalars['String']['input'];
  template?: InputMaybe<NewsletterTemplateInput>;
};


export type MutationCreateOrUpdatePreferenceArgs = {
  input?: InputMaybe<UpdatePreferenceRequest>;
};


export type MutationCreateOrUpdateProspectArgs = {
  request?: InputMaybe<ProspectRequest>;
};


export type MutationCreateOrganizationBankAccountArgs = {
  input: CreateBankAccount;
};


export type MutationCreatePresignedUrlArgs = {
  request?: InputMaybe<CreatePresignedUrlInput>;
};


export type MutationCreateProspectDraftArgs = {
  captchaToken: Scalars['String']['input'];
};


export type MutationCreateProspectDraftByRedirectionArgs = {
  input?: InputMaybe<CreateProspectDraftByRedirectionRequest>;
};


export type MutationCreateProspectDraftBySimulationIdArgs = {
  simulationId: Scalars['String']['input'];
};


export type MutationCreateReasonArgs = {
  organizationId: Scalars['ID']['input'];
  request: CreateReasonRequest;
};


export type MutationCreateResetPasswordSingleUseTokenArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationCreateResetPhoneSingleUseTokenArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationCreateSimulationArgs = {
  request: PaperOrderSimulationRequest;
};


export type MutationCreateSiteArgs = {
  organizationId: Scalars['ID']['input'];
  subdomain: Scalars['String']['input'];
  templateName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateSsoSingleUseTokenV2Args = {
  userId: Scalars['ID']['input'];
};


export type MutationCreateTokenizedCardArgs = {
  queryInput: TokenizedCardInput;
};


export type MutationDeleteBankingCardsArgs = {
  bankingCardIds: Array<Scalars['Long']['input']>;
};


export type MutationDeleteDistributionsS3Args = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  deleteDistributionsRequest: DeleteDistributionsRequest;
  s3Key?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteDraftOrdersArgs = {
  request: DeleteDraftOrdersRequest;
};


export type MutationDeleteKnownDeviceArgs = {
  input: KnownDeviceDeletion;
};


export type MutationDeleteNewsletterCampaignArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationDeleteNewsletterRecipientTargetingRuleArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationDeleteOrderArgs = {
  companyId: Scalars['ID']['input'];
  orderId: Scalars['Long']['input'];
};


export type MutationDeleteOrganizationAddressArgs = {
  attachmentId: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationBankAccountArgs = {
  input: Scalars['ID']['input'];
};


export type MutationDeleteOrganizationGrantArgs = {
  grantId: Scalars['Long']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDeleteReasonArgs = {
  organizationId: Scalars['ID']['input'];
  reasonId: Scalars['Long']['input'];
};


export type MutationDeleteTokenizedCardsArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type MutationDenyProspectArgs = {
  input: Scalars['ID']['input'];
};


export type MutationDenyProspectV2Args = {
  input: Scalars['ID']['input'];
};


export type MutationDetachBeneficiariesArgs = {
  input: BeneficiariesToDetach;
};


export type MutationDetachBeneficiaryIamArgs = {
  input: BeneficiaryToDetach;
};


export type MutationDownloadBatchImportModelArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationDtsCancelCampaignArgs = {
  request: DtsCancelCampaignRequest;
};


export type MutationDtsCreateCampaignDraftArgs = {
  organizationId: Scalars['ID']['input'];
  request: DtsCampaignCreationRequest;
};


export type MutationDtsCreateCampaignDraftFromImportArgs = {
  request: DtsCampaignCreationFromImportRequest;
};


export type MutationDtsCreateOrganizationArgs = {
  request: DtsCreateOrganizationRequest;
};


export type MutationDtsDeleteCampaignDraftArgs = {
  request: DtsDeleteCampaignDraftRequest;
};


export type MutationDtsFinalizeCampaignArgs = {
  request: DtsFinalizeCampaignRequest;
};


export type MutationDtsSendDistributionEmailArgs = {
  distributionId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationDtsUpdateCampaignDraftArgs = {
  organizationId: Scalars['ID']['input'];
  request: DtsCampaignUpdateRequest;
};


export type MutationDtsUpdateCampaignDraftFromImportArgs = {
  request: DtsCampaignUpdateFromImportRequest;
};


export type MutationDuplicateNewsletterCampaignArgs = {
  campaignId: Scalars['String']['input'];
  campaignName: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationDuplicateOrderArgs = {
  request?: InputMaybe<DuplicateOrderRequest>;
};


export type MutationEditCardArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
  request: CardEditRequest;
};


export type MutationEditOrganizationGrantArgs = {
  grantId: Scalars['Long']['input'];
  organizationId: Scalars['ID']['input'];
  request: GrantRequest;
};


export type MutationEditOrganizationWalletSpecificConfigurationArgs = {
  accessible: Scalars['Boolean']['input'];
  configurationId: Scalars['Long']['input'];
  discount: Scalars['BigDecimal']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationExcludeArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  walletId: Scalars['Long']['input'];
};


export type MutationExecuteNewsletterRecipientTargetingRuleArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationExportBeneficiariesArgs = {
  organizationId: Scalars['ID']['input'];
};


export type MutationExportReconciliationResultArgs = {
  input: ExportReconciliationResultInput;
};


export type MutationFlagRemadeOrderArgs = {
  request?: InputMaybe<FlagRemadeOrderRequest>;
};


export type MutationFoodCancelOrderArgs = {
  orderUuid: Scalars['String']['input'];
};


export type MutationFoodCreateCardOrderArgs = {
  queryInput: FoodCardOrderInput;
};


export type MutationFoodCreateReloadOrderArgs = {
  queryInput: FoodReloadOrderQueryInput;
};


export type MutationFoodCreateRenewalCardOrderArgs = {
  queryInput: FoodRenewalCardOrderInput;
};


export type MutationFoodIsReloadFileValidArgs = {
  bucket: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  s3Key: Scalars['String']['input'];
};


export type MutationFoodReloadSummaryPartialUpdateArgs = {
  organizationUuid: Scalars['ID']['input'];
  reloadSummary: FoodReloadSummaryInput;
};


export type MutationFoodReloadSummarySelectAllArgs = {
  organizationUuid: Scalars['ID']['input'];
  selected: Scalars['Boolean']['input'];
  taskUuid: Scalars['ID']['input'];
};


export type MutationFoodReloadSummaryValidationArgs = {
  organizationUuid: Scalars['ID']['input'];
  taskUuid: Scalars['ID']['input'];
};


export type MutationFoodSetOrganizationDefaultAddressArgs = {
  queryInput: FoodSetOrganizationAddressInput;
};


export type MutationFoodSetUserDefaultAddressArgs = {
  queryInput: FoodSetUserAddressInput;
};


export type MutationFoodSetUserDefaultHomeAddressArgs = {
  queryInput: FoodSetUserHomeAddressInput;
};


export type MutationFoodUploadCardExpeditionFileArgs = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  s3Key: Scalars['String']['input'];
};


export type MutationFoodValidateOrderArgs = {
  orderUuid: Scalars['String']['input'];
};


export type MutationGenerateOnboardingLinkArgs = {
  input: GenerateOnboardingLinkRequest;
};


export type MutationImportAnonymousBeneficiariesMassImportArgs = {
  file: Scalars['Upload']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationImportAnonymousBeneficiariesMassImportV2Args = {
  bucket: Scalars['String']['input'];
  input?: InputMaybe<ImportAnonymousBeneficiariesMassImportV2Request>;
  s3Key: Scalars['String']['input'];
};


export type MutationImportBeneficiariesMassImportV6Args = {
  bucket: Scalars['String']['input'];
  input: ImportBeneficiariesMassImportV6Request;
  s3Key: Scalars['String']['input'];
};


export type MutationImportComplexOrderFileArgs = {
  request?: InputMaybe<ImportComplexOrderFileRequest>;
};


export type MutationMakeOrderArgs = {
  orderRequest: OrderRequest;
};


export type MutationManageSynchronizationAccountExternalAccountArgs = {
  activate: Scalars['Boolean']['input'];
  sourceId: Scalars['String']['input'];
  synchronizationDate?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMassFileUpdateArgs = {
  input: MassFileUpdateRequest;
};


export type MutationMassModifyBeneficiariesArgs = {
  file: Scalars['Upload']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationMassModifyBeneficiariesV2Args = {
  bucket: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  s3Key?: InputMaybe<Scalars['String']['input']>;
};


export type MutationModifyNewsletterCampaignNameArgs = {
  campaignId: Scalars['String']['input'];
  campaignName: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationNeedRecontactArgs = {
  input: Scalars['ID']['input'];
};


export type MutationOrderCustomerCardArgs = {
  queryInput: OrderCustomerCardOrderQueryInput;
};


export type MutationOrganizationAsyncOrderPaymentArgs = {
  request: OrganizationAsyncOrderRequest;
};


export type MutationOverrideShopFeeArgs = {
  organizationId: Scalars['ID']['input'];
  request?: InputMaybe<ShopFeeOverrideRequest>;
  shopFeeId: Scalars['Long']['input'];
};


export type MutationOverrideWalletArgs = {
  organizationId: Scalars['ID']['input'];
  request: OrganizationWalletOverrideRequest;
  walletId: Scalars['Long']['input'];
};


export type MutationPacArchiveAdditionalDataSchemaArgs = {
  adsId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationPacArchiveUserGroupArgs = {
  input: PacArchiveUserGroupInput;
};


export type MutationPacCancelEpRequestArgs = {
  organizationId: Scalars['ID']['input'];
  requestType: PacRequestType;
  subjectId: Scalars['ID']['input'];
};


export type MutationPacCheckAncvPerkOverlapArgs = {
  data: PacCheckAncvPerkOverlapInput;
};


export type MutationPacCheckDuplicatedPerkRangeAvailabilityArgs = {
  data: PacCheckDuplicatedPerkRangeAvailabilityInput;
};


export type MutationPacCheckPerkNameAvailabilityArgs = {
  data: PacCheckPerkNameAvailabilityInput;
};


export type MutationPacCheckPerkRangeAvailabilityArgs = {
  data: PacCheckPerkRangeAvailabilityInput;
};


export type MutationPacCheckUserGroupNameAvailabilityArgs = {
  input: PacCheckUserGroupNameAvailabilityInput;
};


export type MutationPacCreateAdditionalDataSchemaArgs = {
  data: PacAdditionalDataSchemaInput;
  organizationId: Scalars['ID']['input'];
};


export type MutationPacCreateBenefitArgs = {
  input: PacCreateBenefitInput;
};


export type MutationPacCreateHasDependentChildrenArgs = {
  data: PacCreateHasDependentChildrenInput;
};


export type MutationPacCreatePerkArgs = {
  organizationId: Scalars['ID']['input'];
  perk?: InputMaybe<PacPerkCreate>;
};


export type MutationPacCreateRightsOwnerArgs = {
  data: PacCreateRoInput;
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
};


export type MutationPacCreateUpdateSegmentArgs = {
  data: PacUpdateCreateSegmentInput;
};


export type MutationPacCreateUserGroupArgs = {
  input: PacCreateUserGroupInput;
};


export type MutationPacDeleteAdditionalDataSchemaArgs = {
  adsId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationPacDeleteRightsOwnerArgs = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationPacDeleteSegmentArgs = {
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['ID']['input'];
  segmentUuid: Scalars['ID']['input'];
};


export type MutationPacEpCreateTargetingRuleArgs = {
  data: PacEpTargetingRuleInput;
  orgaUuid: Scalars['ID']['input'];
};


export type MutationPacEpInitTargetingCalculationRequestArgs = {
  targetingRuleUuid: Scalars['ID']['input'];
};


export type MutationPacGenerateAncvFileArgs = {
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['ID']['input'];
};


export type MutationPacGenerateDistribFileArgs = {
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['ID']['input'];
};


export type MutationPacGenerateEpChildrenDistributionFileArgs = {
  data: PacEpDistributionRequest;
};


export type MutationPacGenerateEpDistributionFileArgs = {
  data: PacEpDistributionRequest;
};


export type MutationPacGenerateEpExportFileArgs = {
  exportRequest: PacEpExportRequest;
};


export type MutationPacGeneratePerkUtilisationFileArgs = {
  perkUuid: Scalars['ID']['input'];
};


export type MutationPacGenerateSepaFileArgs = {
  bankAccountUuid: Scalars['ID']['input'];
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['ID']['input'];
};


export type MutationPacManagerCreatePerkUtilisationArgs = {
  attachments?: InputMaybe<Array<Scalars['Upload']['input']>>;
  data: PacManagerPerkUtilisationCreateInput;
};


export type MutationPacManagerCreatePerkUtilisationForRoArgs = {
  attachments?: InputMaybe<Array<Scalars['Upload']['input']>>;
  data: PacManagerPerkUtilisationCreateForRoInput;
};


export type MutationPacManagerDeleteRightsOwnerArgs = {
  data: PacManagerDeleteRoInput;
};


export type MutationPacManagerRefuseFillingAdArgs = {
  organizationId: Scalars['ID']['input'];
  schemaId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationPacManagerUpdateAdArgs = {
  data: PacManagerUpdateAdInput;
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
};


export type MutationPacManagerUpdateRightsOwnerArgs = {
  data: PacManagerUpdateRoInput;
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
};


export type MutationPacPerkUtilisationForRoArgs = {
  attachments?: InputMaybe<Array<Scalars['Upload']['input']>>;
  data: PacPerkUtilisationCreateForRoInput;
};


export type MutationPacPerkUtilisationRefundPaymentArgs = {
  data: PacPerkUtilisationRefundPaymentInput;
};


export type MutationPacPerkUtilisationSelfArgs = {
  attachments?: InputMaybe<Array<Scalars['Upload']['input']>>;
  data: PacPerkUtilisationCreateSelfInput;
};


export type MutationPacPerkUtilisationUpdateStatusArgs = {
  data: PacPerkUtilisationUpdateStatusInput;
};


export type MutationPacPublishUserGroupArgs = {
  input: PacPublishUserGroupInput;
};


export type MutationPacRefuseFillingAdArgs = {
  organizationId: Scalars['ID']['input'];
  schemaId: Scalars['ID']['input'];
};


export type MutationPacSaveRightsOwnerConfArgs = {
  childActivated?: InputMaybe<Scalars['Boolean']['input']>;
  orgaUuid: Scalars['ID']['input'];
  partnerActivated?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationPacUpdateAdArgs = {
  existingDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
  organizationId: Scalars['ID']['input'];
  schemaId: Scalars['ID']['input'];
  uploadDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  value: Scalars['String']['input'];
};


export type MutationPacUpdateAdditionalDataSchemaArgs = {
  adsId: Scalars['ID']['input'];
  data: PacAdditionalDataSchemaUpdateInput;
  organizationId: Scalars['ID']['input'];
};


export type MutationPacUpdateBankDetailsArgs = {
  data: PacUpdateBankDetailsInput;
};


export type MutationPacUpdateHasDependentChildrenArgs = {
  data: PacUpdateHasDependentChildrenInput;
};


export type MutationPacUpdatePerkArgs = {
  data: PacPerkUpdateInput;
};


export type MutationPacUpdatePerkStatusArgs = {
  data: PacPerkUpdateStatusInput;
};


export type MutationPacUpdateRightsOwnerArgs = {
  data: PacUpdateRoInput;
  files?: InputMaybe<Array<Scalars['Upload']['input']>>;
  organizationId: Scalars['ID']['input'];
};


export type MutationPacUpdateUserGroupArgs = {
  input: PacUpdateUserGroupInput;
};


export type MutationPacValidateRequestArgs = {
  organizationId: Scalars['ID']['input'];
  requestId: Scalars['ID']['input'];
  validateRequests: PacValidateRequests;
};


export type MutationPaymentSheetArgs = {
  beneficiaryId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationProposalPurchaseArgs = {
  request: ProposalPurchaseRequest;
};


export type MutationPublishCommunicationCampaignArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationReconciliateArgs = {
  input: ReconciliateRequestInput;
};


export type MutationReconciliateFileArgs = {
  input: ReconciliateFileInput;
};


export type MutationRefundCardArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
  request: CardRefundRequest;
};


export type MutationRegularizeNotPaidTransactionsTopUpArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type MutationRegularizeTokenizedCardsArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type MutationRegularizeTokenizedCardsV2Args = {
  queryInput: RegularizeTokenizedCardInput;
};


export type MutationRemoveExclusionArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
  walletId: Scalars['Long']['input'];
};


export type MutationRemoveOrganizationWalletArgs = {
  organizationId: Scalars['ID']['input'];
  walletId: Scalars['Long']['input'];
};


export type MutationRemoveOrganizationWalletSpecificConfigurationArgs = {
  configurationId: Scalars['Long']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationRemovePromoCodeArgs = {
  input: RemovePromoCodeInput;
};


export type MutationRemoveReconciliationLineArgs = {
  input: RemoveLineInput;
};


export type MutationRequestCredentialsByIdV2Args = {
  userId: Scalars['ID']['input'];
};


export type MutationResetOtpCountByIdV2Args = {
  userId: Scalars['ID']['input'];
};


export type MutationResetPasswordByIdV2Args = {
  userId: Scalars['ID']['input'];
};


export type MutationResetPhoneNumberByIdV2Args = {
  userId: Scalars['ID']['input'];
};


export type MutationSaveCardProgramArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  queryInput?: InputMaybe<CardProgramQueryInput>;
};


export type MutationSaveNewsletterRecipientTargetingRuleArgs = {
  orgId: Scalars['String']['input'];
  targetingRule: NewsletterRecipientTargetingRuleInput;
};


export type MutationSendBurnMailArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
};


export type MutationSendDistributionMailArgs = {
  distributionId: Scalars['Long']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSendEmailToManagerArgs = {
  orgId: Scalars['ID']['input'];
};


export type MutationSendInvitationByIdV3Args = {
  options?: InputMaybe<InvitationOptions>;
  userId: Scalars['ID']['input'];
};


export type MutationSendInvitationByIdsArgs = {
  input: BeneficiariesToInvite;
};


export type MutationSendInviteMailByIdForHighestRoleArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationSendNewsletterCampaignArgs = {
  query?: InputMaybe<SendNewsletterCampaignInput>;
};


export type MutationSendSurveyResponseArgs = {
  input?: InputMaybe<OrgAdminSurvey>;
};


export type MutationSetBeneficiaryNewsOpenedArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationSetCommunicationPreferencesArgs = {
  newsletterOptIn: Scalars['Boolean']['input'];
  orgId: Scalars['String']['input'];
};


export type MutationSetNewsletterRecipientScopeArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  scope: Scalars['String']['input'];
};


export type MutationSetNewsletterSenderArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  senderName: Scalars['String']['input'];
};


export type MutationSetNewsletterSubjectArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  previewText?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
};


export type MutationShareDistributionFileS3Args = {
  bucket: Scalars['String']['input'];
  s3Key: Scalars['String']['input'];
  sharingFileRequest: DistributionSharingFileRequest;
};


export type MutationShareDistributionForUserArgs = {
  input: DistributionSharingRequestForUser;
};


export type MutationShareDistributionV2Args = {
  distributionId: Scalars['Long']['input'];
  requests: Array<DefaultSharingRequest>;
};


export type MutationSubmitPromoCodeArgs = {
  input: SubmitPromoCodeInput;
};


export type MutationSubscribeArgs = {
  subscriptions: SubscriptionRequests;
};


export type MutationSwitchConsumedArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
  consumed: Scalars['Boolean']['input'];
};


export type MutationTransferArgs = {
  amount: MoneyInput;
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
  message: Scalars['String']['input'];
};


export type MutationUnblockPinArgs = {
  queryInput: UnblockPinQueryInput;
};


export type MutationUnblockPinCardMultiShopArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
};


export type MutationUnlockCardMultiShopArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
};


export type MutationUnsubscribeArgs = {
  subscriptions: Array<Scalars['ID']['input']>;
};


export type MutationUpdate2FaStatusArgs = {
  input: Update2FaStatusRequest;
};


export type MutationUpdateBeneficiaryAdditionalDataArgs = {
  input: UpdateAdditionalDataRequest;
};


export type MutationUpdateBeneficiaryFoodStatusArgs = {
  beneficiaryId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};


export type MutationUpdateBeneficiaryFoodWorkingDaysStatusArgs = {
  beneficiaryId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};


export type MutationUpdateBeneficiaryPreferenceLogoArgs = {
  input: UpdatePreferenceLogo;
};


export type MutationUpdateBeneficiarySiteAccessArgs = {
  input: BeneficiaryEnableSite;
};


export type MutationUpdateBeneficiaryV2Args = {
  patch: UpdateBeneficiaryPatchV2;
};


export type MutationUpdateCampaignArgs = {
  campaignId: Scalars['Long']['input'];
  input: UpdateCampaignRequest;
  organizationId: Scalars['ID']['input'];
};


export type MutationUpdateCommercialOfferArgs = {
  queryInput: CommercialOfferQueryInput;
};


export type MutationUpdateCommunicationFileCampaignArgs = {
  campaign: CommunicationFileCampaignInput;
};


export type MutationUpdateCommunicationNewsCampaignArgs = {
  campaign: CommunicationNewsCampaignInput;
};


export type MutationUpdateCommunicationSurveyCampaignArgs = {
  campaign: CommunicationSurveyCampaignInput;
};


export type MutationUpdateCreateAuthenticatedUserBillingAndShippingAddressArgs = {
  patch: UpdateCreateAuthenticatedUserAddressesRequest;
};


export type MutationUpdateCreateOrganizationAddressArgs = {
  patch?: InputMaybe<UpdateCreateOrganizationAddressRequest>;
};


export type MutationUpdateCreateOrganizationBillingAndShippingAddressArgs = {
  patch: UpdateCreateOrganizationAddressesRequest;
};


export type MutationUpdateCustomerCardArgs = {
  queryInput: UpdateCustomerCardQueryInput;
};


export type MutationUpdateMainManagerArgs = {
  mainManagerId: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};


export type MutationUpdateManagerAdditionalDataArgs = {
  input: UpdateAdditionalDataRequest;
};


export type MutationUpdateMultiShopsCardPhoneNumberArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
  phoneNumber: Scalars['String']['input'];
};


export type MutationUpdateOrderAmountArgs = {
  request?: InputMaybe<UpdateOrderAmountRequest>;
};


export type MutationUpdateOrganizationBankAccountArgs = {
  input: UpdateBankAccount;
};


export type MutationUpdateOrganizationBeneficiariesWalletAccessesS3Args = {
  bucket: Scalars['String']['input'];
  execute: Scalars['Boolean']['input'];
  organizationId: Scalars['ID']['input'];
  s3Key: Scalars['String']['input'];
};


export type MutationUpdateOrganizationEmailLogoArgs = {
  input: UpdateMailLogo;
};


export type MutationUpdateOrganizationFoodWorkingDaysStatusArgs = {
  organizationId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};


export type MutationUpdateOrganizationInformationArgs = {
  patch: UpdateOrganizationPatch;
};


export type MutationUpdateOrganizationKamArgs = {
  kamType: Scalars['String']['input'];
  orgId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationUpdateOrganizationMailLogoArgs = {
  logoFile: Scalars['Upload']['input'];
  organizationId: Scalars['ID']['input'];
};


export type MutationUpdatePreferenceLogoArgs = {
  logoFile: Scalars['Upload']['input'];
  preferenceId: Scalars['ID']['input'];
};


export type MutationUpdateProspectAsAdminArgs = {
  input?: InputMaybe<UpdateProspectDraftRequest>;
};


export type MutationUpdateProspectDraftArgs = {
  input?: InputMaybe<UpdateProspectDraftRequest>;
};


export type MutationUpdateProspectDraftAsAdminArgs = {
  input?: InputMaybe<UpdateProspectDraftRequestV2>;
};


export type MutationUpdateReasonArgs = {
  organizationId: Scalars['ID']['input'];
  reasonId: Scalars['Long']['input'];
  request: UpdateReasonRequest;
};


export type MutationUpdateSelfPersonalInformationArgs = {
  patch: UpdatePersonalInformationPatch;
};


export type MutationUpdateSimulationContactInformationsArgs = {
  request?: InputMaybe<PaperOrderSimulationContactUpdateRequest>;
};


export type MutationUpdateStatusTopUpArgs = {
  beneficiaryId: Scalars['ID']['input'];
  queryInput: TopUpStatusInput;
};


export type MutationUpdateUserBiometryStatusArgs = {
  input: UserBiometryStatusRequest;
};


export type MutationUpdateUserBlockedCauseArgs = {
  input: UpdateUserBlockedCauseRequest;
};


export type MutationUpdateUserEmailArgs = {
  email: UserEmail;
};


export type MutationUpdateUserInfoArgs = {
  input: UserInfo;
};


export type MutationUpdateUserLanguageArgs = {
  input: UserLanguage;
};


export type MutationUpdateUserListStatusArgs = {
  status: UserListStatus;
};


export type MutationUpdateUserLoginArgs = {
  login: UserLogin;
};


export type MutationUpdateUserRolesArgs = {
  input: UpdateUserRolesInput;
};


export type MutationUpdateUserStatusArgs = {
  status: UserStatus;
};


export type MutationUploadCardReloadBatchFileArgs = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  s3Key: Scalars['String']['input'];
};


export type MutationUploadCommunicationFileArgs = {
  file: CommunicationFileInput;
};


export type MutationUploadCommunicationImageArgs = {
  image: CommunicationImageInput;
};


export type MutationUploadFileBankArgs = {
  bucket?: InputMaybe<Scalars['String']['input']>;
  s3Key: Scalars['String']['input'];
};


export type MutationUsersResyncArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationValidateFileBankFoodArgs = {
  taskId: Scalars['ID']['input'];
};


export type MutationValidatePaymentArgs = {
  input: ValidatePaymentInput;
};


export type MutationValidateProspectArgs = {
  input: Scalars['ID']['input'];
};


export type MutationValidateProspectV2Args = {
  input: Scalars['ID']['input'];
};


export type MutationWaitForPaymentArgs = {
  request?: InputMaybe<WaitForPaymentRequest>;
};

export type MyGiftAdvantageOptionOutput = {
  __typename?: 'MyGiftAdvantageOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type NegotiatedDiscountItem = {
  __typename?: 'NegotiatedDiscountItem';
  description: Scalars['String']['output'];
  discountTaxAmount: Scalars['BigDecimal']['output'];
  discountWithTax: Scalars['BigDecimal']['output'];
  discountWithoutTax: Scalars['BigDecimal']['output'];
  percentage: Scalars['BigDecimal']['output'];
  transactionalCode: Scalars['String']['output'];
};

export type NegotiatedPricingTerm = {
  __typename?: 'NegotiatedPricingTerm';
  accountNumber?: Maybe<Scalars['String']['output']>;
  createDate?: Maybe<Scalars['DateTime']['output']>;
  createUserId?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  negotiatedPricingType?: Maybe<NegotiatedPricingType>;
  organizationId: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
  transactionalCode?: Maybe<Scalars['String']['output']>;
  updateUserId?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type NegotiatedPricingTermsResult = {
  __typename?: 'NegotiatedPricingTermsResult';
  negotiatedPricingTerms?: Maybe<Array<NegotiatedPricingTerm>>;
};

export enum NegotiatedPricingType {
  NegotiatedTypeDiscountPercentage = 'NEGOTIATED_TYPE_DISCOUNT_PERCENTAGE',
  NegotiatedTypeNewPrice = 'NEGOTIATED_TYPE_NEW_PRICE',
  NegotiatedTypePercentage = 'NEGOTIATED_TYPE_PERCENTAGE'
}

export type NewsletterAccount = {
  __typename?: 'NewsletterAccount';
  recipient?: Maybe<Recipient>;
};

export type NewsletterCampaign = {
  __typename?: 'NewsletterCampaign';
  campaignId: Scalars['String']['output'];
  campaignName: Scalars['String']['output'];
  creationDate?: Maybe<Scalars['String']['output']>;
  mailSentCount?: Maybe<Scalars['Int']['output']>;
  orgaId: Scalars['String']['output'];
  parameters?: Maybe<NewsletterCampaignParameters>;
  sentDate?: Maybe<Scalars['String']['output']>;
};

export type NewsletterCampaignParameters = {
  __typename?: 'NewsletterCampaignParameters';
  campaignId: Scalars['String']['output'];
  previewText?: Maybe<Scalars['String']['output']>;
  recipientScope: Scalars['String']['output'];
  senderMail: Scalars['String']['output'];
  senderName?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  targetingRule?: Maybe<GetNewsletterRecipientTargetingRule>;
};

export type NewsletterCampaignResult = NewsletterCampaign | NewsletterError;

export type NewsletterCanvas = {
  __typename?: 'NewsletterCanvas';
  canvas?: Maybe<Scalars['String']['output']>;
  html: Scalars['String']['output'];
};

export type NewsletterDraftCampaignPagination = {
  __typename?: 'NewsletterDraftCampaignPagination';
  campaignId: Scalars['String']['output'];
  creationDate: Scalars['String']['output'];
  orgaId: Scalars['String']['output'];
};

export type NewsletterDraftCampaignPaginationInput = {
  campaignId: Scalars['String']['input'];
  creationDate: Scalars['String']['input'];
  orgaId: Scalars['String']['input'];
};

export type NewsletterError = {
  __typename?: 'NewsletterError';
  description?: Maybe<Scalars['String']['output']>;
  errorCodes?: Maybe<Array<Scalars['String']['output']>>;
};

export type NewsletterGuideUrl = {
  __typename?: 'NewsletterGuideUrl';
  url: Scalars['String']['output'];
};

export type NewsletterImageUploaded = {
  __typename?: 'NewsletterImageUploaded';
  name: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type NewsletterListDraftCampaigns = {
  __typename?: 'NewsletterListDraftCampaigns';
  campaigns?: Maybe<Array<Maybe<NewsletterCampaign>>>;
  pagination?: Maybe<NewsletterDraftCampaignPagination>;
};

export type NewsletterListSentCampaigns = {
  __typename?: 'NewsletterListSentCampaigns';
  campaigns?: Maybe<Array<Maybe<NewsletterCampaign>>>;
  pagination?: Maybe<NewsletterSentCampaignPagination>;
};

export type NewsletterRecipientCount = {
  __typename?: 'NewsletterRecipientCount';
  recipientCount: Scalars['Int']['output'];
};

export type NewsletterRecipientTargetingRule = {
  __typename?: 'NewsletterRecipientTargetingRule';
  campaignId: Scalars['String']['output'];
  label: Scalars['String']['output'];
  targetingCalculationStatus?: Maybe<Scalars['String']['output']>;
  targetingRuleId: Scalars['String']['output'];
};

export type NewsletterRecipientTargetingRuleInput = {
  campaignId: Scalars['String']['input'];
  label: Scalars['String']['input'];
  targetingRuleId: Scalars['String']['input'];
};

export type NewsletterSentCampaignPagination = {
  __typename?: 'NewsletterSentCampaignPagination';
  campaignId: Scalars['String']['output'];
  orgaId: Scalars['String']['output'];
  sentDate: Scalars['String']['output'];
};

export type NewsletterSentCampaignPaginationInput = {
  campaignId: Scalars['String']['input'];
  orgaId: Scalars['String']['input'];
  sentDate: Scalars['String']['input'];
};

export type NewsletterTemplate = {
  __typename?: 'NewsletterTemplate';
  desktopPreviewPath: Scalars['String']['output'];
  organizationScope: Scalars['String']['output'];
  templateId: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type NewsletterTemplateInput = {
  organizationScope: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};

export type NewsletterTemplateList = {
  __typename?: 'NewsletterTemplateList';
  pagination?: Maybe<NewsletterTemplatePagination>;
  templates?: Maybe<Array<Maybe<NewsletterTemplate>>>;
};

export type NewsletterTemplatePagination = {
  __typename?: 'NewsletterTemplatePagination';
  organizationScope: Scalars['String']['output'];
  templateId: Scalars['String']['output'];
};

export type NewsletterTemplatePaginationInput = {
  organizationScope: Scalars['String']['input'];
  templateId: Scalars['String']['input'];
};

export type NewsletterTemplatePreview = {
  __typename?: 'NewsletterTemplatePreview';
  html: Scalars['String']['output'];
};

export type NewsletterTracking = {
  __typename?: 'NewsletterTracking';
  campaign?: Maybe<NewsletterCampaignResult>;
  canvas?: Maybe<GetNewsletterCanvasResult>;
  parameters?: Maybe<GetNewsletterCampaignParametersResult>;
  tracking?: Maybe<NewsletterTrackingReportResult>;
};

export type NewsletterTrackingInput = {
  campaignId: Scalars['String']['input'];
  orgaId: Scalars['String']['input'];
};

export type NewsletterTrackingReport = {
  __typename?: 'NewsletterTrackingReport';
  campaignId?: Maybe<Scalars['String']['output']>;
  clickedCount?: Maybe<Scalars['Int']['output']>;
  deliveredCount?: Maybe<Scalars['Int']['output']>;
  openCount?: Maybe<Scalars['Int']['output']>;
};

export type NewsletterTrackingReportResult = NewsletterError | NewsletterTrackingReport;

export type NewsletterTrackingResult = NewsletterError | NewsletterTracking;

export type NewsletterUnsplashGetImageInput = {
  campaignId: Scalars['String']['input'];
  idImage: Scalars['String']['input'];
  ixId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type NewsletterUnsplashImage = {
  __typename?: 'NewsletterUnsplashImage';
  author?: Maybe<Scalars['String']['output']>;
  authorLink?: Maybe<Scalars['String']['output']>;
  htmlLink?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  ixId?: Maybe<Scalars['String']['output']>;
  previewUrl?: Maybe<Scalars['String']['output']>;
};

export type NewsletterUnsplashSearchImages = {
  __typename?: 'NewsletterUnsplashSearchImages';
  currentPage?: Maybe<Scalars['Int']['output']>;
  images?: Maybe<Array<Maybe<NewsletterUnsplashImage>>>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type NewsletterUnsplashSearchInput = {
  itemPerPage: Scalars['Int']['input'];
  orientation: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  query: Scalars['String']['input'];
};

export type NewsletterUploadImageInput = {
  base64Content: Scalars['String']['input'];
  campaignId: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
  fileType: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type NoStockShopMetaData = {
  __typename?: 'NoStockShopMetaData';
  extUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated use Shop.customDiscountTag instead */
  minDiscount?: Maybe<Scalars['Int']['output']>;
};

export type NominalChecksOption = {
  __typename?: 'NominalChecksOption';
  type: NominalChecksType;
};

export type NominalChecksOptionInput = {
  type: NominalChecksType;
};

export type NominalChecksOptionOutput = {
  __typename?: 'NominalChecksOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<NominalChecksType>;
};

export enum NominalChecksType {
  NominalChecks = 'NOMINAL_CHECKS',
  NotNominal = 'NOT_NOMINAL'
}

/** Object used for nullable string fields that may be patched or not by an update mutation. */
export type NullableStringPatch = {
  value?: InputMaybe<Scalars['String']['input']>;
};

export type OfferWallShop = {
  __typename?: 'OfferWallShop';
  discountRatio?: Maybe<Scalars['BigDecimal']['output']>;
  /**  Id wallet / Id Shop to be unique for the discount and cache */
  id: Scalars['String']['output'];
  shop: Shop;
  shopFee?: Maybe<ShopFee>;
};

export type OfferWallShopDiscount = {
  __typename?: 'OfferWallShopDiscount';
  discountRatio?: Maybe<Scalars['BigDecimal']['output']>;
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
};

export type OfferWallShopFilter = {
  allAmounts?: InputMaybe<Scalars['Boolean']['input']>;
  availableImmediately?: InputMaybe<Scalars['Boolean']['input']>;
  availableInShop?: InputMaybe<Scalars['Boolean']['input']>;
  availableOnInternet?: InputMaybe<Scalars['Boolean']['input']>;
  category?: InputMaybe<Scalars['Long']['input']>;
  cumulativeWithPromotion?: InputMaybe<Scalars['Boolean']['input']>;
  mobile?: InputMaybe<Scalars['Boolean']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  shopIds?: InputMaybe<Array<Scalars['Long']['input']>>;
  sorting: WalletSorting;
};

export type OfferWallShopPage = PageResult & {
  __typename?: 'OfferWallShopPage';
  index: Scalars['Int']['output'];
  items: Array<OfferWallShop>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Options = {
  __typename?: 'Options';
  group?: Maybe<GroupOptionOutput>;
  letterTemplate?: Maybe<LetterTemplateOptionOutput>;
  logo?: Maybe<LogoOptionOutput>;
  message?: Maybe<MessageOptionOutput>;
  myGiftAdvantage?: Maybe<MyGiftAdvantageOptionOutput>;
  nominalChecks?: Maybe<NominalChecksOptionOutput>;
  packaging?: Maybe<PackagingOptionOutput>;
  shippingMode?: Maybe<ShippingModeOptionOutput>;
  signaturesList?: Maybe<SignaturesListOptionOutput>;
  summaryVoucher?: Maybe<SummaryVoucherOptionOutput>;
};

export type OrderCustomerCardOrderQueryInput = {
  beneficiaryId: Scalars['ID']['input'];
  customerDeliveryAddressFood?: InputMaybe<DeliveryAddressFoodInput>;
  organizationDeliveryAddressId?: InputMaybe<Scalars['ID']['input']>;
};

export type OrderDetails = {
  __typename?: 'OrderDetails';
  amount?: Maybe<Money>;
  billingInvoice?: Maybe<BillingInvoice>;
  billingOrderForm?: Maybe<BillingOrderForm>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  fees?: Maybe<Fees>;
  giftId?: Maybe<Scalars['String']['output']>;
  isComplexOrder?: Maybe<Scalars['Boolean']['output']>;
  options?: Maybe<Options>;
  orderRefGlady?: Maybe<Scalars['String']['output']>;
  orderUuid?: Maybe<Scalars['String']['output']>;
  products?: Maybe<Array<Maybe<PaperOrderProductOutput>>>;
  remade?: Maybe<Scalars['Boolean']['output']>;
  shippingAddress?: Maybe<PaperOrderAddress>;
  status?: Maybe<PaperOrderOrderStatus>;
  type?: Maybe<ProductType>;
};

export type OrderHistoryItem = {
  __typename?: 'OrderHistoryItem';
  month?: Maybe<Scalars['String']['output']>;
  orders?: Maybe<Array<Maybe<OrderSummary>>>;
};

export enum OrderMode {
  Postpaid = 'POSTPAID',
  Prepaid = 'PREPAID',
  Unknown = 'UNKNOWN'
}

export type OrderProduct = {
  __typename?: 'OrderProduct';
  amountIncludingVat?: Maybe<Money>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Long']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type OrderRequest = {
  companyUuid: Scalars['ID']['input'];
  customReference?: InputMaybe<Scalars['String']['input']>;
  deductedLeftAmount?: InputMaybe<MoneyInput>;
  deductedNoShowAmount?: InputMaybe<MoneyInput>;
  depositUuid: Scalars['ID']['input'];
  items: Array<OrderRequestItem>;
  paymentId?: InputMaybe<Scalars['Long']['input']>;
  simulate: Scalars['Boolean']['input'];
};

export type OrderRequestItem = {
  amounts: Array<InputMaybe<OrderRequestItemAmount>>;
  reasonId: Scalars['ID']['input'];
};

export type OrderRequestItemAmount = {
  amount: Scalars['String']['input'];
  quantity: Scalars['Long']['input'];
};

export enum OrderStatus {
  Created = 'CREATED',
  Paid = 'PAID',
  Sent = 'SENT',
  Validated = 'VALIDATED',
  WaitingForPayment = 'WAITING_FOR_PAYMENT'
}

export enum OrderStatusType {
  Cancelled = 'CANCELLED',
  Error = 'ERROR',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Validated = 'VALIDATED'
}

export type OrderSummary = {
  __typename?: 'OrderSummary';
  amount?: Maybe<Money>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  isComplexOrder?: Maybe<Scalars['Boolean']['output']>;
  orderId?: Maybe<Scalars['String']['output']>;
  orderRefGlady?: Maybe<Scalars['String']['output']>;
  remade?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<PaperOrderOrderStatus>;
  type?: Maybe<ProductType>;
};

export type OrderTask = {
  __typename?: 'OrderTask';
  bucketFilePath?: Maybe<Scalars['String']['output']>;
  checkedNumberLines?: Maybe<Scalars['Long']['output']>;
  checkingNumberLines?: Maybe<Scalars['Long']['output']>;
  creationDate?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<OrderTaskDetailErrorEnum>>>;
  expireAt?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderTaskStatusEnum>;
  taskId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  validatedNumberOrder?: Maybe<Scalars['Long']['output']>;
  validationNumberOrder?: Maybe<Scalars['Long']['output']>;
};

export type OrderTaskDetail = {
  __typename?: 'OrderTaskDetail';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  commandReference?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<OrderTaskErrorDetail>>>;
  organization?: Maybe<Organization>;
  status?: Maybe<OrderTaskDetailStatusEnum>;
  taskId?: Maybe<Scalars['String']['output']>;
};

export enum OrderTaskDetailErrorEnum {
  AmountDoesNotMatch = 'AMOUNT_DOES_NOT_MATCH',
  CouldNotProcessFile = 'COULD_NOT_PROCESS_FILE',
  DuplicateKey = 'DUPLICATE_KEY',
  NoOrdersFound = 'NO_ORDERS_FOUND',
  OrderAlreadyProcessed = 'ORDER_ALREADY_PROCESSED',
  OrderNotFound = 'ORDER_NOT_FOUND',
  OrderNotRecognized = 'ORDER_NOT_RECOGNIZED'
}

export enum OrderTaskDetailStatusEnum {
  CheckingError = 'CHECKING_ERROR',
  PendingForValidation = 'PENDING_FOR_VALIDATION',
  Validated = 'VALIDATED',
  ValidationError = 'VALIDATION_ERROR'
}

export type OrderTaskErrorDetail = {
  __typename?: 'OrderTaskErrorDetail';
  actual?: Maybe<Scalars['String']['output']>;
  code?: Maybe<OrderTaskDetailErrorEnum>;
  expected?: Maybe<Scalars['String']['output']>;
};

export enum OrderTaskStatusEnum {
  CheckingInError = 'CHECKING_IN_ERROR',
  CheckingInProgress = 'CHECKING_IN_PROGRESS',
  PendingForChecking = 'PENDING_FOR_CHECKING',
  PendingForValidation = 'PENDING_FOR_VALIDATION',
  ValidationComplete = 'VALIDATION_COMPLETE',
  ValidationInError = 'VALIDATION_IN_ERROR',
  ValidationInProgress = 'VALIDATION_IN_PROGRESS'
}

export enum OrderType {
  Card = 'CARD',
  Reload = 'RELOAD'
}

export type OrdersQueryInput = {
  orderStatus: Array<InputMaybe<OrderStatusType>>;
  orderTypes: Array<InputMaybe<OrderType>>;
  organizationId: Scalars['ID']['input'];
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  sort: SortInput;
};

export type OrgAdminSurvey = {
  customInfo?: InputMaybe<Scalars['String']['input']>;
  info: Scalars['String']['input'];
};

/** An organization */
export type Organization = {
  __typename?: 'Organization';
  /** The organization account name */
  accountName: Scalars['String']['output'];
  accountingId?: Maybe<Scalars['String']['output']>;
  /** Fetches one additional data schema of this orga's extended profile */
  additionalDataSchema?: Maybe<PacAdditionalDataSchema>;
  additionalModules?: Maybe<Array<PlatformModule>>;
  /** Lists all aggregate monthly payouts for this orga */
  aggregateMonthlyPayouts: PacAggregateMonthlyPayoutsList;
  /** Get all the user groups for this orga */
  allUserGroups?: Maybe<PacAllUserGroupsList>;
  almostEmptyDeposits?: Maybe<Array<Scalars['String']['output']>>;
  availableReasons?: Maybe<DistributionReasonPage>;
  availableWallets?: Maybe<WalletPage>;
  /** Fetches stripe connect account balance (available and pending amounts) */
  balance: PacAccountBalanceDetails;
  bankAccounts?: Maybe<Array<BankAccount>>;
  /** Fetches a list of specific user groups for this orga */
  batchUserGroups?: Maybe<PacBatchUserGroupsList>;
  beneficiariesDistributionsReport?: Maybe<GiftTaskResponse>;
  beneficiariesWalletAccessesReport?: Maybe<GiftTaskResponse>;
  /** Fetches one specific benefit for this orga */
  benefit?: Maybe<PacBenefit>;
  /** Lists all benefits */
  benefits?: Maybe<PacBenefitSearchResult>;
  billingAddress?: Maybe<Address>;
  budget?: Maybe<SubSegment>;
  businessDeveloper?: Maybe<Beneficiary>;
  campaignMailsReport?: Maybe<GiftTaskResponse>;
  campaignMessage?: Maybe<Scalars['String']['output']>;
  campaignReport?: Maybe<GiftTaskResponse>;
  clientCredentials?: Maybe<Array<Secret>>;
  /** Ratio between 0 and 1 */
  consumptionRatio?: Maybe<Scalars['BigDecimal']['output']>;
  /** The organization's country */
  country: Scalars['String']['output'];
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  csm?: Maybe<Beneficiary>;
  defaultPaymentMethod?: Maybe<DefaultPaymentMethod>;
  dtsCampaign: DtsCampaign;
  dtsCampaignConfiguration: DtsCampaignConfiguration;
  dtsCampaignInvoice: Scalars['String']['output'];
  dtsCampaignPurchaseOrder: Scalars['String']['output'];
  dtsCampaigns: Array<DtsCampaign>;
  /** Base64-encoded XLSX file listing the campaigns created in the given interval */
  dtsCampaignsReport: Scalars['String']['output'];
  dtsCountCampaigns: Scalars['Int']['output'];
  dtsCountDistributions: Scalars['Int']['output'];
  /** Base64-encoded XLSX file listing the distributions */
  dtsDistributionsReport: Scalars['String']['output'];
  dtsFindCampaignDraft: DtsFindCampaignDraft;
  dtsIsCampaignCreated: Scalars['Boolean']['output'];
  dtsIsOnboardingCompleted: Scalars['Boolean']['output'];
  dtsMonitoringDistributions: Array<DtsMonitoringDistribution>;
  dtsPreparePaymentSession: DtsPreparePaymentSessionResponse;
  dtsValidateCommand: DtsValidateCommandResponse;
  /** Fetches one request made by a beneficiary to modify their extended profile */
  epRequest: PacEpRequest;
  /** Lists requests made by beneficiaries to modify their extended profile */
  epRequests: PacEpRequests;
  existsGrantSpecificConfiguration?: Maybe<Scalars['Boolean']['output']>;
  existsWalletSpecificConfiguration?: Maybe<Scalars['Boolean']['output']>;
  extendedCampaign?: Maybe<ExtendedCampaignPage>;
  /** The Extended Profile of an organization */
  extendedProfile: PacExtendedProfile;
  /** Checks if a given feature flag is active for the orga */
  featureIsActive?: Maybe<Scalars['Boolean']['output']>;
  /** perso url */
  frontUrl?: Maybe<Scalars['String']['output']>;
  functionalModules?: Maybe<Array<OrganizationFunctionalModule>>;
  fundableWallets: Array<FundableWallet>;
  /** Email for communication purposes */
  genericEmail?: Maybe<Scalars['String']['output']>;
  /** Organization contact phone number */
  genericPhoneNumber?: Maybe<Scalars['String']['output']>;
  giftCampaignIds: Array<Scalars['Long']['output']>;
  giftConfig: GiftOrganizationConfiguration;
  giftDeposit?: Maybe<GiftDeposit>;
  giftDeposits: Array<GiftDeposit>;
  giftOrder?: Maybe<GiftOrderResponse>;
  giftOrders?: Maybe<GiftOrderPage>;
  /** Checks if at least one order exists for this orga after applying search filters */
  hasOrder?: Maybe<Scalars['Boolean']['output']>;
  /** Checks if at least one perk exists for this orga after applying search filters */
  hasPerk?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  /** The SIRET number for french organizations */
  identifier?: Maybe<Scalars['String']['output']>;
  iframeData?: Maybe<IFrameData>;
  invitationMailStatus?: Maybe<MailStatus>;
  isAutonomous?: Maybe<Scalars['Boolean']['output']>;
  isModuleSubscribed?: Maybe<Scalars['Boolean']['output']>;
  /** Check if the configuration already exists for this orga */
  isUserGroupConfigurationUnique: PacIsUserGroupConfigurationUnique;
  /** The default language for communications and newly created beneficiaries */
  locale: Scalars['String']['output'];
  locationAddresses?: Maybe<Array<Address>>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  mainAvailableAmount?: Maybe<Money>;
  mainManager?: Maybe<Beneficiary>;
  managers?: Maybe<Array<Beneficiary>>;
  migrationOrigin?: Maybe<MigrationOrigin>;
  /** The organization name */
  name: Scalars['String']['output'];
  needInfoCheck?: Maybe<Scalars['Boolean']['output']>;
  offerWallShops: Array<CompanyOfferWallShop>;
  /** Fetches one specific order for this orga */
  order: PacOrder;
  /** Lists all orders for this orga after applying search filters */
  orders: PacOrderList;
  /** Lists all payouts for this orga */
  payouts: PacPayoutDataList;
  /** Fetches one specific perk for this orga */
  perk: PacPerk;
  /** List all perk utilisations for this orga */
  perkUtilisations: PacPerkUtilisationsSearch;
  /** Lists all perks for this orga after applying search filters */
  perks: PacPerkList;
  preference?: Maybe<Preference>;
  rna?: Maybe<Scalars['String']['output']>;
  sdxData?: Maybe<SdxData>;
  segment?: Maybe<CompanySegment>;
  shippingAddress?: Maybe<Address>;
  shopFees: Array<Maybe<ShopFee>>;
  siret?: Maybe<Scalars['String']['output']>;
  /** Fetches the situation of on perk defined for this orga */
  situation: PacPerkSituation;
  /** Lists the situation of all perks defined for this orga */
  situations: PacPerkSituationList;
  size?: Maybe<SubSegment>;
  statistics?: Maybe<OrganizationStatistics>;
  status?: Maybe<OrganizationStatus>;
  subSegment?: Maybe<SubSegment>;
  subscribedModules?: Maybe<Array<PlatformModule>>;
  /** All the tags of an organization */
  tags?: Maybe<Array<Scalars['String']['output']>>;
  totalOrderAmount?: Maybe<Money>;
  typology?: Maybe<Scalars['String']['output']>;
  uiRights?: Maybe<OrganizationUiRights>;
  uiRightsV2?: Maybe<OrganizationUiRights>;
  /** Fetches one specific user group for this orga */
  userGroup: PacUserGroup;
  /** Lists all user groups */
  userGroups?: Maybe<PacUserGroupsList>;
  vatEuropeanNumber?: Maybe<Scalars['String']['output']>;
  wallets?: Maybe<WalletPage>;
};


/** An organization */
export type OrganizationAdditionalDataSchemaArgs = {
  id: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationAggregateMonthlyPayoutsArgs = {
  searchParams?: InputMaybe<PacAggregateMonthlyPayoutsSearchParams>;
};


/** An organization */
export type OrganizationAllUserGroupsArgs = {
  searchParams?: InputMaybe<PacAllUserGroupsSearchParams>;
};


/** An organization */
export type OrganizationAvailableReasonsArgs = {
  request: PageRequest;
  specs?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationAvailableWalletsArgs = {
  request: PageRequest;
  specs?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationBatchUserGroupsArgs = {
  userGroupsUuids: Array<Scalars['ID']['input']>;
};


/** An organization */
export type OrganizationBeneficiariesDistributionsReportArgs = {
  currency?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationBenefitArgs = {
  benefitUuid: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationBenefitsArgs = {
  searchParams: PacBenefitSearchParams;
};


/** An organization */
export type OrganizationCampaignMailsReportArgs = {
  campaignId: Scalars['Long']['input'];
};


/** An organization */
export type OrganizationCampaignMessageArgs = {
  reasonId: Scalars['Long']['input'];
};


/** An organization */
export type OrganizationCampaignReportArgs = {
  ids: Array<Scalars['Long']['input']>;
};


/** An organization */
export type OrganizationDtsCampaignArgs = {
  campaignId: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationDtsCampaignConfigurationArgs = {
  campaignId: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationDtsCampaignInvoiceArgs = {
  campaignId: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationDtsCampaignPurchaseOrderArgs = {
  campaignId: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationDtsCampaignsArgs = {
  lastCampaignUuid?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** An organization */
export type OrganizationDtsCampaignsReportArgs = {
  input: DtsCampaignsReportInput;
};


/** An organization */
export type OrganizationDtsCountDistributionsArgs = {
  beneficiaryId?: InputMaybe<Scalars['ID']['input']>;
  campaignId: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationDtsDistributionsReportArgs = {
  input: DtsDistributionsReportInput;
};


/** An organization */
export type OrganizationDtsIsCampaignCreatedArgs = {
  billingOrderId?: InputMaybe<Scalars['String']['input']>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationDtsMonitoringDistributionsArgs = {
  beneficiaryId?: InputMaybe<Scalars['ID']['input']>;
  campaignId: Scalars['ID']['input'];
  lastDistributionId?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


/** An organization */
export type OrganizationDtsPreparePaymentSessionArgs = {
  input: DtsPreparePaymentSessionRequest;
};


/** An organization */
export type OrganizationDtsValidateCommandArgs = {
  billingOrderId?: InputMaybe<Scalars['String']['input']>;
  campaignId?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationEpRequestArgs = {
  requestId: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationEpRequestsArgs = {
  searchParams?: InputMaybe<PacEpRequestsSearchParams>;
};


/** An organization */
export type OrganizationExistsGrantSpecificConfigurationArgs = {
  depositId: Scalars['Long']['input'];
  shopPackageId: Scalars['Long']['input'];
  walletId: Scalars['Long']['input'];
};


/** An organization */
export type OrganizationExistsWalletSpecificConfigurationArgs = {
  shopPackageId: Scalars['Long']['input'];
  walletId: Scalars['Long']['input'];
};


/** An organization */
export type OrganizationExtendedCampaignArgs = {
  request: PageRequest;
  specs?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationFeatureIsActiveArgs = {
  featureName: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationGiftCampaignIdsArgs = {
  specs?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationGiftDepositArgs = {
  id: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationGiftDepositsArgs = {
  specs?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationGiftOrderArgs = {
  orderId?: InputMaybe<Scalars['Long']['input']>;
  paymentId?: InputMaybe<Scalars['Long']['input']>;
};


/** An organization */
export type OrganizationGiftOrdersArgs = {
  orderSpec?: InputMaybe<GiftOrderSpec>;
  request: PageRequest;
  specs?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationHasOrderArgs = {
  searchParams?: InputMaybe<PacHasOrderSearchParams>;
};


/** An organization */
export type OrganizationHasPerkArgs = {
  searchParams?: InputMaybe<PacHasPerkSearchParams>;
};


/** An organization */
export type OrganizationIsModuleSubscribedArgs = {
  moduleCode: Scalars['String']['input'];
};


/** An organization */
export type OrganizationIsUserGroupConfigurationUniqueArgs = {
  searchParams: PacIsUserGroupConfigurationUniqueSearchParams;
};


/** An organization */
export type OrganizationOfferWallShopsArgs = {
  shopPackageId?: InputMaybe<Scalars['Long']['input']>;
  walletId: Scalars['Long']['input'];
};


/** An organization */
export type OrganizationOrderArgs = {
  orderUuid: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationOrdersArgs = {
  searchParams?: InputMaybe<PacOrdersSearchParams>;
};


/** An organization */
export type OrganizationPayoutsArgs = {
  searchParams?: InputMaybe<PacPayoutDataSearchParams>;
};


/** An organization */
export type OrganizationPerkArgs = {
  perkId: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationPerkUtilisationsArgs = {
  searchParams?: InputMaybe<PacPerkUtilisationsSearchParams>;
};


/** An organization */
export type OrganizationPerksArgs = {
  searchParams?: InputMaybe<PacPerksSearchParams>;
};


/** An organization */
export type OrganizationSituationArgs = {
  perkId: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationSituationsArgs = {
  searchParams?: InputMaybe<PacPerksSearchParams>;
};


/** An organization */
export type OrganizationStatisticsArgs = {
  currency?: InputMaybe<Scalars['String']['input']>;
};


/** An organization */
export type OrganizationTotalOrderAmountArgs = {
  startDate: Scalars['DateTime']['input'];
};


/** An organization */
export type OrganizationUserGroupArgs = {
  userGroupUuid: Scalars['ID']['input'];
};


/** An organization */
export type OrganizationUserGroupsArgs = {
  searchParams?: InputMaybe<PacUserGroupsSearchParams>;
};


/** An organization */
export type OrganizationWalletsArgs = {
  request: PageRequest;
  specs?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationAsyncOrderRequest = {
  hostUrl?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<OrganizationOrderRequest>;
  orderId?: InputMaybe<Scalars['Long']['input']>;
  preferredScheme?: InputMaybe<Scalars['String']['input']>;
  returnPath?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

export enum OrganizationCountry {
  Belgium = 'BELGIUM',
  France = 'FRANCE',
  Unknown = 'UNKNOWN'
}

export type OrganizationDistribution = GenericDistribution & {
  __typename?: 'OrganizationDistribution';
  amount: Money;
  campaignStatus: CampaignStatus;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  creationDate: Scalars['DateTime']['output'];
  /**  can be null if campaign is planned */
  detail?: Maybe<DistributionDetail>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  massSharingEnabled: Scalars['Boolean']['output'];
  organizationPreview: DistributionOrganizationPreview;
  reason: DistributionReason;
  userPreview: DistributionUserPreview;
  wallet: Wallet;
};

export type OrganizationDistributionStatistics = {
  __typename?: 'OrganizationDistributionStatistics';
  distributedTotalAmount: Money;
  month: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
  usedTotalAmount: Money;
  year: Scalars['Int']['output'];
};

export type OrganizationFunctionalModule = {
  __typename?: 'OrganizationFunctionalModule';
  activeSubscription?: Maybe<ModuleSubscription>;
  functionalModule: FunctionalModule;
};

export type OrganizationOrderRequest = {
  customReference?: InputMaybe<Scalars['String']['input']>;
  deductedLeftAmount?: InputMaybe<MoneyInput>;
  deductedNoShowAmount?: InputMaybe<MoneyInput>;
  depositUuid: Scalars['String']['input'];
  items: Array<OrderRequestItem>;
  organizationUuid: Scalars['String']['input'];
};

export type OrganizationPageQueryInput = {
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search: Scalars['String']['input'];
};

export type OrganizationPageResponse = {
  __typename?: 'OrganizationPageResponse';
  index: Scalars['Int']['output'];
  items: Array<Maybe<Organization>>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type OrganizationShopFee = ShopFee & {
  __typename?: 'OrganizationShopFee';
  amount?: Maybe<Money>;
  amountRoundingMode?: Maybe<ShopFeeAmountRoundingMode>;
  billingMode: ShopFeeBillingMode;
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  parentFee: GenericShopFee;
  percentage?: Maybe<Scalars['BigDecimal']['output']>;
  shippingMode?: Maybe<ShopFeeShippingMode>;
  shops?: Maybe<Array<Maybe<Shop>>>;
  type: ShopFeeType;
};

export type OrganizationStatistics = {
  __typename?: 'OrganizationStatistics';
  /** Remaining beneficiaries amounts = distributions - usages - refund */
  beneficiariesTotalBalance?: Maybe<Money>;
  /** Sum of all contributions made by company to Glady account */
  contributionsTotalAmount?: Maybe<Money>;
  /** Sum of company deposits amounts from refund */
  depositsRefundTotalAmount?: Maybe<Money>;
  /** Sum of company deposits amounts usable for distributions */
  distributableDepositsTotalAmount?: Maybe<Money>;
  distributionsStatistics: Array<OrganizationDistributionStatistics>;
  /** Limit alert subvention */
  limitAlertSubvention?: Maybe<Money>;
  /** Sum of running distributions amounts */
  runningDistributionTotalAmount?: Maybe<Money>;
  /** Sum of all subventions */
  subventionBalanceSum?: Maybe<Money>;
  usageStatistics: Array<OrganizationUsageStatistics>;
};

export type OrganizationStatus = {
  __typename?: 'OrganizationStatus';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  statusAdditionalData?: Maybe<StatusAdditionalData>;
};

export type OrganizationUiRights = {
  __typename?: 'OrganizationUiRights';
  canCreateOrganization: Scalars['Boolean']['output'];
  canEditKeyAccountManager: Scalars['Boolean']['output'];
  canEditOrgAccountName: Scalars['Boolean']['output'];
  canGenerateApiKey: Scalars['Boolean']['output'];
  canSeeApiKey: Scalars['Boolean']['output'];
  canSeeApiSecret: Scalars['Boolean']['output'];
  canSeeKeyAccountManager: Scalars['Boolean']['output'];
};

export type OrganizationUsageStatistics = {
  __typename?: 'OrganizationUsageStatistics';
  cardType: CardType;
  count: Scalars['Long']['output'];
};

export type OrganizationWallet = {
  __typename?: 'OrganizationWallet';
  available: Scalars['Boolean']['output'];
  wallet: Wallet;
};

export type OrganizationWalletOverride = {
  __typename?: 'OrganizationWalletOverride';
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type OrganizationWalletOverrideRequest = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type PacAccountBalanceDetails = {
  __typename?: 'PacAccountBalanceDetails';
  availableAmount: Scalars['Float']['output'];
  pendingAmount: Scalars['Float']['output'];
};

export type PacAdCriteriaInput = {
  operator: CriteriaOperator;
  refValues: Array<Scalars['String']['input']>;
  schemaId: Scalars['ID']['input'];
};

export type PacAdditionalData = {
  __typename?: 'PacAdditionalData';
  files: Array<PacDocument>;
  id: Scalars['ID']['output'];
  schema: PacAdditionalDataSchema;
  status: PacAdditionalDataStatus;
  value: Scalars['String']['output'];
};

export type PacAdditionalDataRequest = {
  __typename?: 'PacAdditionalDataRequest';
  additionalDataSchema: PacAdditionalDataSchema;
  schemaId: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

/** Defines the schema of an additional data the beneficiaries will have to fill. */
export type PacAdditionalDataSchema = {
  __typename?: 'PacAdditionalDataSchema';
  additionalDataSchemaStatus?: Maybe<PacAdditionalDataSchemaStatus>;
  description?: Maybe<Scalars['String']['output']>;
  documentName?: Maybe<Scalars['String']['output']>;
  hasRequestInProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  isVisible: Scalars['Boolean']['output'];
  /** Additional properties in case the type is `configurable:list`. */
  listTypeDefinition?: Maybe<PacAdsListTypeDefinition>;
  name: Scalars['String']['output'];
  needDocument: Scalars['Boolean']['output'];
  needValidation: Scalars['Boolean']['output'];
  orga: Organization;
  type: Scalars['String']['output'];
};

export type PacAdditionalDataSchemaInput = {
  description: Scalars['String']['input'];
  documentName?: InputMaybe<Scalars['String']['input']>;
  isVisible: Scalars['Boolean']['input'];
  /** Optional: fill only if of type `configurable:list` */
  listPossibleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  needDocument: Scalars['Boolean']['input'];
  needValidation: Scalars['Boolean']['input'];
  type: Scalars['String']['input'];
};

export type PacAdditionalDataSchemaSearch = {
  __typename?: 'PacAdditionalDataSchemaSearch';
  lastEvaluatedKey?: Maybe<PacLastEvaluatedKey>;
  schemas: Array<PacAdditionalDataSchema>;
};

export type PacAdditionalDataSchemaSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  statusFilters?: InputMaybe<Array<PacAdditionalDataSchemaStatus>>;
};

export enum PacAdditionalDataSchemaStatus {
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Published = 'PUBLISHED',
  Unspecified = 'UNSPECIFIED'
}

export type PacAdditionalDataSchemaUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  documentName?: InputMaybe<Scalars['String']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  listPossibleValues?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  needDocument?: InputMaybe<Scalars['Boolean']['input']>;
  needValidation?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum PacAdditionalDataStatus {
  AcceptedByManager = 'ACCEPTED_BY_MANAGER',
  FilledByAdmin = 'FILLED_BY_ADMIN',
  FilledByBenef = 'FILLED_BY_BENEF',
  FilledByManager = 'FILLED_BY_MANAGER',
  RefusedByBenef = 'REFUSED_BY_BENEF'
}

export type PacAdsCompletionRate = {
  __typename?: 'PacAdsCompletionRate';
  completedAndValidate?: Maybe<Scalars['Float']['output']>;
  notCompleted?: Maybe<Scalars['Float']['output']>;
  refusedToCommunicate?: Maybe<Scalars['Float']['output']>;
};

export type PacAdsListTypeDefinition = {
  __typename?: 'PacAdsListTypeDefinition';
  /** The beneficiary will have to select one of these values when filing the corresponding additional data. */
  values: Array<Scalars['String']['output']>;
};

/** Template for an additional data schema. */
export type PacAdsTemplate = {
  __typename?: 'PacAdsTemplate';
  /** Unique and human readable identifier of the template */
  code: Scalars['ID']['output'];
  /** Content of the template, with an empty id */
  schema: PacAdditionalDataSchema;
};

export type PacAggregateMonthlyPayouts = {
  __typename?: 'PacAggregateMonthlyPayouts';
  amount: Scalars['Float']['output'];
  date: Scalars['String']['output'];
};

export type PacAggregateMonthlyPayoutsList = {
  __typename?: 'PacAggregateMonthlyPayoutsList';
  aggregates: Array<PacAggregateMonthlyPayouts>;
  lastEvaluatedKey: PacLastEvaluatedKey;
};

export type PacAggregateMonthlyPayoutsSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export type PacAllUserGroupsList = {
  __typename?: 'PacAllUserGroupsList';
  userGroups: Array<PacUserGroup>;
};

export type PacAllUserGroupsSearchParams = {
  statusFilter?: InputMaybe<Array<PacUserGroupStatus>>;
};

export type PacAmountTuple = {
  __typename?: 'PacAmountTuple';
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type PacArchiveAdditionalDataSchemaResult = GestionError | PacEmpty;

export type PacArchiveUserGroupInput = {
  userGroupUuid: Scalars['String']['input'];
};

export type PacArchiveUserGroupOutput = GestionError | PacEmpty;

export type PacBankDetails = {
  __typename?: 'PacBankDetails';
  bic: Scalars['String']['output'];
  holder: Scalars['String']['output'];
  iban: Scalars['String']['output'];
  userId: Scalars['ID']['output'];
};

export type PacBankDetailsResult = GestionError | PacBankDetails;

export type PacBaselineBenefit = {
  __typename?: 'PacBaselineBenefit';
  benefitCategory: PacBenefitCategory;
  benefitStatus: PacBenefitStatus;
  benefitType: PacBenefitType;
  createTimestamp: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgaUuid: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

export type PacBatchDownload = GestionError | MultipartBody;

/**  Union types */
export type PacBatchFileResult = GestionError | PacBatchJob;

/**  Common */
export type PacBatchJob = {
  __typename?: 'PacBatchJob';
  endDate?: Maybe<Scalars['Float']['output']>;
  line?: Maybe<Scalars['String']['output']>;
  metadata?: Maybe<Array<Maybe<MetadataBatchEntry>>>;
  orgaUuid: Scalars['String']['output'];
  startBy?: Maybe<Beneficiary>;
  startDate: Scalars['Float']['output'];
  status: PacBatchJobStatus;
  subject: Scalars['String']['output'];
  type: PacBatchJobType;
  uuid: Scalars['ID']['output'];
};

export enum PacBatchJobStatus {
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Success = 'SUCCESS',
  Unspecified = 'UNSPECIFIED'
}

export enum PacBatchJobType {
  Ancv = 'ANCV',
  Distrib = 'DISTRIB',
  ExportAdditionalData = 'EXPORT_ADDITIONAL_DATA',
  ExportRightOwner = 'EXPORT_RIGHT_OWNER',
  Sepa = 'SEPA',
  Unspecified = 'UNSPECIFIED'
}

export type PacBatchJobs = {
  __typename?: 'PacBatchJobs';
  pacBatchJobs?: Maybe<Array<Maybe<PacBatchJob>>>;
};

export type PacBatchUserGroupsList = {
  __typename?: 'PacBatchUserGroupsList';
  userGroups: Array<PacUserGroup>;
};

export type PacBenefit = PacCultureBenefit | PacGiftBenefit;

export enum PacBenefitCategory {
  Culture = 'CULTURE',
  Gift = 'GIFT'
}

export type PacBenefitRule = {
  __typename?: 'PacBenefitRule';
  endowmentRule?: Maybe<PacEndowmentRule>;
  reimbursementRule?: Maybe<PacReimbursementRule>;
};

export type PacBenefitSearchParams = {
  cursor: Scalars['String']['input'];
  pageSize: Scalars['Int']['input'];
};

export type PacBenefitSearchResult = {
  __typename?: 'PacBenefitSearchResult';
  benefits: Array<PacBenefit>;
  cursor: Scalars['String']['output'];
};

export enum PacBenefitStatus {
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum PacBenefitType {
  Endowment = 'ENDOWMENT'
}

export type PacCancelEpRequestResult = GestionError | PacEpRequest;

export type PacCheckAncvPerkOverlapInput = {
  endDate: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  startDate: Scalars['String']['input'];
};

export type PacCheckAncvPerkOverlapOutput = {
  __typename?: 'PacCheckAncvPerkOverlapOutput';
  connectAvailable: Scalars['Boolean']['output'];
  paperAvailable: Scalars['Boolean']['output'];
};

export type PacCheckDuplicatedPerkRangeAvailabilityInput = {
  endDate: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  perkANCVType?: InputMaybe<PacPerkAncvType>;
  perkType?: InputMaybe<PacPerkType>;
  startDate: Scalars['String']['input'];
};

export type PacCheckPerkNameAvailabilityInput = {
  organizationId: Scalars['ID']['input'];
  perkName: Scalars['String']['input'];
  perkUuid?: InputMaybe<Scalars['String']['input']>;
};

export type PacCheckPerkNameAvailabilityOutput = {
  __typename?: 'PacCheckPerkNameAvailabilityOutput';
  nameAvailable: Scalars['Boolean']['output'];
};

export type PacCheckPerkRangeAvailabilityInput = {
  endDate: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  perkType?: InputMaybe<PacPerkType>;
  perkUuid?: InputMaybe<Scalars['String']['input']>;
  startDate: Scalars['String']['input'];
};

export type PacCheckPerkRangeAvailabilityOutput = {
  __typename?: 'PacCheckPerkRangeAvailabilityOutput';
  rangeAvailable: Scalars['Boolean']['output'];
};

export type PacCheckUserGroupNameAvailabilityInput = {
  name: Scalars['String']['input'];
  orgaUuid: Scalars['String']['input'];
  userGroupUuid?: InputMaybe<Scalars['String']['input']>;
};

export type PacCheckUserGroupNameAvailabilityOutput = {
  __typename?: 'PacCheckUserGroupNameAvailabilityOutput';
  nameAvailable: Scalars['Boolean']['output'];
};

export type PacChildNbCriteria = {
  operator?: InputMaybe<CriteriaOperator>;
  value: Scalars['String']['input'];
};

export type PacChildNumberCriteria = {
  __typename?: 'PacChildNumberCriteria';
  operator?: Maybe<PacCriteriaOperator>;
  value?: Maybe<Scalars['String']['output']>;
};

export type PacContextDataTuple = {
  __typename?: 'PacContextDataTuple';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PacCreateAdsResult = GestionError | PacAdditionalDataSchema;

export type PacCreateBaselineBenefitInput = {
  benefitStatus: PacBenefitStatus;
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgaUuid: Scalars['String']['input'];
};

export type PacCreateBenefitInput = {
  cultureBenefit?: InputMaybe<PacCreateCultureBenefitInput>;
  /**
   * No better alternative found 😢
   * @oneOf {
   */
  giftBenefit?: InputMaybe<PacCreateGiftBenefitInput>;
};

export type PacCreateBenefitOutput = GestionError | PacCreateBenefitResult;

export type PacCreateBenefitResult = {
  __typename?: 'PacCreateBenefitResult';
  createdBenefit: PacBenefit;
};

export type PacCreateCultureBenefitInput = {
  baseline: PacCreateBaselineBenefitInput;
  /**  @oneOf { */
  targetedEndowmentConfiguration?: InputMaybe<PacTargetedEndowmentConfigurationInput>;
  untargetedEndowmentConfiguration?: InputMaybe<PacUntargetedEndowmentConfigurationInput>;
};

export type PacCreateGiftBenefitInput = {
  baseline: PacCreateBaselineBenefitInput;
  /**
   * No better alternative found 😢
   * @oneOf {
   */
  targetedEndowmentConfiguration?: InputMaybe<PacTargetedEndowmentConfigurationInput>;
  untargetedEndowmentConfiguration?: InputMaybe<PacUntargetedEndowmentConfigurationInput>;
  urssafEvent: PacUrssafEvent;
};

export type PacCreateHasDependentChildrenInput = {
  hasDependentChildren: Scalars['Boolean']['input'];
  orgaUuid: Scalars['ID']['input'];
  userUuid: Scalars['ID']['input'];
};

export type PacCreateRoInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  existingDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  relationship?: InputMaybe<PacRightsOwnerUnionType>;
  type: PacRightsOwnerType;
  unionDate?: InputMaybe<Scalars['String']['input']>;
  uploadDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['ID']['input'];
};

export type PacCreateRoResult = GestionError | PacEmpty;

export type PacCreateUserGroupInput = {
  defaultGroup?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgaUuid: Scalars['String']['input'];
  status: PacUserGroupStatus;
  targetingRuleUuid: Scalars['String']['input'];
};

export type PacCreateUserGroupOutput = GestionError | PacUserGroup;

export enum PacCriteriaOperator {
  Contain = 'CONTAIN',
  DoNotWishToFill = 'DO_NOT_WISH_TO_FILL',
  Equals = 'EQUALS',
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  IntervalGreater = 'INTERVAL_GREATER',
  IntervalLower = 'INTERVAL_LOWER',
  Lower = 'LOWER',
  LowerOrEqual = 'LOWER_OR_EQUAL',
  NotContain = 'NOT_CONTAIN',
  NotEquals = 'NOT_EQUALS',
  OneOf = 'ONE_OF',
  Unrecognized = 'UNRECOGNIZED',
  Unspecified = 'UNSPECIFIED'
}

export type PacCultureBenefit = {
  __typename?: 'PacCultureBenefit';
  baseline: PacBaselineBenefit;
  endowmentConfiguration: PacEndowmentConfiguration;
};

export type PacDeleteAdditionalDataSchemaResult = GestionError | PacEmpty;

export type PacDeleteBankDetailsResult = GestionError | PacEmpty;

export type PacDeleteRightsOwnerResult = GestionError | PacEmpty;

export type PacDestributionExportResult = GestionError | PacBatchJobs;

export type PacDistributionErrorReport = DistributionErrorReport | GestionError;

export type PacDocument = {
  __typename?: 'PacDocument';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  orgaId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type PacDuplicatePerkInput = {
  endDate: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

/** an empty type for GraphQl */
export type PacEmpty = {
  __typename?: 'PacEmpty';
  /** empty field, holds no meaning */
  _?: Maybe<Scalars['Boolean']['output']>;
};

export type PacEndowment = {
  __typename?: 'PacEndowment';
  amountDistributed: Scalars['Float']['output'];
};

export type PacEndowmentConfiguration = PacTargetedEndowmentConfiguration | PacUntargetedEndowmentConfiguration;

export type PacEndowmentInput = {
  amountDistributed: Scalars['Float']['input'];
};

export type PacEndowmentPerUserGroup = {
  __typename?: 'PacEndowmentPerUserGroup';
  endowment: PacEndowment;
  userGroup: PacUserGroup;
};

export type PacEndowmentPerUserGroupInput = {
  endowment: PacEndowmentInput;
  userGroupUuid: Scalars['String']['input'];
};

export type PacEndowmentRule = {
  __typename?: 'PacEndowmentRule';
  amount?: Maybe<Scalars['Float']['output']>;
};

export type PacEndowmentRules = {
  amount: Scalars['Float']['input'];
};

export type PacEpAdCriteria = {
  __typename?: 'PacEpAdCriteria';
  operator?: Maybe<PacEpCriteriaOperator>;
  schema?: Maybe<PacAdditionalDataSchema>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type PacEpAdCriteriaInput = {
  operator: PacEpCriteriaOperator;
  refValues: Array<Scalars['String']['input']>;
  schemaId: Scalars['ID']['input'];
};

export type PacEpChildNumberCriteria = {
  __typename?: 'PacEpChildNumberCriteria';
  operator?: Maybe<PacEpCriteriaOperator>;
  value?: Maybe<Scalars['String']['output']>;
};

export type PacEpChildNumberCriteriaInput = {
  operator?: InputMaybe<PacEpCriteriaOperator>;
  value: Scalars['String']['input'];
};

export type PacEpCreateTargetingRuleResult = GestionError | PacEpTargetingRule;

export enum PacEpCriteriaOperator {
  Contain = 'CONTAIN',
  DoNotWishToFill = 'DO_NOT_WISH_TO_FILL',
  Equals = 'EQUALS',
  Greater = 'GREATER',
  GreaterOrEqual = 'GREATER_OR_EQUAL',
  IntervalGreater = 'INTERVAL_GREATER',
  IntervalLower = 'INTERVAL_LOWER',
  Lower = 'LOWER',
  LowerOrEqual = 'LOWER_OR_EQUAL',
  NotContain = 'NOT_CONTAIN',
  NotEquals = 'NOT_EQUALS',
  OneOf = 'ONE_OF',
  Unrecognized = 'UNRECOGNIZED',
  Unspecified = 'UNSPECIFIED'
}

export type PacEpDistributionRequest = {
  distributionRules: Array<PacEpDistributionRuleInput>;
  orgaUuid: Scalars['String']['input'];
};

export type PacEpDistributionRuleInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  targetingRule?: InputMaybe<PacEpTargetingRuleInput>;
  targetingRuleUuid?: InputMaybe<Scalars['ID']['input']>;
};

/**  Extended Profile */
export type PacEpExportRequest = {
  orgaUuid: Scalars['String']['input'];
  type?: InputMaybe<Array<InputMaybe<PacEpExportType>>>;
};

export enum PacEpExportType {
  Children = 'CHILDREN',
  Schemas = 'SCHEMAS'
}

export type PacEpInitTargetingCalculation = GestionError | PacEpInitTargetingCalculationResponse;

export type PacEpInitTargetingCalculationResponse = {
  __typename?: 'PacEpInitTargetingCalculationResponse';
  requestUuid: Scalars['ID']['output'];
};

export type PacEpPaginatedTargetingCalculationDetailedResult = {
  __typename?: 'PacEpPaginatedTargetingCalculationDetailedResult';
  lastEvaluatedKey: PacLastEvaluatedKey;
  unitaryResults: Array<PacEpTargetingCalculationUnitaryResult>;
};

export type PacEpPollTargetingCalculation = GestionError | PacEpPollTargetingCalculationResponse;

export type PacEpPollTargetingCalculationResponse = {
  __typename?: 'PacEpPollTargetingCalculationResponse';
  status: PacEpTargetingCalculationRequestStatus;
  targetingCalculationResultUuid: Scalars['ID']['output'];
};

/**
 * An extended profile request, or put in other words, a request to modify a part of the extended profile of the beneficiary.
 *
 * These requests will represent modification to the two components of the extended profile: additional datas, and
 * rights owners. Please see PacRequestType to get the different kind of requests.
 *
 * When doing a new request on a type with an existing request with the status IN_PROGRESS, instead of _creating_ a new request,
 * the existing one will be modified and the new request will be _apended_ to the existing one, so as to keep at a maximum one and only one
 * request of each type with the status IN_PROGRESS.
 * As a consequence, all the modifications are stored in the `records` fields, containing the actual modifications being requested.
 */
export type PacEpRequest = {
  __typename?: 'PacEpRequest';
  beneficiary?: Maybe<Beneficiary>;
  createTimestamp: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  lastUpdateTimestamp: Scalars['Float']['output'];
  records: Array<PacEpRequestRecord>;
  status: PacRequestStatus;
  type: PacRequestType;
  userId: Scalars['ID']['output'];
};

/** All the informations on the modification request of one specific subject. */
export type PacEpRequestRecord = {
  __typename?: 'PacEpRequestRecord';
  additionalRequest?: Maybe<PacAdditionalDataRequest>;
  /** Filled by the manager when rejecting a request record */
  comment: Scalars['String']['output'];
  /** Contains a list of documents the benef gave when filling this information as a proof for the manager */
  documents: Array<PacDocument>;
  rightOwnerRequest?: Maybe<PacRightOwnerRequest>;
  /** Status of the record. Different records inside the same request can have different status: some rejected, some accepted. */
  status: PacRequestRecordStatus;
};

export type PacEpRequests = {
  __typename?: 'PacEpRequests';
  lastEvaluatedKey: PacLastEvaluatedKey;
  records: Array<PacEpRequest>;
};

export type PacEpRequestsSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PacRequestStatus>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type PacEpRightsOwnerCriteria = {
  __typename?: 'PacEpRightsOwnerCriteria';
  operator?: Maybe<PacEpCriteriaOperator>;
  rightsOwnerField?: Maybe<PacEpRightsOwnerField>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export enum PacEpRightsOwnerField {
  FieldAge = 'FIELD_AGE',
  FieldBirthdate = 'FIELD_BIRTHDATE',
  FieldRelationtype = 'FIELD_RELATIONTYPE',
  FieldUniondate = 'FIELD_UNIONDATE',
  FieldUnspecified = 'FIELD_UNSPECIFIED',
  Unrecognized = 'UNRECOGNIZED'
}

export type PacEpRoCriteriaInput = {
  operator: PacEpCriteriaOperator;
  refValues: Array<Scalars['String']['input']>;
  rightsOwnerField: PacEpRightsOwnerField;
};

export type PacEpTargetingCalculationDetailedResultSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  targetedOnlyFilter?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum PacEpTargetingCalculationRequestStatus {
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Success = 'SUCCESS',
  Unrecognized = 'UNRECOGNIZED',
  Unspecified = 'UNSPECIFIED'
}

export type PacEpTargetingCalculationResult = {
  __typename?: 'PacEpTargetingCalculationResult';
  creationTimestamp: Scalars['Float']['output'];
  isOutdated: Scalars['Boolean']['output'];
  nbTargetedBeneficiaries: Scalars['Int']['output'];
  nbTargetedChildren: Scalars['Int']['output'];
  paginatedDetailedResult?: Maybe<PacEpPaginatedTargetingCalculationDetailedResult>;
  uuid: Scalars['ID']['output'];
};


export type PacEpTargetingCalculationResultPaginatedDetailedResultArgs = {
  searchParams?: InputMaybe<PacEpTargetingCalculationDetailedResultSearchParams>;
};

export type PacEpTargetingCalculationUnitaryResult = {
  __typename?: 'PacEpTargetingCalculationUnitaryResult';
  beneficiary?: Maybe<Beneficiary>;
  notValidatedCriteriaUuids: Array<Scalars['ID']['output']>;
  /**
   * TODO Do we need need to implement a DataFetcher 🤔 ?
   * If children object we might also need a dataLoader to avoid N+1 problem
   * children: [PacRightsOwnerChild!]!
   * or
   * childrenUuids: [ID!]!
   */
  targeted: Scalars['Boolean']['output'];
  validatedCriteriaUuids: Array<Scalars['ID']['output']>;
};

export type PacEpTargetingRule = {
  __typename?: 'PacEpTargetingRule';
  adCriteria?: Maybe<Array<Maybe<PacEpAdCriteria>>>;
  childNumberCriteria?: Maybe<Array<Maybe<PacEpChildNumberCriteria>>>;
  childrenCriteria?: Maybe<Array<Maybe<PacEpRightsOwnerCriteria>>>;
  /**  TODO implement a DataLoader GES-1400 */
  latestTargetingCalculationResult?: Maybe<PacEpTargetingCalculationResult>;
  name: Scalars['String']['output'];
  partnerCriteria?: Maybe<Array<Maybe<PacEpRightsOwnerCriteria>>>;
  uuid: Scalars['ID']['output'];
};

export type PacEpTargetingRuleInput = {
  adCriteria?: InputMaybe<Array<PacEpAdCriteriaInput>>;
  childNumberCriteria?: InputMaybe<Array<PacEpChildNumberCriteriaInput>>;
  childrenCriteria?: InputMaybe<Array<PacEpRoCriteriaInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  partnerCriteria?: InputMaybe<Array<PacEpRoCriteriaInput>>;
  roCriteria?: InputMaybe<Array<PacEpRoCriteriaInput>>;
};

/** Common type for pagination requests in gestion. */
export type PacExclusiveStartKey = {
  gsiPartitionKey: Scalars['String']['input'];
  gsiSortKey: Scalars['String']['input'];
  partitionKey: Scalars['String']['input'];
};

/**
 * The Extented Profile is the combination of additional data schemas and configuration of right owners.
 *
 * It defines the form/schema of the extended profile the beneficiaries will have to fill.
 */
export type PacExtendedProfile = {
  __typename?: 'PacExtendedProfile';
  additionalDataSchemaSearch: PacAdditionalDataSchemaSearch;
  /**
   *  we do not re-expose the orga type, because this type is already a field of the orga type.
   * @deprecated should use paginated PacExtendedProfile.additionalDataSchemaSearch instead
   */
  additionalDataSchemas: Array<PacAdditionalDataSchema>;
  /** Get Completion Rate of an extended-profile info  */
  adsCompletionRate: PacAdsCompletionRate;
  /** Checks if at least one additional data schema exists for this orga after applying search filters */
  hasAdditionalDataSchema?: Maybe<Scalars['Boolean']['output']>;
  /** Checks if at least one user group exists for this orga after applying search filters */
  hasUserGroup?: Maybe<Scalars['Boolean']['output']>;
  orgaEpCompletionRate: PacOrgaEpCompletionRate;
  /** Because there is only one extended profile per organization in gestion, we can use the orgaId as the entity ID. */
  orgaId: Scalars['ID']['output'];
  /** Polls the targeting calculation request */
  pollTargetingCalculationRequest?: Maybe<PacEpPollTargetingCalculation>;
  rightsOwnerConf: PacRightsOwnerConf;
  /** Fetches a targeting calculation result */
  targetingCalculationResult?: Maybe<PacEpTargetingCalculationResult>;
};


/**
 * The Extented Profile is the combination of additional data schemas and configuration of right owners.
 *
 * It defines the form/schema of the extended profile the beneficiaries will have to fill.
 */
export type PacExtendedProfileAdditionalDataSchemaSearchArgs = {
  searchParams: PacAdditionalDataSchemaSearchParams;
};


/**
 * The Extented Profile is the combination of additional data schemas and configuration of right owners.
 *
 * It defines the form/schema of the extended profile the beneficiaries will have to fill.
 */
export type PacExtendedProfileAdsCompletionRateArgs = {
  schemaUuid: Scalars['ID']['input'];
};


/**
 * The Extented Profile is the combination of additional data schemas and configuration of right owners.
 *
 * It defines the form/schema of the extended profile the beneficiaries will have to fill.
 */
export type PacExtendedProfilePollTargetingCalculationRequestArgs = {
  requestUuid: Scalars['ID']['input'];
};


/**
 * The Extented Profile is the combination of additional data schemas and configuration of right owners.
 *
 * It defines the form/schema of the extended profile the beneficiaries will have to fill.
 */
export type PacExtendedProfileTargetingCalculationResultArgs = {
  targetingCalculationResultUuid: Scalars['ID']['input'];
};

export type PacGiftBenefit = {
  __typename?: 'PacGiftBenefit';
  baseline: PacBaselineBenefit;
  endowmentConfiguration: PacEndowmentConfiguration;
  urssafEvent: PacUrssafEvent;
};

export type PacHasDependentChildren = {
  __typename?: 'PacHasDependentChildren';
  hasDependentChildren: Scalars['Boolean']['output'];
  uuid: Scalars['ID']['output'];
};

export type PacHasDependentChildrenResult = GestionError | PacHasDependentChildren;

export type PacHasOrderSearchParams = {
  perkUuid?: InputMaybe<Scalars['String']['input']>;
  roUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};

export type PacHasPerkSearchParams = {
  schemaUuid?: InputMaybe<Scalars['ID']['input']>;
  statusFilter?: InputMaybe<PacPerkStatus>;
  typeFilter?: InputMaybe<PacPerkType>;
};

export type PacIsUserGroupConfigurationUnique = {
  __typename?: 'PacIsUserGroupConfigurationUnique';
  isUnique: Scalars['Boolean']['output'];
};

export type PacIsUserGroupConfigurationUniqueSearchParams = {
  excludedTargetingRuleUuid: Scalars['String']['input'];
  targetingRule: PacEpTargetingRuleInput;
};

/** Common type for pagination results in gestion. */
export type PacLastEvaluatedKey = {
  __typename?: 'PacLastEvaluatedKey';
  gsiPartitionKey: Scalars['String']['output'];
  gsiSortKey: Scalars['String']['output'];
  partitionKey: Scalars['String']['output'];
};

export type PacManagerDeleteRightsOwnerResult = GestionError | PacEmpty;

export type PacManagerDeleteRoInput = {
  id: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PacManagerPerkUtilisationCreateForRoInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  ancvConnectData?: InputMaybe<PacPerkUtilisationAncvConnectData>;
  ancvPaperDistribution?: InputMaybe<PacPerkUtilisationAncvPaperDistribution>;
  ancvPaperDomicileAddress?: InputMaybe<PacPerkUtilisationAncvPaperDomicileAddress>;
  ancvType?: InputMaybe<PacPerkAncvType>;
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  roUuid: Scalars['String']['input'];
  type: PacPerkType;
  userId: Scalars['ID']['input'];
};

export type PacManagerPerkUtilisationCreateInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  ancvConnectData?: InputMaybe<PacPerkUtilisationAncvConnectData>;
  ancvPaperDistribution?: InputMaybe<PacPerkUtilisationAncvPaperDistribution>;
  ancvPaperDomicileAddress?: InputMaybe<PacPerkUtilisationAncvPaperDomicileAddress>;
  ancvType?: InputMaybe<PacPerkAncvType>;
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  type: PacPerkType;
  userId: Scalars['ID']['input'];
};

export type PacManagerUpdateAdInput = {
  organizationId: Scalars['ID']['input'];
  schemaId: Scalars['ID']['input'];
  uploadDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['ID']['input'];
  value: Scalars['String']['input'];
};

export type PacManagerUpdateAdResult = GestionError | PacEmpty;

export type PacManagerUpdateRoInput = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  existingDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastname?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  relationship?: InputMaybe<PacRightsOwnerUnionType>;
  unionDate?: InputMaybe<Scalars['String']['input']>;
  uploadDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  userId: Scalars['ID']['input'];
};

export type PacManagerUpdateRoResult = GestionError | PacEmpty;

export type PacOrder = {
  __typename?: 'PacOrder';
  id: Scalars['ID']['output'];
  orgaUuid: Scalars['String']['output'];
  perkUuid: Scalars['String']['output'];
  refundTimestamp?: Maybe<Scalars['Float']['output']>;
  roUuid: Scalars['String']['output'];
  status: PacOrderStatus;
  userUuid: Scalars['String']['output'];
};

export type PacOrderList = {
  __typename?: 'PacOrderList';
  lastEvaluatedKey: PacLastEvaluatedKey;
  orders: Array<PacOrder>;
};

export enum PacOrderStatus {
  Canceled = 'CANCELED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

export type PacOrdersSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  perkUuid?: InputMaybe<Scalars['String']['input']>;
  roUuid?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};

export type PacOrgaEpCompletionRate = GestionError | PacOrgaEpCompletionRateResponse;

export type PacOrgaEpCompletionRateResponse = {
  __typename?: 'PacOrgaEpCompletionRateResponse';
  completedProfiles?: Maybe<Scalars['Float']['output']>;
  emptyProfiles?: Maybe<Scalars['Float']['output']>;
  partiallyCompletedProfiles?: Maybe<Scalars['Float']['output']>;
};

export type PacPaymentRule = {
  __typename?: 'PacPaymentRule';
  amount: Scalars['Float']['output'];
  supportedPercentage: Scalars['Float']['output'];
};

export type PacPaymentRules = {
  amount: Scalars['Float']['input'];
  supportedPercentage: Scalars['Float']['input'];
};

export type PacPayoutData = {
  __typename?: 'PacPayoutData';
  amount: Scalars['Float']['output'];
  createdTimestamp: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  orgaUuid: Scalars['String']['output'];
  status: PacPayoutDataStatus;
};

export type PacPayoutDataList = {
  __typename?: 'PacPayoutDataList';
  lastEvaluatedKey: PacLastEvaluatedKey;
  payouts: Array<PacPayoutData>;
};

export type PacPayoutDataSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
};

export enum PacPayoutDataStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  InTransit = 'IN_TRANSIT',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type PacPerk = {
  __typename?: 'PacPerk';
  ancvDeliveryPoint?: Maybe<PacPerkAncvDeliveryPoint>;
  ancvShipmentType?: Maybe<PacPerkAncvShipmentType>;
  ancvType?: Maybe<PacPerkAncvType>;
  description: Scalars['String']['output'];
  endDate: Scalars['String']['output'];
  hasRequestInProgress?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lastBatchJob?: Maybe<PacBatchJob>;
  maxQuantity?: Maybe<Scalars['Int']['output']>;
  multiUsage: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  needOnlinePayment?: Maybe<Scalars['Boolean']['output']>;
  needValidation: Scalars['Boolean']['output'];
  orgaUuid: Scalars['String']['output'];
  remainingStock?: Maybe<Scalars['Int']['output']>;
  segments: Array<PacPerkSegment>;
  startDate: Scalars['String']['output'];
  status: PacPerkStatus;
  stockLimit?: Maybe<Scalars['Int']['output']>;
  target: PacPerkTarget;
  totalStockUsed?: Maybe<Scalars['Int']['output']>;
  type: PacPerkType;
  utilisation?: Maybe<PacPerkUtilisations>;
  utilisationCount: Scalars['Int']['output'];
};


export type PacPerkUtilisationArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type PacPerkUtilisationCountArgs = {
  status?: InputMaybe<PacPerkUtilisationStatus>;
};

export enum PacPerkAncvDeliveryPoint {
  AncvDeliveryPointDomicile = 'ANCV_DELIVERY_POINT_DOMICILE',
  AncvDeliveryPointHeadquarters = 'ANCV_DELIVERY_POINT_HEADQUARTERS'
}

export enum PacPerkAncvShipmentType {
  AncvShipmentTypeCourrierSimple = 'ANCV_SHIPMENT_TYPE_COURRIER_SIMPLE',
  AncvShipmentTypeLettreSuivie = 'ANCV_SHIPMENT_TYPE_LETTRE_SUIVIE',
  AncvShipmentTypeRecommandeArR1 = 'ANCV_SHIPMENT_TYPE_RECOMMANDE_AR_R1',
  AncvShipmentTypeRecommandeArR2 = 'ANCV_SHIPMENT_TYPE_RECOMMANDE_AR_R2',
  AncvShipmentTypeRecommandeArR3 = 'ANCV_SHIPMENT_TYPE_RECOMMANDE_AR_R3',
  AncvShipmentTypeRecommandeSimpleR1 = 'ANCV_SHIPMENT_TYPE_RECOMMANDE_SIMPLE_R1',
  AncvShipmentTypeRecommandeSimpleR2 = 'ANCV_SHIPMENT_TYPE_RECOMMANDE_SIMPLE_R2',
  AncvShipmentTypeRecommandeSimpleR3 = 'ANCV_SHIPMENT_TYPE_RECOMMANDE_SIMPLE_R3'
}

export enum PacPerkAncvType {
  Both = 'BOTH',
  Connect = 'CONNECT',
  Paper = 'PAPER'
}

/**  Gestion */
export type PacPerkBatchJobs = {
  __typename?: 'PacPerkBatchJobs';
  lastEvaluatedKey?: Maybe<PacLastEvaluatedKey>;
  pacBatchJobs?: Maybe<Array<Maybe<PacBatchJob>>>;
};

export type PacPerkChildrenSituation = {
  __typename?: 'PacPerkChildrenSituation';
  children?: Maybe<Array<Maybe<PacPerkRoSituation>>>;
};

export type PacPerkCreate = {
  ancvDeliveryPoint?: InputMaybe<PacPerkAncvDeliveryPoint>;
  ancvShipmentType?: InputMaybe<PacPerkAncvShipmentType>;
  ancvType?: InputMaybe<PacPerkAncvType>;
  description: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  maxQuantity?: InputMaybe<Scalars['Int']['input']>;
  multiUsage: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  needValidation: Scalars['Boolean']['input'];
  orgaId: Scalars['ID']['input'];
  segments: Array<PacPerkSegmentInput>;
  startDate: Scalars['String']['input'];
  status: PacPerkStatus;
  stockLimit?: InputMaybe<Scalars['Int']['input']>;
  target: PacPerkTarget;
  type: PacPerkType;
};

export type PacPerkDataUniquenessInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  perkName: Scalars['String']['input'];
  perkUuid?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export type PacPerkDataUniquenessOutput = {
  __typename?: 'PacPerkDataUniquenessOutput';
  nameAvailability?: Maybe<Scalars['Boolean']['output']>;
  rangeUniqueness?: Maybe<Scalars['Boolean']['output']>;
};

export type PacPerkExportByTypeSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  perkUuid: Scalars['ID']['input'];
  type: PacBatchJobType;
};

export type PacPerkExportResult = GestionError | PacPerkBatchJobs;

export type PacPerkExportSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  orgaUuid: Scalars['ID']['input'];
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  perkUuid: Scalars['ID']['input'];
};

export type PacPerkHomeSituation = {
  __typename?: 'PacPerkHomeSituation';
  eligibleSegments?: Maybe<Array<PacPerkSegment>>;
};

export type PacPerkList = {
  __typename?: 'PacPerkList';
  lastEvaluatedKey: PacLastEvaluatedKey;
  perks: Array<PacPerk>;
};

export type PacPerkOptions = {
  ancvDeliveryPoint?: InputMaybe<PacPerkAncvDeliveryPoint>;
  ancvShipmentType?: InputMaybe<PacPerkAncvShipmentType>;
  ancvType?: InputMaybe<PacPerkAncvType>;
  quantityLimit?: InputMaybe<Scalars['Int']['input']>;
  stockLimit?: InputMaybe<Scalars['Int']['input']>;
  target?: InputMaybe<PacPerkTarget>;
};

export type PacPerkResult = GestionError | PacPerk;

export type PacPerkRoSituation = {
  __typename?: 'PacPerkRoSituation';
  eligibleSegments?: Maybe<Array<PacPerkSegment>>;
  rightsOwner?: Maybe<PacRightsOwner>;
};

export type PacPerkSegment = {
  __typename?: 'PacPerkSegment';
  adCriteria?: Maybe<Array<Maybe<PacSegmentAdCriteria>>>;
  attachmentName?: Maybe<Scalars['String']['output']>;
  benefitRules?: Maybe<PacBenefitRule>;
  childNumberCriteria?: Maybe<Array<Maybe<PacChildNumberCriteria>>>;
  childrenCriteria?: Maybe<Array<Maybe<PacSegmentRightsOwnerCriteria>>>;
  /** @deprecated Apollo relies on unique identifiers (like id) to normalize data in the cache. will be deleted after MEP GES 2024-Q1-S7 */
  id?: Maybe<Scalars['ID']['output']>;
  multiUsage: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  needAttachment: Scalars['Boolean']['output'];
  needValidation: Scalars['Boolean']['output'];
  partnerCriteria?: Maybe<Array<Maybe<PacSegmentRightsOwnerCriteria>>>;
  paymentRule?: Maybe<PacPaymentRule>;
  uuid: Scalars['ID']['output'];
};

export type PacPerkSegmentInput = {
  adCriterias?: InputMaybe<Array<PacAdCriteriaInput>>;
  attachementName?: InputMaybe<Scalars['String']['input']>;
  childNbCriteria?: InputMaybe<Array<PacChildNbCriteria>>;
  endowmentRules?: InputMaybe<PacEndowmentRules>;
  name: Scalars['String']['input'];
  needAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  paymentRules?: InputMaybe<PacPaymentRules>;
  reimbursementRules?: InputMaybe<PacReimbursementRules>;
  roCriterias?: InputMaybe<Array<PacRoCriteriaInput>>;
};

export type PacPerkSituation = {
  __typename?: 'PacPerkSituation';
  perChild?: Maybe<PacPerkChildrenSituation>;
  perHome?: Maybe<PacPerkHomeSituation>;
  perk: PacPerk;
};

export type PacPerkSituationList = {
  __typename?: 'PacPerkSituationList';
  lastEvaluatedKey: PacLastEvaluatedKey;
  situations?: Maybe<Array<PacPerkSituation>>;
};

export enum PacPerkStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Hidden = 'HIDDEN'
}

export enum PacPerkTarget {
  Child = 'CHILD',
  Home = 'HOME'
}

export enum PacPerkType {
  Ancv = 'ANCV',
  CultureEndowment = 'CULTURE_ENDOWMENT',
  Event = 'EVENT',
  GiftEndowment = 'GIFT_ENDOWMENT',
  Other = 'OTHER',
  Reimbursement = 'REIMBURSEMENT',
  Ticketing = 'TICKETING',
  TravelEndowment = 'TRAVEL_ENDOWMENT'
}

export type PacPerkUpdateInput = {
  description: Scalars['String']['input'];
  endDate: Scalars['String']['input'];
  multiUsage: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  needValidation: Scalars['Boolean']['input'];
  options?: InputMaybe<PacPerkOptions>;
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['String']['input'];
  startDate: Scalars['String']['input'];
};

export enum PacPerkUpdateStatus {
  StatusActive = 'STATUS_ACTIVE',
  StatusArchive = 'STATUS_ARCHIVE',
  StatusDeleted = 'STATUS_DELETED',
  StatusHidden = 'STATUS_HIDDEN',
  StatusUnspecified = 'STATUS_UNSPECIFIED'
}

export type PacPerkUpdateStatusInput = {
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['String']['input'];
  status?: InputMaybe<PacPerkUpdateStatus>;
};

export type PacPerkUtilisation = {
  __typename?: 'PacPerkUtilisation';
  benefComment?: Maybe<PacPerkUtilisationComment>;
  beneficiary?: Maybe<Beneficiary>;
  comment: Array<Maybe<PacPerkUtilisationComment>>;
  contextData?: Maybe<Array<Maybe<PacContextDataTuple>>>;
  createTimestamp: Scalars['Float']['output'];
  documents: Array<PacDocument>;
  order?: Maybe<PacOrder>;
  orgaUuid: Scalars['String']['output'];
  origin: Scalars['String']['output'];
  paidAmount?: Maybe<Scalars['Float']['output']>;
  paymentUrl?: Maybe<Scalars['String']['output']>;
  perk?: Maybe<PacPerk>;
  perkId: Scalars['ID']['output'];
  quantity?: Maybe<Scalars['Int']['output']>;
  refundAmount?: Maybe<Scalars['Float']['output']>;
  rejectedComment?: Maybe<PacPerkUtilisationComment>;
  rejectionComment: Scalars['String']['output'];
  requestAmount?: Maybe<Scalars['Float']['output']>;
  roUuid?: Maybe<Scalars['String']['output']>;
  segment: PacPerkSegment;
  status: PacPerkUtilisationStatus;
  statusUpdate: Array<PacPerkUtilisationStatusUpdate>;
  userId: Scalars['ID']['output'];
  uuid: Scalars['String']['output'];
  voucherAvUuid?: Maybe<Scalars['String']['output']>;
  voucherUuid?: Maybe<Scalars['String']['output']>;
};

export enum PacPerkUtilisationActionType {
  Export = 'EXPORT',
  Import = 'IMPORT',
  Manual = 'MANUAL',
  Unspecified = 'UNSPECIFIED'
}

export type PacPerkUtilisationAncvConnectData = {
  email: Scalars['String']['input'];
  phone: Scalars['String']['input'];
};

export type PacPerkUtilisationAncvPaperDistribution = {
  denomination10: Scalars['Int']['input'];
  denomination20: Scalars['Int']['input'];
  denomination25: Scalars['Int']['input'];
  denomination50: Scalars['Int']['input'];
};

export type PacPerkUtilisationAncvPaperDomicileAddress = {
  additionalAddress: Scalars['String']['input'];
  address: Scalars['String']['input'];
  city: Scalars['String']['input'];
  country: PacPerkUtilisationAncvPaperDomicileAddressCountry;
  postalCode: Scalars['String']['input'];
};

export enum PacPerkUtilisationAncvPaperDomicileAddressCountry {
  Allemagne = 'ALLEMAGNE',
  Andorre = 'ANDORRE',
  Belgique = 'BELGIQUE',
  Espagne = 'ESPAGNE',
  France = 'FRANCE',
  Italie = 'ITALIE',
  Luxembourg = 'LUXEMBOURG',
  Monaco = 'MONACO',
  Suisse = 'SUISSE'
}

export type PacPerkUtilisationComment = {
  __typename?: 'PacPerkUtilisationComment';
  from: Scalars['ID']['output'];
  timestamp: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PacPerkUtilisationCreateForRoInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  ancvConnectData?: InputMaybe<PacPerkUtilisationAncvConnectData>;
  ancvPaperDistribution?: InputMaybe<PacPerkUtilisationAncvPaperDistribution>;
  ancvPaperDomicileAddress?: InputMaybe<PacPerkUtilisationAncvPaperDomicileAddress>;
  ancvType?: InputMaybe<PacPerkAncvType>;
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  roUuid: Scalars['String']['input'];
  type: PacPerkType;
};

export type PacPerkUtilisationCreateSelfInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  ancvConnectData?: InputMaybe<PacPerkUtilisationAncvConnectData>;
  ancvPaperDistribution?: InputMaybe<PacPerkUtilisationAncvPaperDistribution>;
  ancvPaperDomicileAddress?: InputMaybe<PacPerkUtilisationAncvPaperDomicileAddress>;
  ancvType?: InputMaybe<PacPerkAncvType>;
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  comment?: InputMaybe<Scalars['String']['input']>;
  orgaUuid: Scalars['ID']['input'];
  perkUuid: Scalars['String']['input'];
  quantity?: InputMaybe<Scalars['Int']['input']>;
  type: PacPerkType;
};

export type PacPerkUtilisationRefundPaymentInput = {
  orgaUuid: Scalars['ID']['input'];
  uuid: Scalars['ID']['input'];
};

export type PacPerkUtilisationResult = GestionError | PacPerkUtilisation;

export enum PacPerkUtilisationStatus {
  Canceled = 'CANCELED',
  New = 'NEW',
  PendingRefund = 'PENDING_REFUND',
  Processed = 'PROCESSED',
  Rejected = 'REJECTED',
  Unspecified = 'UNSPECIFIED',
  Validated = 'VALIDATED'
}

export type PacPerkUtilisationStatusUpdate = {
  __typename?: 'PacPerkUtilisationStatusUpdate';
  actionType?: Maybe<PacPerkUtilisationActionType>;
  from: PacPerkUtilisationStatus;
  modifiedBy?: Maybe<Beneficiary>;
  timestamp: Scalars['String']['output'];
  to: PacPerkUtilisationStatus;
};

export type PacPerkUtilisationUpdateStatusInput = {
  comment?: InputMaybe<Scalars['String']['input']>;
  newStatus: PacPerkUtilisationStatus;
  orgaUuid: Scalars['ID']['input'];
  userUuid?: InputMaybe<Scalars['ID']['input']>;
  uuid: Scalars['ID']['input'];
};

export type PacPerkUtilisations = {
  __typename?: 'PacPerkUtilisations';
  amountConsumed: Array<PacAmountTuple>;
  estimatedAmountConsumed: Array<PacAmountTuple>;
  pristine?: Maybe<Scalars['Boolean']['output']>;
  remainingAmount: Array<PacAmountTuple>;
  utilisations?: Maybe<Array<PacPerkUtilisation>>;
};

export type PacPerkUtilisationsAmounts = {
  __typename?: 'PacPerkUtilisationsAmounts';
  distributedAmount?: Maybe<Scalars['Float']['output']>;
  paidByBenefAmount?: Maybe<Scalars['Float']['output']>;
  paidByOrgaAmount?: Maybe<Scalars['Float']['output']>;
  refundAmount?: Maybe<Scalars['Float']['output']>;
  totalPaidAmount?: Maybe<Scalars['Float']['output']>;
};

export type PacPerkUtilisationsSearch = {
  __typename?: 'PacPerkUtilisationsSearch';
  amounts?: Maybe<PacPerkUtilisationsAmounts>;
  lastEvaluatedKey: PacLastEvaluatedKey;
  utilisations?: Maybe<Array<PacPerkUtilisation>>;
};

export type PacPerkUtilisationsSearchParams = {
  calculateAmounts?: InputMaybe<Scalars['Boolean']['input']>;
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  perkId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PacPerkUtilisationStatus>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type PacPerkUtilisationsSearchSelfParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<PacPerkUtilisationStatus>;
};

export type PacPerksSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  schemaUuid?: InputMaybe<Scalars['ID']['input']>;
  statusFilter?: InputMaybe<PacPerkStatus>;
  typeFilter?: InputMaybe<PacPerkType>;
};

export type PacPublishUserGroupInput = {
  userGroupUuid: Scalars['String']['input'];
};

export type PacPublishUserGroupOutput = GestionError | PacEmpty;

export type PacRefuseFillingAdResult = GestionError | PacAdditionalData;

export type PacReimbursementRule = {
  __typename?: 'PacReimbursementRule';
  maxAmount?: Maybe<Scalars['Float']['output']>;
  minAmount?: Maybe<Scalars['Float']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<PacReimbursementType>;
};

export type PacReimbursementRules = {
  maxAmount?: InputMaybe<Scalars['Float']['input']>;
  minAmount?: InputMaybe<Scalars['Float']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<PacReimbursementType>;
};

export enum PacReimbursementType {
  Amount = 'AMOUNT',
  Percentage = 'PERCENTAGE',
  Unrecognized = 'UNRECOGNIZED',
  Unspecified = 'UNSPECIFIED'
}

export enum PacRelationshipType {
  Marriage = 'MARRIAGE',
  Pacs = 'PACS',
  Single = 'SINGLE',
  Unknown = 'UNKNOWN'
}

export enum PacRequestRecordStatus {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  ToCheck = 'TO_CHECK'
}

export enum PacRequestStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS'
}

export enum PacRequestType {
  AdditionalData = 'ADDITIONAL_DATA',
  RoChildren = 'RO_CHILDREN',
  RoPartner = 'RO_PARTNER',
  Unknown = 'UNKNOWN'
}

export type PacRightOwnerConfResult = GestionError | PacRightsOwnerConf;

export type PacRightOwnerRequest = {
  __typename?: 'PacRightOwnerRequest';
  birthdate?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastname?: Maybe<Scalars['String']['output']>;
  relationship?: Maybe<PacRelationshipType>;
  rightOwnerId?: Maybe<Scalars['String']['output']>;
  type: PacRightsOwnerType;
  unionDate?: Maybe<Scalars['String']['output']>;
};

export type PacRightsOwner = {
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
};

export type PacRightsOwnerChild = PacRightsOwner & {
  __typename?: 'PacRightsOwnerChild';
  birthdate?: Maybe<Scalars['String']['output']>;
  files: Array<PacDocument>;
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
};

/** Defines which kind of rights owner are available for this organization. */
export type PacRightsOwnerConf = {
  __typename?: 'PacRightsOwnerConf';
  childActivated: Scalars['Boolean']['output'];
  orgaId: Scalars['ID']['output'];
  partnerActivated: Scalars['Boolean']['output'];
};

export enum PacRightsOwnerField {
  FieldBirthdate = 'FIELD_BIRTHDATE',
  FieldRelationtype = 'FIELD_RELATIONTYPE',
  FieldUniondate = 'FIELD_UNIONDATE',
  FieldUnspecified = 'FIELD_UNSPECIFIED',
  Unrecognized = 'UNRECOGNIZED'
}

export type PacRightsOwnerPartner = PacRightsOwner & {
  __typename?: 'PacRightsOwnerPartner';
  files: Array<PacDocument>;
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
  unionDate?: Maybe<Scalars['String']['output']>;
  unionType: PacRightsOwnerUnionType;
};

export type PacRightsOwnerSimple = PacRightsOwner & {
  __typename?: 'PacRightsOwnerSimple';
  firstname: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastname: Scalars['String']['output'];
};

export enum PacRightsOwnerType {
  Child = 'CHILD',
  Partner = 'PARTNER',
  Unknown = 'UNKNOWN'
}

export enum PacRightsOwnerUnionType {
  Marriage = 'MARRIAGE',
  Pacs = 'PACS',
  Single = 'SINGLE'
}

export type PacRightsOwners = {
  __typename?: 'PacRightsOwners';
  children: Array<PacRightsOwnerChild>;
  hasFilledChildren: Scalars['Boolean']['output'];
  hasFilledPartner: Scalars['Boolean']['output'];
  partner?: Maybe<PacRightsOwnerPartner>;
};

export type PacRoCriteriaInput = {
  operator: CriteriaOperator;
  refValues: Array<Scalars['String']['input']>;
  rightsOwnerField: RightsOwnerField;
};

export type PacSegmentAdCriteria = {
  __typename?: 'PacSegmentAdCriteria';
  operator?: Maybe<PacCriteriaOperator>;
  schemaUuid?: Maybe<Scalars['ID']['output']>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

export type PacSegmentRightsOwnerCriteria = {
  __typename?: 'PacSegmentRightsOwnerCriteria';
  operator?: Maybe<PacCriteriaOperator>;
  rightsOwnerField?: Maybe<PacRightsOwnerField>;
  values?: Maybe<Array<Scalars['String']['output']>>;
};

/** Represent the last validation state for a _subject_ in gestion sense, that is either an additional data or a right owner. */
export type PacSubjectValidation = {
  __typename?: 'PacSubjectValidation';
  id: Scalars['ID']['output'];
  orga: Organization;
  record: PacEpRequestRecord;
  user: Beneficiary;
};

export enum PacTargetedAudience {
  Beneficiary = 'BENEFICIARY',
  Children = 'CHILDREN'
}

export type PacTargetedEndowmentConfiguration = {
  __typename?: 'PacTargetedEndowmentConfiguration';
  endowmentPerUserGroups: Array<PacEndowmentPerUserGroup>;
  targetedAudience: PacTargetedAudience;
  targetingMode: PacTargetingMode;
};

export type PacTargetedEndowmentConfigurationInput = {
  endowmentPerUserGroups: Array<PacEndowmentPerUserGroupInput>;
  targetedAudience: PacTargetedAudience;
  targetingMode: PacTargetingMode;
};

export enum PacTargetingMode {
  Targeted = 'TARGETED',
  Untargeted = 'UNTARGETED'
}

export type PacUntargetedEndowmentConfiguration = {
  __typename?: 'PacUntargetedEndowmentConfiguration';
  endowment: PacEndowment;
  targetedAudience: PacTargetedAudience;
  targetingMode: PacTargetingMode;
};

export type PacUntargetedEndowmentConfigurationInput = {
  endowment: PacEndowmentInput;
  targetedAudience: PacTargetedAudience;
  targetingMode: PacTargetingMode;
};

/** The `request` field is present only for ads where validation is required. */
export type PacUpdateAdResponse = {
  __typename?: 'PacUpdateAdResponse';
  request?: Maybe<PacEpRequest>;
};

export type PacUpdateAdResult = GestionError | PacUpdateAdResponse;

export type PacUpdateAdsResult = GestionError | PacAdditionalDataSchema;

export type PacUpdateBankDetailsInput = {
  bic: Scalars['String']['input'];
  holder: Scalars['String']['input'];
  iban: Scalars['String']['input'];
};

export type PacUpdateCreateSegmentInput = {
  adCriterias?: InputMaybe<Array<PacAdCriteriaInput>>;
  attachementName?: InputMaybe<Scalars['String']['input']>;
  childNbCriteria?: InputMaybe<Array<PacChildNbCriteria>>;
  endowmentRules?: InputMaybe<PacEndowmentRules>;
  multiUsage?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  needAttachment?: InputMaybe<Scalars['Boolean']['input']>;
  needValidation?: InputMaybe<Scalars['Boolean']['input']>;
  orgaId: Scalars['String']['input'];
  paymentRules?: InputMaybe<PacPaymentRules>;
  perkId: Scalars['String']['input'];
  reimbursementRules?: InputMaybe<PacReimbursementRules>;
  roCriterias?: InputMaybe<Array<PacRoCriteriaInput>>;
  segmentId?: InputMaybe<Scalars['String']['input']>;
  target: PacPerkTarget;
};

export type PacUpdateHasDependentChildrenInput = {
  hasDependentChildren: Scalars['Boolean']['input'];
  uuid: Scalars['ID']['input'];
};

export type PacUpdateRoInput = {
  birthDate?: InputMaybe<Scalars['String']['input']>;
  existingDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  relationship?: InputMaybe<PacRightsOwnerUnionType>;
  rightOwnerId?: InputMaybe<Scalars['ID']['input']>;
  rightsOwnerRequestId?: InputMaybe<Scalars['ID']['input']>;
  type: PacRightsOwnerType;
  unionDate?: InputMaybe<Scalars['String']['input']>;
  uploadDocuments?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type PacUpdateRoResult = GestionError | PacEpRequest;

export type PacUpdateUserGroupInput = {
  createTimestamp: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  orgaUuid: Scalars['String']['input'];
  status: PacUserGroupStatus;
  targetingRuleUuid: Scalars['String']['input'];
  uuid: Scalars['ID']['input'];
};

export type PacUpdateUserGroupOutput = GestionError | PacEmpty;

export enum PacUrssafEvent {
  Christmas = 'CHRISTMAS'
}

export type PacUserEpCompletionRate = GestionError | PacUserEpCompletionRateResponse;

export type PacUserEpCompletionRateResponse = {
  __typename?: 'PacUserEpCompletionRateResponse';
  completionRate?: Maybe<Scalars['Float']['output']>;
};

/**
 * The extended profile of the user.
 *
 * We use the pair `userId`/`orgaId` as the id of the extended profile, because it doesn't have a specific id, being an
 * aggregation of different datas.
 */
export type PacUserExtendedProfile = {
  adCurrentRequest?: Maybe<PacEpRequest>;
  additionalDatas: Array<PacAdditionalData>;
  benef: Beneficiary;
  childrenCurrentRequest?: Maybe<PacEpRequest>;
  hasDependentChildren: PacHasDependentChildrenResult;
  orga: Organization;
  orgaId: Scalars['ID']['output'];
  partnerCurrentRequest?: Maybe<PacEpRequest>;
  rightsOwners: PacRightsOwners;
  subjectValidations: Array<PacSubjectValidation>;
  userEpCompletionRate: PacUserEpCompletionRate;
  userId: Scalars['ID']['output'];
};

/**
 * Type used to distinguate between querying all this information from the user or from the manager,
 * can have an impact on how a resolver is implemented
 */
export type PacUserExtendedProfileBenef = PacUserExtendedProfile & {
  __typename?: 'PacUserExtendedProfileBenef';
  adCurrentRequest?: Maybe<PacEpRequest>;
  additionalDatas: Array<PacAdditionalData>;
  benef: Beneficiary;
  childrenCurrentRequest?: Maybe<PacEpRequest>;
  hasDependentChildren: PacHasDependentChildrenResult;
  orga: Organization;
  orgaId: Scalars['ID']['output'];
  partnerCurrentRequest?: Maybe<PacEpRequest>;
  rightsOwners: PacRightsOwners;
  subjectValidations: Array<PacSubjectValidation>;
  userEpCompletionRate: PacUserEpCompletionRate;
  userId: Scalars['ID']['output'];
};

export type PacUserExtendedProfileForManager = PacUserExtendedProfile & {
  __typename?: 'PacUserExtendedProfileForManager';
  adCurrentRequest?: Maybe<PacEpRequest>;
  additionalDatas: Array<PacAdditionalData>;
  benef: Beneficiary;
  childrenCurrentRequest?: Maybe<PacEpRequest>;
  hasDependentChildren: PacHasDependentChildrenResult;
  orga: Organization;
  orgaId: Scalars['ID']['output'];
  partnerCurrentRequest?: Maybe<PacEpRequest>;
  rightsOwners: PacRightsOwners;
  subjectValidations: Array<PacSubjectValidation>;
  userEpCompletionRate: PacUserEpCompletionRate;
  userId: Scalars['ID']['output'];
};

export type PacUserGroup = {
  __typename?: 'PacUserGroup';
  createTimestamp: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isDefaultGroup?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  orgaUuid: Scalars['String']['output'];
  status: PacUserGroupStatus;
  targetingRule: PacEpTargetingRule;
  uuid: Scalars['ID']['output'];
};

export enum PacUserGroupStatus {
  Archived = 'ARCHIVED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type PacUserGroupsList = {
  __typename?: 'PacUserGroupsList';
  lastEvaluatedKey?: Maybe<PacLastEvaluatedKey>;
  userGroups: Array<PacUserGroup>;
};

export type PacUserGroupsSearchParams = {
  exclusiveStartKey?: InputMaybe<PacExclusiveStartKey>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  statusFilter?: InputMaybe<Array<PacUserGroupStatus>>;
};

export type PacValidateRequest = {
  schemaId?: InputMaybe<Scalars['String']['input']>;
  validateRequestInfo?: InputMaybe<PacValidateRequestInfo>;
};

export type PacValidateRequestInfo = {
  comment?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<PacValidationStatus>;
};

export type PacValidateRequestResult = GestionError | PacEpRequest;

export type PacValidateRequests = {
  validateRequests: Array<PacValidateRequest>;
};

export enum PacValidationStatus {
  ValidationStatusAccepted = 'VALIDATION_STATUS_ACCEPTED',
  ValidationStatusRejected = 'VALIDATION_STATUS_REJECTED'
}

export type PackagingOptionOutput = {
  __typename?: 'PackagingOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<PaperOrderChequePackaging>;
};

export type PageRequest = {
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
};

export type PageResult = {
  index: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type PaperBillingInformation = {
  __typename?: 'PaperBillingInformation';
  billingOptions?: Maybe<BillingOptions>;
  /** deprecated */
  fees: Array<PaperBillingItem>;
  grossTotalAmount: Money;
  options: Array<PaperBillingItem>;
  orderId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  products: Array<PaperBillingProduct>;
  taxAmount: Money;
  totalAmount: Money;
};

export type PaperBillingItem = {
  __typename?: 'PaperBillingItem';
  grossDiscountAmount?: Maybe<Money>;
  grossTotalAmount: Money;
  grossUnitAmount: Money;
  name: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  uom?: Maybe<PaperPriceCatalogUnitOfMeasure>;
};

export type PaperBillingProduct = {
  __typename?: 'PaperBillingProduct';
  amountPerBeneficiary: Money;
  classification?: Maybe<PaperOrderChequeClassification>;
  denominations?: Maybe<Array<Maybe<Denomination>>>;
  grossTotalAmount: Money;
  name: Scalars['String']['output'];
  numberOfBeneficiaries: Scalars['Int']['output'];
  type?: Maybe<PaperOrderChequeType>;
};

export type PaperOrder = {
  __typename?: 'PaperOrder';
  creationDate: Scalars['DateTime']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  orderAmount: Money;
  orderRefDisc?: Maybe<Scalars['String']['output']>;
  orderRefGlady: Scalars['String']['output'];
  organization?: Maybe<PaperOrderOrganization>;
  remade?: Maybe<Scalars['Boolean']['output']>;
  status: PaperOrderOrderStatus;
  updateDate?: Maybe<Scalars['DateTime']['output']>;
};

export type PaperOrderAddress = {
  __typename?: 'PaperOrderAddress';
  city?: Maybe<Scalars['String']['output']>;
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  firstLine?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  secondLine?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export enum PaperOrderChequeClassification {
  Culture = 'CULTURE',
  LibertyAsc = 'LIBERTY_ASC',
  LibertyIct = 'LIBERTY_ICT'
}

export enum PaperOrderChequePackaging {
  Bulk = 'BULK',
  Checkbook = 'CHECKBOOK'
}

export enum PaperOrderChequeType {
  AdultsChristmas = 'ADULTS_CHRISTMAS',
  BackToSchool = 'BACK_TO_SCHOOL',
  ChildrenChristmas = 'CHILDREN_CHRISTMAS',
  ChildBirth = 'CHILD_BIRTH',
  FathersDay = 'FATHERS_DAY',
  MothersDay = 'MOTHERS_DAY',
  Other = 'OTHER',
  Retirement = 'RETIREMENT',
  SaintCatherine = 'SAINT_CATHERINE',
  SaintNicolas = 'SAINT_NICOLAS',
  Weddings = 'WEDDINGS'
}

export type PaperOrderOptions = {
  groupOption?: InputMaybe<GroupOptionInput>;
  letterTemplateOption?: InputMaybe<LetterTemplateOptionInput>;
  logoOption?: InputMaybe<LogoOptionInput>;
  messageOption?: InputMaybe<MessageOptionInput>;
  myGiftAdvantageOption?: InputMaybe<Scalars['Boolean']['input']>;
  nominalChecksOption?: InputMaybe<NominalChecksOptionInput>;
  packaging: PaperOrderChequePackaging;
  shippingModeOption?: InputMaybe<ShippingModeOptionInput>;
  signaturesListOption?: InputMaybe<SignaturesListOptionInput>;
  summaryVoucherOption?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PaperOrderOptionsOutput = {
  __typename?: 'PaperOrderOptionsOutput';
  groupOption?: Maybe<GroupOption>;
  letterTemplateOption?: Maybe<LetterTemplateOption>;
  logoOption?: Maybe<LogoOption>;
  messageOption?: Maybe<MessageOption>;
  myGiftAdvantageOption?: Maybe<Scalars['Boolean']['output']>;
  nominalChecksOption?: Maybe<NominalChecksOption>;
  packaging: PaperOrderChequePackaging;
  shippingModeOption?: Maybe<ShippingModeOption>;
  signaturesListOption?: Maybe<SignaturesListOption>;
  summaryVoucherOption?: Maybe<Scalars['Boolean']['output']>;
};

export enum PaperOrderOrderStatus {
  Canceled = 'CANCELED',
  CancellationPending = 'CANCELLATION_PENDING',
  Created = 'CREATED',
  DeliveryDone = 'DELIVERY_DONE',
  DeliveryError = 'DELIVERY_ERROR',
  DeliveryOngoing = 'DELIVERY_ONGOING',
  Error = 'ERROR',
  ExpeditionDone = 'EXPEDITION_DONE',
  ExternalError = 'EXTERNAL_ERROR',
  ForwardedPackage = 'FORWARDED_PACKAGE',
  IntegrationDone = 'INTEGRATION_DONE',
  IntegrationOngoing = 'INTEGRATION_ONGOING',
  ManufactureDone = 'MANUFACTURE_DONE',
  ManufactureError = 'MANUFACTURE_ERROR',
  ManufactureOngoing = 'MANUFACTURE_ONGOING',
  OrderInPackaging = 'ORDER_IN_PACKAGING',
  PackagingDone = 'PACKAGING_DONE',
  Paid = 'PAID',
  ReadyToShip = 'READY_TO_SHIP',
  ReturnOrderAfterDelivery = 'RETURN_ORDER_AFTER_DELIVERY',
  Sent = 'SENT',
  TechnicalError = 'TECHNICAL_ERROR',
  Undefined = 'UNDEFINED',
  ValidationDone = 'VALIDATION_DONE',
  ValidationOngoing = 'VALIDATION_ONGOING',
  WaitingForPayment = 'WAITING_FOR_PAYMENT'
}

export type PaperOrderOrganization = {
  __typename?: 'PaperOrderOrganization';
  name?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type PaperOrderPageRequest = {
  lastEvaluatedKey?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
};

export type PaperOrderProduct = {
  amountPerBeneficiary: MoneyInput;
  chequeClassification: PaperOrderChequeClassification;
  chequeType?: InputMaybe<PaperOrderChequeType>;
  denominations: DenominationsInput;
  numberOfBeneficiaries: Scalars['Int']['input'];
};

export type PaperOrderProductOutput = {
  __typename?: 'PaperOrderProductOutput';
  amount?: Maybe<Money>;
  amountPerBeneficiary: Money;
  chequeClassification: PaperOrderChequeClassification;
  chequeType?: Maybe<PaperOrderChequeType>;
  denominations: Denominations;
  numberOfBeneficiaries: Scalars['Int']['output'];
};

export type PaperOrderResult = {
  __typename?: 'PaperOrderResult';
  items: Array<PaperOrder>;
  lastEvaluatedKey: Scalars['String']['output'];
};

export type PaperOrderSimulationContactUpdateRequest = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  simulationId?: InputMaybe<Scalars['String']['input']>;
};

export type PaperOrderSimulationProduct = {
  amountPerBeneficiary: MoneyInput;
  chequeClassification: PaperOrderChequeClassification;
  chequeType?: InputMaybe<PaperOrderChequeType>;
  denominations: DenominationsInput;
  numberOfBeneficiaries: Scalars['Int']['input'];
};

export type PaperOrderSimulationRequest = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  paperSimulationOptions: PaperSimulationOptions;
  products: Array<PaperOrderSimulationProduct>;
};

export type PaperOrderSimulationResponse = {
  __typename?: 'PaperOrderSimulationResponse';
  billingSimulation: PaperBillingInformation;
  simulationId: Scalars['String']['output'];
};

export type PaperPriceCatalogRequest = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
};

export type PaperPriceCatalogResult = {
  __typename?: 'PaperPriceCatalogResult';
  amountAndEventGroupOption?: Maybe<PaperProductRatePlan>;
  amountGroupOption?: Maybe<PaperProductRatePlan>;
  beneficiariesShippingOption: PaperProductRatePlan;
  beneficiaryGroupOption?: Maybe<PaperProductRatePlan>;
  bulkPackagingOption: PaperProductRatePlan;
  checkbookPackagingOption: PaperProductRatePlan;
  eventGroupOption?: Maybe<PaperProductRatePlan>;
  freeMessageOption: PaperProductRatePlan;
  letterTemplateOption?: Maybe<PaperProductRatePlan>;
  logoOption: PaperProductRatePlan;
  monoSiteShippingOption: PaperProductRatePlan;
  multiSiteShippingOption: PaperProductRatePlan;
  myGiftAdvantageOption?: Maybe<PaperProductRatePlan>;
  nominalChecksOption: PaperProductRatePlan;
  nominalSignaturesListOption: PaperProductRatePlan;
  serviceFee: PaperProductRatePlan;
  simpleSignaturesListOption: PaperProductRatePlan;
  siteGroupOption?: Maybe<PaperProductRatePlan>;
  standardMessageOption: PaperProductRatePlan;
  summaryVoucherOption?: Maybe<PaperProductRatePlan>;
};

export enum PaperPriceCatalogUnitOfMeasure {
  Beneficiary = 'BENEFICIARY',
  Checkbook = 'CHECKBOOK',
  Command = 'COMMAND',
  Site = 'SITE'
}

export type PaperProductRatePlan = {
  __typename?: 'PaperProductRatePlan';
  description: Scalars['String']['output'];
  grossPrice: Money;
  name: Scalars['String']['output'];
};

export type PaperSimulationOptions = {
  messageOption?: InputMaybe<MessageOptionInput>;
  nominalChecksOption?: InputMaybe<NominalChecksOptionInput>;
  packaging: PaperOrderChequePackaging;
  shippingModeOption?: InputMaybe<ShippingModeOptionInput>;
  signaturesListOption?: InputMaybe<SignaturesListOptionInput>;
};

export type ParsingError = GlobalParsingError | LineParsingError;

export enum ParsingErrorType {
  InvalidHeader = 'INVALID_HEADER',
  InvalidLine = 'INVALID_LINE',
  InvalidLineNumber = 'INVALID_LINE_NUMBER',
  UnsupportedFormat = 'UNSUPPORTED_FORMAT'
}

export type PasswordCondition = {
  __typename?: 'PasswordCondition';
  minStrength?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pattern?: Maybe<Scalars['String']['output']>;
};

export type PasswordConditionResponse = {
  __typename?: 'PasswordConditionResponse';
  canEditPassword: Scalars['Boolean']['output'];
  passwordConditions?: Maybe<Array<PasswordCondition>>;
};

export type Payment = {
  __typename?: 'Payment';
  amount?: Maybe<Money>;
  cardExpirationMonth?: Maybe<Scalars['Int']['output']>;
  cardExpirationYear?: Maybe<Scalars['Int']['output']>;
  cardLast4Digits?: Maybe<Scalars['Int']['output']>;
  code3ds?: Maybe<Scalars['Boolean']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['DateTime']['output'];
  externalRef?: Maybe<Scalars['String']['output']>;
  failure?: Maybe<PaymentFailure>;
  id: Scalars['Long']['output'];
  lastStatusUpdateDate?: Maybe<Scalars['DateTime']['output']>;
  notified?: Maybe<Scalars['DateTime']['output']>;
  provider: PaymentProvider;
  refundAmount?: Maybe<Money>;
  refundExternalReference?: Maybe<Scalars['String']['output']>;
  refundTryCounter?: Maybe<Scalars['Int']['output']>;
  responseCode?: Maybe<Scalars['String']['output']>;
  riskFlag: Scalars['Boolean']['output'];
  saveCard: Scalars['Boolean']['output'];
  status?: Maybe<PaymentStatus>;
  targetType?: Maybe<PaymentTargetType>;
  userUuid?: Maybe<Scalars['String']['output']>;
  version: Scalars['Int']['output'];
};

export type PaymentConfiguration = {
  __typename?: 'PaymentConfiguration';
  creditCardConfiguration: CreditCardPaymentConfiguration;
};

export enum PaymentFailure {
  AmountMismatch = 'AMOUNT_MISMATCH',
  Canceled = 'CANCELED',
  CardCountryDeclined = 'CARD_COUNTRY_DECLINED',
  CardDeclined = 'CARD_DECLINED',
  CurrencyMismatch = 'CURRENCY_MISMATCH',
  FraudSuspected = 'FRAUD_SUSPECTED',
  IncorrectNumber = 'INCORRECT_NUMBER',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  ProcessingError = 'PROCESSING_ERROR',
  ThreeDsDeclined = 'THREE_DS_DECLINED',
  Timeout = 'TIMEOUT',
  Unknown = 'UNKNOWN'
}

export type PaymentIntentSecret = {
  __typename?: 'PaymentIntentSecret';
  /** payment intent's client secret */
  clientSecret: Scalars['String']['output'];
};

export enum PaymentMethod {
  BankTransfer = 'BANK_TRANSFER',
  Check = 'CHECK',
  CreditCard = 'CREDIT_CARD',
  Unset = 'UNSET'
}

export enum PaymentMethodType {
  BankTransfer = 'BANK_TRANSFER',
  Check = 'CHECK',
  CreditCard = 'CREDIT_CARD'
}

export enum PaymentProvider {
  Checkout = 'CHECKOUT',
  CheckoutV2 = 'CHECKOUT_V2',
  Payplug = 'PAYPLUG'
}

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  hostedPaymentUrl?: Maybe<Scalars['String']['output']>;
  paid: Scalars['Boolean']['output'];
  paymentId: Scalars['Long']['output'];
};

export type PaymentResult = GiftError | Payment;

export type PaymentSheet = {
  __typename?: 'PaymentSheet';
  clientSecret?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  ephemeralKey?: Maybe<Scalars['String']['output']>;
  publishableKey?: Maybe<Scalars['String']['output']>;
};

export enum PaymentStatus {
  Approved = 'APPROVED',
  Captured = 'CAPTURED',
  Failed = 'FAILED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Success = 'SUCCESS',
  ToBeRefunded = 'TO_BE_REFUNDED',
  Used = 'USED'
}

export enum PaymentTargetType {
  BeneficiaryPayment = 'BENEFICIARY_PAYMENT',
  CompanyPayment = 'COMPANY_PAYMENT',
  Unknown = 'UNKNOWN'
}

export enum PaymentType {
  CompanyBankTransfer = 'COMPANY_BANK_TRANSFER',
  NoNeed = 'NO_NEED',
  UserCreditCard = 'USER_CREDIT_CARD',
  UserWedoogiftCard = 'USER_WEDOOGIFT_CARD'
}

export type Period = {
  __typename?: 'Period';
  days?: Maybe<Scalars['Int']['output']>;
  months?: Maybe<Scalars['Int']['output']>;
  years?: Maybe<Scalars['Int']['output']>;
};

export type PeriodRequest = {
  endDate: Scalars['DateTime']['input'];
  startDate: Scalars['DateTime']['input'];
};

export type PinCode = {
  __typename?: 'PinCode';
  pin?: Maybe<Scalars['String']['output']>;
};

export type PinCodeQueryInput = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
  cardNumberValidation: Scalars['ID']['input'];
};

/** A module */
export type PlatformModule = {
  __typename?: 'PlatformModule';
  category: ModuleCategory;
  /** The icon url */
  categoryIcon: Scalars['String']['output'];
  /** The category the module belongs to */
  categoryName: Scalars['String']['output'];
  currentSubscription?: Maybe<AutonomousSubscription>;
  functionalCode: Scalars['String']['output'];
  /** The short description of the module */
  homeCardDescription: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** Indicates if it is a free module */
  isFree: Scalars['Boolean']['output'];
  /** Indicates if it is a new module */
  isNew: Scalars['Boolean']['output'];
  /** Indicate if the module is subscribed by the organization */
  isSubscribed: Scalars['Boolean']['output'];
  isVisibleInSubscriptions: Scalars['Boolean']['output'];
  /** The manager front link */
  link?: Maybe<Scalars['String']['output']>;
  productRatesPlans: Array<ProductRatePlan>;
  /** Block in product sheet */
  productSheetBlock: Array<ModuleBlock>;
  /** Indications about subscription of module (Pricing bloc) */
  productSheetPricing: Array<Scalars['String']['output']>;
  /** The complete description of the module */
  productSheetSummary: Scalars['String']['output'];
  /** The illustration url */
  productSheetVisual: Scalars['String']['output'];
  /** Name of the module displayed on home page */
  publicName: Scalars['String']['output'];
  ranking?: Maybe<Scalars['Int']['output']>;
  /** The redirect url */
  redirectUri?: Maybe<ModuleRedirectUri>;
  /** Modules related to this module */
  relatedModules: Array<PlatformModule>;
  /** The url which will be open when the user request a demo */
  requestDemoUrl: Scalars['String']['output'];
  /** An array of section wich describes a module more precisely */
  sections: Array<ModuleSection>;
  /** The name of the module displayed on the related card */
  shortName: Scalars['String']['output'];
};


/** A module */
export type PlatformModuleCategoryArgs = {
  locale: Scalars['String']['input'];
};


/** A module */
export type PlatformModuleCategoryIconArgs = {
  locale: Scalars['String']['input'];
};


/** A module */
export type PlatformModuleCategoryNameArgs = {
  locale: Scalars['String']['input'];
};


/** A module */
export type PlatformModuleCurrentSubscriptionArgs = {
  orgId: Scalars['ID']['input'];
};


/** A module */
export type PlatformModuleHomeCardDescriptionArgs = {
  locale: Scalars['String']['input'];
};


/** A module */
export type PlatformModuleProductRatesPlansArgs = {
  orgId: Scalars['ID']['input'];
};


/** A module */
export type PlatformModuleProductSheetBlockArgs = {
  locale: Scalars['String']['input'];
};


/** A module */
export type PlatformModuleProductSheetPricingArgs = {
  locale: Scalars['String']['input'];
};


/** A module */
export type PlatformModuleProductSheetSummaryArgs = {
  locale: Scalars['String']['input'];
};


/** A module */
export type PlatformModuleProductSheetVisualArgs = {
  locale: Scalars['String']['input'];
};


/** A module */
export type PlatformModulePublicNameArgs = {
  locale: Scalars['String']['input'];
};


/** A module */
export type PlatformModuleRequestDemoUrlArgs = {
  orgId: Scalars['ID']['input'];
};


/** A module */
export type PlatformModuleSectionsArgs = {
  language: Scalars['String']['input'];
};


/** A module */
export type PlatformModuleShortNameArgs = {
  language: Scalars['String']['input'];
};

/** All organization preferences */
export type Preference = {
  __typename?: 'Preference';
  /** The font used by an organization */
  font?: Maybe<Scalars['String']['output']>;
  /** The front URL of a given Organization */
  frontUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The organization logo URL */
  logoUrl: Scalars['String']['output'];
  /** The primary color of the organization */
  primaryColor?: Maybe<Scalars['String']['output']>;
  /** The secondary color of the organization */
  secondaryColor?: Maybe<Scalars['String']['output']>;
};

export type PreviewDistributionRequest = {
  message?: InputMaybe<Scalars['String']['input']>;
  reasonId: Scalars['Long']['input'];
};

export type PreviewSubscriptionResponse = {
  __typename?: 'PreviewSubscriptionResponse';
  amount?: Maybe<Scalars['String']['output']>;
  amountWithoutTax?: Maybe<Scalars['String']['output']>;
  contactedMrr?: Maybe<Scalars['String']['output']>;
  taxAmount?: Maybe<Scalars['String']['output']>;
  totalContractedValue?: Maybe<Scalars['String']['output']>;
};

export type ProductRatePlan = {
  __typename?: 'ProductRatePlan';
  description?: Maybe<Scalars['String']['output']>;
  frequency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  moduleCode?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  segmentCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subSegmentEndRange?: Maybe<Scalars['Int']['output']>;
  subSegmentStartRange?: Maybe<Scalars['Int']['output']>;
  subSegmentUnit?: Maybe<Scalars['String']['output']>;
};

export enum ProductSheetBlockEnum {
  Brands = 'BRANDS',
  Cards = 'CARDS',
  DownloadFile = 'DOWNLOAD_FILE',
  Image = 'IMAGE',
  Prices = 'PRICES',
  Video = 'VIDEO'
}

export enum ProductSkuType {
  Culture = 'CULTURE',
  DepositDistrib = 'DEPOSIT_DISTRIB',
  Discount = 'DISCOUNT',
  Food = 'FOOD',
  Gift = 'GIFT',
  GiftPaper = 'GIFT_PAPER',
  TicketingNoSub = 'TICKETING_NO_SUB'
}

export enum ProductType {
  Culture = 'CULTURE',
  Gift = 'GIFT',
  GiftAndCulture = 'GIFT_AND_CULTURE'
}

export type ProposalArticle = {
  __typename?: 'ProposalArticle';
  code?: Maybe<Scalars['String']['output']>;
  discountAmount?: Maybe<Money>;
  discountRatio?: Maybe<Scalars['BigDecimal']['output']>;
  facialPrice: Money;
  unitPrice: Money;
};

export type ProposalError = {
  __typename?: 'ProposalError';
  content?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ProposalPurchaseRequest = {
  additionalData?: InputMaybe<PurchaseAdditionalData>;
  articles: Array<ProposalPurchaseRequestArticle>;
  id: Scalars['ID']['input'];
  paymentId?: InputMaybe<Scalars['Long']['input']>;
  shippingAddress?: InputMaybe<InputGiftAddress>;
  shopId: Scalars['Long']['input'];
  walletId: Scalars['Long']['input'];
};

export type ProposalPurchaseRequestArticle = {
  amount?: InputMaybe<MoneyInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<InputCardOwner>;
};

export type ProposalRequest = {
  amount?: InputMaybe<MoneyInput>;
  articles?: InputMaybe<Array<ProposalRequestArticle>>;
  shopId: Scalars['Long']['input'];
  walletId: Scalars['Long']['input'];
};

export type ProposalRequestArticle = {
  code?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
};

export type ProposalResult = {
  __typename?: 'ProposalResult';
  articles: Array<ProposalArticle>;
  canSaveCreditCard?: Maybe<Scalars['Boolean']['output']>;
  cardCombinationType?: Maybe<SearchType>;
  shopFee?: Maybe<PurchaseShopFee>;
  totalAmount: Money;
  totalDiscountAmount: Money;
  totalEndowment: Money;
  totalFacialAmount: Money;
  totalFeesAmount: Money;
  totalFundedAmount: Money;
  upsellAmount?: Maybe<Money>;
  upsellPossible: Scalars['Boolean']['output'];
  upsellRejectionCauseText?: Maybe<Scalars['String']['output']>;
};

export type ProposalsResponse = {
  __typename?: 'ProposalsResponse';
  error?: Maybe<ProposalError>;
  proposals: Array<ProposalResult>;
};

export type Prospect = {
  __typename?: 'Prospect';
  addressGroup?: Maybe<ProspectAddressGroup>;
  advantageGroup?: Maybe<ProspectAdvantageGroup>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  fingerprintGroup?: Maybe<ProspectFingerPrintGroup>;
  id: Scalars['ID']['output'];
  orgaGroup?: Maybe<ProspectOrgaGroup>;
  organization?: Maybe<Organization>;
  paperGiftEnabled?: Maybe<Scalars['Boolean']['output']>;
  segmentGroup?: Maybe<ProspectSegmentGroup>;
  status?: Maybe<ProspectStatusEnum>;
  statusGroup?: Maybe<ProspectStatusGroup>;
  userGroup?: Maybe<ProspectUserGroup>;
};

export type ProspectAddressGroup = {
  __typename?: 'ProspectAddressGroup';
  addressLineOne?: Maybe<Scalars['String']['output']>;
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type ProspectAddressGroupInput = {
  addressLineOne?: InputMaybe<Scalars['String']['input']>;
  addressLineTwo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type ProspectAdvantageGroup = {
  __typename?: 'ProspectAdvantageGroup';
  advantages?: Maybe<Array<Advantage>>;
  valid: Scalars['Boolean']['output'];
};

export type ProspectAdvantageGroupInput = {
  advantages?: InputMaybe<Array<Advantage>>;
};

export enum ProspectBotStatus {
  BotstatusBad = 'BOTSTATUS_BAD',
  BotstatusGood = 'BOTSTATUS_GOOD',
  BotstatusNotDetected = 'BOTSTATUS_NOT_DETECTED'
}

export type ProspectDraft = {
  __typename?: 'ProspectDraft';
  addressLineOne?: Maybe<Scalars['String']['output']>;
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyId?: Maybe<Scalars['ID']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  paperGiftEnabled?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  prospectFunction?: Maybe<ProspectFunctionEnum>;
  segment?: Maybe<CompanySegment>;
  status?: Maybe<ProspectStatusEnum>;
  subSegment?: Maybe<SubSegment>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type ProspectDraftV2 = {
  __typename?: 'ProspectDraftV2';
  addressLineOne?: Maybe<Scalars['String']['output']>;
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  identifier?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  paperGiftEnabled?: Maybe<Scalars['Boolean']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  segment?: Maybe<CompanySegment>;
  societeInfoId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ProspectStatusEnum>;
  subSegment?: Maybe<SubSegment>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type ProspectFingerPrintGroup = {
  __typename?: 'ProspectFingerPrintGroup';
  addressIp?: Maybe<Scalars['String']['output']>;
  botStatus?: Maybe<ProspectBotStatus>;
  requestId?: Maybe<Scalars['String']['output']>;
  suspectScore?: Maybe<Scalars['Int']['output']>;
  visitorId?: Maybe<Scalars['String']['output']>;
};

export enum ProspectFunctionEnum {
  Direction = 'DIRECTION',
  Drh = 'DRH',
  MarketingCommunicationDepartment = 'MARKETING_COMMUNICATION_DEPARTMENT',
  MemberCse = 'MEMBER_CSE',
  OfficeManager = 'OFFICE_MANAGER',
  Other = 'OTHER',
  PayrollAdministrator = 'PAYROLL_ADMINISTRATOR',
  Permanent = 'PERMANENT',
  PurchaseDepartment = 'PURCHASE_DEPARTMENT',
  ResponsableAssistRh = 'RESPONSABLE_ASSIST_RH',
  ResponsableCse = 'RESPONSABLE_CSE',
  Rh = 'RH',
  SalesDepartment = 'SALES_DEPARTMENT',
  TreasurerCse = 'TREASURER_CSE',
  Unknown = 'UNKNOWN'
}

export type ProspectOrgaGroup = {
  __typename?: 'ProspectOrgaGroup';
  companyName?: Maybe<Scalars['String']['output']>;
  identifier?: Maybe<Scalars['String']['output']>;
  societeInfoId?: Maybe<Scalars['String']['output']>;
  valid: Scalars['Boolean']['output'];
};

export type ProspectOrgaGroupInput = {
  clientCode?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  identifier?: InputMaybe<Scalars['String']['input']>;
  societeInfoId?: InputMaybe<Scalars['String']['input']>;
};

export type ProspectPageQueryInput = {
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type ProspectPageResponse = {
  __typename?: 'ProspectPageResponse';
  index: Scalars['Int']['output'];
  items: Array<Maybe<ProspectDraft>>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type ProspectRequest = {
  addressGroup?: InputMaybe<ProspectAddressGroupInput>;
  advantageGroup?: InputMaybe<ProspectAdvantageGroupInput>;
  emailSend?: InputMaybe<Scalars['Boolean']['input']>;
  fingerPrintRequestId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  orgaGroup?: InputMaybe<ProspectOrgaGroupInput>;
  paperGiftEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
  segmentGroup?: InputMaybe<ProspectSegmentGroupInput>;
  userGroup?: InputMaybe<ProspectUserGroupInput>;
};

export type ProspectSegmentGroup = {
  __typename?: 'ProspectSegmentGroup';
  budget?: Maybe<SubSegment>;
  segment?: Maybe<CompanySegment>;
  size?: Maybe<SubSegment>;
  subSegment?: Maybe<Array<SubSegment>>;
  valid: Scalars['Boolean']['output'];
};

export type ProspectSegmentGroupInput = {
  segment?: InputMaybe<CompanySegment>;
  subSegment?: InputMaybe<Array<InputMaybe<UpdateSubSegment>>>;
};

export enum ProspectStatusEnum {
  Created = 'CREATED',
  Declined = 'DECLINED',
  Deleted = 'DELETED',
  KybValidationOngoing = 'KYB_VALIDATION_ONGOING',
  NeedKyb = 'NEED_KYB',
  NeedManualValidation = 'NEED_MANUAL_VALIDATION',
  NeedRecontact = 'NEED_RECONTACT',
  Ongoing = 'ONGOING',
  Unknown = 'UNKNOWN',
  Validated = 'VALIDATED'
}

export type ProspectStatusGroup = {
  __typename?: 'ProspectStatusGroup';
  status: ProspectStatusEnum;
  statusReason?: Maybe<ProspectStatusReasons>;
};

export type ProspectStatusReasons = {
  __typename?: 'ProspectStatusReasons';
  outTime: Scalars['Boolean']['output'];
  recentSubscription: Scalars['Boolean']['output'];
};

export type ProspectUserGroup = {
  __typename?: 'ProspectUserGroup';
  email?: Maybe<Scalars['String']['output']>;
  firstname?: Maybe<Scalars['String']['output']>;
  lastname?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  prospectFunction?: Maybe<ProspectFunctionEnum>;
  rgpd: Scalars['Boolean']['output'];
  valid: Scalars['Boolean']['output'];
};

export type ProspectUserGroupInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  lastname?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  prospectFunction?: InputMaybe<ProspectFunctionEnum>;
  rgpd: Scalars['Boolean']['input'];
};

export type PublishCommunicationCampaignResult = CommunicationCampaign | CommunicationError;

export type PurchaseAdditionalData = {
  origin?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  version?: InputMaybe<Scalars['String']['input']>;
};

export type PurchaseRequest = {
  additionalData?: InputMaybe<PurchaseAdditionalData>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /**  FIXME set mandatory later to avoid breaking change */
  items: Array<InputMaybe<PurchaseRequestItem>>;
  paymentId?: InputMaybe<Scalars['Long']['input']>;
  walletId: Scalars['Long']['input'];
};

export type PurchaseRequestItem = {
  amount?: InputMaybe<MoneyInput>;
  billingAddress?: InputMaybe<InputGiftAddress>;
  code?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<InputCardOwner>;
  quantity: Scalars['Int']['input'];
  shippingAddress?: InputMaybe<InputGiftAddress>;
  shippingMode?: InputMaybe<ShippingMode>;
  shopId: Scalars['Long']['input'];
  uuid: Scalars['ID']['input'];
};

export type PurchaseResponse = {
  __typename?: 'PurchaseResponse';
  cartUuid?: Maybe<Scalars['ID']['output']>;
};

export type PurchaseResult = GiftError | PurchaseResponse;

export type PurchaseShopFee = {
  __typename?: 'PurchaseShopFee';
  amount: Money;
  billingMode: ShopFeeBillingMode;
  freeForBeneficiary: Scalars['Boolean']['output'];
  shippingMode?: Maybe<ShopFeeShippingMode>;
  type: ShopFeeType;
};

export type PurchaseStatus = {
  __typename?: 'PurchaseStatus';
  error?: Maybe<GiftError>;
  paymentId?: Maybe<Scalars['Long']['output']>;
  status: CartStatus;
  uuid?: Maybe<Scalars['ID']['output']>;
};

export type PurchasedCard = {
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  consumptionDate: Scalars['DateTime']['output'];
  expeditionDate?: Maybe<Scalars['DateTime']['output']>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  mailTracking?: Maybe<MailTracking>;
  manualConsumptionDate?: Maybe<Scalars['DateTime']['output']>;
  oppositionDate?: Maybe<Scalars['DateTime']['output']>;
  price: Money;
  purchasedCardActions: Array<PurchasedCardAction>;
  refundInitiator?: Maybe<Beneficiary>;
  refundReason?: Maybe<CardRefundReason>;
  serviceAmount?: Maybe<Money>;
  serviceFunded: Scalars['Boolean']['output'];
  shippingAddress?: Maybe<GiftAddress>;
  shippingAmount?: Maybe<Money>;
  shippingFunded: Scalars['Boolean']['output'];
  shop: Shop;
  type: CardType;
  usages?: Maybe<CardUsages>;
};

export enum PurchasedCardAction {
  CopyCode = 'COPY_CODE',
  CopyPin = 'COPY_PIN',
  DownloadPdf = 'DOWNLOAD_PDF',
  MoveToAvailable = 'MOVE_TO_AVAILABLE',
  MoveToUsed = 'MOVE_TO_USED',
  SeeDetails = 'SEE_DETAILS'
}

export type PurchasedCardPage = PageResult & {
  __typename?: 'PurchasedCardPage';
  index: Scalars['Int']['output'];
  items: Array<PurchasedCard>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Query = {
  __typename?: 'Query';
  adminImplemDownload: AdminImplemDownload;
  adminImplemMigrationGet: AdminImplemMigrationResult;
  archiveReasons?: Maybe<Array<ArchiveReason>>;
  authenticatedUser: AuthenticatedUser;
  autocomplete: Array<Maybe<AutocompleteResponse>>;
  autocompleteCity: Array<Maybe<AutocompleteCityResponse>>;
  autocompleteSocieteInfo?: Maybe<Array<SocieteInfoDataCompanyAutoCompleteResult>>;
  autocompleteSpf?: Maybe<Array<SpfDataCompanyAutoCompleteResult>>;
  /** @deprecated Use `beneficiariesV2` instead. */
  beneficiaries?: Maybe<BeneficiariesPage>;
  /** @deprecated Use `beneficiariesV3` instead. */
  beneficiariesV2?: Maybe<BeneficiariesPage>;
  beneficiariesV3?: Maybe<BeneficiariesPage>;
  beneficiary: Beneficiary;
  beneficiaryByLogin?: Maybe<Beneficiary>;
  beneficiaryIds?: Maybe<Array<Scalars['String']['output']>>;
  cardCombination?: Maybe<CardCombinationResponse>;
  cardMultiShopOppositionFees: Money;
  cardProgram: CardProgram;
  cardReloadSummary: CardReloadOrderSummary;
  checkAddressValidity?: Maybe<CheckAddressValidityResponse>;
  checkFeatureFlag?: Maybe<CheckFeatureFlagResponse>;
  /** Check Newsletter campaign name. */
  checkNewsletterCampaignName: CheckNewsletterCampaignNameResult;
  /** Check PerkUtilisation Validity */
  checkPerkUtilisationValidity: PacPerkUtilisationResult;
  countryCodes?: Maybe<Array<CountryCode>>;
  /** Create external account */
  createExternalAccount: ExternalAccountResult;
  detailsImportFileBank: Array<Maybe<OrderTaskDetail>>;
  /** Download a voucher file */
  downloadAccountingVoucher: VoucherFileResult;
  /** Download batch report file. */
  downloadBatchReport?: Maybe<PacBatchDownload>;
  /** Download batch result file. */
  downloadBatchResult?: Maybe<PacBatchDownload>;
  downloadCardVoucher?: Maybe<GiftFileResponse>;
  downloadEpBatchResult?: Maybe<PacBatchDownload>;
  /** Download attachment file. */
  downloadFile?: Maybe<MultipartBody>;
  downloadOrderComplexFile?: Maybe<DownloadOrderComplexFileResponse>;
  downloadOrderPdf?: Maybe<GiftFileResponse>;
  downloadSimulation?: Maybe<DownloadSimulationResponse>;
  /** Download voucher file. */
  downloadVoucherFile?: Maybe<MultipartBody>;
  dtsBenefits: Array<Maybe<Benefit>>;
  dtsUrssafEvents: Array<Maybe<UrssafEvent>>;
  extendedInfoSpf?: Maybe<SpfDataCompanyExtendedResult>;
  findCompanyByName?: Maybe<Organization>;
  foodBalance: FoodBalance;
  foodBeneficiaries: FoodBeneficiariesPage;
  foodBeneficiariesIds: FoodBeneficiariesPage;
  foodCardOrderSummary: CardOrderSummary;
  foodCommercialOffer?: Maybe<CommercialOffer>;
  foodCustomerCards: FoodCustomerCards;
  /**  Order */
  foodDownloadOrderDocument: Array<Maybe<Scalars['Int']['output']>>;
  foodIsOrderDocumentReady: Scalars['Boolean']['output'];
  foodIsUserActive?: Maybe<Scalars['Boolean']['output']>;
  foodOrdersLinesSearch: FoodOrderLinePage;
  foodOrdersSearch: FoodOrderPage;
  foodOrganizationDefaultAddress?: Maybe<FoodDefaultAddress>;
  /**  @provides used to prevent graphql federation and avoid permission denied on the resource. Do not add id field. */
  foodOrganizationShippingAddresses: Array<Maybe<Address>>;
  /**  Reload */
  foodReloadSummary?: Maybe<FoodReloadSummaryResponse>;
  foodReloadSummaryErrors?: Maybe<FoodReloadSummaryErrorsResponse>;
  foodReloadSummaryWorkingDaysTotal?: Maybe<FoodTotalResponse>;
  foodTask?: Maybe<FoodTask>;
  foodTransactions: FoodTransactionsPage;
  foodUserCommercialOffer?: Maybe<CommercialOffer>;
  foodUserDefaultAddress?: Maybe<FoodDefaultAddress>;
  foodUserHomeDefaultAddress?: Maybe<FoodDefaultAddress>;
  /**  if beneficiaryId is empty, use the current user */
  generateCardBarcode?: Maybe<GiftFileResponse>;
  getAddressAutocomplete: AddressData;
  /** Retrieve batch job. */
  getBatchJob?: Maybe<PacBatchFileResult>;
  getBeneficiaryFoodWorkingDaysStatus?: Maybe<ExtraDataStatusType>;
  /** Get Newsletter tracking object. */
  getCampaignNewsletterTracking?: Maybe<NewsletterTrackingResult>;
  getCommunicationCampaign?: Maybe<GetCommunicationCampaignResult>;
  /** Get Communication File download tracking. */
  getCommunicationFileTracking: GetCommunicationFileTrackingResult;
  /** get campaign file url */
  getCommunicationFileUrl: GetCommunicationFileUrlResult;
  /** get communication tracking */
  getCommunicationTracking: GetCommunicationTrackingResult;
  /** Get Communication Survey answer. */
  getCommunicationUserSurveyAnswers: GetCommunicationUserSurveyAnswersResult;
  getDiscOrderReference?: Maybe<GetDiscOrderReferenceResponse>;
  getEpDistributionErrorReport?: Maybe<PacDistributionErrorReport>;
  /** Retrieve extendedProfile of a benef for a manager */
  getExtendedProfile: PacUserExtendedProfileForManager;
  /** Get external account */
  getExternalAccount: ExternalAccountResult;
  /** Retrieve metadata of a attachment file. */
  getFileMetadata?: Maybe<FileMetadata>;
  getLastDraftPaperOrder?: Maybe<GetLastPaperDraftOrderResponse>;
  getLastInvoice: FileResult;
  getListOfUserSupportRoles: RoleAdminSupportResponse;
  getListPerkExportJob?: Maybe<PacPerkExportResult>;
  getListPerkExportJobByType?: Maybe<PacPerkExportResult>;
  /** Get organization's Negotiated pricing terms */
  getNegotiatedPricingTerms: NegotiatedPricingTermsResult;
  /** Get Newsletter account . */
  getNewsletterAccount?: Maybe<GetNewsletterAccountResult>;
  /** Get Newsletter Campaign parameters. */
  getNewsletterCampaignParameters?: Maybe<GetNewsletterCampaignParametersResult>;
  /** Get mail canvas */
  getNewsletterCanvas: GetNewsletterCanvasResult;
  /** get newsletter guide url */
  getNewsletterGuideUrl: GetNewsletterGuideUrlResult;
  /** Get Newsletter recipient count. */
  getNewsletterRecipientCount: GetNewsletterRecipientCountResult;
  /** Get Newsletter targeting rule. */
  getNewsletterRecipientTargetingRule: GetNewsletterRecipientTargetingRuleResult;
  /** List Newsletter template for a organization scope */
  getNewsletterTemplateList: GetNewsletterTemplateListResult;
  /** Get Newsletter template preview. */
  getNewsletterTemplatePreview: GetNewsletterTemplatePreviewResult;
  /** get unsplash image */
  getNewsletterUnsplashImage: UploadNewsletterImageResult;
  getOrder?: Maybe<GetOrderResponse>;
  getOrderDetails?: Maybe<OrderDetails>;
  getOrderHistory?: Maybe<GetOrderHistoryResponse>;
  getOrderShipments?: Maybe<GetOrderShipmentsResponse>;
  getOrderStatus?: Maybe<GetOrderStatusResponse>;
  getOrganizationFoodWorkingDaysStatus?: Maybe<ExtraDataStatusType>;
  getOrganizationInformation?: Maybe<GetOrganizationInformationResult>;
  getPaymentUrl?: Maybe<GetPaymentUrlResponse>;
  getSimulationById?: Maybe<GetSimulationResponse>;
  /** Get updated reconciliation items */
  getUpdatedReconciliationItems: ReconciliationResult;
  getUserEventHistory: EventHistoryResponse;
  getUserRights?: Maybe<UserRights>;
  giftConfiguration: GiftConfiguration;
  giftTask?: Maybe<GiftTaskResponse>;
  giftTaskResult?: Maybe<GiftTaskResultResponse>;
  globalParameters?: Maybe<GlobalParameters>;
  informationsTopUp: TopUpInformation;
  /** Get a invoice with invoice ID or order ID */
  invoice: Invoice;
  /** @deprecated Use `globalParameters` instead. */
  isGlobalTwoFactorEnabled: Scalars['Boolean']['output'];
  isImportFileBankReady?: Maybe<Scalars['Boolean']['output']>;
  knownDevices?: Maybe<Array<KnownDevice>>;
  labelStocks?: Maybe<Array<Maybe<ShopLabelStock>>>;
  /** List all the existing vouchers for a source. */
  listAccountingVoucher: ListAccVoucherResult;
  /** List all campaigns for the beneficiary and given organisation filtered by given status. */
  listCampaignsForBeneficiary: ListCommunicationCampaignsWithBeneficiaryViewResult;
  listCommunicationCampaigns?: Maybe<ListCommunicationCampaignResult>;
  /** List all draft campaigns for the given organisation. */
  listNewsletterDraftCampaigns: ListNewsletterDraftCampaignResult;
  /** List all sent campaigns for the given organisation. */
  listNewsletterSentCampaigns: ListNewsletterSentCampaignResult;
  metrics?: Maybe<Metrics>;
  module?: Maybe<PlatformModule>;
  moduleSubscription?: Maybe<ModuleSubscription>;
  /** Get an order with a payment token */
  order: BillingOrder;
  organization?: Maybe<Organization>;
  organizationConfig: AccountStatus;
  organizationPage?: Maybe<OrganizationPageResponse>;
  /** Lists the available templates for additional data schemas. */
  pacAdsTemplates: Array<PacAdsTemplate>;
  pacListEpDistributionExport?: Maybe<PacDestributionExportResult>;
  pacListEpExport?: Maybe<Array<Maybe<PacBatchJob>>>;
  paperOrders?: Maybe<PaperOrderResult>;
  paperPriceCatalog?: Maybe<PaperPriceCatalogResult>;
  paperPriceCatalogSimulation?: Maybe<PaperPriceCatalogResult>;
  payment?: Maybe<PaymentResult>;
  /** Get a paymentIntent's clientSecret */
  paymentIntentSecret: PaymentIntentSecret;
  pinCode: PinCode;
  preference?: Maybe<Preference>;
  previewDistributionEmail?: Maybe<GiftMailPreviewResponse>;
  proposals?: Maybe<ProposalsResponse>;
  prospect: ProspectDraft;
  prospectPage?: Maybe<ProspectPageResponse>;
  prospectV2: Prospect;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchasedCard?: Maybe<PurchasedCard>;
  redirectPaperUser?: Maybe<RedirectSimulationUserResponse>;
  /** Save mail canvas */
  saveNewsletterCanvas: SaveNewsletterCanvasResult;
  /** Search vouchers by source and by voucher id content. */
  searchAccountingVoucher: ListAccVoucherResult;
  searchBasicCompanyInfo?: Maybe<Array<Maybe<CompanyAutoCompleteResult>>>;
  searchBasicCompanyInfoWithoutSiret?: Maybe<Array<Maybe<CompanyAutoCompleteResultWithoutSiret>>>;
  /** Search Newsletter Unsplash images. */
  searchNewsletterUnsplashImages: SearchNewsletterUnsplashImagesResult;
  segments?: Maybe<Array<SegmentSubSegment>>;
  /** Send Communication Test mail */
  sendCommunicationTestMail: SendCommunicationTestMailResult;
  /** Send Newsletter test. */
  sendNewsletterTest: SendNewsletterTestResult;
  shop?: Maybe<Shop>;
  shopPackages?: Maybe<ShopPackagePage>;
  shops?: Maybe<Array<Maybe<Shop>>>;
  /** Retrieve a site from its siteId (different id from an organization id) */
  site: GetSiteResult;
  /** List available templates for site creation. */
  siteTemplates: Array<SiteTemplate>;
  /**
   * List all the existing sites for an organization, if orgId is not given, list for every
   * organization.
   */
  sites: Array<SiteInfo>;
  statusTopUp: TopUpInformation;
  subscription?: Maybe<PreviewSubscriptionResponse>;
  task?: Maybe<Task>;
  uiRightsOnAllOrganization?: Maybe<OrganizationUiRights>;
  /** Upload image */
  uploadNewsletterImage: UploadNewsletterImageResult;
  users?: Maybe<UsersPage>;
  usersListByLogin?: Maybe<UsersListByLoginResult>;
  validateCompanyIdentifier: Scalars['Boolean']['output'];
  validateCompanyName: Scalars['Boolean']['output'];
  validateCompanyNameUpdate: Scalars['Boolean']['output'];
  validateEmail: Scalars['Boolean']['output'];
  validateIdentifier: Scalars['Boolean']['output'];
  validateIdentifierByCountry: Scalars['Boolean']['output'];
  validateIdentifierV2: Scalars['Boolean']['output'];
  validateManagerEmail: Scalars['Boolean']['output'];
  validateProspectPhoneNumber: Scalars['Boolean']['output'];
  wallet?: Maybe<Wallet>;
};


export type QueryAdminImplemDownloadArgs = {
  filePath: Scalars['String']['input'];
};


export type QueryAdminImplemMigrationGetArgs = {
  migrationUuid: Scalars['ID']['input'];
};


export type QueryAutocompleteArgs = {
  query: AutocompleteAddressRequest;
};


export type QueryAutocompleteCityArgs = {
  query: AutocompleteAddressRequest;
};


export type QueryAutocompleteSocieteInfoArgs = {
  query?: InputMaybe<SearchBasicCompanyInfoRequest>;
};


export type QueryAutocompleteSpfArgs = {
  query?: InputMaybe<SearchBasicCompanyInfoRequest>;
};


export type QueryBeneficiariesArgs = {
  query: BeneficiariesQueryInput;
};


export type QueryBeneficiariesV2Args = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  query: BeneficiariesQueryInput;
};


export type QueryBeneficiariesV3Args = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  query: BeneficiariesQueryInput;
};


export type QueryBeneficiaryArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type QueryBeneficiaryByLoginArgs = {
  beneficiaryLogin: Scalars['String']['input'];
};


export type QueryBeneficiaryIdsArgs = {
  query: BeneficiaryIdsQueryInput;
};


export type QueryCardCombinationArgs = {
  amount: MoneyInput;
  searchTypes: Array<InputMaybe<SearchType>>;
  shopId: Scalars['Long']['input'];
};


export type QueryCardProgramArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryCardReloadSummaryArgs = {
  queryInput: CardReloadSummaryQueryInput;
};


export type QueryCheckAddressValidityArgs = {
  address?: InputMaybe<CheckAddressValidityRequest>;
};


export type QueryCheckFeatureFlagArgs = {
  request?: InputMaybe<CheckFeatureFlagRequest>;
};


export type QueryCheckNewsletterCampaignNameArgs = {
  campaignName: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryCheckPerkUtilisationValidityArgs = {
  orgaUuid: Scalars['ID']['input'];
  utilisationUuid: Scalars['ID']['input'];
};


export type QueryCreateExternalAccountArgs = {
  sourceId: Scalars['String']['input'];
};


export type QueryDetailsImportFileBankArgs = {
  taskId: Scalars['ID']['input'];
};


export type QueryDownloadAccountingVoucherArgs = {
  sourceId: Scalars['String']['input'];
  targetId: Scalars['String']['input'];
  voucherId: Scalars['String']['input'];
};


export type QueryDownloadBatchReportArgs = {
  jobUuid: Scalars['ID']['input'];
  orgaUuid: Scalars['ID']['input'];
};


export type QueryDownloadBatchResultArgs = {
  jobUuid: Scalars['ID']['input'];
  orgaUuid: Scalars['ID']['input'];
};


export type QueryDownloadCardVoucherArgs = {
  beneficiaryId?: InputMaybe<Scalars['ID']['input']>;
  cardId: Scalars['Long']['input'];
};


export type QueryDownloadEpBatchResultArgs = {
  jobUuid: Scalars['ID']['input'];
  orgaUuid: Scalars['ID']['input'];
};


export type QueryDownloadFileArgs = {
  docUuid: Scalars['ID']['input'];
  orgaUuid: Scalars['ID']['input'];
  userUuid: Scalars['ID']['input'];
};


export type QueryDownloadOrderComplexFileArgs = {
  request?: InputMaybe<DownloadOrderComplexFileRequest>;
};


export type QueryDownloadOrderPdfArgs = {
  companyId: Scalars['ID']['input'];
  orderId: Scalars['Long']['input'];
};


export type QueryDownloadSimulationArgs = {
  request: DownloadSimulationRequest;
};


export type QueryDownloadVoucherFileArgs = {
  docUuid: Scalars['ID']['input'];
  orgaUuid: Scalars['ID']['input'];
  userUuid: Scalars['ID']['input'];
};


export type QueryExtendedInfoSpfArgs = {
  query?: InputMaybe<SearchExtendedSpfDataRequest>;
};


export type QueryFindCompanyByNameArgs = {
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFoodBalanceArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type QueryFoodBeneficiariesArgs = {
  queryInput: FoodBeneficiariesQueryInput;
};


export type QueryFoodBeneficiariesIdsArgs = {
  queryInput: FoodBeneficiariesQueryInput;
};


export type QueryFoodCardOrderSummaryArgs = {
  queryInput: CardOrderSummaryQueryInput;
};


export type QueryFoodCommercialOfferArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryFoodCustomerCardsArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type QueryFoodDownloadOrderDocumentArgs = {
  queryInput?: InputMaybe<FoodDownloadOrderDocumentInput>;
};


export type QueryFoodIsOrderDocumentReadyArgs = {
  queryInput?: InputMaybe<FoodDownloadOrderDocumentInput>;
};


export type QueryFoodIsUserActiveArgs = {
  beneficiaryId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryFoodOrdersLinesSearchArgs = {
  queryInput: FoodOrdersLinesSearchInput;
};


export type QueryFoodOrdersSearchArgs = {
  queryInput: FoodOrderSearchInput;
};


export type QueryFoodOrganizationDefaultAddressArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryFoodOrganizationShippingAddressesArgs = {
  beneficiaryId: Scalars['ID']['input'];
};


export type QueryFoodReloadSummaryArgs = {
  queryInput: FoodReloadSummarySearch;
};


export type QueryFoodReloadSummaryErrorsArgs = {
  queryInput: FoodReloadSummaryErrorsInput;
};


export type QueryFoodReloadSummaryWorkingDaysTotalArgs = {
  organizationUuid: Scalars['ID']['input'];
  taskUuid: Scalars['ID']['input'];
};


export type QueryFoodTaskArgs = {
  taskId: Scalars['ID']['input'];
};


export type QueryFoodTransactionsArgs = {
  queryInput: FoodTransactionsQueryInput;
};


export type QueryFoodUserCommercialOfferArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryFoodUserDefaultAddressArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryFoodUserHomeDefaultAddressArgs = {
  organizationId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type QueryGenerateCardBarcodeArgs = {
  cardId: Scalars['Long']['input'];
};


export type QueryGetAddressAutocompleteArgs = {
  query: GetAddressAutocompleteRequest;
};


export type QueryGetBatchJobArgs = {
  jobUuid: Scalars['ID']['input'];
  orgaUuid: Scalars['ID']['input'];
};


export type QueryGetBeneficiaryFoodWorkingDaysStatusArgs = {
  beneficiaryId: Scalars['ID']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetCampaignNewsletterTrackingArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetCommunicationCampaignArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetCommunicationFileTrackingArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetCommunicationFileUrlArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetCommunicationTrackingArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetCommunicationUserSurveyAnswersArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetDiscOrderReferenceArgs = {
  request?: InputMaybe<GetDiscOrderReferenceRequest>;
};


export type QueryGetEpDistributionErrorReportArgs = {
  jobUuid: Scalars['ID']['input'];
};


export type QueryGetExtendedProfileArgs = {
  orgaUuid: Scalars['ID']['input'];
  userUuid: Scalars['ID']['input'];
};


export type QueryGetExternalAccountArgs = {
  sourceId: Scalars['String']['input'];
};


export type QueryGetFileMetadataArgs = {
  docUuid: Scalars['ID']['input'];
  orgaUuid: Scalars['ID']['input'];
  userUuid: Scalars['ID']['input'];
};


export type QueryGetLastDraftPaperOrderArgs = {
  request?: InputMaybe<GetLastPaperDraftOrderRequest>;
};


export type QueryGetLastInvoiceArgs = {
  functionalModuleCode: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};


export type QueryGetListPerkExportJobArgs = {
  pacPerkExportSearchParams?: InputMaybe<PacPerkExportSearchParams>;
};


export type QueryGetListPerkExportJobByTypeArgs = {
  pacPerkExportSearchParams?: InputMaybe<PacPerkExportByTypeSearchParams>;
};


export type QueryGetNegotiatedPricingTermsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetNewsletterAccountArgs = {
  orgId: Scalars['String']['input'];
};


export type QueryGetNewsletterCampaignParametersArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetNewsletterCanvasArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetNewsletterGuideUrlArgs = {
  orgId: Scalars['String']['input'];
};


export type QueryGetNewsletterRecipientCountArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetNewsletterRecipientTargetingRuleArgs = {
  campaignId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};


export type QueryGetNewsletterTemplateListArgs = {
  maxItem: Scalars['Int']['input'];
  orgId: Scalars['String']['input'];
  organizationScope: Scalars['String']['input'];
  pagination?: InputMaybe<NewsletterTemplatePaginationInput>;
};


export type QueryGetNewsletterTemplatePreviewArgs = {
  orgId: Scalars['String']['input'];
  template: NewsletterTemplateInput;
};


export type QueryGetNewsletterUnsplashImageArgs = {
  query: NewsletterUnsplashGetImageInput;
};


export type QueryGetOrderArgs = {
  request?: InputMaybe<GetOrderRequest>;
};


export type QueryGetOrderDetailsArgs = {
  request?: InputMaybe<GetOrderDetailsRequest>;
};


export type QueryGetOrderHistoryArgs = {
  request?: InputMaybe<GetOrderHistoryRequest>;
};


export type QueryGetOrderShipmentsArgs = {
  request?: InputMaybe<GetOrderShipmentsRequest>;
};


export type QueryGetOrderStatusArgs = {
  request?: InputMaybe<GetOrderStatusRequest>;
};


export type QueryGetOrganizationFoodWorkingDaysStatusArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryGetOrganizationInformationArgs = {
  query?: InputMaybe<GetOrganizationInformationRequest>;
};


export type QueryGetPaymentUrlArgs = {
  request?: InputMaybe<GetPaymentUrlRequest>;
};


export type QueryGetSimulationByIdArgs = {
  request: GetSimulationRequest;
};


export type QueryGetUpdatedReconciliationItemsArgs = {
  input: ReconciliateFileInput;
};


export type QueryGetUserEventHistoryArgs = {
  query: EventHistoryRequest;
};


export type QueryGetUserRightsArgs = {
  request?: InputMaybe<UserRightsRequest>;
};


export type QueryGiftTaskArgs = {
  id: Scalars['Long']['input'];
};


export type QueryGiftTaskResultArgs = {
  id: Scalars['Long']['input'];
};


export type QueryInformationsTopUpArgs = {
  beneficiaryId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryInvoiceArgs = {
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryIsImportFileBankReadyArgs = {
  taskId: Scalars['ID']['input'];
};


export type QueryKnownDevicesArgs = {
  userId: Scalars['ID']['input'];
};


export type QueryLabelStocksArgs = {
  shopId: Scalars['Long']['input'];
};


export type QueryListAccountingVoucherArgs = {
  maxItem?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<AccPaginationInput>;
  sourceId: Scalars['String']['input'];
};


export type QueryListCampaignsForBeneficiaryArgs = {
  maxItem?: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['String']['input'];
  pagination?: InputMaybe<CommunicationCampaignPaginationInput>;
};


export type QueryListCommunicationCampaignsArgs = {
  maxItem?: InputMaybe<Scalars['Int']['input']>;
  orgId: Scalars['String']['input'];
  pagination?: InputMaybe<CommunicationCampaignPaginationInput>;
  status: Scalars['String']['input'];
};


export type QueryListNewsletterDraftCampaignsArgs = {
  maxItem?: InputMaybe<Scalars['Int']['input']>;
  orgaId: Scalars['String']['input'];
  pagination?: InputMaybe<NewsletterDraftCampaignPaginationInput>;
};


export type QueryListNewsletterSentCampaignsArgs = {
  maxItem?: InputMaybe<Scalars['Int']['input']>;
  orgaId: Scalars['String']['input'];
  pagination?: InputMaybe<NewsletterSentCampaignPaginationInput>;
};


export type QueryMetricsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type QueryModuleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryModuleSubscriptionArgs = {
  subscriptionId: Scalars['ID']['input'];
};


export type QueryOrderArgs = {
  token: Scalars['ID']['input'];
};


export type QueryOrganizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationConfigArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrganizationPageArgs = {
  query: OrganizationPageQueryInput;
};


export type QueryPacListEpDistributionExportArgs = {
  orgaUuid: Scalars['ID']['input'];
};


export type QueryPacListEpExportArgs = {
  orgaUuid: Scalars['ID']['input'];
};


export type QueryPaperOrdersArgs = {
  request: PaperOrderPageRequest;
  search?: InputMaybe<Search>;
};


export type QueryPaperPriceCatalogSimulationArgs = {
  request?: InputMaybe<PaperPriceCatalogRequest>;
};


export type QueryPaymentArgs = {
  externalRef?: InputMaybe<Scalars['String']['input']>;
  paymentId?: InputMaybe<Scalars['Long']['input']>;
};


export type QueryPaymentIntentSecretArgs = {
  token: Scalars['ID']['input'];
};


export type QueryPinCodeArgs = {
  queryInput: PinCodeQueryInput;
};


export type QueryPreferenceArgs = {
  frontUrl: Scalars['String']['input'];
};


export type QueryPreviewDistributionEmailArgs = {
  companyId: Scalars['ID']['input'];
  request: PreviewDistributionRequest;
};


export type QueryProposalsArgs = {
  request: ProposalRequest;
};


export type QueryProspectArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProspectPageArgs = {
  query: ProspectPageQueryInput;
};


export type QueryProspectV2Args = {
  id: Scalars['ID']['input'];
};


export type QueryPurchaseStatusArgs = {
  cartUuid?: InputMaybe<Scalars['ID']['input']>;
  organizationId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPurchasedCardArgs = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['Long']['input'];
};


export type QueryRedirectPaperUserArgs = {
  request: RedirectSimulationUserRequest;
};


export type QuerySaveNewsletterCanvasArgs = {
  query: SaveNewsletterCanvasInput;
};


export type QuerySearchAccountingVoucherArgs = {
  maxItem?: InputMaybe<Scalars['Int']['input']>;
  pagination?: InputMaybe<AccPaginationInput>;
  partialVoucherId: Scalars['String']['input'];
  sourceId: Scalars['String']['input'];
};


export type QuerySearchBasicCompanyInfoArgs = {
  query?: InputMaybe<SearchBasicCompanyInfoRequest>;
};


export type QuerySearchBasicCompanyInfoWithoutSiretArgs = {
  query?: InputMaybe<SearchBasicCompanyInfoRequest>;
};


export type QuerySearchNewsletterUnsplashImagesArgs = {
  orgId: Scalars['String']['input'];
  params: NewsletterUnsplashSearchInput;
};


export type QuerySendCommunicationTestMailArgs = {
  campaign: CommunicationNewsCampaignInput;
  campaignType: Scalars['String']['input'];
  recipientMail: Scalars['String']['input'];
};


export type QuerySendNewsletterTestArgs = {
  query?: InputMaybe<SendNewsletterTestRequestInput>;
};


export type QueryShopArgs = {
  shopId: Scalars['Long']['input'];
};


export type QueryShopPackagesArgs = {
  request: PageRequest;
  shopPackageSpec?: InputMaybe<GiftShopPackageSpec>;
};


export type QueryShopsArgs = {
  shopIds: Array<InputMaybe<Scalars['Long']['input']>>;
};


export type QuerySiteArgs = {
  siteId: Scalars['ID']['input'];
};


export type QuerySitesArgs = {
  orgId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryStatusTopUpArgs = {
  beneficiaryId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySubscriptionArgs = {
  organizationId: Scalars['ID']['input'];
  productRatePlanId: Scalars['ID']['input'];
};


export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUploadNewsletterImageArgs = {
  query: NewsletterUploadImageInput;
};


export type QueryUsersArgs = {
  query: UsersQueryInput;
};


export type QueryUsersListByLoginArgs = {
  query: UsersListByLoginQueryInput;
};


export type QueryValidateCompanyIdentifierArgs = {
  query?: InputMaybe<CompanyIdentifier>;
};


export type QueryValidateCompanyNameArgs = {
  query?: InputMaybe<ValidateCompanyNameRequest>;
};


export type QueryValidateCompanyNameUpdateArgs = {
  query?: InputMaybe<ValidateCompanyNameUpdateRequest>;
};


export type QueryValidateEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryValidateIdentifierArgs = {
  query?: InputMaybe<ValidateIdentifierRequest>;
};


export type QueryValidateIdentifierByCountryArgs = {
  query?: InputMaybe<ValidateIdentifierRequest>;
};


export type QueryValidateIdentifierV2Args = {
  query?: InputMaybe<CompanyIdentifier>;
};


export type QueryValidateManagerEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryValidateProspectPhoneNumberArgs = {
  phoneNumber: Scalars['String']['input'];
};


export type QueryWalletArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  walletId: Scalars['Long']['input'];
};

export type ReasonResponse = DistributionReason | GiftError;

export type Recipient = {
  __typename?: 'Recipient';
  totalCount: Scalars['Int']['output'];
  unsubscribedCount: Scalars['Int']['output'];
};

export type ReconciliateFileInput = {
  /** Path of the file to be reconciliated */
  path: Scalars['String']['input'];
};

export type ReconciliateRequestInput = {
  /** amount : payment amount to be affected */
  amount: Scalars['String']['input'];
  /** file_path : file path of the concerned reconciliation file */
  filePath: Scalars['String']['input'];
  /** item_id : item's ID in original reconciliation algorithm sequence */
  itemId: Scalars['String']['input'];
  /** order_id : unique ID for a command. ex : CMD-CQTG-00000001 */
  orderId: Scalars['String']['input'];
};

export type ReconciliateResponse = {
  __typename?: 'ReconciliateResponse';
  item: ReconciliationItem;
  reason?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type ReconciliationItem = {
  __typename?: 'ReconciliationItem';
  accountNumber?: Maybe<Scalars['String']['output']>;
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  collectedAmount?: Maybe<Scalars['BigDecimal']['output']>;
  dueAmount?: Maybe<Scalars['BigDecimal']['output']>;
  invoiceId?: Maybe<Scalars['ID']['output']>;
  invoiceNumber?: Maybe<Scalars['ID']['output']>;
  itemId: Scalars['ID']['output'];
  orderId?: Maybe<Scalars['ID']['output']>;
  orderStatus?: Maybe<BillingOrderStatus>;
  organizationId?: Maybe<Scalars['ID']['output']>;
  organizationName?: Maybe<Scalars['String']['output']>;
  originalAmount: Scalars['String']['output'];
  originalDate: Scalars['String']['output'];
  originalLabel: Scalars['String']['output'];
  paymentDate?: Maybe<Scalars['DateTime']['output']>;
  status: ReconciliationStatus;
  totalAmount?: Maybe<Scalars['BigDecimal']['output']>;
};

export type ReconciliationResult = {
  __typename?: 'ReconciliationResult';
  items: Array<ReconciliationItem>;
  reason?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
  treatedCount: Scalars['Int']['output'];
};

export enum ReconciliationStatus {
  AlreadyCompleted = 'ALREADY_COMPLETED',
  CommandNotFound = 'COMMAND_NOT_FOUND',
  CommandNotFoundDynamoSide = 'COMMAND_NOT_FOUND_DYNAMO_SIDE',
  CommandStatusError = 'COMMAND_STATUS_ERROR',
  ExceedingAmount = 'EXCEEDING_AMOUNT',
  Ignored = 'IGNORED',
  IncorrectAmount = 'INCORRECT_AMOUNT',
  PartialAmount = 'PARTIAL_AMOUNT',
  PaymentCreationError = 'PAYMENT_CREATION_ERROR',
  ReadyToBeSubmitted = 'READY_TO_BE_SUBMITTED',
  ReconciliationStatusUnspecified = 'RECONCILIATION_STATUS_UNSPECIFIED',
  SeveralCommands = 'SEVERAL_COMMANDS',
  Succeeded = 'SUCCEEDED',
  UnknownError = 'UNKNOWN_ERROR'
}

export type RedirectSimulationUserRequest = {
  captchaToken?: InputMaybe<Scalars['String']['input']>;
  emailAddress: Scalars['String']['input'];
};

export type RedirectSimulationUserResponse = {
  __typename?: 'RedirectSimulationUserResponse';
  migratedUser?: Maybe<Scalars['Boolean']['output']>;
  newUser?: Maybe<Scalars['Boolean']['output']>;
  redirectionUrl?: Maybe<Scalars['String']['output']>;
};

export enum RedirectUrlType {
  Absolute = 'ABSOLUTE',
  Inqom = 'INQOM',
  Relative = 'RELATIVE',
  Wedoofood = 'WEDOOFOOD',
  Wedoosite = 'WEDOOSITE'
}

export type RegularizationTopUp = {
  __typename?: 'RegularizationTopUp';
  amount?: Maybe<Money>;
  clientSecret?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  ephemeralKey?: Maybe<Scalars['String']['output']>;
  publishableKey?: Maybe<Scalars['String']['output']>;
};

export type RegularizeTokenizedCardInput = {
  beneficiaryUuid?: InputMaybe<Scalars['String']['input']>;
  paymentIntent?: InputMaybe<Scalars['String']['input']>;
};

export type RemadeStatusFilter = {
  remade?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type RemoveLineInput = {
  /** Path of the concerned file */
  filePath: Scalars['String']['input'];
  /** Path of the concerned file */
  itemId: Scalars['String']['input'];
};

export type RemoveLineResponse = {
  __typename?: 'RemoveLineResponse';
  filePath: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type RemovePromoCodeInput = {
  /** Token associated with the order payment */
  token: Scalars['ID']['input'];
};

export type RemovePromoCodeResponse = {
  __typename?: 'RemovePromoCodeResponse';
  /** Reason why promo code was not removed */
  errorReason?: Maybe<Scalars['String']['output']>;
  /** Had promo codes been removed successfully */
  success: Scalars['Boolean']['output'];
};

export enum RightsOwnerField {
  FieldBirthdate = 'FIELD_BIRTHDATE',
  FieldRelationtype = 'FIELD_RELATIONTYPE',
  FieldUniondate = 'FIELD_UNIONDATE',
  FieldUnspecified = 'FIELD_UNSPECIFIED'
}

export enum RnvpErrorCode {
  RnvpErrorCodeAddress = 'RNVP_ERROR_CODE_ADDRESS',
  RnvpErrorCodeAddressNumber = 'RNVP_ERROR_CODE_ADDRESS_NUMBER',
  RnvpErrorCodeCountry = 'RNVP_ERROR_CODE_COUNTRY',
  RnvpErrorCodeHighSuggest = 'RNVP_ERROR_CODE_HIGH_SUGGEST',
  RnvpErrorCodeLowSuggest = 'RNVP_ERROR_CODE_LOW_SUGGEST',
  RnvpErrorCodeNoError = 'RNVP_ERROR_CODE_NO_ERROR',
  RnvpErrorCodeUndefined = 'RNVP_ERROR_CODE_UNDEFINED',
  RnvpErrorCodeZipCodeCity = 'RNVP_ERROR_CODE_ZIP_CODE_CITY'
}

export type RoleAdminSupportResponse = {
  __typename?: 'RoleAdminSupportResponse';
  roleName: Array<Scalars['String']['output']>;
};

export enum RoleName {
  Beneficiary = 'BENEFICIARY',
  Manager = 'MANAGER'
}

export type S3MetadataInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type SaveNewsletterCanvasInput = {
  campaignId: Scalars['String']['input'];
  canvas: Scalars['String']['input'];
  html: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
};

export type SaveNewsletterCanvasResult = Empty | NewsletterError;

export type SaveNewsletterRecipientTargetingRule = {
  __typename?: 'SaveNewsletterRecipientTargetingRule';
  targetingRule?: Maybe<NewsletterRecipientTargetingRule>;
};

export type SaveNewsletterRecipientTargetingRuleResult = NewsletterError | SaveNewsletterRecipientTargetingRule;

export type SdxData = {
  __typename?: 'SdxData';
  clientCode: Array<Scalars['String']['output']>;
  idContract: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Search = {
  compoundFilter?: InputMaybe<CompoundFilter>;
  csmLastName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  period?: InputMaybe<SearchPeriod>;
  remade?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type SearchBasicCompanyInfoRequest = {
  input: Scalars['String']['input'];
  prospectId?: InputMaybe<Scalars['ID']['input']>;
};

export type SearchExtendedSpfDataRequest = {
  input: Scalars['String']['input'];
};

export type SearchNewsletterUnsplashImagesResult = NewsletterError | NewsletterUnsplashSearchImages;

export type SearchPeriod = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum SearchStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum SearchType {
  Ceil = 'CEIL',
  Equal = 'EQUAL',
  Floor = 'FLOOR'
}

export type Secret = {
  __typename?: 'Secret';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
};

export type SegmentSubSegment = {
  __typename?: 'SegmentSubSegment';
  segment?: Maybe<CompanySegment>;
  subSegment?: Maybe<Array<SubSegment>>;
};

export type SendCommunicationTestMailResult = CommunicationError | Empty;

export type SendNewsletterCampaignInput = {
  campaignId: Scalars['String']['input'];
  orgaId: Scalars['String']['input'];
};

export type SendNewsletterCampaignResult = Empty | NewsletterError;

export type SendNewsletterTestRequestInput = {
  campaignId: Scalars['String']['input'];
  mail: Scalars['String']['input'];
  orgaId: Scalars['String']['input'];
};

export type SendNewsletterTestResult = Empty | NewsletterError;

export type ServiceFeeOutput = {
  __typename?: 'ServiceFeeOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type SetCommunicationPreferencesResult = CommunicationError | CommunicationPreference;

export type SetNewsletterRecipientScopeResult = NewsletterCampaignParameters | NewsletterError;

export type ShareResult = DistributionDetail | GiftError;

export type SharedDistribution = {
  __typename?: 'SharedDistribution';
  amount?: Maybe<Money>;
  canBeCancelled: Scalars['Boolean']['output'];
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  creationDate: Scalars['DateTime']['output'];
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  initiatedByAdmin: Scalars['Boolean']['output'];
  initiator?: Maybe<DistributionUserPreview>;
  reason: DistributionReason;
  receiver: DistributionUserPreview;
  walletName?: Maybe<Scalars['String']['output']>;
  walletType?: Maybe<WalletType>;
  zendeskTicketUrl?: Maybe<Scalars['String']['output']>;
};

export type SharedDistributionPage = PageResult & {
  __typename?: 'SharedDistributionPage';
  index: Scalars['Int']['output'];
  items: Array<SharedDistribution>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SharingConfig = {
  __typename?: 'SharingConfig';
  massSharingEnabled: Scalars['Boolean']['output'];
  sharingEnabled: Scalars['Boolean']['output'];
};

export type SharingConfiguration = {
  __typename?: 'SharingConfiguration';
  maximumAmount?: Maybe<Money>;
  minimumAmount?: Maybe<Money>;
};

export type Shipment = {
  __typename?: 'Shipment';
  carrier?: Maybe<Carrier>;
  deliveryDetails?: Maybe<DeliveryDetails>;
  orderId?: Maybe<Scalars['String']['output']>;
  packageId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ShipmentStatus>;
  trackingNumber?: Maybe<Scalars['String']['output']>;
  trackingUrl?: Maybe<Scalars['String']['output']>;
};

export enum ShipmentStatus {
  Canceled = 'CANCELED',
  DeliveryDone = 'DELIVERY_DONE',
  DeliveryError = 'DELIVERY_ERROR',
  DeliveryOngoing = 'DELIVERY_ONGOING',
  ExpeditionDone = 'EXPEDITION_DONE',
  ExternalError = 'EXTERNAL_ERROR',
  ForwardedPackage = 'FORWARDED_PACKAGE',
  ManufactureDone = 'MANUFACTURE_DONE',
  ManufactureError = 'MANUFACTURE_ERROR',
  ManufactureOngoing = 'MANUFACTURE_ONGOING',
  OrderInPackaging = 'ORDER_IN_PACKAGING',
  PackagingDone = 'PACKAGING_DONE',
  ReadyToShip = 'READY_TO_SHIP',
  ReturnOrderAfterDelivery = 'RETURN_ORDER_AFTER_DELIVERY',
  TechnicalError = 'TECHNICAL_ERROR',
  Undefined = 'UNDEFINED',
  Unknown = 'UNKNOWN'
}

export type ShippingFee = {
  __typename?: 'ShippingFee';
  amount: Money;
  shippingMode: ShippingMode;
};

export type ShippingFeesResponse = GiftError | Money;

export enum ShippingMode {
  Acknowledged = 'ACKNOWLEDGED',
  Email = 'EMAIL',
  NormalLetter = 'NORMAL_LETTER',
  NormalLetterForMulti = 'NORMAL_LETTER_FOR_MULTI',
  NormalLetterForPaper = 'NORMAL_LETTER_FOR_PAPER',
  Registered = 'REGISTERED',
  Tracked = 'TRACKED'
}

export type ShippingModeOption = {
  __typename?: 'ShippingModeOption';
  type: ShippingModeType;
};

export type ShippingModeOptionInput = {
  type: ShippingModeType;
};

export type ShippingModeOptionOutput = {
  __typename?: 'ShippingModeOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<ShippingModeType>;
};

export enum ShippingModeType {
  Beneficiaries = 'BENEFICIARIES',
  MonoSite = 'MONO_SITE',
  MultiSite = 'MULTI_SITE'
}

export type Shop = {
  __typename?: 'Shop';
  adminInformation?: Maybe<ShopAdmin>;
  backInStockDate?: Maybe<Scalars['DateTime']['output']>;
  cardType?: Maybe<CardType>;
  cardTypeWording?: Maybe<ShopCardTypeWording>;
  combinationType?: Maybe<ShopCombinationType>;
  conditionAttributes?: Maybe<ShopConditionAttributes>;
  conditions?: Maybe<Array<ShopConditionCategory>>;
  country?: Maybe<Scalars['String']['output']>;
  creationDate?: Maybe<Scalars['DateTime']['output']>;
  customDiscountTag?: Maybe<Scalars['String']['output']>;
  defaultMobilePriority?: Maybe<Scalars['Int']['output']>;
  defaultPriority?: Maybe<Scalars['Int']['output']>;
  descriptionsMarkdown?: Maybe<Array<Translation>>;
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated always null */
  favorite?: Maybe<Scalars['Boolean']['output']>;
  hasMainVoucher?: Maybe<Scalars['Boolean']['output']>;
  helpUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  /**  TODO : optimize with argument to load only categories of current wallet if needed */
  keywords?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  logoDescriptions?: Maybe<Array<Translation>>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  maxPrice?: Maybe<Money>;
  metaData?: Maybe<ShopMetaData>;
  minPrice?: Maybe<Money>;
  mobilePriority?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  /** @deprecated always null */
  novelty?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated always null */
  noveltyEndDate?: Maybe<Scalars['DateTime']['output']>;
  pictureBlockDesktopTabletUrl?: Maybe<Scalars['String']['output']>;
  pictureBlockMobileUrl?: Maybe<Scalars['String']['output']>;
  pictureLineDesktopUrl?: Maybe<Scalars['String']['output']>;
  pictureLineTabletUrl?: Maybe<Scalars['String']['output']>;
  pictureOfferwallDesktopUrl?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  purchaseMode?: Maybe<ShopPurchaseMode>;
  scheduledSoldOutEndDate?: Maybe<Scalars['LocalDate']['output']>;
  scheduledSoldOutStartDate?: Maybe<Scalars['LocalDate']['output']>;
  scheduledSoldOutType?: Maybe<SoldOutType>;
  shopCategories?: Maybe<Array<Category>>;
  /** @deprecated use conditions and conditionAttributes instead */
  shopConditionDescription?: Maybe<ShopConditionDescription>;
  shopSuggestions?: Maybe<Array<Shop>>;
  soldOut?: Maybe<Scalars['Boolean']['output']>;
  soldOutType?: Maybe<SoldOutType>;
  templateLayout?: Maybe<Scalars['String']['output']>;
  timesAndPricesMarkdown?: Maybe<Array<Translation>>;
  url?: Maybe<Scalars['String']['output']>;
  vatRate?: Maybe<Scalars['String']['output']>;
  visible?: Maybe<Scalars['Boolean']['output']>;
  voucherMobileAppEnabled?: Maybe<Scalars['Boolean']['output']>;
};


export type ShopConditionsArgs = {
  language?: InputMaybe<Language>;
  shopConditionsFilter?: InputMaybe<ShopConditionsFilter>;
};


export type ShopShopConditionDescriptionArgs = {
  language?: InputMaybe<Language>;
  shopConditionsFilter?: InputMaybe<ShopConditionsFilter>;
};

export type ShopAdmin = {
  __typename?: 'ShopAdmin';
  administrativeCity?: Maybe<Scalars['String']['output']>;
  autoActivableCards?: Maybe<Scalars['Boolean']['output']>;
  autoOrdering?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  lastPostPaidConsumedCardReportDate?: Maybe<Scalars['DateTime']['output']>;
  orderMode?: Maybe<OrderMode>;
  orderingPeriodicity?: Maybe<ShopOrderingPeriodicity>;
  phone?: Maybe<Scalars['String']['output']>;
  registrationNumber?: Maybe<Scalars['String']['output']>;
  vatNumber?: Maybe<Scalars['String']['output']>;
};

export type ShopBanner = Banner & {
  __typename?: 'ShopBanner';
  category?: Maybe<Category>;
  id: Scalars['Long']['output'];
  imageUrl: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  shop: Shop;
};

export enum ShopCardTypeWording {
  Ancv = 'ANCV',
  FuelCard = 'FUEL_CARD',
  GiftCard = 'GIFT_CARD',
  Manual = 'MANUAL',
  Multi = 'MULTI',
  Promo = 'PROMO',
  Subscription = 'SUBSCRIPTION',
  TirGroupe = 'TIR_GROUPE'
}

export enum ShopCombinationType {
  Combinable = 'COMBINABLE',
  NotCombinable = 'NOT_COMBINABLE',
  NoStock = 'NO_STOCK',
  RestrictedAmounts = 'RESTRICTED_AMOUNTS',
  StockAndRestricted = 'STOCK_AND_RESTRICTED',
  StockAndVariable = 'STOCK_AND_VARIABLE',
  StockAndVariableNotCombinable = 'STOCK_AND_VARIABLE_NOT_COMBINABLE',
  Variable = 'VARIABLE'
}

export type ShopConditionAttributes = {
  __typename?: 'ShopConditionAttributes';
  allAmounts?: Maybe<Scalars['Boolean']['output']>;
  availableImmediately?: Maybe<Scalars['Boolean']['output']>;
  availableInShop?: Maybe<Scalars['Boolean']['output']>;
  availableOnInternet?: Maybe<Scalars['Boolean']['output']>;
  availableOnSmartPhone?: Maybe<Scalars['Boolean']['output']>;
  cumulativeWithPromoCodes?: Maybe<Scalars['Boolean']['output']>;
  cumulativeWithPromotion?: Maybe<Scalars['Boolean']['output']>;
  externalBalanceUrl?: Maybe<Scalars['String']['output']>;
};

export type ShopConditionCategory = {
  __typename?: 'ShopConditionCategory';
  conditionDescriptions?: Maybe<Array<ShopConditionDescriptionAndIcon>>;
  title?: Maybe<Scalars['String']['output']>;
};

export type ShopConditionDescription = {
  __typename?: 'ShopConditionDescription';
  allAmounts?: Maybe<Scalars['Boolean']['output']>;
  availableImmediately?: Maybe<Scalars['Boolean']['output']>;
  availableInShop?: Maybe<Scalars['Boolean']['output']>;
  availableOnInternet?: Maybe<Scalars['Boolean']['output']>;
  availableOnSmartPhone?: Maybe<Scalars['Boolean']['output']>;
  conditions?: Maybe<Array<ShopConditionDescriptionAndIcon>>;
  cumulativeWithPromotion?: Maybe<Scalars['Boolean']['output']>;
  externalBalanceUrl?: Maybe<Scalars['String']['output']>;
  /** @deprecated use Shop.url instead */
  url?: Maybe<Scalars['String']['output']>;
};

export type ShopConditionDescriptionAndIcon = {
  __typename?: 'ShopConditionDescriptionAndIcon';
  description?: Maybe<Scalars['String']['output']>;
  iconPath?: Maybe<Scalars['String']['output']>;
};

export enum ShopConditionsFilter {
  CartSummary = 'CART_SUMMARY',
  ConditionMail = 'CONDITION_MAIL',
  ConditionValidation = 'CONDITION_VALIDATION',
  Full = 'FULL',
  OfferwallFilter = 'OFFERWALL_FILTER',
  Shop = 'SHOP'
}

export type ShopFee = {
  amount?: Maybe<Money>;
  amountRoundingMode?: Maybe<ShopFeeAmountRoundingMode>;
  billingMode: ShopFeeBillingMode;
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  percentage?: Maybe<Scalars['BigDecimal']['output']>;
  shippingMode?: Maybe<ShopFeeShippingMode>;
  shops?: Maybe<Array<Maybe<Shop>>>;
  type: ShopFeeType;
};

export enum ShopFeeAmountRoundingMode {
  Ceiling = 'CEILING',
  CentCeiling = 'CENT_CEILING',
  Floor = 'FLOOR',
  HalfUp = 'HALF_UP'
}

export enum ShopFeeBillingMode {
  Beneficiary = 'BENEFICIARY',
  Organization = 'ORGANIZATION'
}

export type ShopFeeOverrideRequest = {
  amount?: InputMaybe<MoneyInput>;
  amountRoundingMode?: InputMaybe<ShopFeeAmountRoundingMode>;
  billingMode: ShopFeeBillingMode;
  percentage?: InputMaybe<Scalars['BigDecimal']['input']>;
  shippingMode?: InputMaybe<ShopFeeShippingMode>;
};

export enum ShopFeeShippingMode {
  Acknowledged = 'ACKNOWLEDGED',
  NormalLetter = 'NORMAL_LETTER',
  Registered = 'REGISTERED',
  Tracked = 'TRACKED'
}

export enum ShopFeeType {
  ServiceFees = 'SERVICE_FEES',
  ShippingFees = 'SHIPPING_FEES'
}

export type ShopLabel = {
  __typename?: 'ShopLabel';
  code: Scalars['String']['output'];
  id: Scalars['Long']['output'];
  titles?: Maybe<Array<Translation>>;
};

export type ShopLabelStock = {
  __typename?: 'ShopLabelStock';
  label: ShopLabel;
  price: Money;
  quantity: Scalars['Int']['output'];
};

export type ShopMetaData = {
  __typename?: 'ShopMetaData';
  identityRequired?: Maybe<Scalars['Boolean']['output']>;
  noStock?: Maybe<NoStockShopMetaData>;
};

export enum ShopOrderingPeriodicity {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY'
}

export type ShopPackage = {
  __typename?: 'ShopPackage';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Long']['output'];
  name: Scalars['String']['output'];
  publicNames?: Maybe<Array<Translation>>;
  shopCount: Scalars['Long']['output'];
  type: ShopPackageType;
};

export type ShopPackagePage = PageResult & {
  __typename?: 'ShopPackagePage';
  index: Scalars['Int']['output'];
  items: Array<ShopPackage>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export enum ShopPackageType {
  Category = 'CATEGORY',
  ClientSpecificity = 'CLIENT_SPECIFICITY',
  Default = 'DEFAULT',
  Event = 'EVENT',
  GoodDeals = 'GOOD_DEALS',
  Grant = 'GRANT',
  UniqueBrand = 'UNIQUE_BRAND'
}

export enum ShopPurchaseMode {
  Amount = 'AMOUNT',
  Quantity = 'QUANTITY'
}

export type SignaturesListOption = {
  __typename?: 'SignaturesListOption';
  type: SignaturesListType;
};

export type SignaturesListOptionInput = {
  type: SignaturesListType;
};

export type SignaturesListOptionOutput = {
  __typename?: 'SignaturesListOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<SignaturesListType>;
};

export enum SignaturesListType {
  Nominal = 'NOMINAL',
  Simple = 'SIMPLE'
}

export type SimulationOptions = {
  __typename?: 'SimulationOptions';
  messageOption?: Maybe<MessageOption>;
  nominalChecksOption?: Maybe<NominalChecksOption>;
  packaging: PaperOrderChequePackaging;
  shippingModeOption?: Maybe<ShippingModeOption>;
  signaturesListOption?: Maybe<SignaturesListOption>;
};

export type SingleUseToken = {
  __typename?: 'SingleUseToken';
  clientId: Scalars['String']['output'];
  creationDate: Scalars['String']['output'];
  expirationDate: Scalars['String']['output'];
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export enum SingleUseTokenError {
  SsoFailToken = 'SSO_FAIL_TOKEN'
}

export type SingleUseTokenErrorResponse = {
  __typename?: 'SingleUseTokenErrorResponse';
  error?: Maybe<SingleUseTokenError>;
};

export type SingleUseTokenResponse = SingleUseToken | SingleUseTokenErrorResponse;

export type SiteError = {
  __typename?: 'SiteError';
  description?: Maybe<Scalars['String']['output']>;
  errorCodes: Array<Scalars['String']['output']>;
};

export type SiteInfo = {
  __typename?: 'SiteInfo';
  beneficiaryUrl: Scalars['String']['output'];
  managerUrl: Scalars['String']['output'];
  orga: SiteOrganization;
  siteId: Scalars['ID']['output'];
  status: SiteStatus;
};

export type SiteOrganization = {
  __typename?: 'SiteOrganization';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export enum SiteStatus {
  Creating = 'CREATING',
  Deleted = 'DELETED',
  Error = 'ERROR',
  Na = 'NA',
  Ready = 'READY'
}

export type SiteTemplate = {
  __typename?: 'SiteTemplate';
  templateName: Scalars['ID']['output'];
};

export type SocieteInfoDataCompanyAutoCompleteResult = {
  __typename?: 'SocieteInfoDataCompanyAutoCompleteResult';
  fullAddress?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  societeInfoId?: Maybe<Scalars['String']['output']>;
};

export enum SoldOutType {
  Closed = 'CLOSED',
  InStock = 'IN_STOCK',
  NoStock = 'NO_STOCK',
  Unavailable = 'UNAVAILABLE'
}

export enum SortCredits {
  StartDateDesc = 'START_DATE_DESC',
  Unrecognized = 'UNRECOGNIZED',
  WeightDesc = 'WEIGHT_DESC'
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum SortFieldsBeneficiariesQuery {
  Email = 'EMAIL',
  Firstname = 'FIRSTNAME',
  Lastname = 'LASTNAME',
  Login = 'LOGIN'
}

export type SortInput = {
  direction: SortDirection;
  field: Scalars['String']['input'];
};

export type SpfAddressGroupExtendedResult = {
  __typename?: 'SpfAddressGroupExtendedResult';
  addressLineOne?: Maybe<Scalars['String']['output']>;
  addressLineTwo?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryIsoCode?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type SpfDataCompanyAutoCompleteResult = {
  __typename?: 'SpfDataCompanyAutoCompleteResult';
  fullAddress?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  siret?: Maybe<Scalars['String']['output']>;
  spfId?: Maybe<Scalars['String']['output']>;
};

export type SpfDataCompanyExtendedResult = {
  __typename?: 'SpfDataCompanyExtendedResult';
  addressGroup?: Maybe<SpfAddressGroupExtendedResult>;
  clientCode?: Maybe<Scalars['String']['output']>;
  orgaGroup?: Maybe<SpfOrgaGroupExtendedResult>;
  segmentGroup?: Maybe<SpfSegmentGroupExtendedResult>;
  userGroup?: Maybe<SpfUserGroupExtendedResult>;
};

export type SpfOrgaGroupExtendedResult = {
  __typename?: 'SpfOrgaGroupExtendedResult';
  identifier?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type SpfSegmentGroupExtendedResult = {
  __typename?: 'SpfSegmentGroupExtendedResult';
  budget?: Maybe<SubSegment>;
  segment?: Maybe<CompanySegment>;
  size?: Maybe<SubSegment>;
};

export type SpfUserGroupExtendedResult = {
  __typename?: 'SpfUserGroupExtendedResult';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type StandardCard = PurchasedCard & {
  __typename?: 'StandardCard';
  activationDate?: Maybe<Scalars['DateTime']['output']>;
  additionalInformation?: Maybe<AdditionalInformation>;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  codes?: Maybe<CardCodes>;
  consumptionDate: Scalars['DateTime']['output'];
  expeditionDate?: Maybe<Scalars['DateTime']['output']>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Long']['output'];
  locationUrlDownloadable: Scalars['Boolean']['output'];
  mailTracking?: Maybe<MailTracking>;
  manualConsumptionDate?: Maybe<Scalars['DateTime']['output']>;
  oppositionDate?: Maybe<Scalars['DateTime']['output']>;
  partnership: Scalars['Boolean']['output'];
  price: Money;
  purchasedCardActions: Array<PurchasedCardAction>;
  refundInitiator?: Maybe<Beneficiary>;
  refundReason?: Maybe<CardRefundReason>;
  serviceAmount?: Maybe<Money>;
  serviceFunded: Scalars['Boolean']['output'];
  shippingAddress?: Maybe<GiftAddress>;
  shippingAmount?: Maybe<Money>;
  shippingFunded: Scalars['Boolean']['output'];
  shop: Shop;
  shopLabel?: Maybe<ShopLabel>;
  type: CardType;
  usages?: Maybe<CardUsages>;
};

export type StatusAdditionalData = ArchiveReason;

export type StatusPeriodFilter = {
  period?: InputMaybe<SearchPeriod>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** Object used for mandatory string fields that may be patched or not by an update mutation. */
export type StringPatch = {
  value: Scalars['String']['input'];
};

export type SubSegment = {
  __typename?: 'SubSegment';
  endRange?: Maybe<Scalars['Int']['output']>;
  startRange?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<SubSegmentUnit>;
};

export enum SubSegmentUnit {
  Employee = 'EMPLOYEE',
  Eur = 'EUR',
  Unknown = 'UNKNOWN'
}

export type SubmitPromoCodeInput = {
  /** Promo code to apply on the order */
  promoCode: Scalars['String']['input'];
  /** Token associated with the order payment */
  token: Scalars['ID']['input'];
};

export type SubmitPromoCodeResponse = {
  __typename?: 'SubmitPromoCodeResponse';
  /** The discounted amounts of the order */
  discountedOrderAmount?: Maybe<BillingOrderAmount>;
  /** Invalid promo code reason in case invalid promo code */
  invalidPromoCodeReason?: Maybe<Scalars['String']['output']>;
  /** Order number associated with the submitted promo Code */
  orderId: Scalars['ID']['output'];
  /** Promo code submitted */
  promoCode: Scalars['String']['output'];
  /** true if promo code is valid, else false */
  validPromoCode: Scalars['Boolean']['output'];
};

export type SubscriptionRequest = {
  functionalModule: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};

export type SubscriptionRequests = {
  subscriptions: Array<SubscriptionRequest>;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  PendingAcceptance = 'PENDING_ACCEPTANCE',
  PendingActivation = 'PENDING_ACTIVATION',
  Suspended = 'SUSPENDED'
}

export type SummaryVoucherOptionOutput = {
  __typename?: 'SummaryVoucherOptionOutput';
  amounts?: Maybe<Amount>;
  quantity?: Maybe<Scalars['Int']['output']>;
};

export type SupportRole = {
  __typename?: 'SupportRole';
  canAssociate: Scalars['Boolean']['output'];
  canDissociate: Scalars['Boolean']['output'];
  displayableName: Scalars['String']['output'];
  technicalName: Scalars['String']['output'];
};

export type SurveyAnswer = {
  __typename?: 'SurveyAnswer';
  answerId?: Maybe<Scalars['String']['output']>;
  inputId?: Maybe<Scalars['String']['output']>;
};

export type Task = {
  __typename?: 'Task';
  currentStep?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  result?: Maybe<TaskResult>;
  resultType?: Maybe<TaskResultType>;
  status: TaskStatus;
  totalSteps?: Maybe<Scalars['Int']['output']>;
  type: Scalars['String']['output'];
};

export type TaskResult = {
  __typename?: 'TaskResult';
  contentType?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['Base64EncodedBinary']['output']>;
};

export enum TaskResultType {
  Csv = 'CSV',
  Exception = 'EXCEPTION',
  Object = 'OBJECT',
  Pdf = 'PDF',
  Unknown = 'UNKNOWN',
  Xlsx = 'XLSX',
  Zip = 'ZIP'
}

export enum TaskStatus {
  Completed = 'COMPLETED',
  Executing = 'EXECUTING',
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Rejected = 'REJECTED',
  Starting = 'STARTING',
  Unknown = 'UNKNOWN'
}

export enum TermType {
  Evergreen = 'EVERGREEN',
  Termed = 'TERMED'
}

/** ########### Inputs ############ */
export type TokenizedCardInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  setupIntentId?: InputMaybe<Scalars['String']['input']>;
  userUuid?: InputMaybe<Scalars['String']['input']>;
};

export type TokenizedCardTransactionInput = {
  amount?: InputMaybe<Scalars['BigDecimal']['input']>;
  cardToken?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type TopUpInformation = {
  __typename?: 'TopUpInformation';
  tokenizedCards?: Maybe<Array<Maybe<TopUpTokenizedCards>>>;
  topupStatusEnum?: Maybe<TopUpStatus>;
  unpaidAmount?: Maybe<Money>;
};

export type TopUpStatus = {
  __typename?: 'TopUpStatus';
  status?: Maybe<Scalars['String']['output']>;
};

export type TopUpStatusInput = {
  status?: InputMaybe<Scalars['String']['input']>;
};

export type TopUpTokenizedCards = {
  __typename?: 'TopUpTokenizedCards';
  expirationDate: Scalars['DateTime']['output'];
  hashedCode?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  lastDigitCard?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  userUuid?: Maybe<Scalars['String']['output']>;
};

export type TotalPricing = {
  __typename?: 'TotalPricing';
  preTaxAmount: Scalars['BigDecimal']['output'];
  taxAmount: Scalars['BigDecimal']['output'];
  totalAmount: Scalars['BigDecimal']['output'];
};

export enum TransactionType {
  BankCard = 'BANK_CARD',
  BankTransfer = 'BANK_TRANSFER',
  Virtual = 'VIRTUAL'
}

export type Translation = {
  __typename?: 'Translation';
  id: Scalars['Long']['output'];
  language: Language;
  text: Scalars['String']['output'];
};

export type TranslationInput = {
  id?: InputMaybe<Scalars['Long']['input']>;
  language: Language;
  text: Scalars['String']['input'];
};

export enum TwoFactorAuthenticationStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Pending = 'PENDING',
  TwoFactorApp = 'TWO_FACTOR_APP'
}

export type UiAdminParametersRights = {
  __typename?: 'UiAdminParametersRights';
  canAccessGlobalCaptchaUpdate: Scalars['Boolean']['output'];
  canAccessGlobalTwoFactorUpdate: Scalars['Boolean']['output'];
};

export type UiEditRightsOnBeneficiary = {
  __typename?: 'UiEditRightsOnBeneficiary';
  canAssociateRole: Scalars['Boolean']['output'];
  canDissociateRole: Scalars['Boolean']['output'];
  canEdit2FA: Scalars['Boolean']['output'];
  canEditActiveStatus: Scalars['Boolean']['output'];
  canEditArchivedStatus: Scalars['Boolean']['output'];
  canEditBlockedCause: Scalars['Boolean']['output'];
  canEditFirstConnectionStatus: Scalars['Boolean']['output'];
  canEditLanguage: Scalars['Boolean']['output'];
  canEditLockedStatus: Scalars['Boolean']['output'];
  canEditLogin: Scalars['Boolean']['output'];
  canEditMail: Scalars['Boolean']['output'];
  canEditName: Scalars['Boolean']['output'];
};


export type UiEditRightsOnBeneficiaryCanAssociateRoleArgs = {
  roleName: Scalars['String']['input'];
};


export type UiEditRightsOnBeneficiaryCanDissociateRoleArgs = {
  roleName: Scalars['String']['input'];
};

export type UiGiftActionRights = {
  __typename?: 'UiGiftActionRights';
  canBlockDistributionEmail: Scalars['Boolean']['output'];
  canEditOrganizationCampaigns: Scalars['Boolean']['output'];
  canEditOrganizationWalletGrants: Scalars['Boolean']['output'];
  canEditOrganizationWalletsAccess: Scalars['Boolean']['output'];
  canEditOrganizationWalletsSpecificConfiguration: Scalars['Boolean']['output'];
  canEditOverrideShopFees: Scalars['Boolean']['output'];
  canSeeOrganizationMonitoring: Scalars['Boolean']['output'];
  canSeeOrganizationWalletsAccess: Scalars['Boolean']['output'];
  canSeeOrganizationWalletsConfigurations: Scalars['Boolean']['output'];
  canSeeOverrideShopFees: Scalars['Boolean']['output'];
};

export type UiGiftActionRightsOnBeneficiary = {
  __typename?: 'UiGiftActionRightsOnBeneficiary';
  canCancelDistribution: Scalars['Boolean']['output'];
  canCancelMultiShopsCard: Scalars['Boolean']['output'];
  canCancelSharing: Scalars['Boolean']['output'];
  canEditPurchase: Scalars['Boolean']['output'];
  canRefundMulti: Scalars['Boolean']['output'];
  canRefundPurchase: Scalars['Boolean']['output'];
  canSeeCardAdminDetails: Scalars['Boolean']['output'];
  canSeeUserPurchaseCode: Scalars['Boolean']['output'];
  canShareDistribution: Scalars['Boolean']['output'];
  canTransferMultiShopsCardFunds: Scalars['Boolean']['output'];
  canUseMultiShopsCardActions: Scalars['Boolean']['output'];
};

export type UiImplemRights = {
  __typename?: 'UiImplemRights';
  canEditMigration: Scalars['Boolean']['output'];
  canSeeMigration: Scalars['Boolean']['output'];
};

export type UiRights = {
  __typename?: 'UiRights';
  canAccessAccountingAdmin: Scalars['Boolean']['output'];
  canAccessAdmin: Scalars['Boolean']['output'];
  canAccessAdminParameters: Scalars['Boolean']['output'];
  canAccessBilling: Scalars['Boolean']['output'];
  canAccessClients: Scalars['Boolean']['output'];
  canAccessGestionDistribution: Scalars['Boolean']['output'];
  canAccessImplem: Scalars['Boolean']['output'];
  canAccessManagerBridge: Scalars['Boolean']['output'];
  canAccessOldAdmin: Scalars['Boolean']['output'];
  canAccessSiteCreation: Scalars['Boolean']['output'];
  canAccessUserRoles: Scalars['Boolean']['output'];
  canAccessUsers: Scalars['Boolean']['output'];
  canAccessUsersResync: Scalars['Boolean']['output'];
};

export type UnblockPinQueryInput = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
};

export enum UnionType {
  Marriage = 'MARRIAGE',
  Pacs = 'PACS',
  Private = 'PRIVATE',
  Single = 'SINGLE',
  Unspecified = 'UNSPECIFIED'
}

export type Update2FaStatusRequest = {
  twoFAStatus?: InputMaybe<TwoFactorAuthenticationStatus>;
  userId: Scalars['ID']['input'];
};

export type UpdateAdditionalData = {
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAdditionalDataRequest = {
  additionalDatas?: InputMaybe<Array<InputMaybe<UpdateAdditionalData>>>;
  id: Scalars['ID']['input'];
  orgId: Scalars['ID']['input'];
};

export type UpdateAddressRequest = {
  addressLine1: Scalars['String']['input'];
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  countryIsoCode: Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

export type UpdateBankAccount = {
  accountName: Scalars['String']['input'];
  accountOwner: Scalars['String']['input'];
  defaultAccount?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};

export type UpdateBankAccountError = {
  __typename?: 'UpdateBankAccountError';
  type: UpdateBankAccountErrorType;
};

export enum UpdateBankAccountErrorType {
  AccountNameAlreadyExist = 'ACCOUNT_NAME_ALREADY_EXIST',
  Unknown = 'UNKNOWN'
}

export type UpdateBankAccountResponse = BankAccount | UpdateBankAccountError;

export enum UpdateBeneficiaryError {
  AlreadyBeneficiary = 'ALREADY_BENEFICIARY',
  BirthdateFormat = 'BIRTHDATE_FORMAT',
  BirthdateFuture = 'BIRTHDATE_FUTURE',
  CheckRightError = 'CHECK_RIGHT_ERROR',
  EmailFormat = 'EMAIL_FORMAT',
  EmailMandatory = 'EMAIL_MANDATORY',
  EmailSize = 'EMAIL_SIZE',
  FirstnameFormat = 'FIRSTNAME_FORMAT',
  FirstnameMandatory = 'FIRSTNAME_MANDATORY',
  FirstnameSize = 'FIRSTNAME_SIZE',
  GenderFormat = 'GENDER_FORMAT',
  LastnameFormat = 'LASTNAME_FORMAT',
  LastnameMandatory = 'LASTNAME_MANDATORY',
  LastnameSize = 'LASTNAME_SIZE',
  LocaleMandatory = 'LOCALE_MANDATORY',
  LoginUnavailable = 'LOGIN_UNAVAILABLE',
  MobilePhoneFormat = 'MOBILE_PHONE_FORMAT',
  MobilePhoneTooMuchDuplicates = 'MOBILE_PHONE_TOO_MUCH_DUPLICATES',
  SettingValueFormat = 'SETTING_VALUE_FORMAT',
  SettingValueMandatory = 'SETTING_VALUE_MANDATORY',
  Unknown = 'UNKNOWN',
  UnknownLocale = 'UNKNOWN_LOCALE',
  UnknownSetting = 'UNKNOWN_SETTING',
  UuidMandatory = 'UUID_MANDATORY'
}

export type UpdateBeneficiaryErrorResponse = {
  __typename?: 'UpdateBeneficiaryErrorResponse';
  error?: Maybe<UpdateBeneficiaryError>;
};

export type UpdateBeneficiaryPatch = {
  email: Scalars['String']['input'];
  externalRef: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  language: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UpdateBeneficiaryPatchV2 = {
  email?: InputMaybe<Scalars['String']['input']>;
  externalRef?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  isManager?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  login?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['ID']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateCampaignRequest = {
  discountRatio: Scalars['BigDecimal']['input'];
  endDate: Scalars['DateTime']['input'];
  reasonId: Scalars['Long']['input'];
};

export type UpdateCommunicationBeneficiaryViewStatusResult = CommunicationBeneficiaryViewStatus | CommunicationError;

export type UpdateCommunicationFileCampaignResult = CommunicationCampaign | CommunicationError;

export type UpdateCommunicationNewsCampaignResult = CommunicationCampaign | CommunicationError;

export type UpdateCommunicationSurveyCampaignResult = CommunicationCampaign | CommunicationError;

export type UpdateCreateAddressResponse = AuthenticatedUser | Organization;

export type UpdateCreateAuthenticatedUserAddressesRequest = {
  billingAddress?: InputMaybe<UpdateAddressRequest>;
  shippingAddress?: InputMaybe<UpdateAddressRequest>;
};

export type UpdateCreateOrganizationAddressRequest = {
  additionalAttachment?: InputMaybe<Array<AssociationType>>;
  additionalDetachment?: InputMaybe<Array<AssociationType>>;
  address: UpdateAddressRequest;
  associationType: AssociationType;
  attachmentId?: InputMaybe<Scalars['ID']['input']>;
  organizationId: Scalars['ID']['input'];
};

export type UpdateCreateOrganizationAddressesRequest = {
  billingAddress?: InputMaybe<UpdateAddressRequest>;
  organizationId: Scalars['ID']['input'];
  shippingAddress?: InputMaybe<UpdateAddressRequest>;
};

export type UpdateCustomerCardQueryInput = {
  beneficiaryId: Scalars['ID']['input'];
  cardId: Scalars['ID']['input'];
  contactlessPayment: Scalars['Boolean']['input'];
  isBlockingCard?: InputMaybe<Scalars['Boolean']['input']>;
  isContactLessChange?: InputMaybe<Scalars['Boolean']['input']>;
  isLockUnlockCard?: InputMaybe<Scalars['Boolean']['input']>;
  isOnlinePaiementChange?: InputMaybe<Scalars['Boolean']['input']>;
  status: CardStatusType;
};

export type UpdateGlobalParametersValueResponse = {
  __typename?: 'UpdateGlobalParametersValueResponse';
  updatedValue: Scalars['Boolean']['output'];
};

export type UpdateLogoError = {
  __typename?: 'UpdateLogoError';
  type: UpdateLogoErrorType;
};

export enum UpdateLogoErrorType {
  UnsupportedImageSize = 'UNSUPPORTED_IMAGE_SIZE',
  UnsupportedImageType = 'UNSUPPORTED_IMAGE_TYPE'
}

export type UpdateMailLogo = {
  organizationId: Scalars['ID']['input'];
  presignedUrl?: InputMaybe<CreatedPresignedUrl>;
};

export type UpdateOrderAmountRequest = {
  amount?: InputMaybe<MoneyInput>;
  orderId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateOrderAmountResponse = {
  __typename?: 'UpdateOrderAmountResponse';
  orderId?: Maybe<Scalars['String']['output']>;
};

export type UpdateOrganizationMailLogoError = {
  __typename?: 'UpdateOrganizationMailLogoError';
  type: UpdateOrganizationMailLogoErrorType;
};

export enum UpdateOrganizationMailLogoErrorType {
  UnsupportedImageSize = 'UNSUPPORTED_IMAGE_SIZE',
  UnsupportedImageType = 'UNSUPPORTED_IMAGE_TYPE'
}

export type UpdateOrganizationMailLogoResult = Organization | UpdateLogoError | UpdateOrganizationMailLogoError;

/** Fields to patch in the organization. If null is given to (Nullable)StringPatch value, it means it should not be updated. */
export type UpdateOrganizationPatch = {
  /** The account organization name */
  accountName?: InputMaybe<StringPatch>;
  budget?: InputMaybe<UpdateSubSegment>;
  /** The national identifiers of the organization. */
  companyIdentifiers?: InputMaybe<Array<CompanyIdentifier>>;
  /** The generic email for contacting the organization and sending invoices to. */
  genericEmail?: InputMaybe<StringPatch>;
  /** The generic phone number for contacting the organization. */
  genericPhoneNumber?: InputMaybe<NullableStringPatch>;
  id: Scalars['ID']['input'];
  /** The national identifier of the organization. */
  identifier?: InputMaybe<StringPatch>;
  /** The default locale of the organization. */
  locale?: InputMaybe<StringPatch>;
  mainManagerId?: InputMaybe<Scalars['ID']['input']>;
  /** The organizationName */
  name?: InputMaybe<StringPatch>;
  sdxData?: InputMaybe<CompanySdxData>;
  segment?: InputMaybe<CompanySegment>;
  size?: InputMaybe<UpdateSubSegment>;
  /** the status id of an organization */
  status?: InputMaybe<Scalars['ID']['input']>;
  subSegment?: InputMaybe<UpdateSubSegment>;
};

export type UpdatePersonalInformationPatch = {
  birthdate?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  language: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export type UpdatePreferenceLogo = {
  preferenceId: Scalars['ID']['input'];
  presignedUrl: CreatedPresignedUrl;
};

export type UpdatePreferenceLogoResult = Preference | UpdateLogoError;

export type UpdatePreferenceRequest = {
  font?: InputMaybe<Scalars['String']['input']>;
  frontUrl: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<Scalars['String']['input']>;
  secondaryColor?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProspectDraftRequest = {
  addressLineOne?: InputMaybe<Scalars['String']['input']>;
  addressLineTwo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  clientCode?: InputMaybe<Scalars['String']['input']>;
  companyIdentifier?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailSend?: InputMaybe<Scalars['Boolean']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastname?: InputMaybe<Scalars['String']['input']>;
  paperGiftEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  prospectFunction?: InputMaybe<ProspectFunctionEnum>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<CompanySegment>;
  societeInfoId?: InputMaybe<Scalars['String']['input']>;
  subSegment?: InputMaybe<UpdateSubSegment>;
  survey?: InputMaybe<OrgAdminSurvey>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateProspectDraftRequestV2 = {
  addressLineOne?: InputMaybe<Scalars['String']['input']>;
  addressLineTwo?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyIdentifier?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailSend?: InputMaybe<Scalars['Boolean']['input']>;
  firstname?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastname?: InputMaybe<Scalars['String']['input']>;
  paperGiftEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  prospectFunction?: InputMaybe<ProspectFunctionEnum>;
  queryParams?: InputMaybe<Scalars['String']['input']>;
  segment?: InputMaybe<CompanySegment>;
  societeInfoId?: InputMaybe<Scalars['String']['input']>;
  subSegment?: InputMaybe<UpdateSubSegment>;
  zipCode?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateReasonRequest = {
  titles?: InputMaybe<Array<TranslationInput>>;
  visible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateSubSegment = {
  endRange?: InputMaybe<Scalars['Int']['input']>;
  startRange?: InputMaybe<Scalars['Int']['input']>;
  unit?: InputMaybe<SubSegmentUnit>;
};

export type UpdateUserBlockedCauseRequest = {
  blockedCause?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};

export type UpdateUserErrorResponse = {
  __typename?: 'UpdateUserErrorResponse';
  error?: Maybe<UpdateBeneficiaryError>;
};

export type UpdateUserRolesInput = {
  id: Scalars['ID']['input'];
  rolesToAdd: Array<Scalars['String']['input']>;
  rolesToRemove: Array<Scalars['String']['input']>;
};

export type UpdatedBeneficiaryResponse = Beneficiary | UpdateBeneficiaryErrorResponse;

export type UpdatedUserResponse = Beneficiary | UpdateUserErrorResponse;

export type UploadCommunicationFileResult = CommunicationError | CommunicationFile;

export type UploadCommunicationImageResult = CommunicationError | CommunicationImage;

export type UploadDocumentRequest = {
  id: Scalars['ID']['input'];
  identity?: InputMaybe<Array<InputMaybe<Scalars['Upload']['input']>>>;
  kbis?: InputMaybe<Scalars['Upload']['input']>;
};

export type UploadNewsletterImageResult = NewsletterError | NewsletterImageUploaded;

export enum UpsellRejectionCause {
  MaxAmount = 'MAX_AMOUNT',
  MinAmount = 'MIN_AMOUNT',
  UpsellNotPermitted = 'UPSELL_NOT_PERMITTED'
}

export type UrssafEvent = {
  __typename?: 'UrssafEvent';
  type: UrssafEventType;
};

export enum UrssafEventType {
  AdultChristmas = 'ADULT_CHRISTMAS',
  BackToSchool = 'BACK_TO_SCHOOL',
  BirthOrAdoption = 'BIRTH_OR_ADOPTION',
  ChildChristmas = 'CHILD_CHRISTMAS',
  Culture = 'CULTURE',
  FathersDay = 'FATHERS_DAY',
  MothersDay = 'MOTHERS_DAY',
  Other = 'OTHER',
  Retirement = 'RETIREMENT',
  SaintCatherine = 'SAINT_CATHERINE',
  SaintNicolas = 'SAINT_NICOLAS',
  Unrecognized = 'UNRECOGNIZED',
  WeddingOrPacs = 'WEDDING_OR_PACS'
}

export enum UserBiometryStatus {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type UserBiometryStatusRequest = {
  userBiometryStatus: UserBiometryStatus;
};

export type UserDistribution = GenericDistribution & {
  __typename?: 'UserDistribution';
  amount: Money;
  campaignStatus: CampaignStatus;
  cancellationDate?: Maybe<Scalars['DateTime']['output']>;
  creationDate: Scalars['DateTime']['output'];
  /**  can be null if campaign is planned */
  detail?: Maybe<DistributionDetail>;
  endDate: Scalars['DateTime']['output'];
  id: Scalars['Long']['output'];
  massSharingEnabled: Scalars['Boolean']['output'];
  parentDistribution?: Maybe<GenericDistribution>;
  reason: DistributionReason;
  userPreview: DistributionUserPreview;
  wallet: Wallet;
};

export type UserEmail = {
  email: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  notifyByEmail: Scalars['Boolean']['input'];
};

export type UserFound = {
  __typename?: 'UserFound';
  id: Scalars['ID']['output'];
  login?: Maybe<Scalars['String']['output']>;
};

export type UserInfo = {
  firstName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  lastName: Scalars['String']['input'];
};

export type UserLanguage = {
  id: Scalars['ID']['input'];
  language: Scalars['String']['input'];
};

export type UserListStatus = {
  blockedCause?: InputMaybe<Scalars['String']['input']>;
  logins: Array<InputMaybe<Scalars['String']['input']>>;
  status: Scalars['String']['input'];
  statusReason?: InputMaybe<Scalars['String']['input']>;
};

export type UserLogin = {
  id: Scalars['ID']['input'];
  login: Scalars['String']['input'];
};

export type UserOrganizationSpecificData = {
  __typename?: 'UserOrganizationSpecificData';
  /** @deprecated Use additionalDataForManager & additionalDataForBeneficiary instead */
  additionalData?: Maybe<Array<AdditionalData>>;
  additionalDataForBeneficiary?: Maybe<Array<AdditionalData>>;
  additionalDataForManager?: Maybe<Array<AdditionalData>>;
  externalReference?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  tags?: Maybe<Array<Scalars['String']['output']>>;
};

export type UserRights = {
  __typename?: 'UserRights';
  canCancelOrder?: Maybe<Scalars['Boolean']['output']>;
  canCreateOrder?: Maybe<Scalars['Boolean']['output']>;
};

export type UserRightsRequest = {
  organizationId?: InputMaybe<Scalars['String']['input']>;
};

export type UserRole = {
  __typename?: 'UserRole';
  name: Scalars['String']['output'];
  organization?: Maybe<Organization>;
};

export type UserStatus = {
  id: Scalars['ID']['input'];
  status: Scalars['String']['input'];
  statusReason?: InputMaybe<Scalars['String']['input']>;
};

export type UsersListByLoginQueryInput = {
  /** A string to filter the users with login */
  logins: Array<Scalars['String']['input']>;
};

export type UsersListByLoginResult = {
  __typename?: 'UsersListByLoginResult';
  items: Array<Maybe<UserFound>>;
};

export type UsersPage = {
  __typename?: 'UsersPage';
  index: Scalars['Int']['output'];
  items: Array<Maybe<Beneficiary>>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type UsersQueryInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  pageIndex: Scalars['Int']['input'];
  pageSize: Scalars['Int']['input'];
  /** Filter by role name */
  roleName?: InputMaybe<RoleName>;
  /** A string to filter the users with names, login, uuid, emails matching. */
  search?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateCompanyNameRequest = {
  companyName: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateCompanyNameUpdateRequest = {
  companyName: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type ValidateIdentifierRequest = {
  countryIsoCode: Scalars['String']['input'];
  identifier: Scalars['String']['input'];
};

export type ValidatePaymentInput = {
  /** Asynchronous payment method */
  asyncPaymentMethod: AsyncPaymentMethod;
  /** Token associated with the payment */
  token: Scalars['ID']['input'];
};

export type ValidatePaymentResponse = {
  __typename?: 'ValidatePaymentResponse';
  /** Failure message in case payment validation failed */
  failureMessage?: Maybe<Scalars['String']['output']>;
  /** The order number associated with the payment */
  orderId?: Maybe<Scalars['String']['output']>;
  /** true if the payment is validated, else false */
  paymentValidated: Scalars['Boolean']['output'];
};

export type VoucherFile = {
  __typename?: 'VoucherFile';
  content: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  filename: Scalars['String']['output'];
};

export type VoucherFileResult = AccountingError | VoucherFile;

/** deprecated */
export type WaitForPaymentRequest = {
  /** deprecated */
  billingOrder?: InputMaybe<Scalars['Boolean']['input']>;
  orderId: Scalars['String']['input'];
  shippingAddressId: Scalars['String']['input'];
};

/** deprecated */
export type WaitForPaymentResponse = {
  __typename?: 'WaitForPaymentResponse';
  giftOrderId?: Maybe<Scalars['Long']['output']>;
  orderId: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  shippingAddressId: Scalars['String']['output'];
};

export type Wallet = {
  __typename?: 'Wallet';
  acceptFunding: Scalars['Boolean']['output'];
  active: Scalars['Boolean']['output'];
  /** @deprecated not mapped most of the time */
  available: Scalars['Boolean']['output'];
  countryIso3Code: Scalars['String']['output'];
  customReasonAllowed: Scalars['Boolean']['output'];
  defaultConfigurations?: Maybe<WalletConfigurationPage>;
  descriptions?: Maybe<Array<Translation>>;
  disabledWhenNoBalance: Scalars['Boolean']['output'];
  /** @deprecated use distributionAvailable */
  distributionAllowed: Scalars['Boolean']['output'];
  distributionAvailable: Scalars['Boolean']['output'];
  hidden: Scalars['Boolean']['output'];
  id: Scalars['Long']['output'];
  internalDescription: Scalars['String']['output'];
  internalName: Scalars['String']['output'];
  names?: Maybe<Array<Translation>>;
  organizationOverride?: Maybe<OrganizationWalletOverride>;
  priority?: Maybe<Scalars['Int']['output']>;
  sharingAvailable: Scalars['Boolean']['output'];
  /** @deprecated always false */
  shoppingDiscount: Scalars['Boolean']['output'];
  specificConfigurations?: Maybe<WalletConfigurationPage>;
  tutorials?: Maybe<Array<Translation>>;
  type: WalletType;
  /** @deprecated always true */
  upsalePossible: Scalars['Boolean']['output'];
  usedInDistributionCampaigns: Scalars['Boolean']['output'];
  usedInGrants: Scalars['Boolean']['output'];
};


export type WalletDefaultConfigurationsArgs = {
  organizationId?: InputMaybe<Scalars['ID']['input']>;
  request: PageRequest;
};


export type WalletSpecificConfigurationsArgs = {
  organizationId: Scalars['ID']['input'];
  request: PageRequest;
};


export type WalletUsedInDistributionCampaignsArgs = {
  organizationId: Scalars['ID']['input'];
};


export type WalletUsedInGrantsArgs = {
  organizationId: Scalars['ID']['input'];
};

export type WalletConfiguration = {
  __typename?: 'WalletConfiguration';
  accessible: Scalars['Boolean']['output'];
  discount: Scalars['Float']['output'];
  id: Scalars['Long']['output'];
  shopPackage: ShopPackage;
};

export type WalletConfigurationPage = PageResult & {
  __typename?: 'WalletConfigurationPage';
  index: Scalars['Int']['output'];
  items: Array<WalletConfiguration>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type WalletHighlightSection = {
  __typename?: 'WalletHighlightSection';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shops: Array<Scalars['Long']['output']>;
};

export type WalletPage = PageResult & {
  __typename?: 'WalletPage';
  index: Scalars['Int']['output'];
  items: Array<Wallet>;
  size: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export enum WalletSorting {
  Alphabetical = 'ALPHABETICAL',
  DiscountRate = 'DISCOUNT_RATE',
  Relevance = 'RELEVANCE',
  ReverseAlphabetical = 'REVERSE_ALPHABETICAL',
  SmallestAmount = 'SMALLEST_AMOUNT'
}

export type WalletSpecificConfiguration = {
  __typename?: 'WalletSpecificConfiguration';
  accessible: Scalars['Boolean']['output'];
  discount: Scalars['Float']['output'];
  id: Scalars['Long']['output'];
  organization: Organization;
  shopPackage: ShopPackage;
  wallet: Wallet;
};

export type WalletSpecificConfigurationRequest = {
  accessible: Scalars['Boolean']['input'];
  discount: Scalars['BigDecimal']['input'];
  shopPackageId: Scalars['Long']['input'];
};

export enum WalletType {
  CultureCard = 'CULTURE_CARD',
  CultureTicket = 'CULTURE_TICKET',
  Discount = 'DISCOUNT',
  GiftCard = 'GIFT_CARD',
  MovieTicket = 'MOVIE_TICKET',
  ParkTicket = 'PARK_TICKET',
  Partnership = 'PARTNERSHIP',
  RecreationTicket = 'RECREATION_TICKET',
  TripAndHoliday = 'TRIP_AND_HOLIDAY'
}

export type WedoositeInfo = {
  __typename?: 'WedoositeInfo';
  beneficiaryUrl?: Maybe<Scalars['String']['output']>;
  managerUrl?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
};

export type RemovePromoCodeMutationVariables = Exact<{
  input: RemovePromoCodeInput;
}>;


export type RemovePromoCodeMutation = { __typename?: 'Mutation', removePromoCode: { __typename?: 'RemovePromoCodeResponse', success: boolean, errorReason?: string | null } };

export type SubmitPromoCodeMutationVariables = Exact<{
  input: SubmitPromoCodeInput;
}>;


export type SubmitPromoCodeMutation = { __typename?: 'Mutation', submitPromoCode: { __typename?: 'SubmitPromoCodeResponse', orderId: string, promoCode: string, validPromoCode: boolean, invalidPromoCodeReason?: string | null, discountedOrderAmount?: { __typename?: 'BillingOrderAmount', excludingTaxAmount: number, taxAmount: number, includingTaxAmount: number, discountAmount?: number | null } | null } };

export type ValidatePaymentMutationVariables = Exact<{
  input: ValidatePaymentInput;
}>;


export type ValidatePaymentMutation = { __typename?: 'Mutation', validatePayment: { __typename?: 'ValidatePaymentResponse', orderId?: string | null, paymentValidated: boolean, failureMessage?: string | null } };

export type InvoiceQueryVariables = Exact<{
  invoiceId?: InputMaybe<Scalars['ID']['input']>;
  orderId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type InvoiceQuery = { __typename?: 'Query', invoice: { __typename?: 'Invoice', body: string } };

export type OrderQueryVariables = Exact<{
  token: Scalars['ID']['input'];
}>;


export type OrderQuery = { __typename?: 'Query', order: { __typename?: 'BillingOrder', orderId: string, organizationId: string, enablePromoCode: boolean, successUrl?: string | null, promoCode?: string | null, productSkuType: ProductSkuType, journeyName: string, journeyUrl?: string | null, amount: { __typename?: 'BillingOrderAmount', excludingTaxAmount: number, taxAmount: number, includingTaxAmount: number, discountAmount?: number | null, excludingTaxDiscountAmount?: number | null, discountTaxAmount?: number | null }, paymentMethods: Array<{ __typename?: 'BillingPaymentMethod', type: PaymentMethodType, maxAmount?: number | null }>, billingAddress: { __typename?: 'BillingAddress', recipient: string, addressLine1: string, addressLine2?: string | null, zipCode: string, city: string, countryIsoCode: string, label?: string | null }, items: Array<{ __typename?: 'BillingOrderItem', label: string, description?: string | null, totalAmount: number, checkType?: CheckType | null, businessVolumeType?: BusinessVolumeType | null, transactionalCode: string }>, discounts?: Array<{ __typename?: 'DiscountItem', description: string, discountTaxAmount: number, discountType: string, discountValue: number, discountWithTax: number, discountWithoutTax: number, promoCode: string, transactionalCode: string }> | null } };

export type OrderIdQueryVariables = Exact<{
  token: Scalars['ID']['input'];
}>;


export type OrderIdQuery = { __typename?: 'Query', order: { __typename?: 'BillingOrder', orderId: string } };

export type PaymentIntentSecretQueryVariables = Exact<{
  token: Scalars['ID']['input'];
}>;


export type PaymentIntentSecretQuery = { __typename?: 'Query', paymentIntentSecret: { __typename?: 'PaymentIntentSecret', clientSecret: string } };

export const RemovePromoCodeDocument = gql`
    mutation RemovePromoCode($input: RemovePromoCodeInput!) {
  removePromoCode(input: $input) {
    success
    errorReason
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemovePromoCodeGQL extends Apollo.Mutation<RemovePromoCodeMutation, RemovePromoCodeMutationVariables> {
    override document = RemovePromoCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SubmitPromoCodeDocument = gql`
    mutation SubmitPromoCode($input: SubmitPromoCodeInput!) {
  submitPromoCode(input: $input) {
    orderId
    promoCode
    validPromoCode
    discountedOrderAmount {
      excludingTaxAmount
      taxAmount
      includingTaxAmount
      discountAmount
    }
    invalidPromoCodeReason
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SubmitPromoCodeGQL extends Apollo.Mutation<SubmitPromoCodeMutation, SubmitPromoCodeMutationVariables> {
    override document = SubmitPromoCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidatePaymentDocument = gql`
    mutation ValidatePayment($input: ValidatePaymentInput!) {
  validatePayment(input: $input) {
    orderId
    paymentValidated
    failureMessage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidatePaymentGQL extends Apollo.Mutation<ValidatePaymentMutation, ValidatePaymentMutationVariables> {
    override document = ValidatePaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceDocument = gql`
    query Invoice($invoiceId: ID, $orderId: ID) {
  invoice(invoiceId: $invoiceId, orderId: $orderId) {
    body
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceGQL extends Apollo.Query<InvoiceQuery, InvoiceQueryVariables> {
    override document = InvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderDocument = gql`
    query Order($token: ID!) {
  order(token: $token) {
    orderId
    organizationId
    amount {
      excludingTaxAmount
      taxAmount
      includingTaxAmount
      discountAmount
      excludingTaxDiscountAmount
      discountTaxAmount
    }
    enablePromoCode
    successUrl
    paymentMethods {
      type
      maxAmount
    }
    billingAddress {
      recipient
      addressLine1
      addressLine2
      zipCode
      city
      countryIsoCode
      label
    }
    items {
      label
      description
      totalAmount
      checkType
      businessVolumeType
      transactionalCode
    }
    discounts {
      description
      discountTaxAmount
      discountType
      discountValue
      discountWithTax
      discountWithoutTax
      promoCode
      transactionalCode
    }
    promoCode
    productSkuType
    journeyName
    journeyUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderGQL extends Apollo.Query<OrderQuery, OrderQueryVariables> {
    override document = OrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderIdDocument = gql`
    query OrderId($token: ID!) {
  order(token: $token) {
    orderId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderIdGQL extends Apollo.Query<OrderIdQuery, OrderIdQueryVariables> {
    override document = OrderIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PaymentIntentSecretDocument = gql`
    query PaymentIntentSecret($token: ID!) {
  paymentIntentSecret(token: $token) {
    clientSecret
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PaymentIntentSecretGQL extends Apollo.Query<PaymentIntentSecretQuery, PaymentIntentSecretQueryVariables> {
    override document = PaymentIntentSecretDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }