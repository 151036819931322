import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { PaymentIntentSecretGQL } from '../../generated/types';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private readonly stripeErrorMessageBehaviorSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    'une erreur est survenue.',
  );

  constructor(private paymentIntentSecretGQL: PaymentIntentSecretGQL) {}

  getPaymentIntentSecret(token: string): Observable<string> {
    return this.paymentIntentSecretGQL
      .fetch({ token }, { fetchPolicy: 'no-cache' })
      .pipe(map((v) => v.data.paymentIntentSecret.clientSecret));
  }

  getCurrentStripeErrorMessage(): Observable<string> {
    return this.stripeErrorMessageBehaviorSubject.asObservable();
  }

  updateStripeErrorMessage(value: string): void {
    this.stripeErrorMessageBehaviorSubject.next(value);
  }
}
