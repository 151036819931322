import { Component, input, OnInit } from '@angular/core';
import { BillingOrder, BillingOrderItem, BusinessVolumeType } from '../../../../generated/types';
import { OrderDetailsOpenButtonComponent } from '../order-details-open-button/order-details-open-button.component';
import { TranslocoCurrencyPipe } from '@jsverse/transloco-locale';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'app-order-summary-card',
  standalone: true,
  imports: [OrderDetailsOpenButtonComponent, TranslocoCurrencyPipe, TranslocoDirective],
  templateUrl: './order-summary-card.component.html',
  styleUrl: './order-summary-card.component.scss',
})
export class OrderSummaryCardComponent implements OnInit {
  order = input.required<BillingOrder>();
  billingOrderGifts?: BillingOrderItem[];
  billingOrderCultures?: BillingOrderItem[];
  billingOrderFood?: BillingOrderItem[];
  billingOrderOptions?: BillingOrderItem[];

  constructor() {}

  ngOnInit(): void {
    this.billingOrderGifts = this.order().items.filter((o) => o.businessVolumeType === BusinessVolumeType.Gift);
    this.billingOrderCultures = this.order().items.filter((o) => o.businessVolumeType === BusinessVolumeType.Culture);
    this.billingOrderFood = this.order().items.filter((o) => o.businessVolumeType === BusinessVolumeType.Food);
    this.billingOrderOptions = this.order().items.filter(
      (o) => o.businessVolumeType === BusinessVolumeType.NotApplicable,
    );
  }

  countOrderItems(): number {
    return this.order().items.length;
  }

  getAmount(items: BillingOrderItem[]): number {
    return items.map((o) => o.totalAmount).reduce((sum, current) => sum + current, 0);
  }
}
